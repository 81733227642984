<template>
   <b-container>
      <h1 class="mb-3">Create Project Questionnaire</h1>

      <h3>Study</h3>

      <StudySelect class="mb-3" allow-none />

      <b-alert :show="!activeStudyId" variant="primary">
         Select the study you want to create a questionnaire for.
      </b-alert>

      <QuestionnaireCreateForm
         v-if="loaded && activeStudyId"
         :duplicate-titles="duplicateTitles"
         is-project
         @submit="submit"
      />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireCreateForm from './widgets/QuestionnaireCreateForm';
import StudySelect from '../company/widgets/StudySelect';

export default {
   components: {
      QuestionnaireCreateForm,
      StudySelect,
   },

   async created() {
      await this.fetchData();
   },

   data() {
      return {
         loaded: false,
         duplicateTitles: [],
      };
   },

   computed: {
      ...mapGetters({
         activeStudyId: 'companies/activeStudyId',
         hasOpenStudy: 'companies/hasOpenStudy',
         title: 'questionnaire/title',
      }),

      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      /** Load data required to define a project questionnaire */
      async fetchData() {
         this.$store.commit('questionnaire/clearQuestionnaire');

         const requests = [
            this.$store.dispatch('companies/loadCompany', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('projects/loadProjects', {
               companyId: this.companyId,
            }),
            this.$store.dispatch('industries/loadIndustries'),
            this.$store.dispatch('questionnaire/loadTemplates'),
         ];
         await this.blockUntilAllSettled(requests);

         // If the company has no active study, redirect to company detail
         if (!this.hasOpenStudy) {
            this.$router.push({name: 'company-detail', params: {id: this.companyId}});
         }

         this.loaded = true;
      },

      /**
       * Submit a request to create a project questionnaire
       * @param {Array} [titles] - An array of titles. If provided, a questionnaire will be created
       *                           with each title, using the same questions and configuration.
       */
      async submit(titles) {
         try {
            await this.blockingRequest('questionnaire/createProjectQuestionnaire', {titles});
            this.$router.push({name: 'company-detail', params: {id: this.companyId}});
         } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
               const error = err.response.data.errors[0];
               if (error.code == this.$errorCodes().DUPLICATE) {
                  this.duplicateTitles.push(this.title.toLowerCase());
               }
            }
         }
      },
   },
};
</script>

<template>
   <b-form @submit.prevent="submit">
      <b-form-group
         label="Business Component Name"
         label-for="input-project-name"
         invalid-feedback="Required"
      >
         <b-form-input
            id="input-project-name"
            v-model="project.name"
            :state="!validation.name.$invalid"
            ref="name"
         ></b-form-input>
      </b-form-group>

      <b-form-group label="Applicable Periods" v-if="studyPeriods.length > 0">
         <b-form-checkbox id="checkbox-all-periods" @change="onSelectAll"
            >Select All</b-form-checkbox
         >
         <hr class="my-2" />
         <b-form-checkbox-group
            id="group-periods"
            multiple
            v-model="project.periodIds"
            :options="periodOptions"
            stacked
         ></b-form-checkbox-group>
      </b-form-group>
   </b-form>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      project: Object,
      validation: Object,
   },

   computed: {
      ...mapGetters({
         studyPeriods: 'companies/studyPeriods',
      }),

      periodOptions() {
         return this.studyPeriods.map((period) => ({
            value: period.id,
            text: period.label,
            disabled: !period.study.isOpen,
         }));
      },
   },

   methods: {
      onSelectAll(select) {
         if (select) {
            this.project.periodIds = this.studyPeriods
               .filter((period) => {
                  return (
                     period.study.isOpen ||
                     (!period.study.isOpen && this.project.periodIds.includes(period.id))
                  );
               })
               .map((period) => period.id);
         } else {
            this.project.periodIds = this.studyPeriods
               .filter((period) => {
                  return !period.study.isOpen && this.project.periodIds.includes(period.id);
               })
               .map((period) => period.id);
         }
      },

      /** Emit a 'submit' event */
      submit() {
         this.$emit('submit');
      },

      /** Focus the name input (used externally) */
      focusName() {
         this.$refs.name.focus();
      },
   },
};
</script>

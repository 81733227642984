<template>
   <div>
      <b-form-select
         :id="`select-years-${index}`"
         class="mb-2"
         :options="yearOptions"
         v-model="year"
         @input="onInput"
         @change="onChange"
         :state="state"
         @keyup.enter.native="onKeyEnter"
         :disabled="disabled"
      >
         <template #first>
            <b-form-select-option v-if="!noEmptyOption" :value="emptyValue" :disabled="!allowEmpty">
               {{ emptyText }}
            </b-form-select-option>
         </template>
      </b-form-select>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      // for v-model support
      value: Number,

      // Use the current study to define the year range
      study: {
         type: Boolean,
         default: false,
      },

      // Limit years to valid tax years (MIN_TAX_YEAR or later)
      taxYears: {
         type: Boolean,
         default: false,
      },

      // Display MIN_TAX_YEAR as `${MIN_TAX_YEAR} or prior`
      orEarlier: {
         type: Boolean,
         default: false,
      },

      // Display the last year as `{YEAR} or later`
      orLater: {
         type: Boolean,
         default: false,
      },

      // How many years in the past (starting with the current year) to display
      yearsCount: {
         type: Number,
         default: null,
      },

      // Earliest year to list. Can be overrided by tax-years
      minYear: {
         type: Number,
         default: null,
      },

      // Lastest year to list
      maxYear: {
         type: Number,
         default: null,
      },

      // Used to provide a unique id for each form element
      // when there are multiple YearSelects in the same form.
      index: {
         default: '',
      },

      // validation state for bootstrap-vue
      state: {
         type: Boolean,
         default: null,
      },

      // Any years that should be disabled
      disabledYears: {
         type: Array,
         default: () => [],
      },

      // Disable the input
      disabled: {
         type: Boolean,
         default: false,
      },

      emptyText: {
         type: String,
         default: 'Please select one',
      },

      // Enables the empty option
      allowEmpty: {
         type: Boolean,
         default: false,
      },

      emptyValue: {
         default: null,
      },

      noEmptyOption: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         year: this.value,
      };
   },

   computed: {
      ...mapGetters({
         _study: 'companies/study',
      }),

      yearOptions() {
         let years;
         const currentYear = new Date().getFullYear();

         if (this.yearsCount) {
            // Use the last `yearsCount` years
            years = [...Array(this.yearsCount).keys()].map((val) => currentYear - val);
         } else {
            // Selection is based on `minYear` and `maxYear`. Default to `MIN_TAX_YEAR` and `currentYear`.
            const startYear = this.minYear ? this.minYear : this.$constants().MIN_TAX_YEAR;
            const endYear = this.maxYear ? this.maxYear : currentYear;
            const count = endYear - startYear + 1;
            years = [...Array(count).keys()].map((val) => endYear - val);
         }

         if (this.study && this.$REFACTOR('Get study periods')) {
            // Filter out any years not within the current study
            years = years.filter(
               (year) => year >= this._study.years.lower && year <= this._study.years.upper
            );
         }
         if (this.taxYears) {
            // Filter out any years prior to `MIN_TAX_YEAR`
            years = years.filter((year) => year >= this.$constants().MIN_TAX_YEAR);
         }

         return years.map((year, idx) => {
            let text = year;
            if (this.orEarlier && year === this.$constants().MIN_TAX_YEAR) {
               text = `${year} or prior`;
            }
            if (this.orLater && idx === 0) {
               text = `${year} or later`;
            }
            return {
               text,
               value: year,
               disabled: this.disabledYears.includes(year),
            };
         });
      },
   },

   methods: {
      onInput() {
         this.$emit('input', this.year);
      },

      onChange() {
         this.$emit('change', this.year);
      },

      // Pass keyup.enter to parent component
      onKeyEnter() {
         this.$emit('enter', this.year);
      },
   },

   watch: {
      value(value) {
         this.year = value;
      },
   },
};
</script>

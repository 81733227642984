<template>
   <b-container>
      <h1>Configure Studies</h1>

      <b-card>
         <div class="d-flex align-items-center justify-content-end">
            <b-button
               id="btn-create-study"
               variant="primary"
               :to="{name: 'study-create', params: {id: $companyId}}"
            >
               New Study
            </b-button>
         </div>
         <b-table
            id="table-studies"
            :fields="fields"
            :items="studies"
            sort-by="firstPeriod.period.lower"
            show-empty
         >
            <template #empty>
               <b-alert variant="primary" show>
                  No studies have been opened for this company.
               </b-alert>
            </template>
            <template #cell(status)="data">
               <RdigStatusPill :ident="`study-${data.index}`" v-bind="data.value" />
            </template>
            <template #cell(actions)="data">
               <div class="cell-w-buttons justify-content-end">
                  <b-button
                     v-if="data.item.isOpen"
                     variant="primary"
                     size="sm"
                     class="mr-2"
                     :to="{name: 'study-edit', params: {id: $companyId, studyId: data.item.id}}"
                  >
                     Edit
                  </b-button>
                  <b-button
                     v-if="!data.item.isOpen"
                     :id="`btn-open-study-${data.index}`"
                     variant="primary"
                     size="sm"
                     @click="reopenStudy(data.item.id)"
                  >
                     Reopen
                  </b-button>
                  <b-button
                     v-if="data.item.isOpen"
                     :id="`btn-close-study-${data.index}`"
                     variant="danger"
                     size="sm"
                     @click="closeStudy(data.item.id)"
                  >
                     Close
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         fields: [
            {key: 'label', label: 'Study Label', sortable: true},
            {
               key: 'firstPeriod.period.lower',
               label: 'First Study Period',
               formatter: (value, key, item) => item.firstPeriod.label,
               tdClass: 'bold',
               sortable: true,
            },
            {
               key: 'lastPeriod.period.lower',
               label: 'Last Study Period',
               formatter: (value, key, item) => item.lastPeriod.label,
               tdClass: 'bold',
               sortable: true,
            },
            {key: 'description', label: 'Study Description'},
            {key: 'status'},
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         studies: 'companies/studies',
      }),
   },

   methods: {
      async closeStudy(studyId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'This will prevent further data entry for all study periods within this study.',
            {
               id: 'modal-close-study',
               title: 'Close this study?',
               centered: true,
               okVariant: 'danger',
               okTitle: 'Close',
            }
         );

         if (proceed) {
            await this.blockingRequest('companies/closeStudy', {
               companyId: this.$companyId,
               studyId,
            });
         }
      },

      async reopenStudy(studyId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'This will enable editing of any data within this study.',
            {
               id: 'modal-close-study',
               title: 'Reopen this study?',
               centered: true,
               okTitle: 'Open',
            }
         );

         if (proceed) {
            await this.blockingRequest('companies/openStudy', {
               companyId: this.$companyId,
               studyId,
            });
         }
      },
   },

   async created() {
      await this.blockingRequest('companies/loadCompany', {companyId: this.$companyId});
   },
};
</script>

<template>
   <b-container>
      <PageTitle title="Employee Time" />
      <ManagerMessage>
         <template #short> Are these the only projects we should know about? </template>

         <h2>Are these the only projects we should know about?</h2>

         <p>
            We know about these {{ projectCount }} R&D projects your company worked on in
            {{ period.label }}. If you believe there are projects missing from this list, please
            message me now using the secure messaging feature in the top right corner before
            proceeding so we can be sure to get you the best possible credit.
         </p>
      </ManagerMessage>

      <b-card class="mb-5">
         <h5 class="mb-3">Projects</h5>
         <b-table
            id="table-projects"
            small
            class="mb-0"
            :fields="[{key: 'name', sortable: true}]"
            sort-by="name"
            :items="projects"
         >
         </b-table>
      </b-card>

      <b-row>
         <b-col class="d-flex justify-content-between">
            <b-button variant="secondary" :to="{name: 'time-survey-years'}">
               <b-icon-arrow-left-short class="mr-1" />
               Back
            </b-button>
            <b-button
               id="btn-employee-time"
               variant="primary"
               :to="{name: 'time-survey-review', params: this.$route.params}"
            >
               Next
               <b-icon-arrow-right-short class="ml-1" />
            </b-button>
         </b-col>
      </b-row>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   async created() {
      // If the company has no active study or the year is outside the current study, redirect to company detail
      if (!this.hasOpenStudy && this.$REFACTOR('Check if period in open study')) {
         this.$router.push({name: 'company-detail', params: {id: this.companyId}});
      }

      await this.blockingRequest('timesurvey/loadTimeSurvey', {
         companyId: this.companyId,
         periodId: this.periodId,
         assignments: true,
      });
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         manager: 'manager',
         hasOpenStudy: 'companies/hasOpenStudy',
         studyPeriodMap: 'companies/studyPeriodMap',
         projects: 'timesurvey/projects',
      }),

      /** ID for the company this time survey belongs to */
      companyId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.profile.companyId;
         }
      },

      /** The time survey's periodId */
      periodId() {
         return this.$route.params.periodId;
      },

      period() {
         return this.studyPeriodMap[this.periodId];
      },

      /** The number of projects included in this time survey */
      projectCount() {
         return this.projects.length;
      },
   },
};
</script>

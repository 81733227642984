<template>
   <CollapseCard
      title="R&D Business Components"
      :subtitle="`${projects.length} Business Components`"
      ident="projects"
   >
      <div v-if="hasCompanyRole">
         <div class="d-flex justify-content-between my-2 align-items-center">
            <h3>Business Components</h3>
            <b-button
               id="btn-configure-projects"
               size="sm"
               variant="primary"
               class="action-btn ml-2"
               :to="{
                  name: 'projects-configure',
                  params: {id: $companyId},
               }"
            >
               Configure Business Components
            </b-button>
         </div>
         <b-table
            id="table-projects"
            thead-class="table-head"
            :fields="[
               {key: 'name', sortable: true},
               {key: 'humanPeriodLabels', label: 'Study Periods'},
            ]"
            sort-by="name"
            :items="projects"
         >
         </b-table>

         <b-alert :show="!hasOpenStudy" variant="primary" class="m-3 mt-5">
            This company does not have an open study. Open a study to configure project
            questionnaires.
         </b-alert>
      </div>
      <div v-else>
         <b-alert variant="warning" class="m-3" show>
            Only users associated with this company can access business components.
         </b-alert>
      </div>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      hasCompanyRole: Boolean,
   },

   computed: {
      ...mapGetters({
         hasOpenStudy: 'companies/hasOpenStudy',
         projects: 'projects/projects',
         projectQuestionnaires: 'questionnaire/projectQuestionnaires',
      }),
   },

   methods: {
      questionnaireStatus(questionnaire) {
         if (questionnaire.completed !== null) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         }

         let hasAnswer = false;
         questionnaire.questions.forEach((question) => {
            hasAnswer = hasAnswer || question.answers.length > 0;
         });

         if (hasAnswer) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },

      deleteQuestionnaire(questionnaire) {
         this.$emit('deleteQuestionnaire', questionnaire);
      },
   },
};
</script>

<template>
   <b-container v-if="loaded">
      <PageTitle title="Employee Time" />

      <template v-if="!doAssignments">
         <ManagerMessage v-if="manager">
            <template #short> Do you want to assign some of this work to someone else? </template>

            <h2>Do you want to assign some of this work to someone else?</h2>

            <p>
               We need wage info for about <b>{{ employeeSets.length }}</b> employees.
            </p>

            <p class="mb-0">
               You can enter this wage info on your own, or you can create accounts for other people
               at your company and assign certain employees to those users.
            </p>
         </ManagerMessage>
      </template>

      <template v-else>
         <ManagerMessage v-if="manager">
            <template #short>
               <p v-if="selectedUser && !anyAssigned" class="mb-0">
                  Pick employees and click <b>Assign</b>.
               </p>
               <p v-else-if="selectedUser && anyAssigned" class="mb-0">
                  All employees must be assigned before moving on.
               </p>
               <p v-else class="mb-0">Who do you want to assign employees to?</p>
            </template>

            <template v-if="selectedUser">
               <h2 v-if="anyUnassigned">All employees must be assigned before moving on.</h2>
               <h2 v-else>Pick employees and click <b>Assign</b>.</h2>
               <p>
                  Choose one or more employees to assign to the selected user. When you're done with
                  assignments, continue by clicking <b>Next</b> below.
               </p>
               <p class="mb-0">All employees must be assigned before moving on.</p>
            </template>
            <template v-else>
               <h2>Who do you want to assign employees to?</h2>
               <p class="mb-0">
                  First, choose an Onboard user from the dropdown below. If the user doesn't exist,
                  create an account for them with the <b>Invite User</b> button and they will be
                  notified of their new account.
               </p>
            </template>
         </ManagerMessage>

         <b-row class="mb-3">
            <b-col cols="5">
               <b-form-group>
                  <b-form-select id="select-user" v-model="selectedUser">
                     <template #first>
                        <b-form-select-option :value="null" disabled>
                           Select a User
                        </b-form-select-option>
                     </template>

                     <b-form-select-option
                        v-for="user in users"
                        :key="user.id"
                        :value="user"
                        :disabled="selectedUser && selectedUser.id === user.id"
                     >
                        {{ formatUserName(user, profile.id) }}
                     </b-form-select-option>
                  </b-form-select>
               </b-form-group>
            </b-col>
            <b-col class="">
               <b-button id="btn-add-user" variant="primary" @click="addUserModal">
                  Invite User <b-icon-plus />
               </b-button>
            </b-col>
         </b-row>

         <template v-if="selectedUser">
            <h2 class="mb-1">Assign employees</h2>
            <p class="text-instruction mb-1">
               Select the {{ company.companyName }} employees you need {{ selectedUser.firstName }}
               {{ selectedUser.lastName }} to provide R&D contribution info for.
            </p>
            <p class="text-instruction mb-4">
               Tip: Hold <b>Ctrl</b> or <b>Command</b> to select multiple employees, or hold
               <b>Shift</b> to select a range of employees.
            </p>
         </template>

         <b-row v-if="selectedUser">
            <b-col cols="12" md="6" class="mb-4 mb-md-0">
               <h2>Unassigned employees</h2>
               <div v-if="anyUnassigned">
                  <b-input-group class="mb-2">
                     <b-form-input
                        v-model="filters.search"
                        placeholder="Search for an employee"
                     ></b-form-input>
                     <b-input-group-append :is-text="true">
                        <b-icon-search></b-icon-search>
                     </b-input-group-append>
                  </b-input-group>

                  <b-form-row class="mb-2">
                     <b-col class="d-flex align-items-center">
                        <b-form-select size="sm" :options="titles" v-model="filters.title">
                           <template #first>
                              <b-form-select-option :value="null">All titles</b-form-select-option>
                           </template>
                        </b-form-select>

                        <b-form-select
                           size="sm"
                           :options="departments"
                           v-model="filters.department"
                           class="ml-1"
                        >
                           <template #first>
                              <b-form-select-option :value="null">
                                 All departments
                              </b-form-select-option>
                           </template>
                        </b-form-select>

                        <b-button
                           v-if="openStudyPeriods.length > 1"
                           variant="secondary"
                           size="sm"
                           class="no-wrap ml-1"
                           :disabled="selectedSplitEmployee === null"
                           @click="splitEmployeeModal"
                        >
                           Split Time
                        </b-button>
                     </b-col>
                  </b-form-row>

                  <b-form-row class="mb-2">
                     <b-col>
                        <b-button
                           id="btn-select-all-unassigned"
                           size="sm"
                           class="w-100"
                           @click="selectAll('unassigned')"
                        >
                           Select All
                        </b-button>
                     </b-col>
                     <b-col>
                        <b-button
                           id="btn-clear-unassigned"
                           size="sm"
                           class="w-100"
                           @click="clearSelection('unassigned')"
                        >
                           Clear Selection
                        </b-button>
                     </b-col>
                  </b-form-row>
               </div>

               <b-form-select
                  v-show="filteredUnassignedEmployeeSets.length > 0"
                  id="select-unassigned-employees"
                  class="scrollbar employee-select"
                  style="height: 25em"
                  v-model="employeeSelection"
                  multiple
               >
                  <b-form-select-option
                     v-for="employee in filteredUnassignedEmployeeSets"
                     :key="employee.setId"
                     :value="employee"
                     class="d-flex align-items-center py-1"
                  >
                     <b-icon-plus class="text-primary mr-1" font-scale="1.5"></b-icon-plus>
                     {{ formatEmployeeName(employee) }}
                  </b-form-select-option>
               </b-form-select>

               <b-alert
                  :show="
                     unassignedEmployeeSets.length > 0 &&
                     filteredUnassignedEmployeeSets.length === 0
                  "
                  variant="primary"
               >
                  No employees match the search criteria
               </b-alert>

               <b-alert :show="!anyUnassigned" variant="success">
                  All employees have been assigned!
               </b-alert>

               <div
                  class="d-flex justify-content-end mt-2"
                  v-if="filteredUnassignedEmployeeSets.length > 0"
               >
                  <b-button
                     id="btn-assign-employees"
                     variant="primary"
                     @click="assignEmployees"
                     :disabled="employeeSelection.length === 0 || assigning"
                  >
                     <b-spinner small v-if="assigning"></b-spinner>
                     Assign to
                     {{
                        selectedUser.id === profile.id
                           ? 'Me'
                           : `${selectedUser.firstName} ${selectedUser.lastName}`
                     }}
                  </b-button>
               </div>
            </b-col>
            <b-col cols="12" md="6">
               <h2>
                  Employees assigned to {{ selectedUser.firstName }}
                  {{ selectedUser.lastName }}
               </h2>
               <div v-if="assignedEmployeeSets.length > 0">
                  <b-input-group class="mb-2">
                     <b-form-input
                        v-model="assignedFilters.search"
                        placeholder="Search for an employee"
                     ></b-form-input>
                     <b-input-group-append :is-text="true">
                        <b-icon-search></b-icon-search>
                     </b-input-group-append>
                  </b-input-group>

                  <b-form-row class="mb-2">
                     <b-col class="d-flex">
                        <b-form-select size="sm" :options="titles" v-model="assignedFilters.title">
                           <template #first>
                              <b-form-select-option :value="null">All titles</b-form-select-option>
                           </template>
                        </b-form-select>

                        <b-form-select
                           size="sm"
                           :options="departments"
                           v-model="assignedFilters.department"
                           class="ml-1"
                        >
                           <template #first>
                              <b-form-select-option :value="null">
                                 All departments
                              </b-form-select-option>
                           </template>
                        </b-form-select>
                     </b-col>
                  </b-form-row>

                  <b-form-row class="mb-2">
                     <b-col>
                        <b-button
                           id="btn-select-all-assigned"
                           size="sm"
                           class="w-100"
                           @click="selectAll('assigned')"
                        >
                           Select All
                        </b-button>
                     </b-col>
                     <b-col>
                        <b-button
                           id="btn-clear-assigned"
                           size="sm"
                           class="w-100"
                           @click="clearSelection('assigned')"
                        >
                           Clear Selection
                        </b-button>
                     </b-col>
                  </b-form-row>
               </div>

               <b-form-select
                  v-show="filteredAssignedEmployeeSets.length > 0"
                  id="select-assigned-employees"
                  class="employee-select scrollbar"
                  style="height: 25em"
                  v-model="assignedEmployeeSelection"
                  multiple
               >
                  <b-form-select-option
                     v-for="employee in filteredAssignedEmployeeSets"
                     :key="employee.setId + '-assigned'"
                     :value="employee"
                     class="d-flex align-items-center py-1"
                  >
                     <b-icon-x-circle-fill
                        class="text-secondary mr-2"
                        font-scale="1.2"
                     ></b-icon-x-circle-fill>
                     {{ formatEmployeeName(employee) }}
                  </b-form-select-option>
               </b-form-select>

               <b-alert
                  :show="
                     assignedEmployeeSets.length > 0 && filteredAssignedEmployeeSets.length === 0
                  "
                  variant="primary"
               >
                  No employees match the search criteria
               </b-alert>

               <b-alert :show="assignedEmployeeSets.length === 0">
                  No employees have been assigned to {{ selectedUser.firstName }}
                  {{ selectedUser.lastName }}.
               </b-alert>

               <div
                  class="d-flex justify-content-end mt-2"
                  v-if="filteredAssignedEmployeeSets.length > 0"
               >
                  <b-button
                     id="btn-unassign-employees"
                     variant="primary"
                     @click="unassignEmployees"
                     :disabled="assignedEmployeeSelection.length === 0 || unassigning"
                  >
                     <b-spinner small v-if="unassigning"></b-spinner>
                     Unassign
                  </b-button>
               </div>
            </b-col>
         </b-row>
      </template>

      <b-row class="mt-5">
         <b-col class="d-flex justify-content-between">
            <b-button
               variant="secondary"
               class="mr-2 d-flex align-items-center"
               :class="{invisible: isRndig}"
               @click="displayIntro"
            >
               <b-icon-arrow-left-short class="mr-1" />
               View Introduction
            </b-button>

            <b-button
               id="btn-next"
               variant="primary"
               class="d-flex align-items-center"
               :to="nextRoute"
               v-if="doAssignments"
            >
               {{ isRndig ? 'Done' : 'Next' }}
               <b-icon-arrow-right-short class="ml-1" />
            </b-button>
            <div v-else class="d-flex align-items-center">
               <b-button id="btn-assign-others" variant="primary" @click="doAssignments = true">
                  Assign employees to other users
               </b-button>
               <b-button id="btn-assign-all" variant="primary" class="ml-2" @click="assignToMe">
                  Assign all employees to me
               </b-button>
            </div>
         </b-col>
      </b-row>

      <b-modal
         id="modal-split-employee"
         v-if="selectedSplitEmployee"
         :title="`Assign ${selectedSplitEmployee.fullname} to multiple people`"
         centered
         size="lg"
         @ok="splitEmployee"
      >
         <p>
            Split {{ selectedSplitEmployee.fullname }}'s time into multiple time periods so that
            each period may be assigned to a different user?
         </p>
      </b-modal>

      <CreateClientModal />
   </b-container>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';

import {clientUserSuggestions} from '@/mixins';
import CreateClientModal from '@/views/user/widgets/CreateClientModal';

export default {
   components: {
      CreateClientModal,
   },

   mixins: [
      // Adds support for department/job title suggestions
      clientUserSuggestions,
   ],

   data() {
      return {
         loaded: false,
         doAssignments: false,
         selectedUser: null,
         filters: {
            department: null,
            title: null,
            search: null,
         },
         assignedFilters: {
            department: null,
            title: null,
            search: null,
         },
         employeeSelection: [],
         assignedEmployeeSelection: [],
         assigning: false,
         unassigning: false,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         manager: 'manager',
         isRndig: 'isRndig',
         clientState: 'clientState',
         company: 'companies/currentCompany',
         hasOpenStudy: 'companies/hasOpenStudy',
         openStudyPeriods: 'companies/openStudyPeriods',
         studyPeriodMap: 'companies/studyPeriodMap',
         _users: 'users/companyUsers',
         employeeSets: 'tsAssignment/employeeSets',
         _assignedEmployeeSets: 'tsAssignment/assignedEmployeeSets',
      }),

      /** The time survey's company */
      companyId() {
         if (this.$route.params.id) {
            return parseInt(this.$route.params.id);
         } else {
            return this.company.id;
         }
      },

      /** Users of the current company, sorted alphabetically by lastName, firstName */
      users() {
         return Object.values(this._users).sort((a, b) => {
            const nameA = `${a.lastName}${a.firstName}`.toUpperCase();
            const nameB = `${b.lastName}${b.firstName}`.toUpperCase();
            if (nameA < nameB) {
               return -1;
            }
            if (nameA > nameB) {
               return 1;
            }
            return 0;
         });
      },

      /** The available departments */
      departments() {
         const seen = {};
         Object.values(this.employeeSets).forEach((employee) => {
            if (employee.department) {
               seen[employee.department] = true;
            }
         });
         return Object.keys(seen);
      },

      /** The available job titles */
      titles() {
         const seen = {};
         this.employeeSets.forEach((employee) => {
            const title = this.employeeTitle(employee);
            if (title) {
               seen[title] = true;
            }
         });
         return Object.keys(seen);
      },

      /** Unassigned employees, sorted by fullname */
      unassignedEmployeeSets() {
         return this._assignedEmployeeSets(null);
      },

      /** Unassigned employees, filtered according to user input */
      filteredUnassignedEmployeeSets() {
         let employees = [...this.unassignedEmployeeSets];
         if (this.filters.title) {
            employees = employees.filter((employee) => {
               return this.filters.title === this.employeeTitle(employee);
            });
         }

         if (this.filters.department) {
            employees = employees.filter((employee) => {
               return this.filters.department === employee.department;
            });
         }

         if (this.filters.search) {
            employees = employees.filter((employee) => {
               return employee.fullname.toLowerCase().includes(this.filters.search.toLowerCase());
            });
         }

         return employees;
      },

      /** An array of employees assigned to the selected user */
      assignedEmployeeSets() {
         if (!this.selectedUser) {
            return [];
         }
         return this._assignedEmployeeSets(this.selectedUser.id);
      },

      /** Have any employees been assigned? */
      anyAssigned() {
         return this.employeeSets.some((set) => set.assignee !== null);
      },

      /** Are any employees unassigned? */
      anyUnassigned() {
         return this.unassignedEmployeeSets.length > 0;
      },

      /** Assigned employees, filtered according to user input */
      filteredAssignedEmployeeSets() {
         let employees = [...this.assignedEmployeeSets];
         if (this.assignedFilters.title) {
            employees = employees.filter((employee) => {
               return this.assignedFilters.title === this.employeeTitle(employee);
            });
         }

         if (this.assignedFilters.department) {
            employees = employees.filter((employee) => {
               return this.assignedFilters.department === employee.department;
            });
         }

         if (this.assignedFilters.search) {
            employees = employees.filter((employee) => {
               return employee.fullname
                  .toLowerCase()
                  .includes(this.assignedFilters.search.toLowerCase());
            });
         }

         return employees;
      },

      /** Route to the next step */
      nextRoute() {
         if (this.isRndig) {
            return {name: 'company-detail', params: {id: this.companyId}};
         } else {
            return {name: 'time-survey-years', params: {id: this.companyId}};
         }
      },

      /**
       * If one employee set is selected and that employee set can be split, returns
       * that employee set. Otherwise returns null.
       */
      selectedSplitEmployee() {
         // NB: Imediately after clicking 'Select All', the selection array is `[null]`. Hence
         //     the need to check that `employeeSelection[0]` is truthy.
         if (this.employeeSelection.length === 1 && this.employeeSelection[0]) {
            const employeeSet = this.employeeSelection[0];
            if (employeeSet.employees.length > 1) {
               return employeeSet;
            }
         }
         return null;
      },

      /** The resulting year ranges after splitting the selected employee */
      splitYearRanges() {
         if (this.selectedSplitEmployee) {
            const employees = this.selectedSplitEmployee.employees;
            let lower = employees[0].year;
            let upper = employees[employees.length - 1].year;

            if (this.splitYear) {
               const lowerDelta = this.splitYear - lower - 1;
               if (lowerDelta > 0) {
                  lower += `-${lower + lowerDelta}`;
               }

               const upperDelta = upper - this.splitYear;
               if (upperDelta > 0) {
                  upper = `${upper - upperDelta}-${upper}`;
               }
            }

            return {lower, upper};
         }
         return null;
      },
   },

   methods: {
      displayIntro() {
         this.$router.push({name: 'time-survey-intro', query: {redirect: this.$route.path}});
      },

      /**
       * Format a user name with title and department. Use "Me" when user is logged in.
       * @param {Object} user - A user object
       * @param {Number} currentUserProfileId - ID of the currently logged-in user, if any
       */
      formatUserName(user, currentUserProfileId) {
         if (user.id === currentUserProfileId) {
            return 'Me';
         }

         let text = `${user.firstName} ${user.lastName}`;
         if (user.title && user.department) {
            text += ` (${user.title} - ${user.department})`;
         } else if (user.title || user.department) {
            text += ` (${user.title || user.department})`;
         }
         return text;
      },

      /**
       * Format an employee name with title and department
       * @param {Object} employee - An employee object
       */
      formatEmployeeName(employeeSet) {
         let text = employeeSet.fullname;
         const title = this.employeeTitle(employeeSet);
         if (title) {
            text += ` - ${title}`;
         }
         if (employeeSet.department) {
            text += ` - ${employeeSet.department}`;
         }

         if (employeeSet.isSplit) {
            const period = this.studyPeriodMap[employeeSet.employees[0].periodId];
            text += ` (${period.label})`;
         }

         return text;
      },

      /** Returns the latest title from an employee set */
      employeeTitle(employeeSet) {
         return employeeSet.employees[employeeSet.employees.length - 1].title;
      },

      /**
       * Select a user
       * @param {Object} user - A user object
       */
      selectUser(user) {
         this.selectedUser = user;
      },

      /** Select all employees from either the assigned or unassigned list */
      selectAll(type) {
         this.clearSelection(type);
         if ('unassigned' === type) {
            this.employeeSelection.push(...this.filteredUnassignedEmployeeSets);
         } else {
            this.assignedEmployeeSelection.push(...this.filteredAssignedEmployeeSets);
         }
      },

      /** Clear all selected employees in either the assigned or unassigned list */
      clearSelection(type) {
         if ('unassigned' === type) {
            this.employeeSelection.splice(0);
         } else {
            this.assignedEmployeeSelection.splice(0);
         }
      },

      /** Display the employee splitting modal */
      splitEmployeeModal() {
         this.splitYear = this.selectedSplitEmployee.employees[1].year;
         this.$bvModal.show('modal-split-employee');
      },

      /** Split the selected employee */
      splitEmployee() {
         this.$store.commit('tsAssignment/splitEmployeeSet', {
            setId: this.selectedSplitEmployee.setId,
            year: this.splitYear,
         });
      },

      /** Display the create client user modal */
      addUserModal() {
         this.$bvModal.show('modal-create-client');
      },

      /** Submit requests to assign the selected employees to the selected user */
      async assignEmployees() {
         this.assigning = true;
         try {
            const setIds = this.employeeSelection.map((set) => set.setId);
            await this.$store.dispatch('tsAssignment/assignEmployees', {
               assignerId: this.profile.id,
               assigneeId: this.selectedUser.id,
               companyId: this.companyId,
               setIds,
            });
            Vue.set(this, 'employeeSelection', []);
         } finally {
            this.assigning = false;
         }
      },

      /** Submit a request to unassign an employee */
      async unassignEmployees() {
         this.unassigning = true;
         try {
            const setIds = this.assignedEmployeeSelection.map((set) => set.setId);
            await this.$store.dispatch('tsAssignment/unassignEmployees', {
               assignerId: this.profile.id,
               companyId: this.companyId,
               setIds,
            });
            Vue.set(this, 'assignedEmployeeSelection', []);
         } finally {
            this.unassigning = false;
         }
      },

      /** Assign all unassigned employees to the current user */
      async assignToMe() {
         const setIds = this.unassignedEmployeeSets.map((set) => set.setId);
         await this.$store.dispatch('tsAssignment/assignEmployees', {
            assignerId: this.profile.id,
            assigneeId: this.profile.id,
            companyId: this.companyId,
            setIds,
         });

         const nextRoute = {
            name: 'time-survey-years',
            params: this.$route.params,
         };
         this.$router.push(nextRoute);
      },
   },

   async created() {
      // If the company has no active study or the year is outside the current study, redirect to company detail
      if (!this.hasOpenStudy) {
         this.$router.push({name: 'company-detail', params: {id: this.companyId}});
      }

      let requests = [];

      requests.push(this.$store.dispatch('users/loadCompanyUsers', {companyId: this.companyId}));
      if (!this.$route.params.preloadedEmployeeAssignments) {
         requests.push(
            this.$store.dispatch('tsAssignment/loadEmployeeAssignments', {
               companyId: this.companyId,
            })
         );
      }
      requests.push(this.loadSuggestions());

      await this.blockUntilAllSettled(requests);

      if (this.anyAssigned || this.isRndig) {
         this.doAssignments = true;
      }

      this.loaded = true;
   },
};
</script>

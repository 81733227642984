export class Company {
   constructor({
      id = null,
      companyName = null,
      filingDeadline = null,
      fein = null,
      entityType = null,
      taxYearEnd = null,
      industryId = null,
      federalCreditYears = {years: [null]},
      stateCreditYears = [{state: null, years: [null]}],
      payrollTaxElection = false,
      incorporated = null,
      firstRndEffort = null,
      latestCredit = null,
      businessManagers = [],
      projectManagers = [],
      projectAssociates = [],
      cpaInfo = {name: null, firm: null, email: null},
      contactInfo = {name: null, email: null},
      prompts = {supplies: null, uploads: null},
      created = null,
   } = {}) {
      const formatDate = (date) => {
         if (date && typeof date === 'string') {
            let [year, month, day] = date.split('-');
            month = +month - 1; // convert to 0-based index
            return new Date(year, month, day);
         }
         return date;
      };

      if (typeof stateCreditYears === 'object' && !(stateCreditYears instanceof Array)) {
         stateCreditYears = Object.keys(stateCreditYears).map((state) => ({
            state: state.toUpperCase(),
            years: stateCreditYears[state].years,
         }));
      }

      this.id = id;
      this.companyName = companyName;
      this.filingDeadline = formatDate(filingDeadline);
      this._fein = fein;
      this.entityType = entityType;
      this.taxYearEnd = formatDate(taxYearEnd);
      this.industryId = industryId;
      this.federalCreditYears = {
         years: [],
         ...federalCreditYears,
      };
      this.stateCreditYears = stateCreditYears;
      this.payrollTaxElection = payrollTaxElection;
      this.incorporated = formatDate(incorporated);
      this.firstRndEffort = firstRndEffort;
      this.latestCredit = latestCredit;
      this.businessManagers = businessManagers;
      this.projectManagers = projectManagers;
      this.projectAssociates = projectAssociates;
      this.cpaInfo = cpaInfo;
      this.contactInfo = contactInfo;
      this.prompts = prompts;
      this.created = created;
   }

   get fein() {
      return this._fein ? `${this._fein.substring(0, 2)}-${this._fein.substring(2)}` : null;
   }
   set fein(val) {
      if (val) {
         this._fein = val.replace(/-/g, '');
      } else {
         this._fein = null;
      }
   }

   addState() {
      this.stateCreditYears.push({
         state: null,
         years: [null],
      });
   }

   deleteState(index) {
      this.stateCreditYears.splice(index, 1);
   }

   export() {
      const exportDate = (date) => {
         if (!date) {
            return null;
         }
         // convert to UTC so we can use toISOString without potentially rolling over a day
         const offset = date.getTimezoneOffset() * 60 * 1000; // minutes -> milliseconds
         const utcDate = new Date(date.getTime() - offset);
         return utcDate.toISOString().split('T')[0];
      };

      // Filter out any null years or states
      const federalCreditYears = {
         years: this.federalCreditYears.years.filter((year) => year !== null),
      };
      let stateCreditYears = this.stateCreditYears.filter((item) => item.state !== null);
      stateCreditYears.forEach((item) => {
         item.years = item.years.filter((year) => year !== null);
      });
      stateCreditYears = stateCreditYears.reduce((obj, item) => {
         obj[item.state] = {years: item.years};
         return obj;
      }, {});

      const company = {
         companyName: this.companyName,
         filingDeadline: exportDate(this.filingDeadline),
         fein: this._fein,
         entityType: this.entityType,
         taxYearEnd: exportDate(this.taxYearEnd),
         industryId: this.industryId,
         federalCreditYears,
         stateCreditYears,
         payrollTaxElection: this.payrollTaxElection,
         incorporated: exportDate(this.incorporated),
         firstRndEffort: this.firstRndEffort,
         latestCredit: this.latestCredit,
         cpaInfo: this.cpaInfo,
         contactInfo: this.contactInfo,
         prompts: this.prompts,
      };

      return company;
   }
}

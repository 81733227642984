<template>
   <b-container>
      <h1 class="mb-3">Create Company Questionnaire</h1>

      <h3>Study</h3>

      <StudySelect class="mb-3" allow-none />

      <b-alert :show="!activeStudyId" variant="primary">
         Select the study you want to create a questionnaire for.
      </b-alert>

      <QuestionnaireCreateForm
         v-if="loaded && activeStudyId"
         :duplicate-titles="duplicateTitles"
         @submit="submit"
      />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import QuestionnaireCreateForm from './widgets/QuestionnaireCreateForm';
import StudySelect from '../company/widgets/StudySelect';

export default {
   components: {
      QuestionnaireCreateForm,
      StudySelect,
   },

   data() {
      return {
         loaded: false,
         duplicateTitles: [],
      };
   },

   computed: {
      ...mapGetters({
         title: 'questionnaire/title',
         activeStudyId: 'companies/activeStudyId',
         hasOpenStudy: 'companies/hasOpenStudy',
      }),

      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      /** Submit the create questionnaire request */
      async submit() {
         try {
            await this.blockingRequest('questionnaire/createCompanyQuestionnaire', {
               companyId: this.companyId,
               create: true,
            });
            this.$router.push({name: 'company-detail', params: {id: this.companyId}});
         } catch (err) {
            if (err.response && err.response.data && err.response.data.errors) {
               const error = err.response.data.errors[0];
               if (error.code == this.$errorCodes().DUPLICATE) {
                  this.duplicateTitles.push(this.title);
               }
            }
            this.$store.commit('showAlert', {
               response: err.response,
               override: {
                  [this.$errorCodes().DUPLICATE]:
                     'A company questionnaire with this title already exists in the current study.',
               },
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },
   },

   async created() {
      this.$store.commit('questionnaire/clearQuestionnaire');

      const requests = [
         this.$store.dispatch('companies/loadCompany', {
            companyId: this.companyId,
         }),
         this.$store.dispatch('industries/loadIndustries'),
         this.$store.dispatch('questionnaire/loadTemplates'),
      ];
      await this.blockUntilAllSettled(requests);

      if (!this.hasOpenStudy) {
         this.$router.push({name: 'company-detail', params: {id: this.companyId}});
      }

      this.loaded = true;
   },
};
</script>

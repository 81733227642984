<template>
   <b-form-group
      class="percentage-form-group"
      :state="isInvalid ? !isInvalid : null"
      invalid-feedback="Enter an integer between 0 and 100"
   >
      <tcp-input-group append="%">
         <b-form-input
            :id="id"
            v-model="percentage"
            :debounce="debounceTime"
            :formatter="formatInt"
            :state="isInvalid ? !isInvalid : null"
            @focus="storeValue"
            @blur="onBlur"
            @keydown.stop="onKeydown"
            :placeholder="placeholder"
            :disabled="disabled || disableProjectPercentage"
         ></b-form-input>
      </tcp-input-group>

      <b-tooltip :disabled="!showTooltip" :target="id" triggers="focus">
         {{ projectSumMessage }}
      </b-tooltip>
   </b-form-group>
</template>

<script>
import {mapGetters} from 'vuex';

import {formatInt} from '@/helpers/utils';
import {CellType} from '@/store/modules/contractor-time';

export default {
   props: {
      periodId: [String, Number],
      contractorId: [String, Number],
      contractorIndex: [String, Number],
      projectId: {
         type: [String, Number],
         default: null,
      },
      projectIndex: {
         type: [String, Number],
         default: null,
      },
      total: Boolean,
      status: String,
      validation: Object,
      disabled: Boolean,
   },

   data() {
      return {
         debounceTime: 500,
         storedValue: null,
         oldValue: null,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         data: 'contractorTime/data',
         projectSum: 'contractorTime/projectSum',
         _refData: 'contractorTime/refData',
         projectsInPeriod: 'projects/projectsInPeriod',
      }),

      id() {
         return this.genInputId(this.contractorIndex, this.projectIndex);
      },

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      totalPercentage() {
         return this.data[this.contractorId][this.periodId].percentage;
      },

      percentage: {
         get() {
            return this.total
               ? this.totalPercentage
               : this.data[this.contractorId][this.periodId].projects[this.projectId];
         },
         set(value) {
            if (value === '' || value === null) {
               value = null;
            } else {
               value = parseInt(value, 10);
            }
            const id = this.projectId;

            let oldValue;
            if (this.isInvalid) {
               // The invalid value hasn't been saved, so use the stored oldValue instead
               oldValue = this.oldValue;
            } else {
               oldValue = this.percentage;
            }

            this.$store.commit('contractorTime/updateCell', {
               contractorId: this.contractorId,
               periodId: this.periodId,
               kind: this.kind,
               id,
               value,
            });

            if (this.total) {
               if (oldValue === 0) {
                  // Total percentage was 0, so clear project percentages
                  this.clearProjectPercentages(value);
               } else if (value === 0) {
                  // Setting total percentage to 0 sets all project percentages to 0
                  this.setContractorToZero();
               } else {
                  this.savePercentage(oldValue, value, this.kind, id);
               }
            } else {
               this.savePercentage(oldValue, value, this.kind, id);
            }
         },
      },

      kind() {
         return this.total ? CellType.PERC : CellType.PROJ;
      },

      isInvalid() {
         return this.total
            ? this.validation.percentage.$invalid
            : this.validation.projects.$each[this.projectId].$invalid;
      },

      /** Reference data from the most recent study period, if it exists */
      refData() {
         return this._refData(this.contractorId, this.periodId, this.kind, this.projectId);
      },

      /** Reference data, formatted to be used as the input placeholder */
      placeholder() {
         return this.refData === null ? '' : `${this.refData}`;
      },

      /** Should the input display a tooltip? */
      showTooltip() {
         return (
            this.status === this.$constants().StatusType.IN_PROGRESS && !this.validation.$invalid
         );
      },

      /** Message to display when total percentage doesn't match project sum */
      projectSumMessage() {
         const total = parseInt(this.totalPercentage, 10);
         const projectSum = this.projectSum(this.contractorId, this.periodId);
         const diff = total - projectSum;

         const msg =
            diff > 0
               ? `You still have ${diff}% left to allocate to qualified R&D projects.`
               : `Project time total is greater than total R&D time by ${-1 * diff}%.`;

         return msg;
      },

      /** Disable project percentage inputs when the total percentage is 0 */
      disableProjectPercentage() {
         return !this.total && this.totalPercentage === 0;
      },
   },

   methods: {
      formatInt,

      /** Returns the id for the input in the given row and column */
      genInputId(rowIdx, colIdx) {
         const col = colIdx === null ? 'total' : colIdx;
         return `input-perc-${rowIdx}-${col}`;
      },

      /** Store the current percentage value */
      storeValue() {
         this.storedValue = this.percentage;
      },

      /** Restore the percentage to the stored value */
      restoreValue() {
         this.percentage = this.storedValue;
      },

      useRefValue() {
         this.percentage = this.refData;
      },

      /** Returns the input of the next vertical input based on offset */
      nextVerticalInputId(offset) {
         const rowIdx = parseInt(this.contractorIndex, 10) + offset;
         return this.genInputId(rowIdx, this.projectIndex);
      },

      /** Handle keydown events on the input element */
      onKeydown(event) {
         const keycodes = ['Escape', 'ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'];

         if (!keycodes.includes(event.code)) {
            return;
         }

         // Undo on `esc`
         if ('Escape' === event.code) {
            this.restoreValue();
            return;
         }

         if ((event.code === 'Enter' || event.code === 'NumpadEnter') && event.shiftKey) {
            this.useRefValue();
         } else if (['ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'].includes(event.code)) {
            // Navigate up/down
            event.preventDefault();
            const direction = event.code === 'ArrowUp' ? -1 : 1;
            let offset = direction;
            let nextElement;

            const getNextElement = (offset) => {
               const nextId = this.nextVerticalInputId(offset);
               return document.getElementById(nextId);
            };

            // Search for the next input element, skipping disabled inputs
            while ((nextElement = getNextElement(offset)) && nextElement.disabled) {
               offset += direction;
            }

            if (nextElement) {
               nextElement.focus();
               nextElement.select();
            }
         }
      },

      /** If the cell contains an invalid value on blur, clear it */
      onBlur() {
         if (this.isInvalid) {
            this.percentage = null;
         }
      },

      /** Save the percentage value */
      savePercentage(oldValue, newValue, kind, id) {
         if (this.isInvalid) {
            // Don't save an invalid value, but store the oldValue for later use
            this.oldValue = oldValue;
            return;
         }

         this.$store.dispatch('contractorTime/updateCell', {
            companyId: this.companyId,
            contractorId: this.contractorId,
            periodId: this.periodId,
            kind,
            id,
            newValue,
            oldValue,
         });
      },

      /** Set the contractor's total and project percentages to 0 */
      setContractorToZero() {
         const projData = this.projectsInPeriod(this.periodId).reduce((obj, proj) => {
            obj[proj.id] = 0;
            return obj;
         }, {});

         const periods = [
            {
               contractorId: this.contractorId,
               periodId: this.periodId,
               percentage: 0,
               projects: projData,
            },
         ];

         this.$store.dispatch('contractorTime/updateContractors', {
            companyId: this.companyId,
            periods,
         });
      },

      /** Save the total percentage and set all project percentages to null */
      clearProjectPercentages(percentage) {
         if (this.isInvalid) {
            percentage = null;
         }

         const projData = this.projectsInPeriod(this.periodId).reduce((obj, proj) => {
            obj[proj.id] = null;
            return obj;
         }, {});

         const periods = [
            {
               contractorId: this.contractorId,
               periodId: this.periodId,
               percentage,
               projects: projData,
            },
         ];

         this.$store.dispatch('contractorTime/updateContractors', {
            companyId: this.companyId,
            periods,
         });
      },
   },

   watch: {
      /** Manually show/hide the tooltip when the tooltip is enabled/disabled */
      showTooltip(value) {
         if (value) {
            const activeElementId = document.activeElement.id;
            if (activeElementId === this.id) {
               this.$root.$emit('bv::show::tooltip', this.id);
            }
         } else {
            this.$root.$emit('bv::hide::tooltip', this.id);
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.percentage-form-group {
   min-width: 7rem;
   margin-bottom: 0;
}
</style>

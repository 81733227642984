import {SpecialCategories} from './uploads';

import {expenseDataModuleFactory} from '../utils/expense-data';

export const SuppliesDecl = Object.freeze({
   NONE: 'NONE',
   NOT_USA: 'NOT_USA',
   ONE: 'ONE',
   MANY: 'MANY',
});

export default expenseDataModuleFactory('supplies', SpecialCategories.SUPPLIES_DOCS, {
   getters: {
      declaration: (state, getters, rootState, rootGetters) => {
         return rootGetters['companies/activeStudy']?.supplies.declared;
      },

      /** The declared location, provided the section has a declaration of 'ONE' location */
      location: (state, getters, rootState, rootGetters) => {
         return rootGetters['companies/activeStudy']?.supplies.location;
      },
   },

   actions: {
      /** Update the section declaration */
      async saveDeclaration({dispatch}, {companyId, value, location}) {
         let payload = {
            declared: value,
         };
         if (location !== null) {
            payload.location = location;
         }
         await dispatch('_saveSectionData', {companyId, payload});
      },
   },
});

export class Employee {
   constructor({
      id = null,
      fullname = null,
      ident = null,
      location = null,
      title = null,
      department = null,
      subsidiary = null,
      speriod = {id: null},
   } = {}) {
      this.id = id;
      this.fullname = fullname;
      this.ident = ident;
      this.location = location;
      this.title = title;
      this.department = department;
      this.subsidiary = subsidiary;
      this.speriod = speriod;

      this.saving = false;
   }

   get periodId() {
      return this.speriod.id;
   }

   set periodId(val) {
      this.speriod.id = val;
   }

   startSave() {
      this.saving = true;
   }

   endSave() {
      this.saving = false;
   }

   copy() {
      return new Employee({
         id: this.id,
         ident: this.ident,
         fullname: this.fullname,
         title: this.title,
         department: this.department,
         subsidiary: this.subsidiary,
         location: this.location,
         speriod: this.speriod,
      });
   }

   export() {
      const employee = {
         ident: this.ident,
         fullname: this.fullname,
         title: this.title,
         department: this.department,
         subsidiary: this.subsidiary,
         location: this.location,
         speriod: this.periodId,
      };
      if (this.id) {
         employee.id = this.id;
      }
      return employee;
   }
}

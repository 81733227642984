<template>
   <CollapseCard title="Company Information" ident="company-info">
      <b-table id="table-company-info" :items="tableItems" :fields="fields" class="mt-3 mb-0">
         <template #head(value)="data">
            <div class="cell-w-buttons">
               {{ data.label }}
               <b-button
                  id="btn-edit-info"
                  variant="primary"
                  class="ml-2"
                  size="sm"
                  :to="{name: 'company-edit', params: {id: $companyId}}"
               >
                  Edit Info
               </b-button>
            </div>
         </template>
         <template #cell(key)="data">
            <b>{{ data.value }}</b>
         </template>
      </b-table>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         fields: [{key: 'key', label: 'Field', sortable: true}, 'value'],
         companyAttrs: [
            {key: 'companyName'},
            {key: 'fein', label: 'FEIN#'},
            {key: 'incorporated', label: 'Date of Incorporation', formatter: this.formatDate},
            {key: 'entityType', formatter: this.formatEntityType},
            {key: 'industryId', label: 'Industry', formatter: this.formatIndustry},
            {key: 'filingDeadline', formatter: this.formatDate},
            {key: 'taxYearEnd', formatter: this.formatDate},
            {key: 'payrollTaxElection', formatter: this.formatBoolean},
            {key: 'federalCreditYears', formatter: this.formatFederalCreditYears},
            {key: 'stateCreditYears', formatter: this.formatStateCreditYears},
            {
               key: 'firstRndEffort',
               label: 'Year of First R&D Effort',
               formatter: this.formatTaxYear,
            },
            {
               key: 'latestCredit',
               label: 'Year of Latest Credit Generation',
               formatter: this.formatTaxYear,
            },
            {key: 'cpaInfo', label: 'CPA Name', formatter: this.subAttr('name')},
            {key: 'cpaInfo', label: 'CPA Firm', formatter: this.subAttr('firm')},
            {key: 'cpaInfo', label: 'CPA Email', formatter: this.subAttr('email')},
            {key: 'contactInfo', label: 'Contact Name', formatter: this.subAttr('name')},
            {key: 'contactInfo', label: 'Contact Email', formatter: this.subAttr('email')},
         ],
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         industryMap: 'industries/industryMap',
      }),

      tableItems() {
         let items = [];

         for (const attr of this.companyAttrs) {
            var {key, label, formatter} = attr;
            const value = this.company[key];

            if (label === undefined) {
               // convert camelcase to sentence
               label = key.replace(/[A-Z]/g, ' $&');
               label = label.charAt(0).toUpperCase() + label.slice(1);
            }

            const formattedValue = formatter ? formatter(value) : value;

            items.push({key: label, value: formattedValue});
         }

         return items;
      },
   },

   methods: {
      formatIndustry(value) {
         if (null === this.industryMap) {
            return value;
         } else {
            return this.industryMap[value]?.name;
         }
      },

      formatEntityType(value) {
         if (value) {
            const entityTypeMap = {
               S_CORP: 'S Corp',
               C_CORP: 'C Corp',
               PARTNERSHIP: 'Partnership',
            };
            return entityTypeMap[value];
         } else {
            return value;
         }
      },

      formatFederalCreditYears(value) {
         if (value.years) {
            return value.years.join(', ');
         } else {
            return '';
         }
      },

      formatStateCreditYears(value) {
         let stateStrings = [];
         value.forEach((item) => {
            let stateString = `${item.state.toUpperCase()}: `;
            stateString += `[${item.years.join(', ')}]`;
            stateStrings.push(stateString);
         });
         return stateStrings.join(' ');
      },

      formatTaxYear(value) {
         if (this.$constants().MIN_TAX_YEAR === value) {
            return `${this.$constants().MIN_TAX_YEAR} or prior`;
         }
         return value;
      },

      formatBoolean(value) {
         return value ? 'yes' : 'no';
      },

      formatDate(value) {
         if (value) {
            return value.toLocaleDateString();
         }
         return value;
      },

      subAttr(key) {
         return (obj) => obj[key];
      },
   },
};
</script>

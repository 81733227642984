<template>
   <b-container v-if="loaded">
      <b-row class="title-row mt-3 mb-4">
         <b-col class="d-flex align-items-center justify-content-between">
            <h1 class="d-inline-block mb-0" style="text-transform: none">
               {{ company.companyName }}
            </h1>

            <div class="d-flex align-items-center">
               <b-button
                  v-if="isAdmin"
                  class="mr-2"
                  :to="{name: 'admin-company', params: {id: $companyId}}"
               >
                  Admin View
               </b-button>
               <b-button
                  id="btn-export-data"
                  variant="primary"
                  :to="{name: 'company-export', params: {id: $companyId}}"
               >
                  Export Data
               </b-button>
            </div>
         </b-col>
      </b-row>

      <b-alert variant="warning" :show="!hasCompanyRole && hasStaff">
         Some actions are unavailable to users not assigned to this company.
      </b-alert>

      <b-alert variant="danger" :show="!hasStaff">
         This company has no assigned staff. This will limit some functionality for Customer and SME
         users associated with this company.
      </b-alert>

      <CompanyInformation />

      <ProjectsCard :hasCompanyRole="hasCompanyRole" @deleteQuestionnaire="deleteQuestionnaire" />

      <ActivitiesCard />

      <CopyConfigCard />

      <UploadsCard :hasCompanyRole="hasCompanyRole" />

      <StaffRolesCard
         :hasCompanyRole="hasCompanyRole"
         :businessManagers="businessManagers"
         :projectManagers="projectManagers"
         :projectAssociates="projectAssociates"
      />

      <CompanyUserCard />

      <StudyCard :hasCompanyRole="hasCompanyRole" />

      <EmployeesCard v-if="activeStudyId" />

      <CompanyQuestionnaireCard v-if="activeStudyId" @deleteQuestionnaire="deleteQuestionnaire" />

      <ProjectQuestionnaireCard v-if="activeStudyId" @deleteQuestionnaire="deleteQuestionnaire" />

      <TimeSurveyCard v-if="activeStudyId" :error="timeSurvey.error" />

      <ContractorsCard v-if="activeStudyId" />

      <SuppliesCard v-if="activeStudyId" />

      <CloudCard v-if="activeStudyId" />
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

import ErrorCodes from '@/helpers/errorCodes';

import CompanyInformation from './widgets/CompanyInformation';
import StudyCard from './widgets/StudyCard';
import EmployeesCard from './widgets/EmployeesCard';
import ActivitiesCard from './widgets/ActivitiesCard';
import ProjectsCard from './widgets/ProjectsCard';
import CompanyQuestionnaireCard from './widgets/CompanyQuestionnaireCard';
import ProjectQuestionnaireCard from './widgets/ProjectQuestionnaireCard';
import TimeSurveyCard from './widgets/TimeSurveyCard';
import ContractorsCard from './widgets/ContractorsCard';
import SuppliesCard from './widgets/SuppliesCard';
import CloudCard from './widgets/CloudCard';
import CopyConfigCard from './widgets/CopyConfigCard';
import StaffRolesCard from './widgets/StaffRolesCard';
import CompanyUserCard from './widgets/CompanyUserCard';
import UploadsCard from './widgets/UploadsCard.vue';

export default {
   name: 'CompanyDetail',

   components: {
      CompanyInformation,
      StudyCard,
      EmployeesCard,
      ActivitiesCard,
      ProjectsCard,
      CompanyQuestionnaireCard,
      ProjectQuestionnaireCard,
      TimeSurveyCard,
      ContractorsCard,
      SuppliesCard,
      CloudCard,
      CopyConfigCard,
      StaffRolesCard,
      CompanyUserCard,
      UploadsCard,
   },

   async created() {
      this.$store.commit('companies/clearStudies');
      this.$store.commit('employees/clear');
      this.$store.commit('activities/clear');
      this.$store.commit('projects/clear');
      this.$store.commit('timesurvey/clearPeriods');

      await this.fetchData();
      this.loaded = true;
   },

   data() {
      return {
         loaded: false,
         timeSurvey: {
            error: null,
         },
      };
   },

   computed: {
      ...mapGetters({
         isAdmin: 'isAdmin',
         isStaff: 'isStaff',
         profile: 'profile',
         staffUsers: 'users/staffUsers',
         company: 'companies/currentCompany',
         hasOpenStudy: 'companies/hasOpenStudy',
         activeStudyId: 'companies/activeStudyId',
      }),

      // The staff accounts assigned to this company as business managers
      businessManagers() {
         return this.staffUsers.filter((user) => {
            return this.company.businessManagers.includes(user.id);
         });
      },

      // The staff accounts assigned to this company as project managers
      projectManagers() {
         return this.staffUsers.filter((user) => {
            return this.company.projectManagers.includes(user.id);
         });
      },

      // The staff accounts assigned to this company as project associates
      projectAssociates() {
         return this.staffUsers.filter((user) => {
            return this.company.projectAssociates.includes(user.id);
         });
      },

      /** Can the current user act on this company? Staff users must have a role. */
      hasCompanyRole() {
         if (null === this.company) {
            return false;
         }
         const id = this.profile.id;
         if (this.isAdmin) {
            return true;
         } else if (
            this.businessManagers.map((user) => user.id).includes(id) ||
            this.projectManagers.map((user) => user.id).includes(id) ||
            this.projectAssociates.map((user) => user.id).includes(id)
         ) {
            return true;
         } else {
            return false;
         }
      },

      /** True if any staff members have an assigned role with this company */
      hasStaff() {
         return (
            this.businessManagers.length > 0 ||
            this.projectManagers.length > 0 ||
            this.projectAssociates.length > 0
         );
      },
   },

   methods: {
      async fetchData() {
         const requests = [
            // fetch company info
            this.$store
               .dispatch('companies/loadCompany', {companyId: this.$companyId})
               .then(() => {
                  if (this.hasCompanyRole) {
                     requests.push(this.fetchRestrictedData());
                  }
               })
               .catch((err) => {
                  this.$store.commit('showAlert', {
                     response: err.response,
                     fallbackMsg: 'Failed to fetch company information',
                     seconds: 5,
                  });
               }),

            // fetch industries
            this.$store.dispatch('industries/loadIndustries'),

            // fetch users
            this.$store.dispatch('users/loadUsers', {enabledOnly: true}),
         ];

         await this.blockUntilAllSettled(requests);
      },

      // Fetch data restricted to staff associated with this company
      async fetchRestrictedData() {
         const requests = [];

         if (this.activeStudyId) {
            // fetch company questionnaires
            requests.push(
               this.$store
                  .dispatch('questionnaire/loadQuestionnaires', {companyId: this.$companyId})
                  .catch((err) => {
                     this.$store.commit('showAlert', {
                        response: err.response,
                        fallbackMsg: 'Failed to load questionnaires',
                        seconds: 5,
                     });
                  })
            );

            // Fetch fully configured time survey years
            requests.push(
               this.$store
                  .dispatch('timesurvey/loadPossiblePeriods', {companyId: this.$companyId})
                  .then((data) => {
                     this.timeSurvey.error = data.error;
                  })
            );
         }

         // Fetch studies
         requests.push(this.$store.dispatch('companies/loadStudies', {companyId: this.$companyId}));

         // fetch upload categories
         requests.push(
            this.$store.dispatch('uploads/loadCompanyUploadCategories', {
               companyId: this.$companyId,
               summary: true,
               force: true,
            })
         );

         requests.push(this.$store.dispatch('projects/loadProjects', {companyId: this.$companyId}));

         // fetch activities
         requests.push(
            this.$store.dispatch('activities/loadActivities', {companyId: this.$companyId})
         );

         return await Promise.allSettled(requests);
      },

      /** Send the initial delete request to determine if this object can be deleted */
      async deleteQuestionnaire(questionnaire) {
         try {
            await this.blockingRequest('questionnaire/deleteQuestionnaire', {
               questionnaireId: questionnaire.id,
            });
            const proceed = await this.$bvModal.msgBoxConfirm(
               `Are you sure you want to delete questionnaire "${questionnaire.title}"?`,
               {
                  id: 'modal-delete-questionnaire',
                  title: 'Delete Questionnaire?',
                  centered: true,
                  okTitle: 'Delete',
                  okVariant: 'danger',
               }
            );
            if (proceed) {
               await this.blockingRequest('questionnaire/deleteQuestionnaire', {
                  questionnaireId: questionnaire.id,
                  force: true,
               });
            }
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected questionnaire has related data, and cannot be deleted by a staff user. Contact an admin user to delete this questionnaire.',
                  {
                     title: 'Questionnaire Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },
   },

   watch: {
      hasCompanyRole(value) {
         if (value) {
            this.fetchRestrictedData();
         }
      },

      activeStudyId() {
         this.fetchRestrictedData();
      },
   },
};
</script>

<template>
   <b-form-select
      :id="`select-period-${ident}`"
      :options="studyPeriods"
      text-field="label"
      value-field="id"
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      :disabled="disabled"
   >
      <template v-if="emptyText" #first>
         <b-form-select-option :value="null" :disabled="emptyDisabled">
            {{ emptyText }}
         </b-form-select-option>
      </template>
   </b-form-select>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      value: Number,
      emptyText: String,
      emptyDisabled: Boolean,
      activeStudy: Boolean,
      disabled: Boolean,

      // Used to provide a unique identifier for each form element
      // when there are multiple StudyPeriodSelects in the same form.
      ident: {
         default: '',
      },
   },

   computed: {
      ...mapGetters({
         openStudyPeriods: 'companies/openStudyPeriods',
         activeStudyPeriods: 'companies/activeStudyPeriods',
      }),

      studyPeriods() {
         return this.activeStudy ? this.activeStudyPeriods : this.openStudyPeriods;
      },
   },
};
</script>

/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import {Project} from '../models/project';
import ErrorCodes from '@/helpers/errorCodes';
import {sortBy} from '../../helpers/utils';

const state = () => ({
   projects: {},
});

const getters = {
   projects: (state) => Object.values(state.projects).sort(sortBy('name')),

   projectMap: (state) => state.projects,

   /** Projects associated with study periods in the active study */
   projectsInStudy: (state, getters, rootState, rootGetters) => {
      const activeStudyPeriods = rootGetters['companies/activeStudyPeriods'];
      return getters.projects.filter((project) => {
         return project.periods.some((period) => activeStudyPeriods.includes(period));
      });
   },

   /** Returns a method that returns projects that overlap a given year */
   projectsInPeriod: (state, getters) => {
      return (periodId) => {
         return getters.projects.filter((project) => {
            return project.periodIds.includes(+periodId);
         });
      };
   },
};

const mutations = {
   // Clear the projects state
   clear: (state) => {
      state.projects = {};
   },

   // Set the projects in the state
   setProjects: (state, {projects}) => {
      projects.forEach((project) => {
         Vue.set(state.projects, project.id, project);
      });
   },

   // Update or add a project
   updateProject: (state, {project}) => {
      Vue.set(state.projects, project.id, project);
   },

   deleteProject: (state, {projectId}) => {
      if (projectId in state.projects) {
         Vue.delete(state.projects, projectId);
      }
   },
};

const actions = {
   // Create a project
   async createProject({commit}, {project, force}) {
      const projData = (
         await this._vm.$http.post('/api/project', project.export(), {params: {force}})
      ).data;

      commit('updateProject', {project: new Project(projData)});
   },

   // Edit a project
   async editProject({commit}, {project, force}) {
      let projData;

      projData = (
         await this._vm.$http.put(`/api/project/${project.id}`, project.export(), {params: {force}})
      ).data;

      commit('updateProject', {project: new Project(projData)});
   },

   /** Load a single project */
   async loadProject({commit}, {projectId}) {
      const projectData = (await this._vm.$http.get(`/api/project/${projectId}`)).data;
      const project = new Project(projectData);
      commit('setProjects', {projects: [project]});
   },

   /** Load all projects for a company */
   async loadProjects({commit, getters}, {companyId}) {
      const projData = (await this._vm.$http.get(`/api/company/${companyId}/project`)).data.results;
      const projects = projData.map((data) => new Project(data));
      commit('clear');
      commit('setProjects', {projects});
      return getters.projects;
   },

   /** Delete a project */
   async deleteProject({commit}, {projectId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/project/${projectId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteProject', {projectId});
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

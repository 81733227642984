import Vue from 'vue';
import {v4 as uuid4} from 'uuid';
import camelcaseKeys from 'camelcase-keys';
import {Timer} from '@/helpers/utils';

const MsgTypes = Object.freeze({
   PROGRESS: 'PROGRESS',
   THREAD_LIST: 'THREAD_LIST',
   THREAD_MSGS: 'THREAD_MSGS',
   MESSAGE: 'MESSAGE',
   CONTRACTOR_UPDATE: 'CONTRACTOR_UPDATE',
   STUDY_UPDATE: 'STUDY_UPDATE',
});

const CONNECTION_WAIT_MIN = 2; // 2 seconds
const CONNECTION_WAIT_MAX = 7200; // 2 hours

const messageHandlers = {
   [MsgTypes.PROGRESS](data) {
      this.store.commit('progress/setData', {
         data: camelcaseKeys(data.data.progress, {deep: true}),
      });
   },

   [MsgTypes.THREAD_LIST](data) {
      const threads = camelcaseKeys(data.data.threads, {deep: true});
      this.store.commit('messaging/setThreads', {threads});
      if (this.store.getters.isClient && threads.length > 0) {
         this.store.commit('messaging/setCurrentThread', {threadId: threads[0].thread.id});
      }
   },

   [MsgTypes.THREAD_MSGS](data) {
      const messages = camelcaseKeys(data.data.messages, {deep: true});
      this.store.commit('messaging/clearMessages');
      this.store.commit('messaging/setMessages', {messages});
   },

   [MsgTypes.MESSAGE](data) {
      const message = camelcaseKeys(data.data.message, {deep: true});
      const thread = camelcaseKeys(data.data.thread, {deep: true});
      this.store.dispatch('messaging/newMessage', {message, thread});
   },

   [MsgTypes.CONTRACTOR_UPDATE](data) {
      const cellData = camelcaseKeys(data.data.update, {deep: true});
      this.store.commit('contractorTime/updateCell', {
         ...cellData,
         periodId: cellData.speriodId,
         value: cellData.val,
      });
   },

   [MsgTypes.STUDY_UPDATE](data) {
      const studyId = +data.data.update.study.id;
      const companyId = this.store.getters['companies/currentCompany'].id;
      const activeStudyId = +this.store.getters['companies/activeStudyId'];

      if (studyId === activeStudyId) {
         // Current study config may be impacted, so force a refresh
         this.store.commit('setStudyConfigChanged', {value: true});
      } else {
         // Quietly reload study data
         this.store.dispatch('companies/loadCompany', {companyId});
      }

      // Quietly reload progress data
      this.progress();
   },
};

/* Websocket connection to server.
 *
 * Server delivers messages in the following format:
 *
 *  {
 *    event: str,
 *    uuid: UUID | null,
 *    data: {...}
 *  }
 *
 *  Notes:
 *
 *    * event indicates the type of call being made from the server.
 *    * uuid, when not null, will indicate the message is in
 *      response to a client-initiated request using the same uuid,
 *      allowing client to match response with request.  If null,
 *      the message is server-initiated.
 *    * data is server delivered data
 */
class RDIGWebSocket {
   constructor({url = null, handlers, store}) {
      if (!url) {
         const protocol = window.location.protocol.indexOf('https') !== -1 ? 'wss' : 'ws';
         const host = window.location.host;
         url = `${protocol}://${host}/api/ws`;
      }
      this.url = url;
      this.store = store;

      // set connection-wait properties
      this.currentWait = CONNECTION_WAIT_MIN; // How log to wait for a reconnect
      this.currentTimer = null; // Timer associated with the wait

      // Bind `this` on each event handler
      this.handlers = Object.entries(handlers).reduce((obj, [key, value]) => {
         obj[key] = value.bind(this);
         return obj;
      }, {});
   }

   /**
    * _cancelTimer() - cancel the current timeout timer that might have
    * been set earlier to open a connection.
    */
   _cancelTimer() {
      if (this.currentTimer !== null) {
         this.currentTimer.cancel();
         this.currentTimer = null;
      }
   }

   /**
    * _wait() - sleep for `this.currentWait` seconds then double
    * currentWait for the next call to _wait(), up to
    * CONNECTION_WAIT_MAX. This creates an exponential backoff from
    * reconnection attempts.  When a connection eventually occurs,
    * currentWait will be reset to CONNECTION_WAIT_MIN.
    *
    * This will resolve to `true` if the timer ran out; `false` if the
    * timer was canceled.
    */
   async _wait() {
      // save reference to `this` for inside the timer.
      const self = this;

      this.currentTimer = new Timer(() => {
         self.currentWait = Math.min(self.currentWait * 2, CONNECTION_WAIT_MAX);
      }, this.currentWait * 1000);

      return await this.currentTimer.wait();
   }

   /**
    * Async function that resolves when the websocket connection leaves CONNECTING state.
    *
    * @param {int} timeout - maximum time to wait in ms.
    *
    * Resolves to `true` if websocket is OPEN, otherwise `false`.
    */
   async _waitConnectionReady(timeout = 10000) {
      const isOpen = () => this.ws.readyState === WebSocket.OPEN;

      // if we're NOT in CONNECTING state, there's nothing we can do
      // here, so return indicating OPENness
      if (this.ws.readyState !== WebSocket.CONNECTING) {
         return isOpen();
      }

      // in CONNECTING state.  Wait timeout microseconds, polling the
      // state every 100ms.
      const interval = 100;
      const ttl = timeout / interval;
      let loop = 0;
      while (this.ws.readyState === WebSocket.CONNECTING && loop < ttl) {
         await new Timer(null, interval).wait();
         loop++;
      }
      // return w/ OPENness
      return isOpen();
   }

   /**
    * connect() - Connect to server.  Set event handlers, each binding
    * `this` to this instance.
    */
   connect() {
      let ws;

      // close any extant timer to attempt a connect
      this._cancelTimer();

      // create socket and set event handlers, binding each to this
      ws = new WebSocket(this.url);
      ws.addEventListener('close', this.onclose.bind(this));
      ws.addEventListener('error', this.onerror.bind(this));
      ws.addEventListener('message', this.onmessage.bind(this));
      ws.addEventListener('open', this.onopen.bind(this));

      // announce ws state to the app
      this.store.commit('setWsReadyState', ws);

      this.ws = ws;
      this.requests = {};
   }

   /*
    *  WebSocket event listeners.  Each is bound to `this` in the connect() method.
    */
   onopen() {
      // OPEN

      // announce ws state to the app
      this.store.commit('setWsReadyState', this.ws);
      console.log('WebSocket connection OPENED.');

      // cancel any connection timer and reset currentWait
      this._cancelTimer();
      this.currentWait = CONNECTION_WAIT_MIN;

      // subscribe to all channels and fetch messaging thread-list
      this.subscribeAll();
      this.threadList();
   }

   onerror(event) {
      // an error has occurred on the socket - the spec guarantees
      // this will be followed by a CLOSE, so here we simply log an
      // error occurred

      // announce ws state to the app and log
      this.store.commit('setWsReadyState', this.ws);
      console.log('WebSocket received an ERROR:', event);
   }

   async onclose() {
      // CLOSE

      // announce ws state to the app and log
      this.store.commit('setWsReadyState', this.ws);
      console.log(
         `WebSocket CLOSED.  Attempting to reestablish connection in ${this.currentWait} seconds.`
      );

      // wait and attempt re-connect, but only if the wait is not canceled
      if (await this._wait()) {
         this.connect();
      }
   }

   /**
    * Handle a message from the server over the websocket connection. Two types of
    * message handler are available:
    *   * When sending a message to the server, a callback method may be provided to handle the response.
    *   * A handler method can be provided to handle all messages with a particular `event` type.
    */
   onmessage(event) {
      let data = JSON.parse(event.data);
      let request;
      // attempt to match up message with request
      if (data.uuid) {
         request = this.requests[data.uuid];
         if (request === undefined) {
            console.debug('Response to unknown request:', data.uuid);
            return;
         }
         console.debug(`Reply to ${request.msg.event} (${data.uuid}):`, data);
         if (request.callback !== null) {
            request.callback(data);
         }
         delete this.requests[data.uuid];
      } else {
         console.debug('Server-initiated message:', data);
      }
      if (data.event in this.handlers) {
         this.handlers[data.event](data);
      }
   }

   /**
    * Send a formatted message to the server.
    * @param {String} event - The event type
    * @param {Object} data - keyword arguments for the event
    * @param {Function} callback - a callback method to handle the response to this message
    */
   async send({event, data = {}, callback = null} = {}) {
      /* Send an event to the server. */
      if (!(await this._waitConnectionReady())) {
         console.error(`WebSocket not OPEN...not sending ${event}`);
         return;
      }
      let token = await Vue.updateToken();
      let uuid = uuid4();
      let msg = {
         event: event,
         uuid: uuid,
         auth: token,
         data: data,
      };
      console.debug('Sending:', msg);
      this.requests[uuid] = {msg, callback};
      this.ws.send(JSON.stringify(msg));
   }

   /** Send authentication request.

       Server sends:
       {
       event: "AUTH",
       uuid: UUID,
       data: {user: <authenticated user object>}
       }
   */
   auth() {
      this.send({event: 'AUTH'});
   }

   /** Request list of all possible channels.

       Server sends:
       {
       event: "CHANNELS",
       uuid: UUID,
       data: {channels: [channel, channel, ...]}
       }
   */
   channels() {
      this.send({event: 'CHANNELS'});
   }

   /** Close the websocket.

       Server sends ack:
       {
       event: "CLOSE",
       uuid: UUID,
       data: {}
       }
   */
   close() {
      this.send({event: 'CLOSE'});
   }

   /* Fetch progress for the current user.  If the current user is
    * STAFF or ADMIN, company_id is required, else it is ignored by
    * the server and the CAU's company is used.

    Server sends ack:
    {
    event: "PROGRESS",
    uuid: UUID,
    data: {progress: PROGRESS}
    }

   */
   progress({company_id = null, callback = null} = {}) {
      company_id = company_id || null;
      this.send({event: 'PROGRESS', data: {company_id: company_id}, callback});
   }

   /* Subscribe to specified channels.

      Server sends list of subscribed channels:
      {
      event: "SUBSCRIBED",
      uuid: UUID,
      data: {channels: [channel, channel, ...]}
      }
   */
   subscribe(...channels) {
      this.send({event: 'SUBSCRIBE', data: {channels: channels}});
   }

   /* Subscribe to all possible channels.

      Server sends list of subscribed channels:
      {
      event: "SUBSCRIBED",
      uuid: UUID,
      data: {channels: [channel, channel, ...]}
      }
   */
   subscribeAll() {
      this.send({event: 'SUBSCRIBE_ALL'});
   }

   /* Fetch the list of all threads.

      Server sends list of threads:
      {
      event: "THEAD_LIST",
      uuid: UUID,
      data: {threads: []}
      }
   */
   threadList() {
      this.send({event: 'THREAD_LIST'});
   }

   /* Fetch the messages in a thread.

      Server sends list of messages:
      {
      event: "THREAD_MSGS",
      uuid: UUID,
      data: {}
      }
   */
   threadMsgs({threadId = null, callback = null} = {}) {
      this.send({event: 'THREAD_MSGS', data: {thread_id: threadId}, callback});
   }

   /* Post a message to a thread

      Server responds with the message:
      {
      event: "MESSAGE",
      uuid: UUID,
      data: {}
      }
   */
   postMessage(threadId, content) {
      this.send({event: 'MESSAGE_POST', data: {reply_to: threadId, content}});
   }

   /** Update the `seen` state on a message */
   updateMessage({messageId, seen, callback = null}) {
      this.send({event: 'MESSAGE_UPDATE', data: {message_id: messageId, seen}, callback});
   }

   /* Unsubscribe from specified channels.

      Server sends list of subscribed channels:
      {
      event: "SUBSCRIBED",
      uuid: UUID,
      data: {channels: [channel, channel, ...]}
      }
   */
   unsubscribe(...channels) {
      this.send({event: 'UNSUBSCRIBE', data: {channels: channels}});
   }

   /* Unsubscribe from all currently subscribed channels.

      Server sends list of subscribed channels:
      {
      event: "SUBSCRIBED",
      uuid: UUID,
      data: {channels: [channel, channel, ...]}
      }
   */
   unsubscribeAll() {
      this.send({event: 'UNSUBSCRIBE_ALL'});
   }
}

export default {
   install(Vue, options) {
      Vue.prototype.$websocket = new RDIGWebSocket({handlers: messageHandlers, ...options});
   },
};

import Vue from 'vue';

import RdigLayout from '@/views/layouts/RdigLayout';
Vue.component('RdigLayout', RdigLayout);

import CustomerLayout from '@/views/layouts/CustomerLayout';
Vue.component('CustomerLayout', CustomerLayout);

import EmptyLayout from './EmptyLayout.vue';
Vue.component('EmptyLayout', EmptyLayout);

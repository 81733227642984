export const QuestionnaireTypes = Object.freeze({
   COMPANY: 'COMPANY',
   PROJECT: 'PROJECT',
   PROFILE: 'PROFILE',
});

export const AnswerTypes = Object.freeze({
   TEXT: 'TEXT',
   EMAIL: 'EMAIL',
   DATE: 'DATE',
   INTEGER: 'INTEGER',
   NUMBER: 'NUMBER',
   BOOLEAN: 'BOOLEAN',
   CHOICE: 'CHOICE',
   PROJECT_YEARS: 'PROJECT_YEARS',
});

export function Answer() {
   this.answer = null;
   this.submitted = null;
   this.submittedBy = null;
   this.dirty = false;
}

let questionKey = 0;

export function Question(key = null) {
   this.qorder = 0;
   this.question = null;
   this.ansType = null;
   this.validation = {
      minimum: null,
      maximum: null,
      options: [],
      multiple: false,
   };
   this.answer = null;
   this.answers = [];

   if (key) {
      this.key = key;
   } else {
      this.key = questionKey++;
   }
}

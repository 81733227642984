<template>
   <CollapseCard title="Company-Specific Text" ident="client-copy">
      <div class="d-flex align-items-center justify-content-end mb-2">
         <b-button
            id="btn-configure-copy"
            variant="primary"
            :to="{name: 'company-copy-config', params: {id: $companyId}}"
         >
            Configure Company-Specific Text
         </b-button>
      </div>

      <b-list-group>
         <b-list-group-item v-for="(prompt, key, index) in PromptTypes" :key="key">
            <b :id="`prompt-title-${index + 1}`" class="mb-1">{{ prompt.title }}</b>

            <p :id="`prompt-${index + 1}`">{{ company.prompts[key] }}</p>
         </b-list-group-item>
      </b-list-group>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';
import {PromptTypes} from '@/store/modules/companies';

export default {
   data() {
      return {
         PromptTypes,
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
      }),
   },
};
</script>

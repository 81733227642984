<template>
   <b-form class="mx-auto mt-3" @submit.prevent>
      <b-alert
         variant="success"
         :show="savedAlertCountdown"
         fade
         dismissible
         @dismissed="savedAlertCountdown = 0"
         @dismiss-count-down="(val) => (savedAlertCountdown = val)"
      >
         Your template was saved
      </b-alert>

      <b-form-group
         v-if="!isTemplate"
         :label="isProject ? 'Project' : 'Questionnaire Title'"
         label-for="input-title"
         label-class="h4"
         :invalidFeedback="titleInvalidFeedback"
      >
         <b-form-input
            id="input-title"
            ref="title"
            v-model="title"
            :state="!$v.title.$invalid"
         ></b-form-input>
      </b-form-group>

      <b-form-group
         v-if="isProject"
         label="Business Component"
         label-for="select-project"
         label-class="h4"
         class="mb-4"
      >
         <b-form-select
            id="select-project"
            :options="projectsInStudy"
            text-field="name"
            value-field="id"
            v-model="projectId"
            :disabled="isEdit"
         >
            <template #first>
               <b-form-select-option :value="null" disabled>
                  Please select one
               </b-form-select-option>
            </template>
         </b-form-select>
      </b-form-group>

      <b-row v-for="(question, index) in questions" :key="question.key" class="mb-4">
         <b-col cols="11">
            <QuestionForm
               :index="index"
               :question="question"
               :deleteDisabled="questions.length <= 1"
            />
         </b-col>

         <b-col cols="1" class="pl-0 d-flex flex-column align-items-end justify-content-between">
            <b-button-group vertical>
               <button
                  id="btn-chevron-up"
                  class="icon-btn icon-btn-secondary"
                  v-if="index !== 0"
                  @click="reorderQuestionUp(index)"
                  type="button"
               >
                  <b-icon-chevron-up></b-icon-chevron-up>
               </button>
               <button
                  id="btn-chevron-down"
                  class="icon-btn icon-btn-secondary"
                  v-if="index !== questions.length - 1"
                  @click="reorderQuestionDown(index)"
                  type="button"
               >
                  <b-icon-chevron-down></b-icon-chevron-down>
               </button>
            </b-button-group>
         </b-col>
      </b-row>
      <b-row>
         <b-col cols="11" class="d-flex justify-content-between">
            <b-button
               id="btn-add-question"
               variant="primary"
               @click="addQuestion(questions.length)"
            >
               <b-icon-plus></b-icon-plus> Add a question
            </b-button>

            <div class="d-flex align-items-center">
               <b-button
                  v-if="!isTemplate"
                  id="btn-save-template"
                  variant="primary"
                  class="mr-2"
                  @click="configureTemplate"
                  :disabled="$v.questions.$invalid"
               >
                  Save as template
               </b-button>

               <b-button
                  v-if="!isTemplate && isProject && !isEdit"
                  id="btn-save-multiple"
                  variant="primary"
                  class="mr-2"
                  @click="startSavingMultiple"
                  :disabled="$v.$invalid"
               >
                  Save multiple
               </b-button>

               <b-button
                  id="btn-save-questionnaire"
                  variant="primary"
                  @click="submit"
                  :disabled="$v.$invalid"
               >
                  Save
               </b-button>
            </div>
         </b-col>
      </b-row>

      <b-modal
         id="modal-config-template"
         title="Configure Template"
         centered
         size="xl"
         @ok="saveAsTemplate"
         :ok-disabled="!templateConfigValid"
         @hidden="onTemplateSaveHidden"
      >
         <p class="text-dark">Save these questions as a reusable template.</p>
         <TemplateConfigForm />
      </b-modal>

      <b-modal
         id="modal-save-multiple"
         title="Save Multiple Questionnaires"
         size="lg"
         centered
         @ok="saveMultiple"
         :ok-disabled="!multipleTitlesValid"
      >
         <p class="text-dark">
            Save multiple copies of this questionnaire by entering a unique Project name for each
            copy.
         </p>

         <b-form-group
            v-for="(title, index) in projectTitles"
            :key="index"
            invalid-feedback="A questionnaire with this title already exists"
            :state="isDuplicateTitle(projectTitles[index]) ? false : null"
         >
            <b-input-group>
               <b-form-input
                  :id="`input-questionnaire-${index + 1}`"
                  v-model="projectTitles[index]"
                  :state="isDuplicateTitle(projectTitles[index]) ? false : null"
               ></b-form-input>
               <b-input-group-append>
                  <b-button
                     :id="`btn-delete-${index + 1}`"
                     variant="outline-danger"
                     :disabled="projectTitles.length < 2"
                     @click="removeTitle(index)"
                  >
                     <b-icon-x />
                  </b-button>
               </b-input-group-append>
            </b-input-group>
         </b-form-group>
         <div class="d-flex align-items-center justify-content-end mr-2">
            <button id="btn-add-questionnaire" class="icon-btn icon-btn-primary" @click="addTitle">
               <b-icon-plus font-scale="1.4" />
            </button>
         </div>
      </b-modal>
   </b-form>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, requiredIf} from 'vuelidate/lib/validators';
import {AnswerTypes} from '@/helpers/types';

import QuestionForm from '@/views/questionnaire/widgets/QuestionForm';
import TemplateConfigForm from './TemplateConfigForm';

export default {
   name: 'QuestionnaireForm',

   components: {
      QuestionForm,
      TemplateConfigForm,
   },

   props: {
      // An array of titles that have already been used, and are thus invalid
      duplicateTitles: {
         type: Array,
         default: () => [],
      },

      // When true, this is a project questionnaire
      isProject: {
         type: Boolean,
         default: false,
      },

      // When true, we're editing an existing questionnaire. Otherwise, we're creating a new questionnaire.
      isEdit: {
         type: Boolean,
         default: false,
      },

      isTemplate: {
         type: Boolean,
         default: false,
      },

      parentValid: {
         type: Boolean,
         default: true,
      },
   },

   async created() {
      await this.blockingRequest('industries/loadIndustries');
   },

   mounted() {
      if (!this.isEdit && !this.isTemplate) {
         this.$refs.title.focus();
      }
   },

   data() {
      return {
         savedAlertCountdown: 0,
         projectTitles: [],
         templateSaved: false,
      };
   },

   computed: {
      ...mapGetters({
         questions: 'questionnaire/questions',
         templateConfigValid: 'questionnaire/templateConfigValid',
         projects: 'projects/projects',
         projectMap: 'projects/projectMap',
         company: 'companies/currentCompany',
         projectsInStudy: 'projects/projectsInStudy',
      }),

      title: {
         get() {
            return this.$store.getters['questionnaire/title'];
         },
         set(value) {
            this.$store.commit('questionnaire/setTitle', value);
         },
      },

      titleInvalidFeedback() {
         if (!this.$v.title.required) {
            return 'Required';
         }
         if (!this.$v.title.duplicate) {
            return 'A questionnaire with this title already exists';
         }
         return '';
      },

      projectId: {
         get() {
            return this.$store.getters['questionnaire/projectId'];
         },
         set(value) {
            this.$store.commit('questionnaire/setProjectId', value);
         },
      },

      project() {
         if (this.projectId) {
            return this.projectMap[this.projectId];
         }
         return null;
      },

      companyId() {
         return this.$route.params.id;
      },

      multipleTitlesValid() {
         let valid = true;
         const seen = {};
         this.projectTitles.forEach((title) => {
            if (!title) {
               valid = false;
               return;
            }
            const normalizedTitle = title.toLowerCase();
            if (normalizedTitle in seen) {
               valid = false;
            } else {
               seen[normalizedTitle] = true;
            }

            if (this.isDuplicateTitle(title)) {
               valid = false;
            }
         });
         return valid;
      },
   },

   methods: {
      /**
       * Save the current questionnaire. This is handled by the parent component,
       * so just emit a 'submit' event.
       */
      submit() {
         this.$emit('submit', null);
      },

      /** Add a blank question */
      addQuestion(index = null) {
         this.$store.commit('questionnaire/addQuestion', {index, fromUserInput: true});
      },

      /** Move a question forward 1 */
      reorderQuestionUp(index) {
         this.$store.commit('questionnaire/reorderQuestionUp', index);
      },

      /** Move a question back 1 */
      reorderQuestionDown(index) {
         this.$store.commit('questionnaire/reorderQuestionDown', index);
      },

      /** Display the template configuration modal */
      configureTemplate() {
         this.$store.commit('questionnaire/clearTemplateConfig');
         const config = {
            qtype: this.isProject ? 'PROJECT' : 'COMPANY',
         };
         if (this.company.industryId) {
            config.industries = [this.company.industryId];
         }
         this.$store.commit('questionnaire/setTemplateConfig', config);
         this.$bvModal.show('modal-config-template');
         this.templateSaved = false;
      },

      /** Display the save multiple modal */
      startSavingMultiple() {
         this.projectTitles = [this.title, null];
         this.$bvModal.show('modal-save-multiple');
      },

      /** Remove a project Title */
      removeTitle(index) {
         this.projectTitles.splice(index, 1);
      },

      /** Add a project title */
      addTitle() {
         this.projectTitles.push(null);
      },

      /** Submit the questionnaire with multiple titles */
      saveMultiple() {
         this.$emit('submit', this.projectTitles);
      },

      isDuplicateTitle(title) {
         if (title) {
            return this.duplicateTitles.includes(title.toLowerCase());
         }
         return false;
      },

      async saveAsTemplate() {
         await this.blockingRequest('questionnaire/createTemplate');
         this.savedAlertCountdown = 10;
         this.templateSaved = true;
      },

      onTemplateSaveHidden() {
         if (this.templateSaved) {
            window.scrollTo({top: 0, behavior: 'smooth'});
         }
      },
   },

   validations() {
      return {
         title: {
            required: requiredIf(() => !this.isTemplate),
            duplicate(value) {
               if (this.duplicateTitles.length > 0) {
                  return !this.duplicateTitles.includes(value.toLowerCase());
               }
               return true;
            },
         },
         projectId: {
            required: requiredIf(() => this.isProject),
         },
         questions: {
            $each: {
               question: {
                  required,
               },
               ansType: {
                  required,
               },
               validation: {
                  options(value, question) {
                     if (question.ansType === AnswerTypes.CHOICE) {
                        return (
                           value.options.map((val) => val.value).filter((val) => val).length >= 2
                        );
                     } else {
                        return true;
                     }
                  },

                  minMax(value, question) {
                     let valid = true;
                     if (value.minimum && value.maximum) {
                        valid = valid && value.minimum < value.maximum;
                     }
                     if (question.ansType === AnswerTypes.INTEGER) {
                        if (value.minimum && !Number.isInteger(value.minimum)) {
                           valid = valid && Number.isInteger(value.minimum);
                        }
                        if (value.maximum) {
                           valid = valid && Number.isInteger(value.maximum);
                        }
                     }
                     return valid;
                  },
               },
            },
         },
         parentValid: {
            parentValid(value) {
               return value;
            },
         },
      };
   },
};
</script>

<template>
   <FieldInput
      ref="input"
      :ident="id"
      prepend="$"
      :field="netAmountField"
      :formatter="formatNetAmount"
      :disabled="disabled"
      required
      @input="setAmount"
   />
</template>

<script>
import {mapGetters} from 'vuex';
import {formatCurrency} from '@/helpers/utils';
import FieldInput from '@/components/forms/FieldInput';

export default {
   components: {
      FieldInput,
   },

   props: {
      ident: {
         type: [String, Number],
      },
      summary: {
         type: Object,
         default: () => ({}),
      },
      disabled: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      ...mapGetters({
         adHocData: 'supplies/adHocData',
         vendorsMap: 'supplies/vendorsMap',
      }),

      id() {
         return `input-net-${this.ident}`.replace(/\s/g, '-');
      },

      netAmountField() {
         return this.summary?.netAmount || null;
      },
   },

   methods: {
      formatNetAmount(value) {
         if (value === null) {
            return null;
         }
         return formatCurrency(value / 100);
      },

      async setAmount(netAmount) {
         if (netAmount === '') {
            netAmount = null;
         }

         if (netAmount !== null) {
            netAmount = parseFloat(netAmount.replace(/[^\d.]/g, ''));
            netAmount = netAmount * 100;
         }

         this.$store.dispatch('supplies/saveNetAmount', {
            companyId: this.$companyId,
            summary: this.summary,
            netAmount,
         });
      },
   },
};
</script>

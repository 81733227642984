<template>
   <div>
      <!-- Import File -->
      <b-modal
         id="modal-upload-file"
         centered
         size="lg"
         :ok-disabled="!canImportFile"
         @ok="onFileSelected"
         ok-only
      >
         <template #modal-title>
            <slot name="file-select-title"> Upload your transactions </slot>
         </template>

         <p class="mb-3">
            <slot name="intro"></slot>
         </p>

         <div
            v-if="templateDownload"
            class="d-flex justify-content-center mb-3"
            @click="downloadTemplate"
         >
            <b-button id="btn-download-template" variant="primary"
               >Download Expense Account Template</b-button
            >
         </div>

         <p class="mb-3">
            Imported files don't have to use this exact format. They can contain any number of
            additional columns as long as they include:
            <b>
               {{ requiredColumnNames.join(', ') }}
            </b>
         </p>

         <div class="form-section form-section-light">
            <b-form-row>
               <b-col cols="12" lg="6">
                  <b-form-group
                     label="General Ledger Account Name"
                     label-for="input-account"
                     :invalid-feedback="`Too similar to '${duplicateAccountName}'`"
                  >
                     <template #label>
                        General Ledger Account Name <span class="text-danger">*</span>
                     </template>
                     <b-form-input
                        id="input-account"
                        v-model="newFile.description"
                        :state="duplicateAccountName ? false : null"
                     ></b-form-input>
                  </b-form-group>
               </b-col>
               <b-col cols="12" lg="6">
                  <b-form-group label="XLSX or CSV file" label-for="input-file" class="mb-0">
                     <template #label>
                        XLSX or CSV file <span class="text-danger">*</span>
                     </template>
                     <b-form-file
                        id="input-file"
                        v-model="newFile.file"
                        accept=".csv, .xlsm, .xlsx, .xltx"
                     ></b-form-file>
                  </b-form-group>
                  <div class="d-flex align-items-center justify-content-between">
                     <small :class="[fileSizeExceedsLimit ? 'text-danger' : 'text-muted']">
                        {{ formatBytes(uploadSize) }} Selected
                     </small>
                     <b-form-text>Limit {{ formatBytes(MAX_INTERNAL_FILE_SIZE) }}</b-form-text>
                  </div>
               </b-col>
            </b-form-row>
         </div>

         <template #modal-ok>
            <div id="btn-next" class="d-flex align-items-center">
               Next <b-icon-arrow-right-short class="ml-1" />
            </div>
         </template>
      </b-modal>

      <!-- Select header row -->
      <b-modal
         id="modal-upload-header"
         title="Which row contains the header?"
         centered
         size="xl"
         @ok="onHeaderRowSelected"
      >
         <p class="mb-3">
            If any of the non-data rows in your ledger file contain column headings, select it
            below.
         </p>

         <div class="form-section form-section-light">
            <div class="no-header-container">
               <b-checkbox
                  id="checkbox-no-header"
                  :ref="`cb${-1}`"
                  :checked="headerRow === -1"
                  @change="selectHeaderRow(-1)"
                  :class="{
                     'table-hidden': headerRow !== null && headerRow >= 0,
                     'table-selected': headerRow === -1,
                  }"
               >
                  This file does not contain headers
               </b-checkbox>
            </div>

            <hr />

            <b-table
               id="table-header-row"
               class="scrollbar mb-0"
               :table-class="{'fade-out': tableData.length >= 5}"
               :fields="previewFields"
               :items="tableData"
               selectable
               :selected-variant="null"
               hover
               @row-clicked="(item, idx) => selectHeaderRow(idx)"
               thead-class="hide"
               responsive
               small
               borderless
            >
               <template #cell()="data">
                  <div class="line-clamp-1" style="min-width: 8rem">{{ data.value }}</div>
               </template>
               <template #cell(input)="data">
                  <b-checkbox
                     :id="`checkbox-header-row-${data.index + 1}`"
                     :ref="`cb${data.index}`"
                     :checked="data.index === headerRow"
                     @change="selectHeaderRow(data.index)"
                  ></b-checkbox>
               </template>
            </b-table>
         </div>

         <template #modal-footer="{ok, cancel}">
            <div class="d-flex align-items-center justify-content-between w-100">
               <b-button
                  id="btn-back"
                  class="d-flex align-items-center"
                  variant="secondary"
                  @click="
                     cancel();
                     startNewImport();
                  "
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  Back
               </b-button>

               <b-button
                  id="btn-next"
                  class="d-flex align-items-center ml-2"
                  variant="primary"
                  @click="ok"
                  :disabled="headerRow === null"
               >
                  Next
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </template>
      </b-modal>

      <!-- First Data Row -->
      <b-modal
         id="modal-first-data-row"
         title="Select the first data row"
         size="xl"
         centered
         @ok="onFirstDataRowSelected"
      >
         <p class="text-instruction mb-3">
            If your ledger file contains column headers or any other non-data rows, please select
            the first row that contains data. Everything before this row will be ignored when
            processing your data.
         </p>

         <div class="form-section form-section-light">
            <b-table
               id="table-first-data-row"
               class="scrollbar mb-0"
               :table-class="{'fade-out': tableData.length >= 5}"
               :tbody-tr-class="dataRowRowClass"
               :fields="previewFields"
               :items="tableData"
               selectable
               :selected-variant="null"
               hover
               @row-clicked="(item, idx) => selectFirstDataRow(idx)"
               thead-class="hide"
               responsive
               small
               borderless
            >
               <template #cell()="data">
                  <div class="line-clamp-1" style="min-width: 8rem">{{ data.value }}</div>
               </template>
               <template #cell(input)="data">
                  <b-checkbox
                     v-if="data.index > headerRow"
                     :id="`checkbox-data-row-${data.index + 1}`"
                     :ref="`fdr-cb${data.index}`"
                     :checked="data.index === firstDataRow"
                     @change="selectFirstDataRow(data.index)"
                  ></b-checkbox>
               </template>
            </b-table>
         </div>

         <template #modal-footer="{ok, cancel}">
            <div class="d-flex align-items-center justify-content-between w-100">
               <b-button
                  id="btn-back"
                  class="d-flex-align-items-center"
                  variant="secondary"
                  @click="
                     cancel();
                     startHeaderSelection();
                  "
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  Back
               </b-button>

               <b-button
                  id="btn-next"
                  variant="primary"
                  @click="ok"
                  :disabled="firstDataRow === null"
                  class="d-flex align-items-center ml-2"
               >
                  Next
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </template>
      </b-modal>

      <!-- Map Columns -->
      <b-modal
         id="modal-map-columns"
         title="Map your CSV columns"
         size="xl"
         centered
         @ok="uploadFile"
      >
         <p class="text-instruction mb-3">
            Select the columns of your CSV file that represent the following required fields:
            <b>{{ requiredColumnNames.slice(0, requiredColumnNames.length - 1).join(', ') }},</b>
            and <b>{{ requiredColumnNames[requiredColumnNames.length - 1] }}</b
            >. You may optionally indicate that a column contains a <b>Description</b>.
         </p>

         <div class="form-section form-section-light">
            <b-table
               id="table-map-columns"
               class="scrollbar mb-0"
               :table-class="{'fade-out': tableData.length >= 5}"
               :items="tableData"
               responsive
               borderless
               small
            >
               <template #head()="data">
                  <b-form-select
                     :id="`select-column-${parseInt(data.column) + 1}`"
                     :options="colOptions"
                     style="min-width: 12rem"
                     v-model="mapping[data.column]"
                     @change="(val) => mappingSelected(data.column, val)"
                  ></b-form-select>
               </template>
               <template #cell()="data">
                  <div class="line-clamp-2">{{ data.value }}</div>
               </template>
            </b-table>
         </div>

         <template #modal-footer="{ok, cancel}">
            <div class="d-flex align-items-center justify-content-between w-100">
               <b-button
                  id="btn-back"
                  class="d-flex align-items-center"
                  variant="secondary"
                  @click="
                     cancel();
                     startDataRowSelection();
                  "
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  Back
               </b-button>

               <b-button
                  id="btn-done"
                  variant="success"
                  @click="ok"
                  :disabled="!requiredColumnsMapped"
                  class="ml-2"
               >
                  Import
               </b-button>
            </div>
         </template>
      </b-modal>

      <!-- Validation Errors -->
      <b-modal
         id="modal-failed-upload"
         title="Import Failed"
         title-class="text-danger"
         size="xl"
         centered
      >
         <template v-if="pendingUpload.errorCode === ErrorCodes.FILE_VALIDATION_FAILED">
            <h3>Validation Errors</h3>
            <p>
               Your file contains invalid or incomplete data, so it wasn't saved. Review the errors
               below then upload the file again once you've addressed the issues.
            </p>

            <b-table
               :fields="validationErrorFields"
               sort-by="name"
               :items="pendingUpload.errors"
               class="scrollbar"
               responsive
               sticky-header="500px"
            >
               <template #cell(name)="data">
                  {{ columnlabel(data.value) }}
               </template>
               <template #cell(error)="data">
                  {{ formatErrorMsg(data.item) }}
               </template>
            </b-table>
         </template>
         <template v-else-if="pendingUpload.errorCode === ErrorCodes.UPLOAD_CATEGORY_LOCKED">
            <h6>Upload Category Locked</h6>
            <p>
               The <b>{{ sectionLabel }}</b> upload category has been locked and cannot accept any
               new files at this time. If you need to upload additional files, please message your
               R&D credit team using the messaging feature in the top right corner.
            </p>
         </template>
         <template v-else-if="pendingUpload.errorCode === ErrorCodes.DUPLICATE">
            <h6>Duplicate Account Name</h6>
            <p>An account with the same name has already been uploaded.</p>
         </template>
         <template v-else-if="pendingUpload.errorCode === ErrorCodes.NO_ENCODING_FOUND">
            <h6>Unknown Character Encoding</h6>
            <p>
               The file uploaded is encoded with an unknown character set. Message us if you need
               further help. The character sets we attempted to use while decoding this file were:
            </p>

            <b-table
               :fields="decodingErrorFields"
               :items="pendingUpload.errors"
               sort-by="name"
               class="scrollbar"
               responsive
               sticky-header="500px"
            >
            </b-table>
         </template>
         <template v-else>
            <h6>Unknown error</h6>
            An unknown error occurred. Please try again later or message us.
         </template>
         <template #modal-footer="{cancel}">
            <div class="d-flex align-items-center justify-content-between w-100">
               <b-button
                  id="btn-back"
                  class="d-flex-align-items-center"
                  variant="secondary"
                  @click="
                     cancel();
                     startMapping();
                  "
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  Back
               </b-button>

               <b-button
                  id="btn-next"
                  variant="primary"
                  @click="cancel"
                  class="d-flex align-items-center ml-2"
               >
                  OK
               </b-button>
            </div>
         </template>
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';
import ErrorCodes from '@/helpers/errorCodes';

const slugify = (str) => {
   if (str === null) {
      return null;
   }
   return str.replace(/[~!@#$%^&*()_+={}|[\]\\:”;'<>?,./\s]/g, '_');
};

const UploadSteps = Object.freeze({
   FILE: 'FILE',
   FIRST_DATA_ROW: 'FIRST_DATA_ROW',
   HEADER_ROW: 'HEADER_ROW',
   MAPPING: 'MAPPING',
});

export default {
   props: {
      section: String,
      columns: Array,
      templateDownload: {
         type: Boolean,
         default: false,
      },
      sectionLabel: {
         type: String,
         default: null,
      },
   },

   data() {
      return {
         MAX_INTERNAL_FILE_SIZE,
         ErrorCodes,
         validationErrorFields: [
            {key: 'name', label: 'Field', sortable: true},
            {key: 'record', label: 'Row', sortable: true},
            {key: 'column', sortable: true},
            {key: 'error', sortable: true},
         ],
         decodingErrorFields: [{key: 'encoding', sortable: true}, {key: 'error'}],

         newFile: {
            file: null,
            description: null,
         },
         uploadStep: UploadSteps.FILE,
         headerRow: null,
         firstDataRow: null,
         mapping: {},
      };
   },

   computed: {
      ...mapGetters({
         activeStudyPeriods: 'companies/activeStudyPeriods',
      }),

      periodNames() {
         return this.activeStudyPeriods.map((p) => p.label);
      },

      prettyPeriodNames() {
         const periodNames = this.periodNames;
         let returnString = '';

         if (periodNames.length < 3) {
            returnString += periodNames.join(' and ');
         } else {
            // Join all but the last period names with a comma (,)
            returnString += periodNames.slice(0, periodNames.length - 1).join(', ');

            // Add the last period name preceded by "and"
            returnString += `${returnString} and ${periodNames[-1]}`;
         }
         return returnString;
      },

      uploadModule() {
         return `${this.section}Uploads`;
      },

      uploadedFiles() {
         return this.$store.getters[`${this.uploadModule}/uploadedFiles`];
      },

      pendingUpload() {
         return this.$store.getters[`${this.uploadModule}/pendingUpload`];
      },

      requiredColumnNames() {
         return this.columns
            .filter((col) => col.required)
            .map((col) => (col.labelLong ? col.labelLong : col.label));
      },

      /** Is the string in the new account name input a duplicate? */
      duplicateAccountName() {
         if (this.newFile.description === null) {
            return false;
         }
         const slugName = slugify(this.newFile.description.trim());
         const duplicate = this.uploadedFiles.find(
            (file) =>
               slugify(file.description).localeCompare(slugName, undefined, {
                  sensitivity: 'accent',
               }) === 0
         );
         return duplicate ? duplicate.description : null;
      },

      /** Size of the selected file */
      uploadSize() {
         if (this.newFile.file === null) {
            return 0;
         }
         return this.newFile.file.size;
      },

      /** Does the selected file exceed the max file size for upload */
      fileSizeExceedsLimit() {
         return this.uploadSize >= MAX_INTERNAL_FILE_SIZE;
      },

      /** Is the selected file ready for import? */
      canImportFile() {
         return (
            this.newFile.file !== null &&
            this.newFile.description !== null &&
            this.newFile.description.trim().length > 0 &&
            !this.duplicateAccountName &&
            !this.fileSizeExceedsLimit
         );
      },

      /** Fields for the header select table */
      previewFields() {
         const fields = [...Array(this.pendingUpload.columnCount).keys()].map((idx) => {
            return {
               key: `${idx}`,
               label: '',
            };
         });
         return [{key: 'input', label: ''}, ...fields];
      },

      /** Data for the CSV preview table */
      tableData() {
         let rows;
         switch (this.uploadStep) {
            case UploadSteps.MAPPING:
               rows = this.pendingUpload.trimmedPreviewRows;
               break;
            case UploadSteps.HEADER_ROW:
            case UploadSteps.FIRST_DATA_ROW:
               rows = this.pendingUpload.previewRows;
               break;
            default:
               return [];
         }
         return rows.map((row, rowIdx) => {
            // Init the row object with an entry for each table column, as the row data
            // could potentially have fewer entries than the table
            const rowObj = [...Array(this.pendingUpload.columnCount)].reduce(
               (obj, item, idx) => ({...obj, [idx]: null}),
               {}
            );

            // Write the row data to the row object
            row.forEach((item, colIdx) => {
               rowObj[colIdx] = item;
            });

            switch (this.uploadStep) {
               case UploadSteps.MAPPING:
                  if (rowIdx === 0 && this.headerRow >= 0) {
                     rowObj['_rowVariant'] = 'selected';
                  }
                  break;
               case UploadSteps.FIRST_DATA_ROW:
                  // Insert the row index into the row object
                  rowObj.index = rowIdx;

                  if (rowIdx === this.headerRow) {
                     rowObj['_rowVariant'] = 'selected';
                  } else if (rowIdx < this.headerRow) {
                     rowObj['_rowVariant'] = 'hidden';
                  } else if (rowIdx === this.firstDataRow) {
                     rowObj['_rowVariant'] = 'selected';
                  } else if (this.firstDataRow && rowIdx < this.firstDataRow) {
                     rowObj['_rowVariant'] = 'hidden';
                  }
                  break;
               case UploadSteps.HEADER_ROW:
                  // Insert the row index into the row object
                  rowObj.index = rowIdx;

                  if (rowIdx === this.headerRow) {
                     rowObj['_rowVariant'] = 'selected';
                  } else if (this.headerRow !== null) {
                     rowObj['_rowVariant'] = 'hidden';
                  }
                  break;
               default:
                  break;
            }

            return rowObj;
         });
      },

      /** Options for column mapping */
      colOptions() {
         return [
            {value: null, text: '----'},
            ...this.columns.map((col) => ({value: col.key, text: col.label})),
         ];
      },

      /** Has every required column been mapped? */
      requiredColumnsMapped() {
         let cols = this.columns
            .filter((col) => col.required)
            .map((col) => col.key)
            .reduce((obj, col) => {
               obj[col] = false;
               return obj;
            }, {});

         Object.values(this.mapping).forEach((val) => {
            Object.keys(cols).forEach((col) => (cols[col] = cols[col] || val === col));
         });

         return Object.values(cols).every((val) => val);
      },

      latestUpload() {
         return this.uploadedFiles.reduce((prev, current) => {
            return prev && new Date(prev.uploadedAt) > new Date(current.uploadedAt)
               ? prev
               : current;
         }, null);
      },
   },

   methods: {
      formatBytes,

      columnlabel(key) {
         const col = this.columns.find((c) => c.key === key);
         return col ? col.label : '';
      },

      startNewImport() {
         this.clearImport();
         this.uploadStep = UploadSteps.FILE;
         this.$bvModal.show('modal-upload-file');
      },

      /** Format an upload file error message */
      formatErrorMsg(data) {
         if (data.error === 'No records found') {
            return `No records found with a purchase date within the time period${
               this.periodNames.length === 1 ? '' : 's'
            } ${this.prettyPeriodNames}`;
         }
         return data.error;
      },

      /** Clear the file import form */
      clearImport() {
         this.newFile.file = null;
         this.newFile.description = null;
         this.$store.commit(`${this.uploadModule}/newImport`);
      },

      /** Import the file from the file input */
      async onFileSelected() {
         if (this.uploadSize === 0) {
            const fileSelect = await this.$bvModal.msgBoxConfirm(
               'The file you selected is empty. Please check the file and try again.',
               {
                  title: 'Empty File',
                  centered: true,
                  okTitle: 'Select a different file',
               }
            );
            if (fileSelect) {
               this.startNewImport();
            }
            return;
         }

         try {
            await this.$store.dispatch(`${this.uploadModule}/importFile`, {...this.newFile});
         } catch (err) {
            if (err.message === 'InsufficientColumns') {
               const fileSelect = await this.$bvModal.msgBoxConfirm(
                  "The file you selected doesn't have enough columns to contain all the required data. Please check the file and try again.",
                  {
                     title: 'Insufficient Columns',
                     centered: true,
                     okTitle: 'Select a different file',
                  }
               );
               if (fileSelect) {
                  this.startNewImport();
               }
            }
            return;
         }

         this.startHeaderSelection();
      },

      /** Display the header selection modal */
      startHeaderSelection() {
         this.headerRow = null;
         this.uploadStep = UploadSteps.HEADER_ROW;
         this.$bvModal.show('modal-upload-header');
      },

      /** Handle selecting a CSV row as the header row */
      selectHeaderRow(idx) {
         this.$nextTick(() => {
            if (idx === this.headerRow) {
               this.headerRow = null;
            } else {
               this.headerRow = idx;
               // Hack to make sure the checkbox stays checked
               this.$refs[`cb${idx}`].localChecked = true;
            }
         });
      },

      /** Store the header row and proceed to the next step */
      onHeaderRowSelected() {
         this.$store.commit(`${this.uploadModule}/setHeaderRow`, {headerRow: this.headerRow});
         this.startDataRowSelection();
      },

      /** Display the data row selection modal */
      startDataRowSelection() {
         this.firstDataRow = null;
         this.uploadStep = UploadSteps.FIRST_DATA_ROW;
         this.$bvModal.show('modal-first-data-row');
      },

      /** Handle selecting a CSV row as the first data row */
      selectFirstDataRow(idx) {
         this.$nextTick(() => {
            if (idx === this.firstDataRow) {
               this.firstDataRow = null;
            } else {
               this.firstDataRow = idx;
               // Hack to make sure the checkbox stays checked
               this.$refs[`fdr-cb${idx}`].localChecked = true;
            }
         });
      },

      /** Store the first data row and proceed to the next step */
      onFirstDataRowSelected() {
         this.$store.commit(`${this.uploadModule}/setFirstDataRow`, {
            firstDataRow: this.firstDataRow,
         });
         this.startMapping();
      },

      dataRowRowClass(item) {
         if (item.index <= this.headerRow) {
            return 'no-hover';
         }
      },

      /** Clear the current mapping, then display the column mapping modal */
      startMapping() {
         this.mapping = {};
         for (let i = 0; i < this.pendingUpload.columnCount; i++) {
            this.$set(this.mapping, i, null);
         }
         this.uploadStep = UploadSteps.MAPPING;
         this.$bvModal.show('modal-map-columns');
      },

      /** Select a mapping for a column */
      mappingSelected(idx, val) {
         const match = Object.entries(this.mapping).find(
            (entry) => entry[0] !== idx && entry[1] === val
         );
         if (match) {
            this.mapping[match[0]] = null;
         }
      },

      /** Perform the file upload */
      async uploadFile() {
         const mapping = Object.entries(this.mapping).reduce((obj, entry) => {
            if (entry[1]) {
               obj[entry[1]] = +entry[0];
            }
            return obj;
         }, {});
         this.$store.commit(`${this.uploadModule}/setMapping`, {mapping});
         try {
            await this.blockingRequest(`${this.uploadModule}/uploadFile`, {
               mapping,
            });
            const skippedRecords = this.latestUpload.validation.post.skipped;
            if (skippedRecords > 0) {
               this.$bvModal.msgBoxOk(
                  `Your file was imported successfully, but ${skippedRecords} record${
                     skippedRecords > 1 ? 's were' : ' was'
                  } ignored due to having a purchase date outside of the time period${
                     this.periodNames.length === 1 ? '' : 's'
                  } ${this.prettyPeriodNames}.`,
                  {
                     title: 'Upload Successful',
                     centered: true,
                     okVariant: 'primary',
                  }
               );
            }

            this.clearImport();
         } catch {
            this.showFailedUploadModal();
         }
      },

      showFailedUploadModal() {
         this.$bvModal.show('modal-failed-upload');
      },

      async downloadTemplate() {
         await this.blockingRequest(`${this.uploadModule}/downloadTemplate`);
      },
   },
};
</script>

<style lang="scss" scoped>
.no-header-container {
   padding-left: 0.3rem;
}

::v-deep #table-header-row,
::v-deep #table-first-data-row,
::v-deep #table-map-columns,
::v-deep .no-header-container {
   @for $row from 1 through 3 {
      &.fade-out tr:nth-last-child(#{$row}) {
         color: lighten($black, percentage(1 - ($row * 0.25)));

         .custom-control-label::before {
            border-color: lighten($gray-800, percentage(0.8 - ($row * 0.2)));
         }
      }
   }

   .table-selected,
   .table-selected .custom-control-label {
      font-weight: 800;
      color: $black !important;
   }

   .table-hidden {
      color: $gray-450 !important;

      .custom-control-label::before {
         border-color: $gray-450 !important;
      }

      .custom-control-input:checked ~ .custom-control-label::before {
         background-color: $gray-450 !important;
      }
   }

   &.table-hover > tbody > tr.no-hover:hover > td {
      background-color: $white;
      cursor: auto;
   }
}
</style>

import {SpecialCategories} from './uploads';
import {expenseDataModuleFactory} from '../utils/expense-data';

export default expenseDataModuleFactory('cloudcomputing', SpecialCategories.CLOUDCOMPUTING_DOCS, {
   getters: {
      /** The server requires a location of 'XX' for cloud expense data */
      location() {
         return 'XX';
      },
   },
});

import store from '..';
import {sortBy} from '@/helpers/utils';

export class Project {
   constructor({id = null, name = null, periodIds = [], speriods = null, companyId = null} = {}) {
      this.id = id;
      this.name = name;
      this.periodIds = periodIds;
      this.companyId = companyId;
      if (speriods && this.periodIds.length === 0) {
         this.periodIds = speriods.map((period) => period.id);
      }
   }

   get periods() {
      return this.periodIds
         .map((periodId) => store.getters['companies/studyPeriodMap'][periodId])
         .sort(sortBy('startDate'));
   }

   get humanPeriodLabels() {
      const periodCount = this.periods.length;
      if (periodCount < 3) {
         return this.periods.map((period) => period.label).join(', ');
      } else {
         return `${this.periods[0].label}, ..., ${this.periods[periodCount - 1].label}`;
      }
   }

   clone() {
      return new Project(this);
   }

   export() {
      return {
         project: {
            name: this.name,
            companyId: this.companyId,
         },
         speriodIds: this.periodIds,
      };
   }
}

import store from '..';

export const ContractTypes = Object.freeze({
   FIXED_FEE: 'Fixed fee',
   TIME_MAT: 'Time & materials',
   FEE_CAP: 'Fee-cap',
});

export const ContractorStatus = Object.freeze({
   NOT_STARTED: 'NOT_STARTED',
   INCOMPLETE: 'INCOMPLETE',
   COMPLETE: 'COMPLETE',
   NONE: 'NONE',
});

export class Contractor {
   static #key = 0;

   constructor({
      id = null,
      companyId = null,
      fullname = null,
      contractType = null,
      location = null,
      years = null,
      contractOptOut = false,
      description = null,
      companyIpRights = null,
      economicRisk = null,
   } = {}) {
      if (id !== null) {
         this.id = id;
      } else {
         this.id = `local-${Contractor.#key++}`;
      }

      this.companyId = companyId;
      this.fullname = fullname;
      this.contractType = contractType;
      this.location = location;
      this.contractOptOut = contractOptOut;
      this.description = description;
      this.companyIpRights = companyIpRights;
      this.economicRisk = economicRisk;

      if (years !== null) {
         this.yearsLower = years.lower;
         this.yearsUpper = years.upper;
      } else {
         this.yearsLower = null;
         this.yearsUpper = null;
      }

      this.uploadFiles = [];
   }

   get years() {
      return {
         lower: this.yearsLower,
         upper: this.yearsUpper,
      };
   }

   /** Contractor data formatted for exporting to the server */
   get forExport() {
      const studyId = store.getters['companies/activeStudyId'];
      const contractor = {
         companyId: this.companyId,
         studyId,
         fullname: this.fullname,
         location: this.location,
         contractOptOut: this.contractOptOut,
         contractType: this.contractType,
         companyIpRights: this.companyIpRights,
         economicRisk: this.economicRisk,
         description: this.description || '',
      };
      return contractor;
   }
}

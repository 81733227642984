<template>
   <b-form-select
      :id="`select-study-${ident}`"
      :options="studyOptions"
      :value="activeStudyId"
      @change="selectStudy"
   >
      <b-form-select-option :value="null" v-if="allowNone">None</b-form-select-option>
   </b-form-select>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      allowNone: {
         type: Boolean,
      },

      // Used to provide a unique identifier for each form element
      // when there are multiple StudyPeriodSelects in the same form.
      ident: {
         default: '',
      },
   },

   computed: {
      ...mapGetters({
         openStudies: 'companies/openStudies',
         activeStudyId: 'companies/activeStudyId',
      }),

      studyOptions() {
         return this.openStudies.map((study) => ({value: study.id, text: study.label}));
      },
   },

   methods: {
      selectStudy(studyId) {
         this.$store.commit('companies/setActiveStudy', studyId);
      },
   },
};
</script>

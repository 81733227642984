<template>
   <CollapseCard
      title="Project Questionnaires"
      :subtitle="activeStudyId ? `${projectQuestionnaires.length} Questionnaires` : null"
      ident="project-questionnaire"
   >
      <div v-if="activeStudyId">
         <div class="d-flex align-items-center justify-content-between mt-5 mb-2">
            <h3>Project Questionnaires</h3>
            <b-button
               id="btn-add-project-questionnaire"
               size="sm"
               variant="primary"
               class="action-btn ml-2"
               :to="{
                  name: 'project-questionnaire-create',
                  params: {
                     id: $companyId,
                  },
               }"
            >
               Add New Questionnaire
            </b-button>
         </div>
         <b-table
            id="table-project-questionnaires"
            class="mb-0"
            :fields="questionnaireFields"
            :items="projectQuestionnaires"
            sort-by="projectId"
         >
            <template #cell(title)="data">
               <div class="d-flex align-items-center">
                  <b-icon-circle-fill
                     class="mr-2"
                     :variant="questionnaireStatus(data.item).variant"
                  ></b-icon-circle-fill>
                  {{ data.value }}
               </div>
            </template>

            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex align-items-center justify-content-end">
                  <b-button
                     v-if="isStaff && questionnaireStatus(data.item).text === 'Not Started'"
                     id="btn-delete-project-questionnaire"
                     variant="danger"
                     size="sm"
                     @click="deleteQuestionnaire(data.item)"
                  >
                     Delete
                  </b-button>
                  <b-button
                     id="btn-edit-project-questionnaire"
                     variant="primary"
                     size="sm"
                     class="ml-2"
                     :to="{
                        name: 'project-questionnaire-edit',
                        params: {id: $companyId, questionnaireId: data.item.id},
                     }"
                  >
                     Edit
                  </b-button>
                  <b-button
                     id="btn-view-project-questionnaire"
                     variant="primary"
                     size="sm"
                     class="ml-2"
                     :to="{
                        name: 'project-questionnaire-complete',
                        params: {id: $companyId, questionnaireId: data.item.id},
                     }"
                  >
                     View
                  </b-button>
               </div>
            </template>
         </b-table>
      </div>

      <b-alert :show="!activeStudyId" variant="primary" class="m-3">
         No study selected. Select a study to configure project questionnaires.
      </b-alert>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         questionnaireFields: [
            {key: 'title', label: 'Project', sortable: true},
            {
               key: 'projectId',
               label: 'Business Component',
               formatter: (value) => this.projectName(value),
               sortable: true,
               sortByFormatted: true,
            },
            {
               key: 'status',
               formatter: (value, key, item) => this.questionnaireStatus(item).text,
               sortable: true,
               sortByFormatted: true,
               class: 'no-wrap',
            },
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         activeStudyId: 'companies/activeStudyId',
         projectMap: 'projects/projectMap',
         projectQuestionnaires: 'questionnaire/projectQuestionnaires',
      }),
   },

   methods: {
      questionnaireStatus(questionnaire) {
         if (questionnaire.completed !== null) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         }

         let hasAnswer = false;
         questionnaire.questions.forEach((question) => {
            hasAnswer = hasAnswer || question.answers.length > 0;
         });

         if (hasAnswer) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },

      /** The name of the project with the given ID */
      projectName(id) {
         if (id in this.projectMap) {
            return this.projectMap[id].name;
         }
         return '';
      },

      deleteQuestionnaire(questionnaire) {
         this.$emit('deleteQuestionnaire', questionnaire);
      },
   },
};
</script>

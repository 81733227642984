<template>
   <b-container>
      <div class="standard-width mx-auto">
         <h1 class="page-title">Edit Company Info</h1>
      </div>
      <CompanyForm ref="form" :company="company" @submit="onSubmit" v-if="company" />
   </b-container>
</template>

<script>
import CompanyForm from '@/views/company/widgets/CompanyForm';

export default {
   name: 'CompanyEdit',

   components: {
      CompanyForm,
   },

   async created() {
      const companyId = this.$route.params.id;
      try {
         this.company = await this.blockingRequest('companies/fetchCompany', {companyId});
      } catch (err) {
         this.$store.commit('showAlert', {
            response: err.response,
            fallbackMsg: 'Failed to fetch company information',
            seconds: 5,
         });
      }
   },

   data() {
      return {
         company: null,
      };
   },

   methods: {
      async onSubmit(company) {
         // Sanity check that the form is validated
         if (this.$refs.form.$v.$invalid) {
            return;
         }

         const payload = {
            company,
            id: this.$companyId,
         };

         try {
            await this.blockingRequest('companies/updateCompany', payload);
            this.$router.push({name: 'company-detail', params: {id: this.$companyId}});
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },
   },
};
</script>

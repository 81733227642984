import GetStartedStage from '@/views/stages/GetStartedStage';
import QuestionnaireStage from '@/views/stages/QuestionnaireStage';
import UploadsStage from '@/views/stages/UploadsStage';

export default [
   {
      path: '/get-started',
      name: 'get-started-stage',
      component: GetStartedStage,
      meta: {
         authGroup: 'client',
         stage: 'get-started',
         allowUnconfigured: true,
         allowUngreeted: true,
         activeStudyRedirect: null,
      },
   },
   {
      path: '/uploads',
      name: 'uploads-stage',
      component: UploadsStage,
      meta: {
         authGroup: 'client',
         stage: 'uploads',
         allowUnconfigured: true,
         activeStudyRedirect: null,
      },
   },
   {
      path: '/questionnaires',
      name: 'questionnaires',
      component: QuestionnaireStage,
      meta: {
         authGroup: 'client',
         stage: 'questionnaire',
         activeStudyRedirect: null,
      },
   },
];

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollapseCard',{attrs:{"title":"Project Questionnaires","subtitle":_vm.activeStudyId ? ((_vm.projectQuestionnaires.length) + " Questionnaires") : null,"ident":"project-questionnaire"}},[(_vm.activeStudyId)?_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-5 mb-2"},[_c('h3',[_vm._v("Project Questionnaires")]),_c('b-button',{staticClass:"action-btn ml-2",attrs:{"id":"btn-add-project-questionnaire","size":"sm","variant":"primary","to":{
               name: 'project-questionnaire-create',
               params: {
                  id: _vm.$companyId,
               },
            }}},[_vm._v(" Add New Questionnaire ")])],1),_c('b-table',{staticClass:"mb-0",attrs:{"id":"table-project-questionnaires","fields":_vm.questionnaireFields,"items":_vm.projectQuestionnaires,"sort-by":"projectId"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon-circle-fill',{staticClass:"mr-2",attrs:{"variant":_vm.questionnaireStatus(data.item).variant}}),_vm._v(" "+_vm._s(data.value)+" ")],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"cell-w-buttons d-flex align-items-center justify-content-end"},[(_vm.isStaff && _vm.questionnaireStatus(data.item).text === 'Not Started')?_c('b-button',{attrs:{"id":"btn-delete-project-questionnaire","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteQuestionnaire(data.item)}}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"id":"btn-edit-project-questionnaire","variant":"primary","size":"sm","to":{
                     name: 'project-questionnaire-edit',
                     params: {id: _vm.$companyId, questionnaireId: data.item.id},
                  }}},[_vm._v(" Edit ")]),_c('b-button',{staticClass:"ml-2",attrs:{"id":"btn-view-project-questionnaire","variant":"primary","size":"sm","to":{
                     name: 'project-questionnaire-complete',
                     params: {id: _vm.$companyId, questionnaireId: data.item.id},
                  }}},[_vm._v(" View ")])],1)]}}],null,false,2835124463)})],1):_vm._e(),_c('b-alert',{staticClass:"m-3",attrs:{"show":!_vm.activeStudyId,"variant":"primary"}},[_vm._v(" No study selected. Select a study to configure project questionnaires. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
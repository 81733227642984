<template>
   <b-modal
      id="modal-financial-data"
      body-class="modal-body pt-1"
      title="Contract Researcher Payment"
      centered
      :size="studyPeriods.length > 3 ? 'xl' : 'lg'"
      @shown="onModalShown"
      @ok="submit"
      ok-title="Save"
      ok-variant="success"
      :ok-disabled="isComplete"
      ok-only
      @hide="onHide"
   >
      <p class="text-instruction" v-if="studyPeriods.length > 1">
         For each time period, enter the total amount paid to each contract researcher. If a
         contractor was not paid in a given time period, enter 0.
      </p>
      <p class="text-instruction" v-else>
         Enter the total amount paid to each contract researcher. If a contractor was not paid in a
         given time period, enter 0.
      </p>

      <b-table
         id="table-payment-data"
         class="mb-4"
         :fields="paymentFields"
         :items="paymentTableItems"
         sort-by="fullname"
         small
      >
         <template #cell(fullname)="data">
            <b>{{ data.value }}</b>
         </template>

         <template #cell()="data">
            <tcp-input-group prepend="$">
               <b-form-input
                  :id="inputId(data.index, data.field.key)"
                  :class="{
                     'input-warning': [null, ''].includes(getData(data.item.id, data.field.key)),
                  }"
                  placeholder="0.00"
                  :value="getData(data.item.id, data.field.key)"
                  @focus="storeValue(getData(data.item.id, data.field.key))"
                  @update="(value) => updateData(data.item.id, data.field.key, value)"
                  @keydown.stop="(evt) => onKeydown(evt, data.index, data.item.id, data.field.key)"
                  :formatter="formatCurrency"
                  lazy-formatter
                  :disabled="isComplete"
                  autocomplete="off"
               ></b-form-input>
            </tcp-input-group>
         </template>
      </b-table>

      <b-form-row class="mb-0">
         <b-col cols="8">
            <b-form-group
               label="Upload Supporting Financial Documentation"
               label-for="file-financial-docs"
               class="mb-0"
            >
               <b-form-file
                  id="file-financial-docs"
                  v-model="uploadFiles"
                  placeholder=""
                  multiple
                  :disabled="isComplete"
               >
               </b-form-file>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-between">
               <small :class="[fileSizeExceedsLimit ? 'text-danger' : 'text-muted']">
                  {{ formatBytes(uploadSize) }} Selected / Limit
                  {{ formatBytes(MAX_INTERNAL_FILE_SIZE) }}
               </small>
            </div>
         </b-col>

         <b-col
            cols="4"
            class="d-flex justify-content-end align-items-start"
            style="padding-top: 2rem"
         >
            <b-button
               id="btn-upload-file"
               variant="secondary"
               @click="doUploadFiles"
               :disabled="uploadFiles.length === 0 || uploading"
               style="margin-top: 0.125rem"
            >
               <b-spinner small v-if="uploading"></b-spinner>
               <b-icon-upload class="mr-1" v-else></b-icon-upload>
               Upload
            </b-button>
         </b-col>
      </b-form-row>

      <b-table
         v-if="financialDocuments.length > 0"
         :fields="documentFields"
         sort-by="uploadedAt"
         :items="financialDocuments"
         id="table-financial-docs"
         class="scrollbar mb-0 mt-3"
         small
         sticky-header="150px"
      >
         <template #cell(name)="data">
            <b>{{ data.value }}</b>
         </template>
         <template #cell(uploadedAt)="data">
            {{ formatDatetime(data.value) }}
         </template>
         <template #cell(uploadedBy)="data">
            {{ userName(data.value) }}
         </template>
         <template #cell(actions)="data">
            <div class="cell-w-buttons justify-content-end">
               <button
                  v-if="!isSME && !isComplete"
                  class="icon-btn icon-btn-danger"
                  @click="deleteFile(data.item.id)"
               >
                  <b-icon-trash />
               </button>
            </div>
         </template>
      </b-table>
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import {formatBytes, formatCurrency} from '@/helpers/utils';

export default {
   data() {
      return {
         MAX_INTERNAL_FILE_SIZE,
         focusedContractor: null,
         uploading: false,
         documentFields: [
            {key: 'name', label: 'File Name', sortable: true},
            {key: 'uploadedAt', sortable: true},
            {key: 'uploadedBy', sortable: true},
            {key: 'actions', label: ''},
         ],
         uploadFiles: [],
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isSME: 'isSME',
         users: 'users/companyUsers',
         studyPeriods: 'companies/activeStudyPeriods',
         studyPeriodMap: 'companies/studyPeriodMap',
         isComplete: 'contractors/isComplete',
         _contractors: 'contractors/contractors',
         financialData: 'contractors/editFinancialData',
         finanicalDataPeriodIds: 'contractors/financialDataPeriodIds',
         dataDirty: 'contractors/editFinancialDataDirty',
         financialDataStatusMap: 'contractors/financialDataStatusMap',
         financialDocuments: 'contractors/financialDocuments',
      }),

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      /** An array of contractors who have financial data to edit */
      contractors() {
         const contractors = this._contractors.filter(
            (contractor) => contractor.id in this.financialData
         );
         return contractors;
      },

      paymentFields() {
         const periodFields = this.finanicalDataPeriodIds.map((periodId) => {
            const period = this.studyPeriodMap[periodId];
            return {key: `${period.id}`, label: period.label};
         });

         return [{key: 'fullname', label: 'Contract Researcher', sortable: true}, ...periodFields];
      },

      paymentTableItems() {
         return this.contractors;
      },

      /** Total size of selected files for upload */
      uploadSize() {
         if (this.uploadFiles.length > 0) {
            return this.uploadFiles.map((file) => file.size).reduce((sum, item) => sum + item);
         }
         return 0;
      },

      /** Do the selected files exceed the max file size for upload */
      fileSizeExceedsLimit() {
         return this.uploadSize >= MAX_INTERNAL_FILE_SIZE;
      },
   },

   methods: {
      /** Called by the parent component to display the modal */
      show(contractorId = null) {
         this.uploadFiles = [];
         this.focusedContractor = contractorId;
         this.$store.commit('contractors/loadEditFinancialData');
         this.$bvModal.show('modal-financial-data');
      },

      onModalShown() {
         if (this.focusedContractor !== null) {
            const idx = this.contractors.findIndex((c) => c.id === this.focusedContractor);
            if (idx >= 0) {
               const periodId = this.studyPeriods[0].id;
               const inputId = this.inputId(idx, periodId);
               const inputEl = document.getElementById(inputId);
               if (inputEl) {
                  inputEl.focus();
               }
            }
         }
      },

      formatBytes,
      formatCurrency,

      /** Format a date string */
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },

      /** The name of the user with the given id */
      userName(id) {
         if (id in this.users) {
            const user = this.users[id];
            return `${user.firstName} ${user.lastName}`;
         }
         return 'R&D Incentives Group';
      },

      /** Generates an ID for a payment amount input */
      inputId(contractorIndex, periodId) {
         return `input-amount-${contractorIndex}-${periodId}`;
      },

      /** Set a contractor's payment amount to its default value (0.00) */
      setDefaultValue(contractorId, periodId) {
         this.updateData(contractorId, periodId, '0.00');
      },

      /** Store a value */
      storeValue(value) {
         this.storedValue = value;
      },

      /** Restore a contractor's payment amount to the stored value */
      restoreValue(contractorId, periodId) {
         this.updateData(contractorId, periodId, this.storedValue);
      },

      /** Keyboard controls for the contractor payment table */
      onKeydown(event, contractorIndex, contractorId, periodId) {
         const keycodes = ['Escape', 'ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'];
         if (!keycodes.includes(event.code)) {
            return;
         }

         if ('Escape' === event.code) {
            this.restoreValue(contractorId, periodId);
            return;
         }

         if ((event.code === 'Enter' || event.code === 'NumpadEnter') && event.shiftKey) {
            this.setDefaultValue(contractorId, periodId);
         } else if (['ArrowUp', 'ArrowDown', 'Enter', 'NumpadEnter'].includes(event.code)) {
            event.preventDefault();
            const direction = event.code === 'ArrowUp' ? -1 : 1;
            const nextId = this.inputId(contractorIndex + direction, periodId);
            const nextElement = document.getElementById(nextId);

            if (nextElement) {
               nextElement.select();
            }
         }
      },

      /** Get the invoiced amount for a contractor in a period */
      getData(contractorId, periodId) {
         const amount = this.financialData[contractorId][periodId].invoiced;
         return amount;
      },

      /** Store an updated value */
      updateData(contractorId, periodId, value) {
         this.$store.commit('contractors/setEditFinancialData', {
            contractorId,
            periodId,
            value,
         });
      },

      /** Delete an uploaded document */
      async deleteFile(fileId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this file?',
            {
               title: 'Delete this file?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('contractors/deleteFinancialDocument', {
               fileId,
               force: true,
            });
         }
      },

      /** Upload the selected files */
      async doUploadFiles() {
         this.uploading = true;
         await this.blockingRequest('contractors/uploadFinancialDocuments', {
            files: this.uploadFiles,
         });
         this.uploadFiles = [];
         this.uploading = false;
      },

      /** Save financial data and upload any selected files */
      async submit() {
         const requests = [
            this.$store.dispatch('contractors/saveFinancialData', {companyId: this.companyId}),
            this.$store.dispatch('contractors/uploadFinancialDocuments', {files: this.uploadFiles}),
         ];
         await this.blockUntilAllSettled(requests);
      },

      /** Check for dirty form data before hiding the modal */
      async onHide(evt) {
         if ((this.dataDirty || this.uploadFiles.length > 0) && evt.trigger !== 'ok') {
            evt.preventDefault();
            const save = await this.$bvModal.msgBoxConfirm(
               'Would you like to save your work before proceeding? Any unsaved changes will be lost.',
               {
                  centered: true,
                  okTitle: 'Save',
                  cancelTitle: 'Proceed without saving',
               }
            );
            if (save === null) {
               return;
            } else if (save) {
               await this.submit();
            }
            this.uploadFiles = [];
            this.$store.commit('contractors/setFinancialDataDirty', {value: false});
            this.$bvModal.hide('modal-financial-data');
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#table-contractor-financial-information::v-deep > tbody > tr:not(.b-table-details):hover {
   background-color: #eee;
   cursor: pointer;
}
</style>

/* eslint-disable no-empty-pattern */

import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';
import {sortBy} from '../../helpers/utils';
import {TsStates, TsEmployeeStates} from '@/helpers/constants';

export const CellType = Object.freeze({
   PERCENTAGE: 'PERC',
   PROJECT: 'PROJ',
   ACTIVITY: 'ACT',
});

const state = () => ({
   companyId: null,
   periodId: null,
   refPeriodId: null,
   data: {},
   employees: {},
   projects: {},
   activities: {},
   assignments: {},
   periods: {
      POSSIBLE: [],
      DATA: [],
      VALIDATED: [],
   },

   // UI state
   ui: {
      currentEmployeeId: null,
      selectedEmployeeIds: [],
   },
});

const getters = {
   data: (state) => state.data,

   refPeriodId: (state) => state.refPeriodId,

   refPeriod: (state, getters, rootState, rootGetters) => {
      return rootGetters['companies/studyPeriodMap'][getters.refPeriodId];
   },

   employees: (state) => {
      return state.employees;
   },

   /** The list of employees in this time survey */
   employeesList: (state, getters) => {
      return Object.values(getters.employees);
   },

   employeeIds: (state) => {
      return Object.keys(state.employees);
   },

   activities: (state) => {
      return state.activities;
   },

   /** Projects in this time survey returned as a mapping keyed on the project id */
   projectMap: (state) => {
      return state.projects;
   },

   /** Projects in this time survey returned as an array, sorted by name */
   projects: (state) => {
      return Object.values(state.projects).sort(sortBy('name'));
   },

   assignments: (state) => {
      return Object.values(state.assignments);
   },

   periods: (state) => state.periods,

   possiblePeriods: (state, getters, rootState, rootGetters) => {
      return state.periods.POSSIBLE.filter((periodId) => {
         const period = rootGetters['companies/studyPeriodMap'][periodId];
         return period.study.isOpen;
      });
   },

   validatedPeriods: (state) => {
      return state.periods.VALIDATED;
   },

   /** An array of employees without assignments */
   unassignedEmployees: (state) => {
      return Object.values(state.employees).filter((employee) => {
         return !(employee.id in state.assignments);
      });
   },

   /**
    * An array of employees assigned to a user
    * @param {string} assigneeId - The ID of a user account
    */
   assignedEmployees: (state) => (assigneeId) => {
      return Object.values(state.employees).filter((employee) => {
         return (
            employee.id in state.assignments &&
            state.assignments[employee.id].assigneeId === assigneeId
         );
      });
   },

   /** Time survey data filtered by assignment */
   assignedData: (state) => (assigneeId) => {
      return Object.entries(state.data)
         .filter(([id]) => {
            return id in state.assignments && state.assignments[id].assigneeId === assigneeId;
         })
         .reduce((obj, [id, data]) => {
            obj[id] = {id, ...data};
            return obj;
         }, {});
   },

   /** Time survey data filtered by no assignment */
   unassignedData: (state) => {
      return Object.entries(state.data)
         .filter(([id]) => {
            return !(id in state.assignments);
         })
         .reduce((obj, [id, data]) => {
            obj[id] = {id, ...data};
            return obj;
         }, {});
   },

   /** A mapping of user IDs to their assignment's completion status */
   assignmentsComplete: (state) => {
      const assignmentsComplete = Object.values(state.assignments).reduce((map, assignment) => {
         const assigneeId = assignment.assigneeId;
         const isComplete = assignment.completed !== null;
         if (assigneeId in map) {
            map[assigneeId] = map[assigneeId] && isComplete;
         } else {
            map[assigneeId] = isComplete;
         }
         return map;
      }, {});

      return assignmentsComplete;
   },

   //
   // UI State
   //

   /** ID of the current employee */
   currentEmployeeId: (state) => state.ui.currentEmployeeId,

   /** Index of the current employee within the selected employee array */
   currentIdx: (state, getters) => {
      const id = getters.currentEmployeeId ? getters.currentEmployeeId : null;
      return state.ui.selectedEmployeeIds.indexOf(+id);
   },

   /**
    * ID of the next employee in the selected employee array. If
    * there is no next employee, returns null.
    */
   nextEmployeeId: (state, getters) => {
      if (getters.currentIdx >= 0 && getters.currentIdx < state.ui.selectedEmployeeIds.length - 1) {
         return getters.selectedEmployeeIds[getters.currentIdx + 1];
      } else {
         return null;
      }
   },

   /**
    * ID of the previous employee in the selected employee array. If
    * there is no previous employee, returns null.
    */
   previousEmployeeId: (state, getters) => {
      if (getters.currentIdx && getters.currentIdx > 0) {
         return getters.selectedEmployeeIds[getters.currentIdx - 1];
      } else {
         return null;
      }
   },

   /** IDs of employees currently selected for data entry */
   selectedEmployeeIds: (state) => state.ui.selectedEmployeeIds,

   /** Index of the current employee within the selected employees array
    * when selected employees are sorted by employee fullname.
    */
   currentIdxByFullname: (state, getters) => {
      const id = getters.currentEmployeeId ? getters.currentEmployeeId : null;
      return getters.selectedEmployeeIdsSortedByFullname.indexOf(+id);
   },

   /**
    * ID of the next employee in the selected employee array as ordered
    * by employee fullname. If there is no next employee, returns null.
    */
   nextEmployeeIdByFullname: (state, getters) => {
      // ... todo
      const sortedEmployeeIds = getters.selectedEmployeeIdsSortedByFullname;
      const sortedCurrentIdx = sortedEmployeeIds.indexOf(+getters.currentEmployeeId);

      if (sortedCurrentIdx >= 0 && sortedCurrentIdx < sortedEmployeeIds.length - 1) {
         return sortedEmployeeIds[sortedCurrentIdx + 1];
      } else {
         return null;
      }
   },

   /**
    * ID of the previous employee in the selected employee array as
    * ordered by employee fullname. If there is no previous employee,
    * returns null.
    */
   previousEmployeeIdByFullname: (state, getters) => {
      // ... todo
      const sortedEmployeeIds = getters.selectedEmployeeIdsSortedByFullname;
      const sortedCurrentIdx = sortedEmployeeIds.indexOf(+getters.currentEmployeeId);

      if (sortedCurrentIdx && sortedCurrentIdx > 0) {
         return sortedEmployeeIds[sortedCurrentIdx - 1];
      } else {
         return null;
      }
   },

   /** IDs of employees currently selected for data entry,
    * sorted by employee.fullname ascending
    */
   selectedEmployeeIdsSortedByFullname: (state, getters) => {
      // ... todo

      const sortedSelectedEmployees = [...getters.employeesList]
         .sort((a, b) => a.fullname.localeCompare(b.fullname))
         .filter((employee) => state.ui.selectedEmployeeIds.includes(employee.id));
      return sortedSelectedEmployees.map((employee) => employee.id);
   },

   /**
    * Compute the state of employee data
    * @returns {Object} - Mapping of employee IDs to `TsEmployeeStates`
    */
   employeeStateMap: (state, getters) => {
      const states = {};

      getters.employeesList.forEach((employee) => {
         if (getters.data[employee.id].percentage === null) {
            states[employee.id] = TsEmployeeStates.INCOMPLETE;
         } else if (!getters.projectSumEqualsTotal(employee.id)) {
            states[employee.id] = TsEmployeeStates.INVALID;
         } else {
            states[employee.id] = TsEmployeeStates.VALID;
         }
      });

      return states;
   },

   /**
    * Computes the sum of an employee's project percentages
    * @param {string} employeeId
    */
   projectSum: (state, getters) => (employeeId) => {
      const projectSum = Object.values(getters.data[employeeId].projects)
         .map((x) => +x)
         .reduce((a, b) => a + b);
      return projectSum;
   },

   /**
    * Computes the difference between an employee's total percentage and the
    * sum of their project percentages.
    * @param {string} employeeId
    */
   percentageToProjectSumDiff: (state, getters) => (employeeId) => {
      const percentage = getters.data[employeeId].percentage;
      const projectSum = getters.projectSum(employeeId);
      return +percentage - projectSum;
   },

   /**
    * Checks whether the declared percentage for an employee matches
    * the sum of their project time percentages.
    * @param {string} employeeId
    */
   projectSumEqualsTotal: (state, getters) => (employeeId) => {
      const percentage = getters.data[employeeId].percentage;
      if (percentage !== null) {
         const projectSum = getters.projectSum(employeeId);
         return +percentage === +projectSum;
      }
      return true;
   },

   /**
    * Computes the current state of the time survey
    * @returns {TsStates} - The state of the time survey
    */
   timesurveyState: (state, getters, rootState, rootGetters) => (periodId) => {
      // Check the current user's assignments for invalid or incomplete data
      let invalid = false;
      let incomplete = false;

      const profileId = rootGetters.profile.id;

      if (getters.validatedPeriods.includes(+periodId)) {
         return TsStates.VALIDATED;
      }

      if (profileId in getters.assignmentsComplete && !getters.assignmentsComplete[profileId]) {
         getters.assignedEmployees(profileId).forEach((employee) => {
            switch (getters.employeeStateMap[employee.id]) {
               case TsEmployeeStates.INVALID:
                  invalid = invalid || true;
                  break;
               case TsEmployeeStates.INCOMPLETE:
                  incomplete = incomplete || true;
                  break;
            }
         });

         if (invalid) {
            return TsStates.ASSIGNMENTS_INVALID;
         } else if (incomplete) {
            return TsStates.ASSIGNMENTS_INCOMPLETE;
         } else {
            return TsStates.ASSIGNMENTS_COMPLETE;
         }
      }

      // For SME users, this is all we need to check, so return VALID
      if (rootGetters.isSME) {
         return TsStates.VALID;
      }

      // Check the rest of the time survey for invalid or incomplete data
      getters.employeesList.forEach((employee) => {
         switch (getters.employeeStateMap[employee.id]) {
            case TsEmployeeStates.INVALID:
               invalid = invalid || true;
               break;
            case TsEmployeeStates.INCOMPLETE:
               incomplete = incomplete || true;
               break;
         }
      });

      if (invalid) {
         return TsStates.TS_INVALID;
      }
      if (incomplete) {
         return TsStates.TS_INCOMPLETE;
      }

      const anyAssignmentsIncomplete = Object.values(getters.assignmentsComplete).some(
         (val) => val === false
      );
      if (anyAssignmentsIncomplete) {
         return TsStates.TS_INCOMPLETE;
      }

      return TsStates.VALID;
   },
};

const mutations = {
   /** Clear the state */
   clear: (state) => {
      state.companyId = null;
      state.periodId = null;
      state.data = {};
      state.employees = {};
      state.projects = {};
      state.activities = {};
      state.assignments = {};
   },

   clearPeriods: (state) => {
      state.periods = {
         POSSIBLE: [],
         DATA: [],
         VALIDATED: [],
      };
   },

   /** Reset the UI state */
   resetUIState: (state) => {
      Vue.set(state, 'ui', {
         currentEmployeeId: null,
         selectedEmployeeIds: Object.keys(state.employees),
      });
   },

   /**
    * Set time survey data
    * @param {{timeSurvey: Object, companyId: string, periodId: string|integer}}
    */
   setTimeSurvey: (state, {timeSurvey, companyId, periodId}) => {
      state.companyId = companyId;
      state.periodId = periodId;
      state.data = timeSurvey.tsData;
      state.refPeriodId = timeSurvey.refsperiod || null;

      timeSurvey.employees.forEach((employee) => {
         Vue.set(state.employees, employee.id, employee);
      });
      timeSurvey.projects.forEach((project) => {
         Vue.set(state.projects, project.id, project);
      });
      timeSurvey.activities.forEach((activity) => {
         Vue.set(state.activities, activity.id, activity);
      });
   },

   /**
    * Set the assignments array
    * @param {{assignments: Object[]}}
    */
   setAssignments: (state, {assignments}) => {
      assignments.forEach((assignment) => {
         Vue.set(state.assignments, assignment.employeeId, assignment);
      });
   },

   /** Add an assignment */
   setAssignment: (state, {assignment}) => {
      Vue.set(state.assignments, assignment.employeeId, assignment);
   },

   /** Delete an assignment */
   deleteAssignment: (state, {employeeId}) => {
      Vue.delete(state.assignments, employeeId);
   },

   /** Set an activity value */
   setActivity: (state, {employeeId, activityId, value}) => {
      state.data[employeeId].activities[activityId] = value ? 1 : 0;
   },

   /** Set a project value */
   setProject: (state, {employeeId, projectId, value}) => {
      state.data[employeeId].projects[projectId] = value;
   },

   /** Set an employee's qualifying R&D percentage */
   setPercentage: (state, {employeeId, value}) => {
      state.data[employeeId].percentage = value;
   },

   /** Set an employee's data */
   setData: (state, {employeeId, value}) => {
      Vue.set(state.data, employeeId, value);
   },

   /** Set the validatedPeriods array */
   setValidatedPeriods: (state, {periodIds}) => {
      state.periods.VALIDATED = [...periodIds];
   },

   /** Add a periodId to the validatedPeriods array */
   addValidatedPeriod: (state, {periodId}) => {
      state.periods.VALIDATED.push(+periodId);
   },

   /** Remove a periodId from the validatedPeriods array */
   deleteValidatedPeriod: (state, {periodId}) => {
      const idx = state.periods.VALIDATED.indexOf(+periodId);
      if (idx >= 0) {
         state.periods.VALIDATED.splice(idx, 1);
      }
   },

   /** Clear the compled state of an assignment */
   clearAssignmentCompleted: (state, {employeeId}) => {
      if (employeeId in state.assignments) {
         Vue.set(state.assignments[employeeId], 'completed', null);
      }
   },

   setPeriods: (state, {periods}) => {
      state.periods = periods;
   },

   setPossiblePeriods: (state, {periodIds}) => {
      state.periods.POSSIBLE = periodIds;
   },

   /** Remove a period from the list of DATA periods. ie. periods where time survey data has been entered */
   deleteDataPeriod: (state, {periodId}) => {
      state.periods.DATA = state.periods.DATA.filter((dPeriodId) => dPeriodId !== +periodId);
   },

   //
   // UI State
   //

   /** Select an employee */
   selectEmployee: (state, {employeeId}) => {
      state.ui.currentEmployeeId = employeeId;
   },

   setSelectedEmployees: (state, {employeeIds}) => {
      state.ui.selectedEmployeeIds = employeeIds.map((id) => +id);
   },
};

const actions = {
   /**
    * Load a time survey
    * @param {Object} payload
    * @param {string} payload.companyId - The time survey's company
    * @param {string|integer} payload.periodId - The time survey's period
    * @param {Boolean} [payload.assignments=false] - also load time survey assigments
    */
   async loadTimeSurvey(
      {commit, dispatch},
      {companyId, periodId, assignments = false, refData = false}
   ) {
      let timeSurvey;
      let assignmentsData;
      let requests = [];

      const params = {
         ref: refData,
      };

      requests.push(
         this._vm.$http
            .get(`/api/company/${companyId}/timesurvey/${periodId}`, {params})
            .then((response) => {
               timeSurvey = response.data;
            })
      );

      if (assignments) {
         requests.push(
            dispatch('loadTimeSurveyAssignments', {companyId, periodId, save: false}).then(
               (data) => {
                  assignmentsData = data;
               }
            )
         );
      }

      await Promise.all(requests);

      commit('clear');
      commit('setTimeSurvey', {timeSurvey, companyId, periodId});

      if (assignments) {
         commit('setAssignments', {assignments: assignmentsData});
      }
   },

   /**
    * Load the assignments of a time survey
    * @param {Object} payload
    * @param {string} payload.companyId - The time survey's company
    * @param {string|integer} payload.periodId - The time survey's period
    * @param {Boolean} [payload.save=false] - Save the data to state
    * @param {Boolean} [payload.all=true] - Fetch all assignments. Otherwise only fetches current user's assignments
    */
   async loadTimeSurveyAssignments({commit}, {companyId, periodId, save = true, all = true}) {
      const params = {all};

      const assignments = (
         await this._vm.$http.get(`/api/company/${companyId}/timesurvey/${periodId}/assignment`, {
            params,
         })
      ).data.results;

      if (save) {
         commit('setAssignments', {assignments});
      }
      return assignments;
   },

   /**
    * Save an employee's time survey data
    * @param {Object} payload
    * @param {string} payload.employeeId - The ID of the employee whose data is being saved
    */
   async saveEmployee({state, commit}, {employeeId}) {
      const payload = {
         employeeId,
         ...formatDataForServer(state.data[employeeId]),
      };
      await this._vm.$http.post(
         `/api/company/${state.companyId}/timesurvey/${state.periodId}`,
         payload
      );

      // Invalidate time survey, and clear assignment completion
      commit('deleteValidatedPeriod', {periodId: state.periodId});
      commit('clearAssignmentCompleted', {employeeId});
   },

   /**
    * Update a single cell (percentage, project, activity) in a time survey
    * @param {String} employeeId
    * @param {CellType} kind - Cell type. One of 'PERC', 'PROJ', or 'ACT'.
    * @param {String} id - Project or activity ID. Must be `null` for total percentage
    * @param {Number} value
    */
   async updateCell({commit, state}, {employeeId, kind, id = null, value}) {
      await this._vm.$http.post(
         `/api/company/${state.companyId}/timesurvey/${state.periodId}/employee/${employeeId}`,
         {
            kind,
            id,
            val: value,
         }
      );

      // Invalidate time survey, and clear assignment completion
      commit('deleteValidatedPeriod', {periodId: state.periodId});
      commit('clearAssignmentCompleted', {employeeId});
   },

   /**
    * Fetch a list of possible time survey periods
    * @param {Object} payload
    * @param {string} payload.companyId
    */
   async loadPossiblePeriods({commit}, {companyId}) {
      const data = (
         await this._vm.$http.get(`/api/company/${companyId}/timesurvey?speriods=POSSIBLE`)
      ).data;

      commit('setPossiblePeriods', {periodIds: data.results});
      return {
         periodIds: data.results,
         error: data.error,
      };
   },

   /**
    * load all time survey period data:
    *    VALIDATED: Periods for which a time survey has been validated (submitted)
    *    DATA: Periods for which time survey data exists
    *    POSSIBLE: Periods for which a time survey has been fully configured
    * @param {Object} payload
    * @param {Object} payload.companyId
    */
   async loadPeriods({commit}, {companyId, allStudies = false}) {
      let requests = [];

      let periods = {};

      ['VALIDATED', 'DATA', 'POSSIBLE'].forEach((type) => {
         let params = {
            speriods: type,
            all_studies: allStudies,
         };
         requests.push(
            this._vm.$http
               .get(`/api/company/${companyId}/timesurvey`, {params})
               .then((response) => {
                  periods[type] = response.data.results;
               })
         );
      });
      await Promise.all(requests);

      commit('setPeriods', {periods});

      return periods;
   },

   /**
    * Load the periods for which a time survey has been validated
    * @param {Object} payload
    * @param {string} payload.companyId
    */
   async loadValidatedPeriods({commit}, {companyId}) {
      const data = (
         await this._vm.$http.get(`/api/company/${companyId}/timesurvey?speriods=VALIDATED`)
      ).data;
      const periodIds = data.results;
      commit('setValidatedPeriods', {periodIds});
      return periodIds;
   },

   /**
    * Mark all of a user's assigned employees complete
    * @param {string} userId - ID of the user
    * @param {string} periodId - Period of the time survey
    */
   async markAssignmentsComplete({commit}, {userId, periodId}) {
      const assignments = (
         await this._vm.$http.post(`/api/user/${userId}/timesurvey/${periodId}/complete`)
      ).data.results;
      commit('setAssignments', {assignments});
   },

   /** Submit the current time survey to the server for validation */
   async validate({commit}, {companyId, periodId}) {
      await this._vm.$http.post(`/api/company/${companyId}/timesurvey/${periodId}/validate`);
      commit('addValidatedPeriod', {periodId});
   },

   /**
    * Delete time survey data
    * @param {string} payload
    * @param {string} payload.companyId - ID of the company the time survey belongs to
    * @param {string} payload.periodId - The periodId of the time survey
    * @param {Boolean} payload.force - If true, do the deletion. Otherwise, the server will
    *                                  return a 409 with an enumeration of the related data
    *                                  that would also be deleted by this action.
    */
   async deleteTimeSurvey({commit}, {companyId, periodId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/company/${companyId}/timesurvey/${periodId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteDataPeriod', {periodId});
   },
};

/**
 * Format time survey data being sent to the server
 * @param {Object} data - Time survey data
 * @returns a formated time survey data object
 */
const formatDataForServer = (data) => {
   if (data.percentage === '') {
      data.percentage = null;
   }
   Object.keys(data.projects).forEach((key) => {
      if (data.projects[key] === '') {
         data.projects[key] = null;
      }
   });
   return data;
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

<template>
   <b-card style="margin-bottom: 2rem">
      <div class="d-flex align-items-center justify-content-between">
         <h2 class="mb-0">Cloud Computing</h2>

         <div class="d-flex align-items-center">
            <span id="supplies-declaration" class="mr-2">{{ declarationStr }}</span>
            <b-button
               id="btn-cloud-computing"
               variant="primary"
               :to="{name: 'cloud-declaration-staff', params: {id: $companyId}}"
            >
               View
            </b-button>
         </div>
      </div>
   </b-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         declaration: 'cloud/declaration',
      }),

      declarationStr() {
         switch (this.declaration) {
            case null:
               return 'No Declaration';
            case true:
               return 'Used Cloud Computing';
            case false:
               return 'No Cloud Computing';
         }
         return 'Unconfigured';
      },
   },
};
</script>

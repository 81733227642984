<template>
   <b-container v-if="loaded">
      <PageTitle title="Cloud Computing" />

      <SectionAssignment section="cloud" />

      <ManagerMessage v-if="manager">
         <template #short>
            <span v-if="noData"> No qualified cloud computing expenses yet. </span>
            <span v-else-if="!completed">How much of this spending went to cloud computing?</span>
         </template>

         <div v-if="completed">
            <h2>This section is complete.</h2>
            <p>
               As long as everything still looks correct, you're all done here! If you need to make
               any additional changes, you'll need to unlock this section first.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </div>
         <div v-else-if="noData">
            <h2>No qualified cloud computing expenses yet.</h2>

            <p class="mb-0">
               The accounts provided so far do not contain expenses in the time period{{
                  periodNames.length === 1 ? '' : 's'
               }}
               {{ prettyPeriodNames }}. You can go back and continue to upload expense accounts, or
               update your answer on the first page of this section to indicate that your company
               made no qualified cloud computing purchases during this time period.
            </p>
         </div>
         <div v-else>
            <h2>How much of this spending went to cloud computing?</h2>

            <p>
               For each vendor, please enter the percentage of purchase spending that went to cloud
               computing.
            </p>
         </div>
      </ManagerMessage>

      <b-alert
         :show="!manager && completed"
         variant="primary"
         class="d-flex align-items-center justify-content-between mb-5"
      >
         <span>
            The cloud computing section has been submitted. Click <b>Unlock</b> to make additional
            changes.
         </span>
         <b-button variant="white" @click="unlock">Unlock</b-button>
      </b-alert>

      <CollapseCard
         v-for="periodId in periods"
         :key="periodId"
         :ident="periodId"
         :title="periodLabel(periodId)"
         :subtitle="`${accountCount(periodId)} accounts`"
      >
         <template #title>
            <div class="d-flex align-items-center">
               <small>
                  <RdigStatusPill
                     :ident="`yr-${periodId}`"
                     class="mr-2"
                     :status="accountStatus[periodId].status"
                  />
               </small>
               <h2 class="mb-0 mt-1">{{ periodLabel(periodId) }}</h2>
            </div>
         </template>

         <hr class="mb-4" />

         <div
            v-for="(account, accountIndex) in sortedAccounts(periodId)"
            :key="`${periodId}:${account}`"
            class="account-section"
         >
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${periodId}-${accountIndex + 1}`"
                  class="mr-2"
                  :status="accountStatus[periodId].accounts[account]"
               />
               <h2 :id="`account-name-${periodId}-${accountIndex + 1}`" class="mb-0 mt-1">
                  Account: {{ account }}
               </h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-cloud-vendors-${periodId}-${accountIndex + 1}`"
                     :fields="summaryFields"
                     sort-by="vendor"
                     :items="sortedSummaries(periodId, account)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(totalDebit)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(totalCredit)="data">
                        {{ formatMoney(data.value) }}
                     </template>
                     <template #cell(netAmount)="data">
                        {{ formatMoney(data.value.value) }}
                     </template>
                     <template #cell(input)="data">
                        <div class="d-flex align-items-center justify-content-between">
                           <CloudVendorPercentageInput
                              :ident="`${data.index}-${periodId}-${account}`"
                              :summary="data.item"
                              :disabled="completed || setAllInput[`${periodId}:${account}`]"
                           />
                        </div>
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${periodId}-${accountIndex + 1}`"
                     class="mb-3"
                     @change="(val) => selectSetAll(periodId, account, val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <CloudVendorPercentageInput
                     v-if="setAllInput[`${periodId}:${account}`]"
                     :ident="`${periodId}-${account}-all`"
                     style="margin-left: 1.5rem; max-width: 8rem"
                     :setMany="sortedSummaries(periodId, account)"
                  />
               </div>
            </div>
         </div>

         <div v-if="adHocSummaries(periodId).length > 0" class="account-section">
            <div class="d-flex align-items-center mb-4">
               <RdigStatusPill
                  :ident="`acc-${periodId}-manual`"
                  class="mr-2"
                  :status="accountStatus[periodId].adHocStatus"
               />
               <h2 :id="`account-name-${periodId}-manual`" class="mb-0 mt-1">
                  Manually-Added Vendors
               </h2>
            </div>
            <div class="account-data">
               <div class="flex-grow">
                  <b-table
                     :id="`table-cloud-vendors-${periodId}-manual`"
                     :fields="adHocSummaryFields"
                     sort-by="vendorId"
                     :items="adHocSummaries(periodId)"
                  >
                     <template #cell(vendor)="data">
                        <b>{{ data.value }}</b>
                     </template>
                     <template #cell(netAmount)="data">
                        <CloudVendorAmountInput
                           :ident="`${data.index}-${periodId}-adhoc`"
                           :summary="data.item"
                           :disabled="completed"
                        />
                     </template>
                     <template #cell(input)="data">
                        <CloudVendorPercentageInput
                           :ident="`${data.index}-${periodId}-adhoc`"
                           :summary="data.item"
                           :disabled="completed || setAllInput[`${periodId}:manual`]"
                        />
                     </template>
                  </b-table>
               </div>
               <div class="right-col">
                  <b-form-checkbox
                     :id="`checkbox-same-percentages-${periodId}-manual`"
                     class="mb-3"
                     @change="(val) => selectSetAll(periodId, 'manual', val)"
                     :disabled="completed"
                  >
                     Set all vendors to the same qualifying percent
                  </b-form-checkbox>
                  <CloudVendorPercentageInput
                     v-if="setAllInput[`${periodId}:manual`]"
                     :ident="`${periodId}-adhoc-all`"
                     :setMany="adHocSummaries(periodId)"
                     style="margin-left: 1.5rem; max-width: 8rem"
                  />
               </div>
            </div>
         </div>
      </CollapseCard>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'cloud-vendors'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-review-cloud"
            v-if="!noData"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'cloud-review'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {FieldStatus} from '@/store/utils';
import SectionAssignment from '../components/SectionAssignment';
import CloudVendorPercentageInput from './widgets/CloudVendorPercentageInput';
import CloudVendorAmountInput from './widgets/CloudVendorAmountInput';
import {sortBy} from '@/helpers/utils';

export default {
   components: {
      SectionAssignment,
      CloudVendorAmountInput,
      CloudVendorPercentageInput,
   },

   data() {
      return {
         loaded: false,
         FieldStatus,
         StatusType: this.$constants().StatusType,

         summaryFields: [
            {key: 'vendor', label: 'Name', sortable: true},
            {key: 'totalDebit', label: 'Total Debits', sortable: true},
            {key: 'totalCredit', label: 'Total Credits', sortable: true},
            {key: 'netAmount', label: 'Net Amount', sortable: true},
            {key: 'input', label: ''},
         ],
         adHocSummaryFields: [
            {
               key: 'vendorId',
               label: 'Name',
               sortable: true,
               formatter: (vendorId) => this.vendorsMap[vendorId].name,
               sortByFormatted: true,
            },
            {key: 'netAmount', label: 'Net Amount', sortable: true},
            {key: 'input', label: ''},
         ],
         setAllInput: {},
      };
   },

   computed: {
      ...mapGetters({
         manager: 'manager',
         activeStudyPeriods: 'companies/activeStudyPeriods',
         studyPeriodMap: 'companies/studyPeriodMap',
         usStates: 'usStates',
         completed: 'cloud/isCompleted',
         declaration: 'cloud/declaration',
         accountData: 'cloud/accountData',
         adHocData: 'cloud/adHocData',
         vendorsMap: 'cloud/vendorsMap',
      }),

      noData() {
         return (
            Object.keys(this.accountData).length === 0 && Object.keys(this.adHocData).length === 0
         );
      },

      periodNames() {
         return this.activeStudyPeriods.map((p) => p.label);
      },

      prettyPeriodNames() {
         const periodNames = this.periodNames;
         let returnString = '';

         if (periodNames.length < 3) {
            returnString += periodNames.join(' and ');
         } else {
            // Join all but the last period names with a comma (,)
            returnString += periodNames.slice(0, periodNames.length - 1).join(', ');

            // Add the last period name preceded by "and"
            returnString += `${returnString} and ${periodNames[-1]}`;
         }
         return returnString;
      },

      periods() {
         return Object.keys({
            ...this.accountData,
            ...this.adHocData,
         });
      },

      accountStatus() {
         const status = {};

         this.periods.forEach((periodId) => {
            const accounts = {};

            const accountStatus = (summaryData) => {
               let accountStatus;
               if (
                  summaryData.every(
                     (item) =>
                        item.percentage.value === null &&
                        (item.vendorId === null || item.netAmount.value === null)
                  )
               ) {
                  accountStatus = this.StatusType.INCOMPLETE;
               } else if (
                  summaryData.every(
                     (item) =>
                        item.percentage.value !== null &&
                        item.netAmount.value !== null &&
                        !isNaN(item.netAmount.value)
                  )
               ) {
                  accountStatus = this.StatusType.COMPLETE;
               } else {
                  accountStatus = this.StatusType.IN_PROGRESS;
               }
               return accountStatus;
            };

            let importedStatus = this.StatusType.NA;
            if (periodId in this.accountData) {
               Object.keys(this.accountData[periodId]).forEach((account) => {
                  const summaryData = this.accountData[periodId][account];
                  accounts[account] = accountStatus(summaryData);
               });

               if (Object.values(accounts).every((item) => item === this.StatusType.COMPLETE)) {
                  importedStatus = this.StatusType.COMPLETE;
               } else if (
                  Object.values(accounts).every((item) => item === this.StatusType.INCOMPLETE)
               ) {
                  importedStatus = this.StatusType.INCOMPLETE;
               } else {
                  importedStatus = this.StatusType.IN_PROGRESS;
               }
            }

            let adHocStatus = this.StatusType.NA;
            if (periodId in this.adHocData) {
               adHocStatus = accountStatus(this.adHocData[periodId]);
               console.log(adHocStatus);
            }

            let periodStatus;
            if (adHocStatus === this.StatusType.NA) {
               periodStatus = importedStatus;
            } else if (importedStatus === this.StatusType.NA) {
               periodStatus = adHocStatus;
            } else if (
               importedStatus === this.StatusType.COMPLETE &&
               adHocStatus === this.StatusType.COMPLETE
            ) {
               periodStatus = this.StatusType.COMPLETE;
            } else if (
               importedStatus === this.StatusType.INCOMPLETE &&
               adHocStatus === this.StatusType.INCOMPLETE
            ) {
               periodStatus = this.StatusType.INCOMPLETE;
            } else {
               periodStatus = this.StatusType.IN_PROGRESS;
            }

            status[periodId] = {
               accounts,
               status: periodStatus,
               adHocStatus,
            };
         });

         return status;
      },
   },

   methods: {
      /** Name for the given period */
      periodLabel(periodId) {
         return this.studyPeriodMap[periodId].label;
      },

      /** Account names of a given period, sorted alphabetically */
      sortedAccounts(periodId) {
         return periodId in this.accountData ? Object.keys(this.accountData[periodId]).sort() : [];
      },

      /** Summaries for a given period and account, sorted by vendor name */
      sortedSummaries(periodId, account) {
         return Object.values(this.accountData[periodId][account]).sort(sortBy('vendor'));
      },

      adHocSummaries(periodId) {
         return this.adHocData[periodId]
            ? Object.values(this.adHocData[periodId]).sort(
                 sortBy((sd) => this.vendorsMap[sd.vendorId].name)
              )
            : [];
      },

      accountCount(periodId) {
         let count = this.sortedAccounts(periodId).length;
         if (this.adHocSummaries(periodId).length > 0) {
            count++;
         }
         return count;
      },

      /** Format cents as USD */
      formatMoney(cents) {
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      selectSetAll(periodId, account, val) {
         Vue.set(this.setAllInput, `${periodId}:${account}`, val);
      },

      /** Unlock the section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('cloud/unlock', {
               companyId: this.$companyId,
            });
         }
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('cloud/loadData', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
.account-section {
   padding-left: 1rem;

   @include lg {
      padding-left: 3rem;
      margin-top: 1.5rem;
   }

   .account-data {
      display: flex;
      flex-direction: column-reverse;
      align-items: stretch;
      justify-content: space-between;

      @include lg {
         flex-direction: row;
      }
   }
}

.right-col {
   @include lg {
      margin-top: 45px;
      margin-left: 1rem;
      padding-left: 0.75rem;
      border-left: 6px solid $gray-500;
   }
}
</style>

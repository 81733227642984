<template>
   <CollapseCard
      ident="uploads"
      title="Uploads"
      :subtitle="`${uploadCategories.length} Categories`"
   >
      <div class="mt-3" v-if="hasCompanyRole">
         <h3 class="mb-2">Documents</h3>
         <b-table
            id="table-categories"
            class="mb-3"
            thead-class="table-head"
            :fields="fields"
            sort-by="name"
            sort-icon-left
            :items="externalUploadCategories"
         >
            <template #head(status)="data">
               <div class="cell-w-buttons">
                  {{ data.label }}
                  <b-button
                     id="btn-configure-categories"
                     variant="primary"
                     size="sm"
                     :to="{name: 'configure-uploads', params: {id: $companyId}}"
                  >
                     Configure Categories
                  </b-button>
               </div>
            </template>
            <template #cell(name)="data">
               <b-icon-circle-fill
                  class="mr-2"
                  :variant="uploadStatus(data.item).variant"
               ></b-icon-circle-fill>
               {{ data.value }}
            </template>
         </b-table>

         <h3 class="mb-2">Data Files</h3>

         <b-table
            id="table-internal-categories"
            class="mb-0"
            thead-class="table-head"
            :fields="fields"
            sort-by="name"
            sort-icon-left
            :items="internalUploadCategories"
         >
            <template #cell(name)="data">
               <b-icon-circle-fill
                  class="mr-2"
                  :variant="uploadStatus(data.item).variant"
               ></b-icon-circle-fill>
               {{ data.value }}
            </template>
            <template #cell(status)="data">
               {{ uploadStatus(data.item).text }}
            </template>
         </b-table>
      </div>
      <div v-else>
         <b-alert variant="warning" class="m-3" show>
            Only staff associated with this company can access document uploads.
         </b-alert>
      </div>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   props: {
      hasCompanyRole: Boolean,
   },

   data() {
      return {
         fields: [
            {key: 'name', label: 'Category', sortable: true},
            {
               key: 'status',
               class: 'no-wrap',
               formatter: (value, key, item) => this.uploadStatus(item).text,
               sortable: true,
               sortByFormatted: true,
            },
         ],
      };
   },

   computed: {
      ...mapGetters({
         uploadCategories: 'uploads/uploadCategories',
         externalUploadCategories: 'uploads/externalUploadCategories',
         internalUploadCategories: 'uploads/internalUploadCategories',
      }),
   },

   methods: {
      uploadStatus(item) {
         if (item.locked) {
            return {
               text: 'Completed',
               variant: 'success',
            };
         } else if (item.summary && item.summary.length > 0) {
            return {
               text: 'In Progress',
               variant: 'warning',
            };
         } else {
            return {
               text: 'Not Started',
               variant: 'danger',
            };
         }
      },
   },
};
</script>

<template>
   <b-badge
      :id="id"
      class="status-pill"
      :class="{'cursor-pointer': btn && !disabled}"
      pill
      :variant="variant"
   >
      {{ text || status }}
   </b-badge>
</template>

<script>
export default {
   props: {
      ident: [String, Number],
      status: String,
      text: {
         type: String,
         default: null,
      },
      btn: Boolean,
      disabled: Boolean,
   },

   computed: {
      id() {
         return `status-pill-${this.ident}`;
      },

      variant() {
         switch (this.status) {
            case this.$constants().StatusType.COMPLETE:
               return 'success';
            case this.$constants().StatusType.IN_PROGRESS:
               return 'primary';
            case this.$constants().StatusType.INCOMPLETE:
               return 'gray';
            case this.$constants().StatusType.NA:
               return 'white';
         }
         return null;
      },
   },
};
</script>

<style lang="scss" scoped>
::v-deep.badge-white {
   border: 1px solid $black;
}

.status-pill {
   min-width: 6.5em;
}
</style>

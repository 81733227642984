<template>
   <b-container>
      <PageTitle title="Employee Time" />

      <div class="d-flex align-items-start justify-content-end mb-4">
         <b-button
            id="btn-assign-employees"
            variant="secondary"
            size="sm"
            :to="previousRoute"
            v-if="isCustomer"
         >
            Assign Employees
         </b-button>
      </div>

      <div class="mb-4">
         <ManagerMessage v-if="manager">
            <template #short>
               <span v-if="!allComplete">
                  Tell us about the time your employees spent on R&D projects.
               </span>
               <span v-else> This section is complete. </span>
            </template>

            <div v-if="!allComplete">
               <h2>Tell us about the time your employees spent on R&D projects.</h2>
               <p class="mb-0">
                  Wages paid for time spent on R&D projects qualify for the R&D tax credit, and are
                  usually where most of the credit comes from. This section is where you tell us how
                  much time employees spent on R&D. Questions? Contact me with the secure messaging
                  feature in the top right.
               </p>
            </div>

            <div v-else>
               <h2>This section is complete.</h2>
               <p>
                  You're all done entering employee time. Visit the dashboard to see if we still
                  need more info from you.
               </p>
               <div class="d-flex justify-content-end">
                  <b-button variant="success" :to="{name: 'Home'}"> Dashboard </b-button>
               </div>
            </div>
         </ManagerMessage>
      </div>

      <h2 class="text-dark-gray mb-1">How much time have your employees spent on R&D?</h2>
      <p class="text-instruction mb-4">
         Click <b>Enter time</b> below to tell us about employee time spent on qualified R&D work
         during that time period.
      </p>

      <b-table
         v-if="timeSurveyTableItems.length > 0"
         :fields="fields"
         sort-by="period"
         :items="timeSurveyTableItems"
         id="table-time-survey"
         class="mb-3"
      >
         <template #cell(status)="data">
            <RdigStatusPill :ident="data.item.periodId" :status="data.value" />
         </template>

         <template #cell(actions)="data">
            <div class="cell-w-buttons d-flex justify-content-end">
               <b-button
                  id="btn-start"
                  v-if="allowTimeEntry(data.item.periodId)"
                  variant="primary"
                  size="sm"
                  class="btn-w-icon"
                  @click="timeSurveyRowClicked(data.item)"
               >
                  Enter time
                  <custom-icon icon="icon-chevron-right" height="16px" width="16px" class="ml-1" />
               </b-button>
               <span v-else class="text-dark-gray"><b>No Assignments</b></span>
            </div>
         </template>
      </b-table>

      <b-button variant="secondary" class="d-flex align-items-center" @click="displayIntro">
         <b-icon-arrow-left-short class="mr-1" />
         View Introduction
      </b-button>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {StudyPeriod} from '@/store/models/study';

export default {
   data() {
      return {
         fields: [
            StudyPeriod.tableField(),
            {key: 'status', class: 'no-wrap', sortable: true},
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         isRndig: 'isRndig',
         isCustomer: 'isCustomer',
         isSME: 'isSME',
         profile: 'profile',
         manager: 'manager',
         activeStudyId: 'companies/activeStudyId',
         company: 'companies/currentCompany',
         periodData: 'timesurvey/periods',
         possiblePeriods: 'timesurvey/possiblePeriods',
         studyPeriodMap: 'companies/studyPeriodMap',
         assignedEmployeeSets: 'tsAssignment/assignedEmployeeSets',
         hasAssignmentInPeriod: 'tsAssignment/hasAssignmentInPeriod',
         allAssignmentsCompleteInPeriod: 'tsAssignment/allAssignmentsCompleteInPeriod',
      }),

      /** The time survey's company */
      companyId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.company.id;
         }
      },

      /** Are any employees unassigned? */
      anyUnassigned() {
         return this.assignedEmployeeSets(null).length > 0;
      },

      // Items for the time survey table
      timeSurveyTableItems() {
         return this.possiblePeriods
            .map((periodId) => {
               let status = this.$constants().StatusType.INCOMPLETE;
               let smeAssignmentsComplete;
               const period = this.studyPeriodMap[periodId];

               if (this.isSME) {
                  smeAssignmentsComplete = this.allAssignmentsCompleteInPeriod(
                     this.profile.id,
                     periodId
                  );
               }

               if (this.periodData.VALIDATED.includes(periodId) || smeAssignmentsComplete) {
                  status = this.$constants().StatusType.COMPLETE;
               } else if (this.periodData.DATA.includes(periodId)) {
                  status = this.$constants().StatusType.IN_PROGRESS;
               }
               return {periodId, period, status};
            })
            .filter((data) => +data.period.studyId === +this.activeStudyId);
      },

      /** All possible years have been validated (submitted) */
      allComplete() {
         return this.possiblePeriods.length === this.periodData.VALIDATED.length;
      },

      /** Route to the previous step */
      previousRoute() {
         let name = 'time-survey-assignments';
         if (this.isRndig) {
            name += '-staff';
         }

         return {name, params: {id: this.companyId}};
      },
   },

   methods: {
      displayIntro() {
         this.$router.push({name: 'time-survey-intro', query: {redirect: this.$route.path}});
      },

      allowTimeEntry(year) {
         if (!this.isSME) {
            return true;
         }
         return this.hasAssignmentInPeriod(this.profile.id, year);
      },

      // Redirect to the time survey
      async timeSurveyRowClicked(item) {
         try {
            await this.$router.push({
               name: 'time-survey-year-base',
               params: {periodId: item.periodId},
            });
         } catch {
            // vue-router throws an exception when the destination route changes during a route change,
            // which we expect here (see the beforeEnter method for this route in
            // @/router/modules/time-survey.js). No additional error handling needed.
         }
      },
   },

   async created() {
      let requests = [];

      if (!this.$route.params.preloadedEmployeeAssignments) {
         requests.push(
            this.$store.dispatch('tsAssignment/loadEmployeeAssignments', {
               companyId: this.companyId,
            })
         );
      }
      requests.push(this.$store.dispatch('timesurvey/loadPeriods', {companyId: this.companyId}));
      await this.blockUntilAllSettled(requests);
   },
};
</script>

<template>
   <b-card id="card-study" style="margin-bottom: 2rem">
      <div class="d-flex align-items-center justify-content-between mb-3">
         <h2>Studies</h2>
         <b-button
            id="btn-configure-studies"
            variant="primary"
            :to="{name: 'company-studies', params: {id: $companyId}}"
         >
            Configure Studies
         </b-button>
      </div>

      <template v-if="openStudies.length > 0">
         <b-row class="mb-2">
            <b-col cols="3">
               <b>Currently Viewing Study</b>
            </b-col>
            <b-col>
               <StudySelect ident="studies" allow-none />
            </b-col>
         </b-row>
      </template>

      <template v-if="activeStudy">
         <b-row class="mb-2">
            <b-col cols="3">
               <b>Status</b>
            </b-col>
            <b-col>
               <RdigStatusPill ident="study" v-bind="activeStudy.status" />
            </b-col>
         </b-row>
         <b-row class="mb-2">
            <b-col cols="3">
               <b>Description</b>
            </b-col>
            <b-col id="study-description">
               {{ activeStudy.description || '--' }}
            </b-col>
         </b-row>
         <b>Study Periods</b>
         <b-table
            id="table-study-periods"
            :fields="fields"
            :items="activeStudy.periods"
            sort-by="startDate"
         >
            <template #cell(startDate)="data">
               {{ data.item.period.lower }}
            </template>
            <template #cell(endDate)="data">
               {{ data.item.period.upper }}
            </template>
         </b-table>
      </template>
      <b-alert variant="primary" :show="openStudies.length === 0">
         No open study exists for this company.
      </b-alert>
   </b-card>
</template>

<script>
import {mapGetters} from 'vuex';
import StudySelect from './StudySelect';

export default {
   props: {
      hasCompanyRole: Boolean,
   },

   components: {
      StudySelect,
   },

   data() {
      return {
         fields: [{key: 'startDate'}, {key: 'endDate'}, {key: 'label'}],
      };
   },

   computed: {
      ...mapGetters({
         openStudies: 'companies/openStudies',
         activeStudy: 'companies/activeStudy',
      }),
   },
};
</script>

/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import {UploadCategory, FileSummary, FileSelection} from '../models/uploadCategory';
import ErrorCodes from '@/helpers/errorCodes';
import {downloadFile, sortBy} from '../../helpers/utils';

export const SpecialCategories = Object.freeze({
   CLOUDCOMPUTING: 'CLOUDCOMPUTING',
   CLOUDCOMPUTING_DOCS: 'CLOUDCOMPUTING_DOCS',
   CONTRACTOR_DOCS: 'CONTRACTOR_DOCS',
   CONTRACTOR_FINANCIAL_DOCS: 'CONTRACTOR_FINANCIAL_DOCS',
   EMPLOYEES: 'EMPLOYEES',
   SUPPLIES: 'SUPPLIES',
   SUPPLIES_DOCS: 'SUPPLIES_DOCS',
});

const state = () => ({
   uploadCategories: {},
   companyId: null,
   fileSelection: FileSelection.ACTIVE,
   progress: {
      loaded: 0,
      total: 0,
   },
});

const getters = {
   uploadCategories: (state) => Object.values(state.uploadCategories).sort(sortBy('name')),
   uploadCategoryMap: (state) => state.uploadCategories,

   externalUploadCategories: (state, getters) => {
      return getters.uploadCategories.filter((category) => !category.internal);
   },

   internalUploadCategories: (state, getters) => {
      return getters.uploadCategories.filter((category) => category.internal);
   },

   fileSelection: (state) => state.fileSelection,

   progress: (state) => state.progress,
};

const mutations = {
   /** Clear the uploadCategories state object */
   clearUploadCategories: (state) => {
      state.uploadCategories = {};
   },

   // Update an upload category
   updateUploadCategory: (state, {uploadCategory}) => {
      if (uploadCategory.id in state.uploadCategories) {
         Object.assign(state.uploadCategories[uploadCategory.id], uploadCategory);
      } else {
         Vue.set(state.uploadCategories, uploadCategory.id, uploadCategory);
      }
   },

   // Set upload categories from a list
   setUploadCategories: (state, {uploadCategories}) => {
      uploadCategories.forEach((category) => {
         Vue.set(state.uploadCategories, category.id, category);
      });
   },

   /** Recompute the file versions within a category based on upload date */
   recomputeFileVersions: (state, {uploadCategoryId}) => {
      const summary = state.uploadCategories[uploadCategoryId].summary;

      const fileGroups = summary.reduce((obj, file) => {
         const ident = `${file.name}::${file.periodId}`;
         if (ident in obj) {
            obj[ident].push(file);
         } else {
            obj[ident] = [file];
         }
         return obj;
      }, {});

      Object.values(fileGroups).forEach((group) => {
         group.sort(sortBy('uploadedAt')).forEach((item, idx) => {
            Vue.set(item, 'version', idx + 1);
         });
      });
   },

   /** Remove an upload category from state */
   deleteUploadCategory: (state, {uploadCategoryId}) => {
      if (uploadCategoryId in state.uploadCategories) {
         Vue.delete(state.uploadCategories, uploadCategoryId);
      }
   },

   /** Remove a file from an upload category */
   deleteFile: (state, {uploadCategoryId, fileId}) => {
      if (uploadCategoryId in state.uploadCategories) {
         state.uploadCategories[uploadCategoryId].summary = state.uploadCategories[
            uploadCategoryId
         ].summary.filter((file) => {
            return file.id !== fileId;
         });
      }
   },

   // Set an upload summary list for an upload category
   setUploadSummary: (state, {uploadCategoryId, summary}) => {
      if (uploadCategoryId in state.uploadCategories) {
         state.uploadCategories[uploadCategoryId].summary = summary;
      } else {
         console.log(`No upload category currently loaded with id ${uploadCategoryId}`);
      }
   },

   // Update the file upload progress
   setProgress: (state, {loaded, total}) => {
      state.progress = {
         loaded,
         total,
      };
   },
};

const actions = {
   // Fetch and return the upload categories for a company
   async fetchCompanyUploadCategories({rootGetters}, {companyId, summary = false}) {
      const activeStudyId = rootGetters['companies/activeStudyId'];
      if (activeStudyId === null) {
         const response = await this._vm.$http.get(`/api/company/${companyId}/uploadcategory`, {
            params: {summary},
         });
         return response.data.results.map(
            (uploadCategoryData) => new UploadCategory(uploadCategoryData)
         );
      } else {
         const response = await this._vm.$http.get(
            `/api/company/${companyId}/uploadcategoryprops`,
            {
               params: {summary},
            }
         );

         return response.data.results.map((uploadCategoryPropsData) =>
            UploadCategory.fromPropsData(uploadCategoryPropsData)
         );
      }
   },

   // Fetch the upload categories for a company and load them into the state
   async loadCompanyUploadCategories(
      {state, commit, dispatch},
      {companyId, summary = false, force = true}
   ) {
      // Only load categories if they haven't already been loaded
      // or the force flag is true.
      if (companyId === state.companyId && !force) {
         return state.uploadCategories;
      }

      // Send the request
      const categories = await dispatch('fetchCompanyUploadCategories', {companyId, summary});

      // Update the state
      commit('clearUploadCategories');
      commit('setUploadCategories', {uploadCategories: categories});
      state.companyId = companyId;
      return categories;
   },

   /** Load a special uploadCategory */
   async loadSpecialCategory({commit}, {category, companyId, summary = true}) {
      const params = {summary};
      const response = await this._vm.$http.get(
         `/api/company/${companyId}/uploadcategoryprops/${SpecialCategories[category]}`,
         {params}
      );
      const uploadCategory = UploadCategory.fromPropsData(response.data);
      commit('updateUploadCategory', {uploadCategory});
      return uploadCategory;
   },

   // Add a new upload category and update the state
   async saveNewCategory({commit}, {name, projectIds, companyId}) {
      // Send the request
      const response = await this._vm.$http.post('/api/uploadcategory', {
         name,
         projectIds,
         companyId,
      });
      const uploadCategory = new UploadCategory(response.data);

      // Update state
      commit('updateUploadCategory', {uploadCategory});
      return uploadCategory;
   },

   // Edit a category and update the state
   async editCategory({state}, {id, name, projectIds, companyId}) {
      const uploadCategory = state.uploadCategories[id];

      if (uploadCategory.internal) {
         await this._vm.$bvModal.msgBoxOk(
            'This is a protected upload category and cannot be modified.',
            {
               title: 'Protected Upload Category',
               centered: true,
            }
         );
         return;
      }

      // Send the request
      const response = await this._vm.$http.put(`/api/uploadcategory/${id}`, {
         name,
         companyId,
         projectIds,
      });
      let updatedCategory = new UploadCategory(response.data);

      // Update state
      if (uploadCategory.id in state.uploadCategories) {
         state.uploadCategories[uploadCategory.id].name = updatedCategory.name;
         state.uploadCategories[uploadCategory.id].projects = updatedCategory.projects;
      }

      return updatedCategory;
   },

   /** Delete an upload category */
   async deleteUploadCategory({state, commit}, {uploadCategoryId, force = false, bvModal}) {
      const uploadCategory = state.uploadCategories[uploadCategoryId];

      if (uploadCategory.internal) {
         await bvModal.msgBoxOk('This is a protected upload category and cannot be deleted.', {
            title: 'Protected Upload Category',
            centered: true,
         });
         throw 'Cannot delete protected upload category.';
      }

      const params = {force};
      try {
         await this._vm.$http.delete(`/api/uploadcategory/${uploadCategoryId}`, {params});
      } catch (err) {
         const errCode = err.response ? err.response.data.errors[0].code : null;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteUploadCategory', {uploadCategoryId});
   },

   // Mark an upload category as complete
   async markCategoryComplete({state}, {id}) {
      await this._vm.$http.post(`/api/uploadcategory/${id}/completed`);
      const uploadCategory = state.uploadCategories[id];
      uploadCategory.locked = true;
      return uploadCategory;
   },

   // Unmark an upload category as complete
   async unmarkCategoryComplete({state}, {id}) {
      await this._vm.$http.delete(`/api/uploadcategory/${id}/completed`);
      const uploadCategory = state.uploadCategories[id];
      uploadCategory.locked = false;
      return uploadCategory;
   },

   // Upload a file to an upload category
   async uploadFiles(
      {commit},
      {
         uploadCategoryId,
         files,
         periodId = null,
         description = null,
         validation = null,
         doSave = true,
      }
   ) {
      if (files.length < 1) {
         return;
      }

      // Format the form data
      let formData = new FormData();
      files.forEach((file) => {
         formData.append('files', file);
      });

      if (periodId !== null) {
         formData.append('speriod_id', periodId);
      }

      if (description) {
         formData.append('description', description);
      }

      if (validation) {
         const validationStr = JSON.stringify(validation);
         formData.append('validation', validationStr);
      }

      commit('setProgress', {loaded: 0, total: 0});

      let response;
      // Send the request, setting content-type headers
      try {
         response = await this._vm.$http.post(
            `/api/uploadcategory/${uploadCategoryId}/upload`,
            formData,
            {
               headers: {'Content-Type': 'multipart/form-data'},
               onUploadProgress: function (event) {
                  commit('setProgress', {loaded: event.loaded, total: event.total});
               },
            }
         );
      } catch (err) {
         const code = err.response.data.errors[0].code;
         if (code === ErrorCodes.CLOSED_STUDY) {
            await this._vm.$bvModal.msgBoxOk(
               'The selected period is part of a study which has been closed. No new files may be uploaded for the selected period.',
               {
                  title: 'Closed Study',
                  centered: true,
               }
            );
            throw err;
         } else {
            throw err;
         }
      }
      const summaryData = response.data.results;
      const summary = summaryData.map((data) => new FileSummary(data));

      // Update state
      if (doSave) {
         commit('setUploadSummary', {uploadCategoryId, summary});
      }
      return summary;
   },

   /**
    * Delete an uploaded file
    * @param {string|number} fileId - ID of the file to delete
    * @param {string|number} [uploadCategoryId] - Upload category the file belongs to. If provided, the file will be removed in the app state
    * @param {boolean} [force=false] - Force the operation
    */
   async deleteFile({commit}, {fileId, uploadCategoryId = null, force = false}) {
      const params = {force};
      await this._vm.$http.delete(`/api/uploadfile/${fileId}`, {params});
      if (uploadCategoryId !== null) {
         commit('deleteFile', {uploadCategoryId, fileId});
         commit('recomputeFileVersions', {uploadCategoryId});
      }
   },

   /**
    * Send a request to update the study period and description on a file or group of files. Either a
    * name and periodId, or a fileId may be used to identify the files to be updated. If name and
    * currentPeriodId are provided, all files in the same category with matching name and period
    * will be updated.
    *
    * @param {string} uploadCategoryId - The ID of the upload category the file belongs to.
    * @param {string, number} periodId - The study period to be assigned to the file.
    * @param {string} [name] - The name of a file. Must also provide `currentPeriodId`.
    * @param {string} [currentPeriodId] - The current study period of the file. Must also provide `name`.
    * @param {string} [fileId] - The ID of an uploaded file
    */
   async updateFileDetails(
      {state, commit},
      {uploadCategoryId, periodId, description, name = null, currentPeriodId = null, fileId = null}
   ) {
      const summary = state.uploadCategories[uploadCategoryId].summary;
      let files;

      if (name) {
         // Collect all versions of the given file name
         files = summary.filter((item) => item.name === name && item.periodId === currentPeriodId);
      } else if (fileId) {
         files = summary.filter((item) => item.id === fileId);
      } else {
         // No identifier, so do nothing
         return;
      }

      const requests = files.map((file) => {
         this._vm.$http.put(`/api/uploadfile/${file.id}`, {speriodId: periodId, description});
      });
      await Promise.all(requests);

      // Update each file
      files.forEach((file) => {
         file.periodId = periodId;
         file.description = description;
      });

      commit('recomputeFileVersions', {uploadCategoryId});
   },

   async downloadFile({}, {fileId}) {
      const response = await this._vm.$http.get(`/api/uploadfile/${fileId}`, {
         responseType: 'blob',
      });
      downloadFile(response);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

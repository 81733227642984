<template>
   <div class="sidebar-container" :class="{'msg-expanded': !msgCollapsed}">
      <div class="sidebar-content">
         <b-navbar-brand class="brand" :to="{name: 'Home'}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1275.59 368.5" class="logo">
               <g id="a" />
               <g id="b" />
               <g id="c">
                  <g>
                     <path
                        class="f"
                        d="M123.91,182.02h-24.5c0,50.01,40.54,90.55,90.55,90.55v-24.5c-36.48,0-66.05-29.57-66.05-66.05Z"
                     />
                     <path
                        class="e logo-long"
                        d="M663.42,134.37h24.6v95.31h-24.6v-11.25c-7.37,9.28-17.72,13.92-31.07,13.92s-23.6-4.86-32.69-14.58c-9.09-9.71-13.62-21.63-13.62-35.74s4.53-26.02,13.62-35.74c9.09-9.71,19.98-14.58,32.69-14.58,13.36,0,23.7,4.64,31.07,13.92v-11.25Zm-45.36,67c4.95,5.03,11.25,7.54,18.86,7.54s13.95-2.51,18.97-7.54c5.03-5.01,7.52-11.46,7.52-19.35s-2.5-14.32-7.52-19.35c-5.03-5.03-11.34-7.52-18.97-7.52s-13.92,2.5-18.86,7.52c-4.96,5.03-7.43,11.46-7.43,19.35s2.47,14.33,7.43,19.35Z"
                     />
                     <path
                        class="e logo-long"
                        d="M732.66,150.76c2.3-6.1,6.07-10.67,11.34-13.73,5.27-3.04,11.15-4.58,17.64-4.58v27.46c-7.51-.88-14.21,.64-20.11,4.58-5.91,3.94-8.87,10.49-8.87,19.62v45.56h-24.59v-95.31h24.59v16.4Z"
                     />
                     <path
                        class="e logo-long"
                        d="M842.3,96.25h24.59V229.68h-24.59v-11.25c-7.24,9.28-17.53,13.92-30.89,13.92s-23.8-4.86-32.87-14.58c-9.09-9.71-13.64-21.63-13.64-35.74s4.55-26.02,13.64-35.74c9.08-9.71,20.04-14.58,32.87-14.58s23.64,4.64,30.89,13.92v-49.36Zm-45.27,105.12c5.01,5.03,11.34,7.54,18.96,7.54s13.92-2.51,18.88-7.54c4.95-5.01,7.43-11.46,7.43-19.35s-2.48-14.32-7.43-19.35c-4.96-5.03-11.25-7.52-18.88-7.52s-13.95,2.5-18.96,7.52c-5.03,5.03-7.54,11.46-7.54,19.35s2.51,14.33,7.54,19.35Z"
                     />
                     <path
                        class="e logo-long"
                        d="M905.26,228.55c-2.03,2.02-4.51,3.04-7.43,3.04s-5.4-1.02-7.43-3.04c-2.03-2.03-3.06-4.51-3.06-7.45s1.02-5.4,3.06-7.43c2.03-2.03,4.51-3.06,7.43-3.06s5.4,1.02,7.43,3.06c2.03,2.03,3.06,4.51,3.06,7.43s-1.02,5.41-3.06,7.45Z"
                     />
                     <path
                        class="e logo-long"
                        d="M1060.38,134.37h12.58v95.31h-12.58v-18.68c-8.77,13.98-21.61,20.97-38.5,20.97-13.61,0-25.19-4.84-34.8-14.49-9.59-9.66-14.38-21.49-14.38-35.46s4.79-25.8,14.38-35.45c9.6-9.66,21.19-14.49,34.8-14.49,16.89,0,29.74,6.98,38.5,20.96v-18.68Zm-64.24,74.45c7.24,7.31,16.13,10.95,26.68,10.95s19.45-3.65,26.7-10.95c7.24-7.31,10.86-16.24,10.86-26.79s-3.61-19.47-10.86-26.78c-7.24-7.31-16.15-10.97-26.7-10.97s-19.44,3.66-26.68,10.97c-7.24,7.31-10.86,16.23-10.86,26.78s3.61,19.48,10.86,26.79Z"
                     />
                     <path
                        class="e logo-long"
                        d="M1177.46,229.68h-15.06l-29.72-39.33-29.74,39.33h-15.06l37.26-49.29-34.78-46.03h15.05l27.27,36.07,27.26-36.07h15.05l-34.78,46.03,37.26,49.29Z"
                     />
                     <path
                        class="e logo-long"
                        d="M308.38,131.7c10.43,0,19.04,3.51,25.83,10.49,6.79,7,10.19,16.65,10.19,28.98v58.52h-24.59v-55.48c0-6.35-1.71-11.2-5.13-14.58-3.44-3.37-8.02-5.04-13.73-5.04-6.36,0-11.43,1.97-15.25,5.91-3.82,3.94-5.72,9.85-5.72,17.72v51.47h-24.59v-95.31h24.59v10.67c5.97-8.89,15.44-13.34,28.41-13.34Z"
                     />
                     <path
                        class="e logo-long"
                        d="M418.6,131.7c12.83,0,23.8,4.87,32.87,14.58,9.09,9.73,13.64,21.64,13.64,35.74s-4.55,26.03-13.64,35.74c-9.08,9.73-20.04,14.58-32.87,14.58s-23.64-4.64-30.89-13.92v11.25h-24.59V96.25h24.59v49.36c7.24-9.28,17.55-13.92,30.89-13.92Zm-23.46,69.67c4.96,5.03,11.25,7.54,18.88,7.54s13.95-2.51,18.97-7.54c5.01-5.01,7.52-11.46,7.52-19.35s-2.51-14.32-7.52-19.35c-5.03-5.03-11.36-7.52-18.97-7.52s-13.92,2.5-18.88,7.52c-4.95,5.03-7.43,11.46-7.43,19.35s2.48,14.33,7.43,19.35Z"
                     />
                     <path
                        class="e logo-long"
                        d="M551.4,182.02c0,14.26-11.56,25.83-25.83,25.83s-25.83-11.56-25.83-25.83,11.56-25.83,25.83-25.83,25.83,11.56,25.83,25.83Zm-25.83-50.32c-27.79,0-50.32,22.53-50.32,50.32s22.53,50.32,50.32,50.32,50.32-22.53,50.32-50.32-22.53-50.32-50.32-50.32Z"
                     />
                     <path
                        class="e logo-long"
                        d="M965.41,146.58v-12.21h-27.44v-26.68h-12.58v96.65c0,11.06,3.34,18.55,10.01,22.49,6.67,3.94,16.68,4.89,30.02,2.85v-11.05c-6.1,.25-11.17,.34-15.25,.28-4.06-.06-7.11-1.21-9.14-3.43-2.05-2.22-3.06-5.94-3.06-11.15v-57.76h27.44Z"
                     />
                     <path
                        class="e"
                        d="M215.79,182.02c0,14.26-11.56,25.83-25.83,25.83s-25.83-11.56-25.83-25.83,11.56-25.83,25.83-25.83,25.83,11.56,25.83,25.83Zm-25.83-50.32c-27.79,0-50.32,22.53-50.32,50.32s22.53,50.32,50.32,50.32,50.32-22.53,50.32-50.32-22.53-50.32-50.32-50.32Z"
                     />
                  </g>
               </g>
               <g id="d" />
            </svg>
         </b-navbar-brand>

         <div class="progress-container">
            <b-form-select
               v-if="openStudies.length > 1"
               id="select-sb-study"
               size="sm"
               class="mb-2"
               :options="studyOptions"
               :value="activeStudyId"
               @change="selectStudy"
            >
            </b-form-select>

            <div class="d-flex align-items-center">
               <span id="progress-percentage-sidebar" class="mr-2 text-small"
                  >{{ progressTotal }}%</span
               >
               <b-progress
                  :value="progressTotal"
                  :max="100"
                  variant="white"
                  class="w-100 progress-white"
               ></b-progress>
            </div>
         </div>

         <div class="stages-container">
            <div class="dashboard-container">
               <b-button id="btn-dashboard" variant="primary" :to="{name: dashboardStage.route}">
                  <div class="d-flex align-items-center">
                     <custom-icon width="20" height="20" class="mr-1" :icon="dashboardStage.icon" />
                     <span class="stage-name">
                        {{ dashboardStage.name }}
                     </span>
                  </div>
               </b-button>
            </div>

            <div class="stage-list">
               <div
                  v-for="(stage, index) in listStages"
                  :key="index"
                  :id="`sidebar-item-${index + 1}`"
                  class="stage-item"
               >
                  <div class="stage" :class="{active: stage.active}" @click="stageClicked(stage)">
                     <custom-icon :icon="stage.icon" class="stage-icon" />
                     <span class="ml-2 stage-name">
                        {{ stage.name }}
                     </span>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="background"></div>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         stages: 'stages',
         progress: 'progress/activeStudyProgress',
         isAppConfigured: 'isAppConfigured',
         msgCollapsed: 'messaging/clientMessagingCollapsed',
         openStudies: 'companies/openStudies',
         activeStudyId: 'companies/activeStudyId',
      }),

      listStages() {
         return this.stages.slice(1);
      },

      dashboardStage() {
         return this.stages[0];
      },

      /** The current total progress percentage */
      progressTotal() {
         return this.progress.total;
      },

      studyOptions() {
         return this.openStudies.map((study) => ({value: study.id, text: study.label}));
      },
   },

   methods: {
      async selectStudy(studyId) {
         const requests = [
            this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId}),
            this.$store.dispatch('progress/loadProgress', {
               companyId: this.$companyId,
               allStudies: true,
            }),
         ];
         await this.blockUntilAllSettled(requests);
         this.$store.commit('companies/setActiveStudy', studyId);
         if (this.$route.meta.activeStudyRedirect) {
            this.$router.push({
               name: this.$route.meta.activeStudyRedirect,
               params: this.$route.params,
            });
         } else if (this.$route.meta.activeStudyRedirect !== null && this.$route.name !== 'Home') {
            this.$router.push({name: 'Home'});
         }
      },

      async stageClicked(stage) {
         const stageRoute = stage.route || '';
         const isSameStage =
            this.$route.name === stageRoute ||
            // The `time-survey-base` route redirects the client to another route that starts with `time-survey`,
            // and must be treated specially
            (stageRoute === 'time-survey-base' && this.$route.name.startsWith('time-survey'));

         if (!isSameStage) {
            try {
               await this.$router.push({name: stage.route});
            } catch {
               // vue-router throws an exception when the destination route changes during a route change,
               // which we expect in some cases (see the beforeEnter method for this route in
               // @/router/modules/time-survey.js). No additional error handling needed.
            }
         }
      },
   },
};
</script>

<style lang="scss" scoped>
$sidebar-dark: $gray-900;
$sidebar-width: 13rem;
$sidebar-width-collapsed: 3.9rem;

@mixin collapse {
   overflow-x: visible;
   width: $sidebar-width-collapsed;

   .sidebar-content {
      pointer-events: none;
   }

   .brand {
      pointer-events: auto;
      width: 3.5rem;
      padding-left: 0.3rem;
      overflow-x: hidden;

      .logo-long {
         display: none;
      }
   }

   .background {
      width: $sidebar-width-collapsed;
   }

   .progress-container {
      display: none;
   }

   .dashboard-container {
      padding: 12px 14px 12px 9px;
      text-align: left;
   }

   #btn-dashboard {
      pointer-events: auto;
      padding: 0.35rem;

      svg {
         margin: 3px 3px 2px 2px;
      }

      .stage-name {
         transition: width 0.3s ease;
      }

      &:hover {
         .stage-name {
            width: 6rem;
         }
      }
   }

   .stage-name {
      display: inline-block;
      width: 0;
      overflow: hidden;
      flex-shrink: 0;
      white-space: nowrap;
      transition: width 0.3s ease;
   }

   .stage {
      padding: 16px 14px 16px 18px;
   }

   .stage-item {
      pointer-events: auto;
      width: $sidebar-width-collapsed;
      background: $gray-800;
      transition: width 0.3s ease;
   }

   .stage-item:hover {
      width: $sidebar-width;
      box-shadow: 0px 4px 20px 0px #005d8b26;
      .stage-name {
         width: 10rem;
      }
   }
}

.sidebar-container {
   width: $sidebar-width;
   position: fixed;
   left: 0;
   top: 0;
   bottom: 0;
   z-index: 1030;

   .background {
      width: $sidebar-width;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1029;
      background: $gray-800;
      box-shadow: 0px 4px 20px 0px #005d8b26;
   }

   .sidebar-content {
      // display: block;
      width: $sidebar-width;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1030;
      overflow-y: scroll;
      // Hide the scrollbar in various browsers
      &::-webkit-scrollbar {
         display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
   }

   .progress-container {
      color: $white;
      padding: 0 1rem;
      display: flex;
      flex-direction: column;
   }

   .brand {
      margin-top: 16px;
      margin-bottom: 2rem;
      margin-right: 0;
      padding: 0;
      display: block;
      text-align: center;

      .logo {
         width: 192px;
         .e {
            fill: $white;
         }
         .f {
            fill: $primary;
         }
      }

      img {
         width: 12rem;
      }
   }

   .dashboard-container {
      padding: 20px 14px;
      text-align: center;

      .btn {
         padding-top: 0.5rem;
         padding-bottom: 0.5rem;

         svg {
            margin-right: 0.5rem;
         }
      }
   }

   .stages-container {
      color: $white;
   }

   .stage-list {
      list-style: none;
      padding: 0;
   }

   .stage {
      padding: 18px 14px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;
      background: transparent;

      &:hover {
         color: $primary-200;
      }

      .stage-icon {
         flex-shrink: 0;
      }

      &.active {
         background-color: $sidebar-dark;
         border-left: 6px solid $primary;
      }
   }

   @include lg-max {
      @include collapse;
   }

   &.msg-expanded {
      @media all and (max-width: 1335px) {
         @include collapse;
      }
   }
}
</style>

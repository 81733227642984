<template>
   <b-container>
      <h1 class="mb-3">Configure Business Components</h1>
      <b-card>
         <b-table
            id="table-edit-projects"
            class="mb-0"
            :fields="fields"
            sort-by="name"
            :items="projects"
         >
            <template #head(actions)>
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button variant="primary" size="sm" @click="newProjectModal">
                     New Business Component
                  </b-button>
               </div>
            </template>
            <template #cell(actions)="data">
               <div class="cell-w-buttons d-flex justify-content-end">
                  <b-button
                     v-if="isStaff"
                     :id="`btn-delete-${data.index}`"
                     variant="danger"
                     size="sm"
                     class="mr-2"
                     @click="deleteProjectModal(data.item)"
                  >
                     Delete
                  </b-button>
                  <b-button
                     :id="`btn-edit-${data.index}`"
                     variant="primary"
                     size="sm"
                     @click="editProjectModal(data.item)"
                  >
                     Edit
                  </b-button>
               </div>
            </template>
         </b-table>
      </b-card>

      <b-modal
         id="modal-new-project"
         title="New Business Component"
         centered
         ok-title="Submit"
         @ok="saveNewProject"
         :ok-disabled="$v.newProject.$invalid"
         @shown="focusName"
      >
         <ProjectForm
            ref="newProjectForm"
            :project="newProject"
            :validation="$v.newProject"
            @submit="saveNewProject"
         />
      </b-modal>

      <b-modal
         id="modal-edit-project"
         title="Edit Business Component"
         centered
         ok-title="Submit"
         @ok="saveEditProject"
         :ok-disabled="$v.editProject.$invalid"
      >
         <ProjectForm
            :project="editProject"
            :validation="$v.editProject"
            @submit="saveEditProject"
         />
      </b-modal>

      <b-modal
         id="modal-delete-project"
         title="Delete Business Component"
         centered
         @ok="submitDeleteProject"
         ok-title="Delete"
         ok-variant="danger"
      >
         Are you sure you want to delete business component <b>{{ deleteProject.name }}</b
         >?
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';

import ErrorCodes from '@/helpers/errorCodes';
import {Project} from '@/store/models/project';
import ProjectForm from './widgets/ProjectForm';

export default {
   components: {
      ProjectForm,
   },

   data() {
      return {
         fields: [
            {key: 'name', sortable: true},
            {key: 'humanPeriodLabels', label: 'Study Periods'},
            'actions',
         ],
         newProject: new Project(),
         newProjectValid: false,
         editProject: new Project(),
         editProjectValid: false,
         deleteProject: new Project(),
      };
   },

   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         projects: 'projects/projects',
      }),

      /** The ID of the current company */
      companyId() {
         return this.$route.params.id;
      },
   },

   methods: {
      /** Send a POST request to create a new project */
      async saveNewProject() {
         if (this.$v.newProject.$invalid) {
            return;
         }

         try {
            await this.$store.dispatch('projects/createProject', {
               project: this.newProject,
               companyId: this.companyId,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            const code = err.response.data.errors[0].code;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A project with that name already exists',
                  seconds: 5,
               });
            } else if (code === 120) {
               const force = await this.$bvModal.msgBoxConfirm(
                  'Creating this project would invalidate a time survey that has already been submitted. Would you like to proceed anyways?',
                  {
                     centered: true,
                  }
               );
               if (force) {
                  await this.$store.dispatch('projects/createProject', {
                     project: this.newProject,
                     companyId: this.companyId,
                     force: true,
                  });
               }
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Clear the new project object and display the new project modal */
      newProjectModal() {
         this.newProject = new Project({companyId: this.$companyId});
         this.$bvModal.show('modal-new-project');
      },

      /** Focus the name field in the new project modal */
      focusName() {
         this.$refs.newProjectForm.focusName();
      },

      /** Send a PUT request to update a project */
      async saveEditProject() {
         if (this.$v.editProject.$invalid) {
            return;
         }

         try {
            await this.blockingRequest('projects/editProject', {
               project: this.editProject,
               companyId: this.companyId,
            });
         } catch (err) {
            const msg = err.response.data.errors[0].detail;
            const code = err.response.data.errors[0].code;
            if (msg.includes('UniqueViolationError')) {
               this.$store.commit('showAlert', {
                  msg: 'A project with that name already exists',
                  seconds: 5,
               });
            } else if (msg.includes('year_within_project_years')) {
               this.$store.commit('showAlert', {
                  msg: "Invalid date range: Time survey data already exists for at least one of the years you're attempting to remove from this project's date range",
                  seconds: 10,
               });
            } else if (code === 120) {
               const force = await this.$bvModal.msgBoxConfirm(
                  'Editing this project would invalidate a time survey that has already been submitted. Would you like to proceed anyways?',
                  {
                     centered: true,
                  }
               );
               if (force) {
                  await this.blockingRequest('projects/editProject', {
                     project: this.editProject,
                     force: true,
                  });
               }
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Create a copy of the selected project's data and display the edit project modal */
      editProjectModal(project) {
         this.editProject = project.clone();
         this.$bvModal.show('modal-edit-project');
      },

      async deleteProjectModal(project) {
         try {
            await this.blockingRequest('projects/deleteProject', {
               projectId: project.id,
            });
            this.deleteProject = project;
            this.$bvModal.show('modal-delete-project');
         } catch (err) {
            const errCode = err.response.data.errors[0].code;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected business component has related data, and cannot be deleted by a staff user. Contact an admin user to delete this business component.',
                  {
                     title: 'Business Component Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },

      async submitDeleteProject() {
         await this.blockingRequest('projects/deleteProject', {
            projectId: this.deleteProject.id,
            force: true,
         });
      },
   },

   async created() {
      await this.blockingRequest('projects/loadProjects', {companyId: this.companyId});
   },

   validations() {
      const projectValidation = () => ({
         name: {required},
      });

      return {
         newProject: projectValidation(),
         editProject: projectValidation(),
      };
   },
};
</script>

<template>
   <b-container v-if="loaded">
      <PageTitle title="Cloud Computing" />

      <SectionAssignment section="cloud" />

      <ManagerMessage v-if="manager">
         <div v-if="completed">
            <h2>You're all done with this section!</h2>
            <p>Head over to the dashboard to find out what you need to do next.</p>
            <div class="d-flex justify-content-end">
               <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
            </div>
         </div>
         <div v-else-if="dataComplete">
            <h2 class="mb-0">Does everything look correct?</h2>
            <p>
               If all the information shown here looks correct, click
               <b>{{ hasSectionAssignment ? 'Done' : 'Complete' }}</b> below to finish up.
            </p>
         </div>
         <div v-else-if="hasSectionAssignment">
            <h2>This section still needs some data.</h2>
            <p>
               This section is not yet complete, but if you've entered all the information you have
               you can click Done below to be done with your assignment.
            </p>
         </div>
         <div v-else>
            <h2>This section still needs some data.</h2>
            <p>
               Finish entering required data in the previous screen, then come back here to complete
               this section.
            </p>
         </div>
      </ManagerMessage>

      <b-alert variant="success" class="mb-5" :show="!manager && completed">
         This section has been submitted.
      </b-alert>

      <div v-for="(periodId, periodIndex) in periods" :key="periodIndex">
         <h2 :id="`header-${periodIndex}`" class="mb-4">
            Cloud Computing purchases for {{ periodLabel(periodId) }}
         </h2>

         <b-card class="mb-4">
            <template v-if="periodId in accountData">
               <div
                  v-for="(account, accountIndex) in sortedAccounts(periodId)"
                  :key="`${periodIndex}:${account}`"
                  class="mb-4"
               >
                  <h2 :id="`account-name-${periodIndex}-${accountIndex + 1}`" class="mb-4">
                     {{ account }}
                  </h2>

                  <b-row class="mb-3">
                     <b-col cols="4">
                        <b>Vendor</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Total Debits</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Total Credits</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Net Amount</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Percentage</b>
                     </b-col>
                  </b-row>
                  <b-row
                     v-for="(
                        {vendor, totalDebit, totalCredit, netAmount, percentage}, index
                     ) in sortedVendors(periodId, account)"
                     :key="`${periodIndex}:${account}:${vendor}`"
                     class="mb-3"
                  >
                     <b-col :id="`vendor-${periodIndex}-${index}`" cols="4">
                        <b>{{ vendor }}</b>
                     </b-col>
                     <b-col :id="`total-debit-${periodIndex}-${index}`" cols="2">{{
                        formatMoney(totalDebit)
                     }}</b-col>
                     <b-col :id="`total-credit-${periodIndex}-${index}`" cols="2">{{
                        formatMoney(totalCredit)
                     }}</b-col>
                     <b-col :id="`net-amount-${periodIndex}-${index}`" cols="2">
                        {{ formatMoney(netAmount.value) }}
                     </b-col>
                     <b-col :id="`percentage-${periodIndex}-${index}`" cols="2">
                        {{ formatPercent(percentage.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="percentage.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="
                              'This vendor\'s qualifying percentage has not been entered.'
                           "
                        ></b-icon>
                     </b-col>
                  </b-row>
               </div>
            </template>

            <div v-if="Object.keys(adHocData).length > 0">
               <h2 class="mb-4">Manually-Added Vendors</h2>
               <div>
                  <b-row class="mb-3">
                     <b-col cols="4">
                        <b>Vendor</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Net Amount</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Percentage</b>
                     </b-col>
                  </b-row>
                  <b-row
                     v-for="({vendor, netAmount, percentage}, index) in sortedVendors(
                        periodId,
                        null
                     )"
                     :key="`${periodIndex}:${vendor.id}`"
                     class="mb-3"
                  >
                     <b-col :id="`vendor-adhoc-${periodIndex}-${index}`" cols="4">
                        <b>{{ vendor.name }}</b>
                     </b-col>
                     <b-col :id="`net-amount-adhoc-${periodIndex}-${index}`" cols="2">
                        {{ formatMoney(netAmount.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="netAmount.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="'This vendor\'s net amount has not been entered.'"
                        ></b-icon>
                     </b-col>
                     <b-col :id="`percentage-adhoc-${periodIndex}-${index}`" cols="2">
                        {{ formatPercent(percentage.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="percentage.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="
                              'This vendor\'s qualifying percentage has not been entered.'
                           "
                        ></b-icon>
                     </b-col>
                  </b-row>
               </div>
            </div>
         </b-card>
      </div>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'cloud-percentages'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-submit"
            variant="success"
            v-if="!completed && !hasSectionAssignment"
            :disabled="!dataComplete"
            @click="submit"
         >
            Complete <b-icon-check />
         </b-button>
         <b-button
            v-if="hasSectionAssignment"
            id="btn-complete-assignment"
            variant="success"
            @click="completeAssignment"
         >
            Done <b-icon-check />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from '@/helpers/utils';
import SectionAssignment from '../components/SectionAssignment';

export default {
   components: {
      SectionAssignment,
   },

   data() {
      return {
         loaded: false,
      };
   },

   computed: {
      ...mapGetters({
         hasSectionAssignment: 'cloud/hasSectionAssignment',
         isSME: 'isSME',
         manager: 'manager',
         studyPeriodMap: 'companies/studyPeriodMap',
         accountData: 'cloud/accountData',
         adHocData: 'cloud/adHocData',
         completed: 'cloud/isCompleted',
         vendorsMap: 'cloud/vendorsMap',
      }),

      periods() {
         return Object.keys({
            ...this.accountData,
            ...this.adHocData,
         });
      },

      dataComplete() {
         const accountDataComplete = Object.values(this.accountData).reduce(
            (periodAgg, periodData) => {
               const yearComplete = Object.values(periodData).reduce((accountAgg, vendors) => {
                  const accountComplete = vendors.every((v) => v.percentage.value !== null);
                  return accountAgg && accountComplete;
               }, true);
               return periodAgg && yearComplete;
            },
            true
         );

         if (!accountDataComplete) {
            return false;
         }

         return Object.values(this.adHocData).reduce((periodAgg, vendors) => {
            const yearComplete = vendors.every(
               (v) => v.percentage.value !== null && v.netAmount.value !== null
            );
            return periodAgg && yearComplete;
         }, true);
      },
   },

   methods: {
      /** Complete a section assignment */
      async completeAssignment() {
         await this.blockingRequest('cloud/completeSectionAssignment', {
            companyId: this.$companyId,
         });

         if (this.isSME) {
            this.$router.push({name: 'Home'});
         }
      },

      /** Account names of a given year, sorted alphabetically */
      sortedAccounts(periodId) {
         return Object.keys(this.accountData[periodId]).sort();
      },

      /** Vendors of a given period and account, sorted by vendor name */
      sortedVendors(periodId, account) {
         let vendors;

         if (account === null) {
            vendors = this.adHocData[periodId];

            // We join the appropriate vendors to ad-hoc summaries
            vendors.forEach((sd) => {
               sd.vendor = this.vendorsMap[sd.vendorId];
            });
         } else {
            vendors = this.accountData[periodId][account];
         }
         return [...vendors].sort(sortBy('vendor'));
      },

      /** Format cents as USD */
      formatMoney(cents) {
         if (cents === null) {
            return null;
         }
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      /** Format a percentage */
      formatPercent(percentage) {
         if (percentage === null) {
            return '';
         }
         return `${percentage}%`;
      },

      /** Name for the given period */
      periodLabel(periodId) {
         return this.studyPeriodMap[periodId].label;
      },

      async submit() {
         // sanity check
         if (!this.dataComplete) {
            return;
         }
         await this.blockingRequest('cloud/submit', {companyId: this.$companyId});
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('cloud/loadData', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
      this.loaded = true;
   },
};
</script>

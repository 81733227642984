<template>
   <div>
      <div class="d-flex align-items-center justify-content-between mb-3">
         <h2 class="mb-0">Study Periods</h2>
         <b-button id="btn-add-study-period" variant="primary" @click="addPeriod">
            Add Study Period
         </b-button>
      </div>
      <b-table
         id="table-study-periods"
         :fields="fields"
         :items="study.periods"
         sort-by="startDate"
         show-empty
      >
         <template #empty>
            <b-alert variant="primary" show> Add at least one study period before saving. </b-alert>
         </template>
         <template #cell(startDate)="data">
            {{ data.item.period.lower }}
         </template>
         <template #cell(endDate)="data">
            {{ data.item.period.upper }}
         </template>
         <template #cell(actions)="data">
            <div class="cell-w-buttons justify-content-end">
               <b-button
                  :id="`btn-edit-${data.index}`"
                  size="sm"
                  class="mr-2"
                  @click="editPeriod(data.index)"
               >
                  Edit
               </b-button>
               <button
                  v-if="!edit || study.periods.length > 1"
                  :id="`btn-delete-${data.index}`"
                  class="icon-btn icon-btn-danger"
                  type="button"
                  @click="deletePeriod(data.item)"
               >
                  <b-icon-trash></b-icon-trash>
               </button>
            </div>
         </template>
      </b-table>

      <b-form-group
         label="Study Label"
         label-for="input-study-label"
         :state="$v.study.label.$invalid ? false : null"
         :invalid-feedback="$v.study.label.required ? 'Max 32 characters' : 'required'"
      >
         <b-form-input
            id="input-study-label"
            v-model="study.label"
            :state="$v.study.label.$invalid ? false : null"
            @input="$emit('change')"
         ></b-form-input>
      </b-form-group>

      <b-form-group label="Description" label-for="textarea-study-description">
         <b-form-textarea
            id="textarea-study-description"
            v-model="study.description"
            @input="$emit('change')"
         ></b-form-textarea>
      </b-form-group>

      <b-modal
         id="modal-add-period"
         :title="editingPeriod ? 'Edit Study Period' : 'Add Study Period'"
         size="lg"
         centered
         @ok="insertPeriod"
         :ok-disabled="$v.currentPeriod.$invalid"
      >
         <b-form-row>
            <b-col cols="4">
               <b-form-group
                  label="Start Date"
                  label-for="input-start-date"
                  :state="
                     $v.currentPeriod.startDate.$invalid || invalidFeedback !== null ? false : null
                  "
                  :invalid-feedback="currentPeriod.startDate ? invalidFeedback : 'required'"
               >
                  <v-date-picker
                     ref="startDate"
                     timezone="UTC"
                     v-model="currentPeriod.startDate"
                     :model-config="modelConfig"
                     :disabled-dates="disabledDates"
                     :attributes="startDateAttrs"
                     :input-debounce="1000"
                     mode="date"
                     @input="onInputStartDate"
                     @popoverDidShow="onDatepickerShown('startDate')"
                  >
                     <template v-slot="{inputValue, inputEvents}">
                        <input
                           id="input-start-date"
                           class="form-control"
                           :class="{
                              'is-invalid':
                                 $v.currentPeriod.startDate.$invalid || invalidFeedback !== null,
                           }"
                           :value="inputValue"
                           v-on="inputEvents"
                           placeholder="mm/dd/yyyy"
                        />
                     </template>
                  </v-date-picker>
               </b-form-group>
            </b-col>
            <b-col cols="4">
               <b-form-group
                  label="End Date"
                  label-for="input-end-date"
                  :state="$v.currentPeriod.endDate.$invalid ? false : null"
                  invalid-feedback="required"
               >
                  <v-date-picker
                     ref="endDate"
                     timezone="UTC"
                     v-model="currentPeriod.endDate"
                     :model-config="modelConfig"
                     :disabled-dates="disabledDates"
                     :attributes="endDateAttrs"
                     :input-debounce="1000"
                     mode="date"
                     @input="onInputEndDate"
                     @popoverDidShow="onDatepickerShown('endDate')"
                  >
                     <template v-slot="{inputValue, inputEvents}">
                        <input
                           id="input-end-date"
                           class="form-control"
                           :class="{
                              'is-invalid':
                                 $v.currentPeriod.endDate.$invalid || invalidFeedback !== null,
                           }"
                           :value="inputValue"
                           v-on="inputEvents"
                           placeholder="mm/dd/yyyy"
                        />
                     </template>
                  </v-date-picker>
               </b-form-group>
            </b-col>
            <b-col cols="4">
               <b-form-group
                  label="Label"
                  label-for="input-study-period-label"
                  :invalid-feedback="currentPeriod.label ? 'Duplicate label' : 'Required'"
               >
                  <b-form-input
                     id="input-study-period-label"
                     v-model="currentPeriod.label"
                     :state="$v.currentPeriod.label.$invalid ? false : null"
                  ></b-form-input>
               </b-form-group>
            </b-col>
         </b-form-row>
      </b-modal>

      <b-modal
         id="modal-confirm-delete-period"
         title="Delete this study period?"
         centered
         ok-title="Delete"
         ok-variant="danger"
         @ok="confirmDeletePeriod"
      >
         Removing this period will permanently destroy client-entered data associated with them.
         This includes:

         <ul>
            <li v-for="str in relatedStrings" :key="str">{{ str }}</li>
         </ul>

         Restoring this data, even from a recent backup, will be costly, time-intensive, and maybe
         impossible.
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, maxLength} from 'vuelidate/lib/validators';
import {StudyPeriod} from '@/store/models/study';

export default {
   props: {
      study: Object,
      edit: Boolean,
   },

   data() {
      return {
         fields: [
            {key: 'startDate'},
            {key: 'endDate'},
            {key: 'label'},
            {key: 'actions', label: ''},
         ],
         modelConfig: {
            type: 'string',
            mask: 'YYYY-MM-DD',
         },
         currentPeriod: new StudyPeriod(),
         periodBackup: null,
         editingPeriod: false,
         relatedData: null,
         deletingPeriod: null,
      };
   },

   computed: {
      ...mapGetters({
         studyPeriods: 'companies/studyPeriods',
      }),

      allPeriods() {
         let periods = [...this.studyPeriods, ...this.study.periods];

         if (this.editingPeriod) {
            // When editing a period, ignore that period
            periods = periods.filter((period) => !period.isEqual(this.periodBackup));
         }
         return periods;
      },

      disabledDates() {
         const currentYear = new Date().getFullYear();
         const endOfNextYear = new Date(Date.UTC(currentYear + 2, 0, 1));
         const earliestAllowed = new Date(Date.UTC(1983, 11, 31));

         let periods = this.allPeriods;

         return [
            {end: earliestAllowed},
            {start: endOfNextYear},
            ...periods.map((period) => ({
               start: period.startDate,
               end: period.endDate,
            })),
         ];
      },

      startDateAttrs() {
         const attrs = [];
         if (this.currentPeriod.endDate) {
            attrs.push({
               key: 'endDate',
               dot: true,
               dates: this.currentPeriod.endDate,
            });
         }
         return attrs;
      },

      endDateAttrs() {
         const attrs = [];
         if (this.currentPeriod.startDate) {
            attrs.push({
               key: 'startDate',
               dot: true,
               dates: this.currentPeriod.startDate,
            });
         }
         return attrs;
      },

      invalidFeedback() {
         if (!(this.currentPeriod.startDate && this.currentPeriod.endDate)) {
            return null;
         }

         if (!this.$v.currentPeriod.startBeforeEnd) {
            return 'End date must be later than start date';
         } else if (!this.$v.currentPeriod.oneYearMax) {
            return 'A study period may not be longer than one year';
         } else if (!this.$v.currentPeriod.noOverlap) {
            const period = this.currentPeriodOverlap;
            return `Overlapping periods are not allowed. The period would overlap with the period "${period.label}" (${period.period.lower} to ${period.period.upper}). Please correct this and try again.`;
         }

         return null;
      },

      relatedStrings() {
         if (this.relatedData === null) {
            return [];
         }
         let employeeCount = 0;
         let contractorCount = 0;
         let timeSurveyRows = 0;
         let expenseImports = 0;
         let expenseData = 0;
         const rootData = this.relatedData[this.relatedData._root];
         const relatedItemKeys = rootData.related;

         for (let key of relatedItemKeys) {
            const item = this.relatedData[key];
            switch (item.type) {
               case 'Employee_StudyPeriod':
                  employeeCount += item.count;
                  break;
               case 'ContractorPeriod_StudyPeriod':
                  contractorCount += item.count;
                  break;
               case 'TimeSurveyBase_StudyPeriod':
                  timeSurveyRows += item.count;
                  break;
               case 'AnyImportedExpense_StudyPeriod':
                  expenseImports += item.count;
                  break;
               case 'AnyExpenseSummary_StudyPeriod':
                  expenseData += item.count;
                  break;
               default:
                  break;
            }
         }

         let strs = [];
         if (employeeCount > 0) {
            strs.push(`${employeeCount} employees`);
         }
         if (contractorCount > 0) {
            strs.push(`${contractorCount} contractors`);
         }
         if (timeSurveyRows > 0) {
            strs.push(`${timeSurveyRows} rows of employee and contractor time data`);
         }
         if (expenseImports > 0) {
            strs.push(`${expenseImports} rows of imported vendor data`);
         }
         if (expenseData > 0) {
            strs.push(`${expenseData} rows of expense data`);
         }

         if (strs.length === 0) {
            strs.push(`No related data`);
         }

         return strs;
      },

      currentPeriodOverlap() {
         return (
            this.allPeriods.find((existingPeriod) =>
               existingPeriod.overlaps(this.currentPeriod.startDate, this.currentPeriod.endDate)
            ) || null
         );
      },
   },

   methods: {
      addPeriod() {
         this.editingPeriod = false;
         this.currentPeriod = new StudyPeriod();
         this.$bvModal.show('modal-add-period');
      },

      editPeriod(index) {
         this.editingPeriod = true;
         this.periodBackup = this.study.periods[index];

         this.$nextTick(() => {
            this.currentPeriod = this.study.periods[index].copy();
            this.$bvModal.show('modal-add-period');
         });
      },

      onDatepickerShown(field) {
         if (this.currentPeriod[field]) {
            return;
         }
         const component = this.$refs[field];
         const currentYear = new Date().getFullYear();
         const month = field === 'startDate' ? 1 : 12;
         component.move({month, year: currentYear});
      },

      async insertPeriod() {
         if (this.edit) {
            if (this.editingPeriod) {
               await this.blockingRequest('companies/editStudyPeriod', {
                  companyId: this.$companyId,
                  studyId: this.study.id,
                  studyPeriod: this.currentPeriod,
               });
            } else {
               await this.blockingRequest('companies/addStudyPeriod', {
                  companyId: this.$companyId,
                  studyId: this.study.id,
                  studyPeriod: this.currentPeriod,
               });
            }
         } else {
            if (this.editingPeriod) {
               this.study.removePeriod(this.periodBackup);
            }
            this.study.addPeriod(this.currentPeriod);
         }
      },

      onInputStartDate(date) {
         if (date && !this.currentPeriod.endDate) {
            // Set end date to startDate + 1year - 1day
            const endDate = new Date(date);
            endDate.setFullYear(endDate.getFullYear() + 1);
            endDate.setDate(endDate.getDate() - 1);
            this.currentPeriod.endDate = endDate.toISOString().split('T')[0];
            if (!this.currentPeriod.label) {
               const [year, month, day] = date.split('-');
               const isJanOne = month === '01' && day === '01';
               if (isJanOne) {
                  this.currentPeriod.label = year;
               }
            }
         }
      },

      onInputEndDate(date) {
         if (date && !this.currentPeriod.startDate) {
            // Set start date to endDate - 1year + 1day
            const startDate = new Date(date);
            startDate.setFullYear(startDate.getFullYear() - 1);
            startDate.setDate(startDate.getDate() + 1);
            this.currentPeriod.startDate = startDate.toISOString().split('T')[0];
            if (!this.currentPeriod.label) {
               const [year, month, day] = date.split('-');
               const isDecThirtyone = month === '12' && day === '31';
               if (isDecThirtyone) {
                  this.currentPeriod.label = year;
               }
            }
         }
      },

      async deletePeriod(period) {
         if (this.edit) {
            this.relatedData = await this.blockingRequest('companies/deleteStudyPeriod', {
               companyId: this.$companyId,
               studyId: this.study.id,
               periodId: period.id,
               force: false,
            });

            this.deletingPeriod = period;
            this.$bvModal.show('modal-confirm-delete-period');
         } else {
            this.study.removePeriod(period);
         }
      },

      async confirmDeletePeriod() {
         await this.blockingRequest('companies/deleteStudyPeriod', {
            companyId: this.$companyId,
            studyId: this.study.id,
            periodId: this.deletingPeriod.id,
            force: true,
         });
      },
   },

   validations() {
      return {
         study: {
            label: {
               required,
               maxLength: maxLength(32),
            },
         },

         currentPeriod: {
            startDate: {
               required,
               noContains(date) {
                  return !this.allPeriods.some((period) => period.containsDate(date));
               },
            },
            endDate: {
               required,
               noContains(date) {
                  return !this.allPeriods.some((period) => period.containsDate(date));
               },
            },
            label: {
               required,
               unique(label) {
                  return (
                     this.study.periods
                        .filter((period) => {
                           if (this.editingPeriod) {
                              return period !== this.periodBackup;
                           }
                           return true;
                        })
                        .findIndex(
                           (period) => period.label.toLowerCase() === label.toLowerCase()
                        ) === -1
                  );
               },
            },

            startBeforeEnd(period) {
               return period.startDate < period.endDate;
            },

            noOverlap() {
               return this.currentPeriodOverlap === null;
            },

            oneYearMax(period) {
               if (!(period.startDate && period.endDate)) {
                  return true;
               }
               const yearDiff = period.endDate.getYear() - period.startDate.getYear();
               if (yearDiff > 1) {
                  return false;
               } else if (yearDiff === 0) {
                  return true;
               }

               const monthDiff = period.endDate.getMonth() - period.startDate.getMonth();
               if (monthDiff > 0) {
                  return false;
               } else if (monthDiff < 0) {
                  return true;
               }

               return period.endDate.getDate() < period.startDate.getDate();
            },
         },
      };
   },
};
</script>

import {downloadFile} from '../../helpers/utils';

const PREVIEW_ROW_COUNT = 10;
const IMPORT_ROW_COUNT = PREVIEW_ROW_COUNT * 2;

export const DataFileStatus = Object.freeze({
   CONFIGURING: 'CONFIGURING',
   PENDING: 'PENDING',
   IN_PROGRESS: 'IN_PROGRESS',
   COMPLETE: 'COMPLETE',
   FAILED: 'FAILED',
});

export class DataFileUpload {
   constructor(
      category,
      {
         file = null,
         name = '',
         mapping = {},
         headerRow = null,
         firstDataRow = null,
         maxPreviewRows = PREVIEW_ROW_COUNT,
      } = {}
   ) {
      this.category = category;
      this.file = file;
      this.name = name;
      this.mapping = {...mapping};
      this.headerRow = headerRow;
      this.firstDataRow = firstDataRow;
      this.maxPreviewRows = maxPreviewRows;

      this.imported = false;
      this._previewRows = [];

      this.status = DataFileStatus.PENDING;
      this.errors = [];
      this.errorCode = null;
      this.uploadStartedAt = null;
   }

   //
   // PROPS
   //
   get fileName() {
      return this.file?.name;
   }

   get validation() {
      return {
         type: this.category,
         data: {
            mapping: this.mapping,
            first_record: this.firstDataRow,
         },
      };
   }

   set previewRows(val) {
      this._previewRows = val;
   }

   get previewRows() {
      return this._previewRows.slice(0, this.maxPreviewRows);
   }

   get columnCount() {
      return Math.max(0, ...this.previewRows.map((row) => row.length));
   }

   get trimmedPreviewRows() {
      const rows = this._previewRows.slice(
         this.firstDataRow,
         this.firstDataRow + this.maxPreviewRows
      );
      if (this.headerRow !== null) {
         rows.unshift(this._previewRows[this.headerRow]);
      }
      return rows;
   }

   //
   // UPLOAD STATUS MUTATIONS
   //
   uploadStarted() {
      this.status = DataFileStatus.IN_PROGRESS;
      this.uploadStartedAt = new Date();
   }

   uploadCompleted() {
      this.status = DataFileStatus.COMPLETE;
   }

   uploadFailed(errorCode, errors) {
      this.errorCode = errorCode;
      this.errors = errors;
      this.status = DataFileStatus.FAILED;
   }
}

/**
 * Factory method for a Vuex module handling data file uploads
 * @param {*} dataFileUploadCategory - The upload category that data will be uploaded to
 * @param {boolean} [namespaced=true] - declare the module as namespaced
 * @param {Object} [state] - Additional state variables to mix in
 * @param {Object} [getters] - Additional getters to mix in
 * @param {Object} [mutations] - Additional mutations to mix in
 * @param {Object} [actions] - Additional actions to mix in
 * @returns A Vuex module configuration object
 */
export const dataFileUploadModuleFactory = (
   dataFileUploadCategory,
   {
      namespaced = true,
      templateUrl = null,
      state = {},
      getters = {},
      mutations = {},
      actions = {},
   } = {}
) => ({
   namespaced,

   state() {
      return {
         pendingUpload: new DataFileUpload(dataFileUploadCategory),
         uploadCategoryId: null,
         ...state,
      };
   },

   getters: {
      pendingUpload: (state) => state.pendingUpload,

      uploadCategory: (state, getters, rootState, rootGetters) => {
         if (state.uploadCategoryId === null) {
            return null;
         }
         return rootGetters['uploads/uploadCategoryMap'][state.uploadCategoryId];
      },

      uploadedFiles: (state, getters) => {
         if (getters.uploadCategory === null) {
            return [];
         }
         return getters.uploadCategory.summary.filter((file) => {
            return file.validation && file.validation.type === dataFileUploadCategory;
         });
      },

      ...getters,
   },

   mutations: {
      newImport(state) {
         state.pendingUpload = new DataFileUpload(dataFileUploadCategory);
      },

      clearUploads(state) {
         state.uploadCategoryId = null;
         state.failedUploads = [];
      },

      setFirstDataRow(state, {firstDataRow}) {
         state.pendingUpload.firstDataRow = firstDataRow;
      },

      setHeaderRow(state, {headerRow}) {
         if (headerRow < 0) {
            headerRow = null;
         }
         state.pendingUpload.headerRow = headerRow;
      },

      setMapping(state, {mapping}) {
         state.pendingUpload.mapping = {...mapping};
      },

      setUploadCategoryId(state, {uploadCategoryId}) {
         state.uploadCategoryId = uploadCategoryId;
      },

      ...mutations,
   },

   actions: {
      /** Import the data from the file */
      async importFile({state}, {file, description, rows = IMPORT_ROW_COUNT}) {
         let formData = new FormData();
         formData.append('file', file);
         formData.append('rows', rows);

         const response = await this._vm.$http.post(`/api/uploadfile/inspect`, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
         });

         state.pendingUpload.previewRows = response.data.rows;
         state.pendingUpload.file = file;
         state.pendingUpload.name = description;
         state.pendingUpload.imported = true;
      },

      /** Load the data file upload category */
      async loadUploadCategory({commit, dispatch}, {companyId}) {
         const uploadCategory = await dispatch(
            'uploads/loadSpecialCategory',
            {
               companyId,
               category: dataFileUploadCategory,
            },
            {root: true}
         );

         commit('setUploadCategoryId', {
            uploadCategoryId: uploadCategory.id,
         });
      },

      /** Upload the current pending file */
      async uploadFile({state, dispatch}) {
         const file = state.pendingUpload;
         file.uploadStarted();

         try {
            await dispatch(
               'uploads/uploadFiles',
               {
                  uploadCategoryId: state.uploadCategoryId,
                  files: [file.file],
                  description: file.name,
                  validation: file.validation,
               },
               {root: true}
            );
            file.uploadCompleted();
         } catch (err) {
            const errors = err.response.data.errors[0].detail;
            const errorCode = err.response.data.errors[0].code;
            file.uploadFailed(errorCode, errors);
            throw err;
         }
      },

      /** Delete a file from the data file upload category */
      async deleteFile({state, dispatch}, {fileId}) {
         await dispatch(
            'uploads/deleteFile',
            {
               fileId,
               force: true,
               uploadCategoryId: state.uploadCategoryId,
            },
            {root: true}
         );
      },

      async downloadTemplate() {
         if (!templateUrl) {
            throw new Error('Not implemented');
         }
         const response = await this._vm.$http.get(templateUrl, {
            responseType: 'blob',
         });
         downloadFile(response);
      },

      ...actions,
   },
});

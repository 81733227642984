import Vue from 'vue';

let EMPLOYEE_SET_COUNT = 0;
const newSetId = () => parseInt(EMPLOYEE_SET_COUNT++, 10);

const state = () => ({
   employeeSets: {},
});

const getters = {
   employeeSets: (state) =>
      Object.values(state.employeeSets).sort((a, b) => {
         const aStr = `${a.fullname}:${a.department}`;
         const bStr = `${b.fullname}:${b.department}`;
         if (aStr < bStr) {
            return -1;
         }
         if (aStr > bStr) {
            return 1;
         }
         return 0;
      }),

   assignedEmployeeSets: (state, getters) => (assigneeId) => {
      return getters.employeeSets.filter((set) => set.assignee === assigneeId);
   },

   allAssignmentsCompleteInPeriod: (state, getters) => (assigneeId, periodId) => {
      const employeeSets = getters.assignedEmployeeSets(assigneeId);
      let hasAssignment = false;

      const allCompleted = employeeSets.every((set) => {
         const employeesByPeriod = set.employees.filter(
            (employee) => employee.periodId === periodId
         );
         return employeesByPeriod.every((employee) => {
            hasAssignment = true;
            return !!employee.assignmentCompleted;
         });
      });

      return hasAssignment && allCompleted;
   },

   /** Does the user have an assignment in period? */
   hasAssignmentInPeriod: (state, getters) => (assigneeId, periodId) => {
      const employeeSets = getters.assignedEmployeeSets(assigneeId);
      return employeeSets.some((set) => {
         return set.employees.some((employee) => employee.periodId === periodId);
      });
   },
};

const mutations = {
   initEmployeeSets: (state, {employees, assignments}) => {
      // Sort employees by name, dept, period
      employees = employees.sort((a, b) => {
         if (a.fullname < b.fullname) {
            return -1;
         } else if (a.fullname > b.fullname) {
            return 1;
         }

         if (a.department < b.department) {
            return -1;
         } else if (a.department > b.department) {
            return 1;
         }

         if (a.period.lower < b.period.lower) {
            return -1;
         } else if (a.period.lower > b.period.lower) {
            return 1;
         }

         return 0;
      });

      // Build employee sets
      const employeeSets = [];
      employees.forEach((employee) => {
         let nameMatch = false;
         let assigneeMatch = false;
         let previousSet = null;

         // Does this employee match the previous employee's name and dept?
         if (employeeSets.length > 0) {
            // employees are sorted by name and dept, so just check the previous set
            previousSet = employeeSets[employeeSets.length - 1];
            nameMatch =
               previousSet.fullname === employee.fullname &&
               previousSet.department === employee.department;
         }

         // Is the employee assigned to the same user (including no user)
         const assigment = assignments.find((a) => a.employeeId === employee.id);
         const assignee = assigment ? assigment.assigneeId : null;
         assigneeMatch = previousSet ? previousSet.assignee === assignee : true;

         // If this employee is a match of the previous employee, push its data onto
         // the previous employeeSet. Otherwise, push a new employeeSet onto the array.
         const employeeData = (({id, title, speriod}, assignment) => ({
            id,
            title,
            periodId: speriod.id,
            assignerId: assignment ? assignment.assignerId : null,
            assignmentCompleted: assignment ? assignment.completed : null,
         }))(employee, assigment);

         if (nameMatch && assigneeMatch) {
            previousSet.employees.push(employeeData);
         } else {
            employeeSets.push({
               setId: newSetId(),
               fullname: employee.fullname,
               department: employee.department,
               assignee,
               employees: [employeeData],
               isSplit: false,
            });
         }
      });

      // Clear state, then store sets in an object keyed on the setId
      state.employeeSets = {};
      employeeSets.forEach((set) => {
         Vue.set(state.employeeSets, set.setId, set);
      });
   },

   /** Update the asignee on an employee set */
   assignSet: (state, {setId, assigneeId, assignerId = null}) => {
      const employeeSet = state.employeeSets[setId];
      employeeSet.assignee = assigneeId;
      employeeSet.employees.forEach((employee) => {
         employee.assignerId = assignerId;
      });
   },

   /** Split an employee set into individual employees */
   splitEmployeeSet: (state, {setId}) => {
      const employeeSet = state.employeeSets[setId];

      employeeSet.employees.forEach((employee) => {
         const set = Object.assign({}, employeeSet, {
            setId: newSetId(),
            employees: [employee],
            isSplit: true,
         });
         Vue.set(state.employeeSets, set.setId, set);
      });
      Vue.delete(state.employeeSets, setId);
   },
};

const actions = {
   /**
    * Load all employees and assignments in the current study, then
    * initialize employee sets
    */
   async loadEmployeeAssignments({commit, dispatch, rootGetters}, {companyId}) {
      const employeePromise = dispatch('employees/fetchEmployees', {companyId}, {root: true});
      const periods = rootGetters['companies/openStudyPeriods'];

      const assignmentRequests = periods.map((period) => {
         return dispatch(
            'timesurvey/loadTimeSurveyAssignments',
            {companyId, periodId: period.id, save: false},
            {root: true}
         );
      });

      const employees = await employeePromise;
      const assignments = (await Promise.all(assignmentRequests)).flat();

      commit('initEmployeeSets', {employees, assignments});
   },

   async assignEmployees({state, commit}, {setIds, assigneeId, assignerId}) {
      let assignments = [];
      setIds.forEach((setId) => {
         const set = state.employeeSets[setId];
         set.employees.forEach((employee) => {
            assignments.push({
               employeeId: employee.id,
               assigneeId,
               speriodId: employee.periodId,
            });
         });
      });

      await this._vm.$http.post(`/api/user/${assignerId}/timesurvey/assignment`, {
         assignments,
      });

      setIds.forEach((setId) => {
         commit('assignSet', {setId, assigneeId, assignerId});
      });
   },

   /**
    * Bulk unassign a list of employee sets
    * @param {string} companyId - ID of the company the employees belong to
    * @param {Number[]} setIds - A list of employee set IDs
    */
   async unassignEmployees({state, commit, rootGetters}, {companyId, setIds, assignerId}) {
      if (setIds.length === 0) {
         return;
      }

      let assignments = [];
      setIds.forEach((setId) => {
         const set = state.employeeSets[setId];
         set.employees.forEach((employee) => {
            const period = rootGetters['companies/studyPeriodMap'][employee.periodId];
            assignments.push({
               companyId,
               studyId: period.studyId,
               period: period.period,
               employeeId: employee.id,
               assigneeId: set.assignee,
               assignerId: employee.assignerId,
            });
         });
      });

      await this._vm.$http.delete(`/api/user/${assignerId}/timesurvey/assignment`, {
         data: {
            companyId,
            assignments,
         },
      });

      setIds.forEach((setId) => {
         commit('assignSet', {setId, assigneeId: null});
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

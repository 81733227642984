<template>
   <b-container>
      <h1>Create Study</h1>
      <b-card>
         <p>
            Define the start and end dates for
            <b
               >every distinct period for which you need the client to provide data, including
               baseline year</b
            >. Your users will see the Period Label for each period defined.
         </p>
         <StudyForm class="mb-3" :study="study" />
         <div class="d-flex justify-content-end">
            <b-button
               id="btn-save-study"
               variant="success"
               @click="submit"
               :disabled="
                  study &&
                  (study.periods.length === 0 || study.label === null || study.label === '')
               "
            >
               Save
            </b-button>
         </div>
      </b-card>
   </b-container>
</template>

<script>
import StudyForm from './widgets/StudyForm';
import {Study} from '@/store/models/study';

export default {
   components: {
      StudyForm,
   },

   data() {
      return {
         study: null,
      };
   },

   methods: {
      async submit() {
         await this.blockingRequest('companies/createStudy', {
            companyId: this.$companyId,
            study: this.study,
         });
         this.$router.push({name: 'company-studies', params: {id: this.$companyId}});
      },
   },

   created() {
      this.study = new Study();
   },
};
</script>

import Vue from 'vue';
import {mapGetters} from 'vuex';

/**
 * Global mixin
 * Anything defined here will be available on every component
 */
Vue.mixin({
   computed: {
      $profile() {
         return this.$store.getters.profile;
      },

      $companyId() {
         return this.$profile.companyId ? this.$profile.companyId : this.$route.params.id;
      },
   },

   methods: {
      $REFACTOR(msg) {
         console.warn(`REFACTOR (${this.$options._componentTag}): ${msg}`);
         return false;
      },

      $clientStaffRoute(route) {
         if (this.$store.getters.isClient) {
            return route;
         }

         let {name, params} = route;
         route.name = `${name}-staff`;
         if (!params) {
            params = {};
         }
         params.id = this.$store.getters['companies/currentCompany'].id;

         return {
            ...route,
            params,
         };
      },

      /**
       * Dispatch an action, blocking user input with an overlay until the Promise is resolved
       * @param {string} action - A Vuex action
       * @param {Object} payload - payload object to dispatch the action with
       * @returns The return value from the action
       */
      async blockingRequest(action, payload) {
         let response;
         this.$emit('showSpinner');
         try {
            response = await this.$store.dispatch(action, payload);
         } catch (err) {
            console.log(action, err);
            throw err;
         } finally {
            this.$emit('hideSpinner');
         }
         return response;
      },

      /**
       * Block user input until all the promises are resolved
       * @param {Promise[]} promises - An array of promises
       */
      async blockUntilAllSettled(promises) {
         this.$emit('showSpinner');
         return Promise.all(promises).finally(() => {
            this.$emit('hideSpinner');
         });
      },
   },
});

/**
 * Adds support for providing suggestions for department, title, and subsidiary when creating client users
 * Assumes that `this.companyId` contains the company ID
 */
export const clientUserSuggestions = {
   computed: {
      ...mapGetters({_suggestionCompany: 'companies/currentCompany'}),

      /** An array of unique job titles for existing employees/users of the selected company */
      companyTitles() {
         if (this._suggestionCompany && this._suggestionCompany.titles) {
            return this._suggestionCompany.titles;
         } else {
            return [];
         }
      },

      /** An array of unique departments for existing employees/users of the selected company */
      companyDepartments() {
         if (this._suggestionCompany && this._suggestionCompany.departments) {
            return this._suggestionCompany.departments;
         } else {
            return [];
         }
      },

      /** An array of unique subsidiary company names of the selected company */
      companySubsidiaries() {
         if (this._suggestionCompany && this._suggestionCompany.subsidiaries) {
            return this._suggestionCompany.subsidiaries;
         } else {
            return [];
         }
      },
   },

   methods: {
      /**
       * Load detailed company data, which includes distinct departments and titles
       * of the users/employees of the company
       * @param {Boolean} [force=false] - force reload
       */
      async loadSuggestions(force = false) {
         if (this.companyId) {
            await this.$store.dispatch('companies/loadCompany', {
               companyId: this.companyId,
               detail: true,
               force,
            });
         }
      },
   },
};

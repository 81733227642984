<template>
   <b-container v-if="loaded">
      <PageTitle title="Documents" />

      <transition name="fade" mode="out-in">
         <div v-if="displayIntro" key="intro-content">
            <p>
               This section is the place where you can upload any files you need to share with us.
               Every file goes into a category and a time period. If you think a category is
               missing, or can't decide where to upload something, just let us know.
            </p>

            <div class="d-flex justify-content-end align-items-center mt-4">
               <b-button id="btn-begin" variant="success" @click="hideIntro">
                  Begin
                  <b-icon-arrow-right-short />
               </b-button>
            </div>
         </div>

         <div v-else key="main-content">
            <div class="d-flex align-items-center justify-content-end mb-4">
               <b-button
                  id="btn-add-user"
                  v-if="isCustomer"
                  variant="secondary"
                  @click="addUserModal"
                  size="sm"
                  class="mr-2"
               >
                  Invite User <b-icon-plus />
               </b-button>
            </div>

            <ManagerMessage v-if="manager">
               <template #short>
                  <span v-if="!isAppConfigured">
                     Here is where you can share your documents with us.</span
                  >
                  <span v-else-if="displayIntro">Need to share some documents with us?</span>
                  <span v-else> Here you can upload any documents we may need. </span>
               </template>

               <transition name="fade" mode="out-in">
                  <div v-if="!isAppConfigured" key="mm-unconfigured">
                     <h2>Here is where you can share your documents with us.</h2>
                     <p class="mb-0">
                        We have sent you a list of files that we'll need in order to configure your
                        R&amp;D credit study. Please upload those files here into the appropriate
                        categories. If you're unsure about anything, feel free to contact me!
                     </p>
                  </div>

                  <div v-else-if="displayIntro" key="mm-intro">
                     <h2>Need to share some documents with us?</h2>
                     <p class="mb-0">
                        This is the place to do it. Questions? Message me and your R&D credit team
                        using the secure messaging feature in the top right corner.
                     </p>
                  </div>

                  <div v-else key="mm-content">
                     <h2>Here you can upload any documents we may need.</h2>
                     <p class="mb-0">
                        If you need to share any documentation with us about your company or its
                        qualified R&amp;D activities, choose a category and click the upload button.
                        Message me if you need any help.
                     </p>
                  </div>
               </transition>
            </ManagerMessage>

            <UploadTable v-if="uploadCategories.length > 0" key="companyUploads" class="mb-3" />

            <b-alert variant="primary" class="mb-0 mt-3" :show="uploadCategories.length === 0">
               You don't have any document upload categories assigned to you.
            </b-alert>

            <b-button
               v-if="!displayIntro"
               variant="secondary"
               class="d-flex align-items-center ml-2"
               @click="displayIntro = true"
            >
               <b-icon-arrow-left-short class="mr-1" />
               View Introduction
            </b-button>
         </div>
      </transition>

      <CreateClientModal>
         Create accounts for users at your company. The accounts you create will only have access to
         the information you want them to see.
      </CreateClientModal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import CreateClientModal from '@/views/user/widgets/CreateClientModal';
import UploadTable from '@/views/uploads/widgets/UploadTable';

export default {
   components: {
      CreateClientModal,
      UploadTable,
   },

   data() {
      return {
         loaded: false,
         displayIntro: true,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         manager: 'manager',
         clientState: 'clientState',
         uploadCategories: 'uploads/uploadCategories',
         activeStudyId: 'companies/activeStudyId',
         company: 'companies/currentCompany',
         isAppConfigured: 'isAppConfigured',
         isCustomer: 'isCustomer',
         previousStage: 'previousStage',
         nextStage: 'nextStage',
      }),

      // The company ID for the logged in user
      companyId() {
         return this.profile.companyId;
      },

      /** Has the user seen the intro for this section */
      seenIntro() {
         return this.clientState.sectionIntros.uploads;
      },
   },

   methods: {
      /** Display the create client user modal */
      addUserModal() {
         this.$bvModal.show('modal-create-client');
      },

      hideIntro() {
         if (!this.seenIntro) {
            this.$store.dispatch('setSectionIntro', {
               section: 'uploads',
               value: true,
            });
         }
         this.displayIntro = false;
      },

      async loadData() {
         let requests = [
            this.$store
               .dispatch('uploads/loadCompanyUploadCategories', {
                  companyId: this.companyId,
                  summary: true,
                  force: true,
               })
               .catch((err) => {
                  this.$store.commit('showAlert', {
                     response: err.response,
                     fallbackMsg: 'Failed to load file upload categories',
                  });
               }),
         ];

         await this.blockUntilAllSettled(requests);
      },
   },

   watch: {
      activeStudyId() {
         this.loadData();
      },
   },

   async created() {
      try {
         await this.loadData();
      } finally {
         this.displayIntro = !this.seenIntro;
         this.loaded = true;
      }
   },
};
</script>

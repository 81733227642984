<template>
   <b-container>
      <PageTitle title="Contract Research" />

      <div class="d-flex align-items-center justify-content-end mb-4">
         <b-button
            v-if="!isSME"
            id="btn-assign"
            variant="secondary"
            class="mr-2"
            size="sm"
            :to="$clientStaffRoute({name: 'contractors-permissions'})"
         >
            Assign Section
         </b-button>
      </div>

      <template v-if="manager">
         <ManagerMessage v-if="isComplete">
            <h2>This section is complete!</h2>

            <p>
               Visit your dashboard to see what's next. If you need to modify the info in this
               section you must unlock it first. Please note that this may cause delays in your
               credit calculation.
            </p>

            <div class="d-flex align-items-center justify-content-end">
               <b-button variant="primary" v-if="!isSME" class="ml-2" @click="toggleCompletion">
                  Unlock
               </b-button>
               <b-button variant="success" :to="{name: 'Home'}" class="ml-2"> Dashboard </b-button>
            </div>
         </ManagerMessage>

         <ManagerMessage v-else>
            <h2>Tell us about your contracted R&D work.</h2>

            <p class="mb-0">
               Payments made to contractors for research & development qualify for the R&D tax
               incentive when the financial risk and intellectual property rights are retained by
               your company. This is where you tell us how the time of those contracted by your
               company to do R&D was divided among your R&D projects in each time period. Questions?
               Please contact us using the secure messaging feature in the top right.
            </p>
         </ManagerMessage>
      </template>

      <h2>How much time did your contractors spend on R&D?</h2>

      <p class="text-instruction mb-4">
         Click <b>Enter Time</b> on a time period below to tell us about contractors' time in that
         time period.
      </p>

      <b-table id="table-contractor-time" :fields="fields" :items="tableItems" sort-by="period">
         <template #cell(period)="data">
            <b>{{ data.value }}</b>
         </template>

         <template #cell(status)="data">
            <RdigStatusPill :ident="data.item.period.id" :status="data.value" />
         </template>

         <template #cell(actions)="data">
            <div class="cell-w-buttons justify-content-end">
               <b-button
                  :id="`btn-enter-time-${data.index}`"
                  variant="primary"
                  size="sm"
                  class="d-flex align-items-center"
                  :to="
                     $clientStaffRoute({
                        name: 'contractor-time-data',
                        params: {periodId: data.item.period.id},
                     })
                  "
               >
                  Enter Time
                  <custom-icon icon="icon-chevron-right" height="16px" width="16px" class="ml-1" />
               </b-button>
            </div>
         </template>
      </b-table>

      <div class="d-flex">
         <b-button
            id="btn-back"
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'contractors-home'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

import {StudyPeriod} from '@/store/models/study';
import {ContractorStatus} from '@/store/models/contractor';

export default {
   data() {
      return {
         StatusType: this.$constants().StatusType,

         fields: [
            StudyPeriod.tableField(),
            {key: 'status', sortable: true},
            {key: 'actions', label: ''},
         ],
      };
   },

   computed: {
      ...mapGetters({
         isSME: 'isSME',
         manager: 'manager',
         profile: 'profile',
         company: 'companies/currentCompany',
         studyPeriodMap: 'companies/studyPeriodMap',
         studyPeriodIds: 'contractorTime/configuredPeriodIds',
         _periodStatus: 'contractorTime/periodStatus',
         isComplete: 'contractors/isComplete',
      }),

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      tableItems() {
         return this.studyPeriodIds.map((periodId) => {
            const period = this.studyPeriodMap[periodId];
            return {
               period,
               status: this.periodStatus[periodId],
            };
         });
      },

      /** Convert `ContractorStatus` to `StatusType` in `periodStatus` map */
      periodStatus() {
         return Object.entries(this._periodStatus).reduce((obj, [periodId, contractorStatus]) => {
            let status;
            switch (contractorStatus) {
               case ContractorStatus.COMPLETE:
                  status = this.StatusType.COMPLETE;
                  break;
               case ContractorStatus.INCOMPLETE:
                  status = this.StatusType.IN_PROGRESS;
                  break;
               default:
                  status = this.StatusType.INCOMPLETE;
            }

            obj[periodId] = status;
            return obj;
         }, {});
      },
   },

   methods: {
      async toggleCompletion() {
         let msg, title;
         if (this.isComplete) {
            msg =
               "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
            title = 'Unlock this section?';
         } else {
            msg =
               'Marking this section complete will unassign invited users from this section and lock the section to data entry. To modify this section afterward will require you to unlock this section, which may delay your credit calculation.';
            title = 'Is everything complete and correct?';
         }

         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });

         if (proceed) {
            try {
               await this.blockingRequest('contractors/saveCompleted', {
                  companyId: this.companyId,
                  completed: !this.isComplete,
               });
            } catch {
               await this.loadData();
            }
         }
      },
   },

   async created() {
      const requests = [
         this.$store.dispatch('contractors/loadContractors', {
            companyId: this.companyId,
         }),
      ];

      // If the in-memory company data doesn't match the required
      // company data for this page, load it.
      if (this.companyId !== this.company.id) {
         requests.push(
            this.$store.dispatch('companies/loadCompany', {
               companyId: this.companyId,
               force: true,
            })
         );
      }

      await this.blockUntilAllSettled(requests);
   },
};
</script>

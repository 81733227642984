<template>
   <b-container class="mt-5">
      <h1>The four-part test: qualifying for the R&D tax credit</h1>

      <TestCards
         class="mb-2"
         :active="goalActive"
         :selected="selectedGoalIdx"
         :gradient="false"
         @selection="(idx) => (selectedGoalIdx = selectedGoalIdx === idx ? null : idx)"
      />

      <ManagerMessage class="mx-auto mt-5 mb-3" id="mainMM">
         <div>
            <h2 v-if="isUntouched">Let's talk.</h2>
            <p v-if="isUntouched">
               Companies investing in efforts to improve often qualify for the
               <a href="https://www.irs.gov/businesses/research-credit">R&D credit</a> and don't
               know it. Congress has defined a <em>four-part test</em>, and we've built tools like
               this to help our clients understand it to their advantage.
            </p>
            <p v-if="isUntouched">
               All four of these qualifications must be satisfied for work to qualify as R&D. Select
               a test part to learn more.
            </p>

            <h2 v-if="selectedGoalIdx === 0">The work's purpose has to be improvement.</h2>
            <span v-if="selectedGoalIdx === 0">
               The purpose of qualified R&amp;D effort must be to achieve a new or improved product
               or process. The product or process must
               <strong
                  >provide new or improved functionality, performance, reliability,
                  scalability,</strong
               >
               and/or overall <strong>increase in quality</strong>. Efforts like aesthetic changes
               and routine maintenance usually will not.
            </span>

            <h2 v-if="selectedGoalIdx === 1">The work has to be technological.</h2>
            <span v-if="selectedGoalIdx === 1">
               Only efforts that
               <strong>fundamentally rely on a hard science</strong> qualify. A project that applies
               engineering disciplines, chemistry, biological science, software engineering,
               material science, physical science, food science, etc. will qualify. Soft
               science-based efforts like marketing research, sociology, psychology will usually not
               qualify.
            </span>

            <h2 v-if="selectedGoalIdx === 2">You have to have had uncertainty.</h2>
            <span v-if="selectedGoalIdx === 2">
               There must be
               <b>technical uncertainties or challenges</b> at some point during your efforts.
               Uncertainties concerning capability, optimal methodology, appropriate design of the
               initiative, etc. will likely qualify. Simple fixes, routine repairs &amp;
               maintenance, routine production work usually will not.
            </span>

            <h2 v-if="selectedGoalIdx === 3">You had to experiment.</h2>
            <span v-if="selectedGoalIdx === 3">
               Companies must be able to demonstrate that their efforts utilized a process which
               <b>evaluated alternatives</b> to achieving the desired outcome. This could include
               conducting systematic/incremental trial-and-error, prototyping, first article
               development, modeling, testing to validate design hypotheses in order to arrive at a
               final design, etc. Work that did not experiment or evaluate alternatives will not
               usually qualify.
            </span>

            <h2 v-if="isQualified">Your work likely qualifies for the R&D tax credit!</h2>
            <span v-if="isQualified">
               The description of your R&D work sounds like a good candidate for the R&D tax credit.
               Book a kickoff meeting with us below to get started claiming this credit utilizing
               RDIG's risk-free, frictionless, expert-led process.
            </span>

            <h2 v-if="isUnqualified">Your credit could use our expert review.</h2>
            <span v-if="isUnqualified">
               Many companies qualify for the R&D credit but lack the expertise necessary to uncover
               their qualifications. Our team thrives on discovering the details of our clients'
               work. Don’t miss out, book a risk-free consultation with the RDIG team below and let
               us help you identify expenses you qualify to claim for the R&D work you've done.
            </span>
         </div>

         <p v-if="!isQualified">{{ commentary }}</p>
         <p v-if="!isProcessing">
            <strong>
               Below, you can <span v-if="!isUntouched">further</span> describe the innovative work
               your company has done in the last few years. This free tool will help you determine
               and describe the work you've done that qualifies.
            </strong>
         </p>
      </ManagerMessage>

      <b-form-textarea
         ref="projectDescription"
         class="mb-3"
         v-model="notes"
         placeholder="Example: we are a landscaping firm we experimented with irrigation methods to reduce water usage."
         rows="3"
         @input="onDescriptionInput"
         @blurdisabled="onDescriptionBlur"
         :disabled="isProcessing"
      ></b-form-textarea>

      <div class="d-flex justify-content-end align-items-center mb-3">
         <b-form-checkbox v-model="autoSave" class="mr-2" :disabled="isProcessing">
            Autosave
         </b-form-checkbox>
         <b-button @click="testProject" variant="primary" :disabled="isProcessing">Save</b-button>
      </div>

      <div
         id="calendly-inline-widget"
         class="calendly-inline-widget"
         :key="calendlyUrl"
         :data-url="calendlyUrl"
         style="min-width: 320px; height: 800px"
      ></div>
   </b-container>
</template>

<script>
import axios from 'axios';
import TestCards from '../stages/widgets/TestCards.vue';

const NEEDS_MORE_INFO = -1;
const QUALIFIED = 1;
const UNQUALIFIED = 0;

export default {
   components: {
      TestCards,
   },

   data() {
      return {
         autoSave: true,
         typingTimeout: null,
         notes: '',
         last_notes: '',
         commentary: null,
         selectedGoalIdx: null,
         goalsList: [
            {value: 'purpose', label: 'Purpose'},
            {value: 'technological', label: 'Technological'},
            {
               value: 'uncertainty',
               label: 'Eliminate Uncertainty',
            },
            {value: 'experimental', label: 'Experimentation'},
         ],
         goalState: {
            purpose: NEEDS_MORE_INFO,
            experimental: NEEDS_MORE_INFO,
            technological: NEEDS_MORE_INFO,
            uncertainty: NEEDS_MORE_INFO,
         },
         isProcessing: null,
      };
   },

   computed: {
      goalActive() {
         return this.goalsList.map((goal) => {
            return this.goalState[goal.value];
         });
      },

      isUntouched() {
         return (
            Object.values(this.goalState).every((goal) => goal === NEEDS_MORE_INFO) &&
            this.selectedGoalIdx === null &&
            this.isProcessing === null
         );
      },

      isQualified() {
         return Object.values(this.goalState).every((goal) => goal === QUALIFIED);
      },

      isUnqualified() {
         return (
            Object.values(this.goalState).some((goal) => goal === UNQUALIFIED) && !this.isProcessing
         );
      },

      calendlyUrl() {
         const prefill_info = encodeURIComponent(this.last_notes);

         return this.isQualified
            ? `https://calendly.com/meetrdig/begin?hide_gdpr_banner=1&a3=${prefill_info}`
            : `https://calendly.com/meetrdig/intro?hide_gdpr_banner=1&a4=${prefill_info}`;
      },
   },

   methods: {
      async testProject() {
         if (this.isProcessing) {
            return;
         }
         const url = '/api/project/inspect';
         this.selectedGoalIdx = null;
         this.isProcessing = true;
         this.commentary = 'Thinking about your project description...';
         this.last_notes = this.notes;

         try {
            const response = await axios.post(url, {project_description: this.notes});

            const {commentary, ...goalState} = response.data;
            this.commentary = commentary;
            this.goalState = goalState;
         } catch (error) {
            this.goalState = {
               purpose: NEEDS_MORE_INFO,
               experimental: NEEDS_MORE_INFO,
               technological: NEEDS_MORE_INFO,
               uncertainty: NEEDS_MORE_INFO,
            };
            this.commentary =
               "Something went wrong on our end. We weren't able to process your description. Please try again, and " +
               'reach out to us if you continue to experience issues.';
         }

         this.isProcessing = false;

         // Set focus to the projectDescription textarea with a delay

         this.$nextTick(() => {
            const textarea = this.$refs.projectDescription;
            textarea.focus();
            textarea.selectionStart = textarea.selectionEnd = textarea.value.length;
         });
      },

      onDescriptionInput() {
         const typingDelay = 2000; // Delay in milliseconds

         clearTimeout(this.typingTimeout);
         if (this.autoSave) {
            this.typingTimeout = setTimeout(() => {
               this.testProject();
            }, typingDelay);
         }
      },

      onDescriptionBlur() {
         clearTimeout(this.typingTimeout);
         this.testProject();
      },

      /** Load the calendly script */
      loadScript() {
         let widget = document.getElementById('calendly-inline-widget');
         let script = document.getElementById('calendly-script');
         if (script) {
            script.remove();
         }
         if (widget) {
            const newScript = document.createElement('script');
            newScript.id = 'calendlyScript';
            newScript.type = 'text/javascript';
            newScript.src = 'https://assets.calendly.com/assets/external/widget.js';
            newScript.async = true;
            document.head.appendChild(newScript);
         }
      },
   },

   watch: {
      calendlyUrl() {
         // Reload the script to update the widget based on the new URL
         this.loadScript();
      },
   },

   mounted() {
      this.loadScript();
   },
};
</script>

<style scoped>
h1 {
   text-transform: capitalize;
   text-align: center;
}

::v-deep .profile-image-container {
   display: none;
}

#mainMM {
   width: 100%;
}

#mainMM h2 {
   margin-top: 0.5rem;
}

#mainMM div {
   margin-bottom: 0.5rem;
}

#mainMM.manager-message-container::v-deep .manager-message {
   padding-left: 26px;
   color: #000;
}
</style>

<template>
   <b-container>
      <PageTitle title="Contract Research" />

      <div class="d-flex align-items-center justify-content-end mb-4">
         <b-button
            v-if="!isSME"
            id="btn-assign"
            variant="secondary"
            class="mr-2"
            size="sm"
            :to="$clientStaffRoute({name: 'contractors-permissions'})"
         >
            Assign Section
         </b-button>
      </div>

      <template v-if="manager">
         <ManagerMessage v-if="!allContractorsComplete">
            <h2>What percent of contract researchers' time was spent on your R&D projects?</h2>

            <p class="mb-0">
               Tell us what percent of your contract researchers' time was spent on R&D projects in
               this time period. Remember to assign other Onboard users to this section if someone
               else at your company can better provide some of this information.
            </p>
         </ManagerMessage>

         <ManagerMessage v-else-if="!allPeriodsComplete">
            <h2>This time period looks good.</h2>

            <p class="mb-0">
               All of your contact researchers have valid data for this time period, but other time
               periods are incomplete. If you're confident all the information for this time period
               is accurate, click Back below and choose an incomplete time period to continue with
               this section.
            </p>
         </ManagerMessage>

         <ManagerMessage v-else-if="!isComplete">
            <h2>All your contractors' R&D time is entered.</h2>

            <p class="mb-0">
               All of your contact researchers have valid data for every time period. Return to the
               first screen of this section to finish up.
            </p>
         </ManagerMessage>

         <ManagerMessage v-else>
            <h2>This section is complete!</h2>

            <p>
               Visit your dashboard to see what's next. If you need to modify the info in this
               section you must unlock it first. Please note that this may cause delays in your
               credit calculation.
            </p>

            <div class="d-flex align-items-center justify-content-end">
               <b-button variant="primary" v-if="!isSME" class="ml-2" @click="toggleCompletion">
                  Unlock
               </b-button>
               <b-button variant="success" :to="{name: 'Home'}" class="ml-2"> Dashboard </b-button>
            </div>
         </ManagerMessage>
      </template>

      <div class="year-selection">
         <div class="year-selection-row">
            <div>
               <b-button
                  id="btn-prev-period"
                  v-if="previousPeriod != null"
                  variant="primary"
                  class="d-flex align-items-center"
                  :to="{name: $route.name, params: {periodId: previousPeriod.id}}"
               >
                  <b-icon-arrow-left-short class="mr-1" />
                  {{ previousPeriod.label }}
               </b-button>
            </div>

            <h1 id="current-period">{{ period.label }}</h1>

            <div>
               <b-button
                  id="btn-next-period"
                  v-if="nextPeriod != null"
                  variant="primary"
                  class="d-flex align-items-center"
                  :to="{name: $route.name, params: {periodId: nextPeriod.id}}"
               >
                  {{ nextPeriod.label }}
                  <b-icon-arrow-right-short class="ml-1" />
               </b-button>
            </div>
         </div>
         <hr class="my-0" />
      </div>

      <b-card class="mb-3">
         <p>
            You can use your keyboard's arrow keys to move up and down between contract researchers,
            and tab or shift+tab to move right and left between fields.
         </p>

         <p v-if="refPeriod">
            Placeholder values are values we found in your
            {{ refPeriod.label }} study, provided for your reference. To use a field's reference
            value again this time period, press shift+enter.
         </p>

         <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="d-flex align-items-center">
               <button
                  v-if="anyContractorsSelected"
                  class="icon-btn icon-btn-secondary mr-3"
                  v-b-tooltip="'Unselect All'"
                  @click="unselectAll"
               >
                  <b-icon-dash-square />
               </button>
            </div>

            <div class="d-flex align-items-center">
               <b-button
                  v-if="
                     (anyContractorsSelected || incompleteContractorIds.length > 0) && !isComplete
                  "
                  id="btn-zero-time"
                  variant="secondary"
                  size="sm"
                  class="ml-2"
                  @click="setContractorsToZero"
               >
                  {{
                     anyContractorsSelected
                        ? "Set selected contractors' contributions to 0%"
                        : "Set all incomplete contractors' contributions to 0%"
                  }}
               </b-button>
            </div>
         </div>

         <b-table
            id="table-contractors"
            :items="tableItems"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            no-local-sorting
            @sort-changed="onSortChanged"
            class="scrollbar mb-0"
            small
            responsive
            no-border-collapse
            sticky-header="550px"
         >
            <template #cell(fullname)="data">
               <div class="d-flex align-items-center">
                  <b-form-checkbox
                     v-if="contractorSelection && !isComplete"
                     tabindex="-1"
                     size="lg"
                     v-model="contractorSelection[data.item.id]"
                     :value="true"
                  ></b-form-checkbox>
                  <b>{{ data.value }}</b>
               </div>
            </template>

            <template #cell(status)="data">
               <RdigStatusPill :ident="data.index" :status="data.value" />
            </template>

            <template #cell(total)="data">
               <TimePercentageInput
                  :periodId="periodId"
                  :contractor-id="data.item.id"
                  :contractor-index="data.index"
                  total
                  :status="data.item.status"
                  :validation="$v.data.$each[data.item.id][periodId]"
                  :disabled="isComplete"
               />
            </template>

            <template
               v-for="({slot: pSlot, key, id: pId}, pIdx) in tableSlotsProjects"
               #[pSlot]="data"
            >
               <TimePercentageInput
                  :key="key"
                  :periodId="periodId"
                  :contractor-id="data.item.id"
                  :contractor-index="data.index"
                  :project-id="pId"
                  :project-index="pIdx"
                  :status="data.item.status"
                  :validation="$v.data.$each[data.item.id][periodId]"
                  :disabled="isComplete"
               />
            </template>
         </b-table>
      </b-card>

      <div class="d-flex align-items-center justify-content-between">
         <b-button :to="$clientStaffRoute({name: 'contractor-time-years'})">
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {integer, minValue, maxValue} from 'vuelidate/lib/validators';

import {ContractorStatus} from '@/store/models/contractor';
import {sortBy} from '@/helpers/utils';
import TimePercentageInput from './widgets/TimePercentageInput';

export default {
   components: {
      TimePercentageInput,
   },

   data() {
      return {
         StatusType: this.$constants().StatusType,
         loaded: false,
         tableData: [],
         sortBy: 'fullname',
         sortDesc: false,
         contractorSelection: {},
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         manager: 'manager',
         isSME: 'isSME',
         studyPeriods: 'companies/activeStudyPeriods',
         studyPeriodMap: 'companies/studyPeriodMap',
         contractors: 'contractors/contractors',
         contractorMap: 'contractors/contractorMap',
         isComplete: 'contractors/isComplete',
         canEditTimeData: 'contractorPermissions/canEditTimeData',
         contractorPeriodStatus: 'contractorTime/contractorPeriodStatus',
         _refPeriodId: 'contractorTime/refPeriodId',
         data: 'contractorTime/data',
         periodStatus: 'contractorTime/periodStatus',
         projectsInPeriod: 'projects/projectsInPeriod',
      }),

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },

      periodId() {
         return this.$route.params.periodId;
      },

      period() {
         return this.studyPeriodMap[this.periodId];
      },

      nextPeriod() {
         const periodIndex = this.studyPeriods.findIndex((period) => period.id === +this.periodId);
         return periodIndex < this.studyPeriods.length - 1
            ? this.studyPeriods[periodIndex + 1]
            : null;
      },

      previousPeriod() {
         const periodIndex = this.studyPeriods.findIndex((period) => period.id === +this.periodId);
         return periodIndex > 0 ? this.studyPeriods[periodIndex - 1] : null;
      },

      refPeriod() {
         return this.contractors.length > 0
            ? this._refPeriodId(this.contractors[0].id, this.periodId)
            : null;
      },

      fields() {
         const fields = [
            {key: 'fullname', label: 'Name', stickyColumn: true, sortable: true},
            {key: 'status', sortable: true},
            {key: 'total', label: 'Total R&D Time', sortable: true},
            ...this.projectFields,
         ];
         return fields;
      },

      projectFields() {
         return this.sortedProjects.map((project) => ({
            key: project.key,
            label: project.name,
            projectId: project.id,
         }));
      },

      /** Projects sorted by name */
      sortedProjects() {
         return this.projectsInPeriod(this.periodId)
            .sort(sortBy('name'))
            .map((proj) => ({
               key: `project-${proj.id}`,
               ...proj,
            }));
      },

      /** Scoped slot metadata for projects **/
      tableSlotsProjects() {
         return this.sortedProjects.map((proj) => ({
            slot: `cell(${proj.key})`,
            key: proj.key,
            id: proj.id,
         }));
      },

      tableItems() {
         return this.tableData
            .map((contractor) => ({
               ...contractor,
               status: this.contractorStatusTypeMap[contractor.id],
            }))
            .filter((contractor) => contractor.status !== this.StatusType.NA);
      },

      contractorStatusTypeMap() {
         return this.contractors.reduce((obj, contractor) => {
            obj[contractor.id] = this.contractorStatusType(contractor.id);
            return obj;
         }, {});
      },

      allContractorsComplete() {
         return Object.values(this.contractorStatusTypeMap).every((status) =>
            [this.StatusType.COMPLETE, this.StatusType.NA].includes(status)
         );
      },

      allPeriodsComplete() {
         return Object.values(this.periodStatus).every(
            (status) => status === ContractorStatus.COMPLETE
         );
      },

      anyContractorsSelected() {
         return Object.values(this.contractorSelection).some((val) => val);
      },

      selectedContractorIds() {
         return Object.entries(this.contractorSelection)
            .filter((entry) => entry[1])
            .map((entry) => entry[0]);
      },

      incompleteContractorIds() {
         return Object.entries(this.contractorStatusTypeMap)
            .filter((entry) => {
               return entry[1] === this.StatusType.INCOMPLETE;
            })
            .map((entry) => entry[0]);
      },
   },

   methods: {
      /** Map ContractorStatus to StatusType */
      contractorStatusType(contractorId) {
         if (!this.canEditTimeData(contractorId)) {
            return this.$constants().StatusType.NA;
         }

         const contractorStatus = this.contractorPeriodStatus(contractorId, this.periodId);
         switch (contractorStatus) {
            case ContractorStatus.NOT_STARTED:
               return this.StatusType.INCOMPLETE;
            case ContractorStatus.INCOMPLETE:
               return this.StatusType.IN_PROGRESS;
            case ContractorStatus.COMPLETE:
               return this.StatusType.COMPLETE;
            default:
               return this.StatusType.NA;
         }
      },

      initTableData() {
         this.tableData = [...this.contractors];
         this.sortTableBy(this.sortBy, this.sortDesc);
      },

      /** Handle the `sort-changed` event from the table */
      onSortChanged(ctx) {
         this.sortTableBy(ctx.sortBy, ctx.sortDesc);
      },

      sortTableBy(fieldname, desc) {
         const [before, after] = desc ? [-1, 1] : [1, -1];

         const compare = (a, b) => {
            if (
               fieldname === 'fullname' &&
               Object.prototype.hasOwnProperty.call(a, 'localeCompare')
            ) {
               return a.localeCompare(b) > 0;
            } else {
               return a > b;
            }
         };

         const getData = (fieldname, contractor) => {
            let val;
            switch (fieldname) {
               case 'total':
                  val = this.data[contractor.id][this.periodId].percentage;
                  if (val === null) {
                     val = -1;
                  }
                  break;
               case 'status':
                  switch (this.contractorStatusTypeMap[contractor.id]) {
                     case this.StatusType.INCOMPLETE:
                        val = 0;
                        break;
                     case this.StatusType.IN_PROGRESS:
                        val = 10;
                        break;
                     case this.StatusType.COMPLETE:
                        val = 20;
                  }
                  break;
               case 'fullname':
                  val = contractor.fullname.toLowerCase();
                  break;
               default:
                  val = contractor[fieldname];
            }
            return val;
         };

         this.tableData.sort((a, b) => {
            let valA = getData(fieldname, a);
            let valB = getData(fieldname, b);

            // First, sort by cell value
            if (compare(valA, valB)) {
               return before;
            } else if (compare(valB, valA)) {
               return after;
            }

            // Second, sort by total percentage (unless the cell value is the total)
            if (fieldname !== 'total') {
               const percentageA = getData('total', a);
               const percentageB = getData('total', b);
               if (compare(percentageA, percentageB)) {
                  return -1;
               } else if (compare(percentageB, percentageA)) {
                  return 1;
               }
            }

            // TODO sort by ref value (pending server-side implementation)

            return 0;
         });
      },

      initContractorSelection() {
         this.contractors.forEach((contractor) => {
            this.$set(this.contractorSelection, contractor.id, false);
         });
      },

      unselectAll() {
         Object.keys(this.contractorSelection).forEach((id) => {
            this.contractorSelection[id] = false;
         });
      },

      async toggleCompletion() {
         let msg, title;
         if (this.isComplete) {
            msg =
               "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
            title = 'Unlock this section?';
         } else {
            msg =
               'Marking this section complete will unassign invited users from this section and lock the section to data entry. To modify this section afterward will require you to unlock this section, which may delay your credit calculation.';
            title = 'Is everything complete and correct?';
         }

         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });

         if (proceed) {
            try {
               await this.blockingRequest('contractors/saveCompleted', {
                  companyId: this.companyId,
                  completed: !this.isComplete,
               });
            } catch {
               await this.loadData();
            }
         }
      },

      async setContractorsToZero() {
         const contractorIds = this.anyContractorsSelected
            ? this.selectedContractorIds
            : this.incompleteContractorIds;
         const contractors = contractorIds.map((id) => this.contractorMap[id]);

         const proceed = await this.$bvModal.msgBoxConfirm(
            `The ${contractors.length} ${
               this.anyContractorsSelected ? 'selected' : 'incomplete'
            } contractor${
               contractors.length > 1 ? 's' : ''
            } will have their total R&D contribution and all project contributions set to 0%.`,
            {
               title: 'Set contributions to zero?',
               centered: true,
               okVariant: 'danger',
               cancelVariant: 'gray',
            }
         );

         if (!proceed) {
            return;
         }

         const projData = this.projectsInPeriod(this.periodId).reduce((obj, proj) => {
            obj[proj.id] = 0;
            return obj;
         }, {});

         const periods = contractors.map((contractor) => {
            return {
               contractorId: contractor.id,
               periodId: this.periodId,
               percentage: 0,
               projects: projData,
            };
         });

         await this.blockingRequest('contractorTime/updateContractors', {
            companyId: this.companyId,
            periods,
         });
      },
   },

   watch: {
      loaded(val) {
         if (val) {
            this.initTableData();
         }
      },
   },

   validations() {
      const percentageValidation = () => ({
         integer,
         minValue: minValue(0),
         maxValue: maxValue(100),
      });

      return {
         data: {
            $each: {
               [this.periodId]: {
                  percentage: percentageValidation(),
                  projects: {
                     $each: percentageValidation(),
                  },
               },
            },
         },
      };
   },

   async created() {
      const requests = [
         this.$store.dispatch('contractors/loadContractors', {
            companyId: this.companyId,
            ref: true,
         }),
         this.$store.dispatch('contractorPermissions/loadUserPermissions', {
            userId: this.profile.id,
         }),
         this.$store.dispatch('projects/loadProjects', {
            companyId: this.companyId,
         }),
      ];

      // If the in-memory company data doesn't match the required
      // company data for this page, load it.
      if (this.companyId !== this.company?.id) {
         requests.push(
            this.$store.dispatch('companies/loadCompany', {
               companyId: this.companyId,
               force: true,
            })
         );
      }

      await this.blockUntilAllSettled(requests);

      this.initContractorSelection();

      this.loaded = true;
   },
};
</script>

<style lang="scss" scoped>
::v-deep {
   .b-table-sticky-header {
      scroll-padding-top: 95px;
   }

   .b-table {
      font-size: 15px;
   }
}

// Year Selection
.year-selection {
   margin-bottom: 2rem;

   .year-selection-row {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h1 {
         color: inherit;
         margin: 0;
      }

      .year-nav-btn button {
         border: 0;
         background-color: transparent;
         color: $secondary;
         transition: all 0.2s ease;

         &:hover {
            color: $primary;
            text-decoration: underline;
         }

         svg {
            margin-bottom: 0.6em;
         }
      }
   }
}
</style>

<template>
   <b-container v-if="questions.length > 0">
      <b-sidebar
         title="Questions"
         id="sidebar-questions"
         class="sidebar-questionnairet"
         header-class="border-right border-bottom"
         body-class="border-right"
         width="30rem"
         backdrop
         backdrop-variant="white"
      >
         <b-list-group flush>
            <b-list-group-item
               v-for="(question, index) in questions"
               :key="question.key"
               href="#"
               class="border-bottom"
               @click="selectQuestion(index)"
            >
               <small>
                  <span>{{ index + 1 }}.</span>
                  {{ question.question }}
               </small>
            </b-list-group-item>
         </b-list-group>
      </b-sidebar>

      <div
         class="d-flex justify-content-end mb-4"
         v-if="!isSME && !isCompleted && assignee === null"
      >
         <b-button
            id="btn-assign-questionnaire"
            size="sm"
            variants="primary"
            class="mr-2"
            v-b-toggle.collapse-assignments
         >
            Assign Questionnaire
         </b-button>
      </div>

      <b-collapse id="collapse-assignments" v-if="assignee === null">
         <b-card class="mb-4">
            <h2 class="mb-3">Assign this questionnaire to another user</h2>

            <b-row class="mb-3">
               <b-col>
                  <b-form-select id="select-user" v-model="selectedUser">
                     <template #first>
                        <b-form-select-option :value="null" disabled>
                           Select a User
                        </b-form-select-option>
                     </template>

                     <b-form-select-option v-for="user in users" :key="user.id" :value="user">
                        {{ formatUserName(user) }}
                     </b-form-select-option>
                  </b-form-select>
               </b-col>

               <b-col class="d-flex align-items-center justify-content-end">
                  <b-button id="btn-add-user" variant="primary" @click="addUserModal">
                     Invite User
                     <b-icon-plus />
                  </b-button>
               </b-col>
            </b-row>

            <b-button
               id="btn-assign-user"
               variant="primary"
               @click="assignQuestionnaire"
               :disabled="!selectedUser"
            >
               Assign User
            </b-button>
         </b-card>
         <CreateClientModal> </CreateClientModal>
      </b-collapse>

      <b-alert :show="!isSME && assignee !== null" variant="primary" class="mt-3">
         <div class="d-flex align-items-center justify-content-between">
            <span id="msg-assignment">
               This questionnaire is currently assigned to
               <b>{{ assigneeName }}</b
               >.
            </span>
            <b-button
               id="btn-unassign-questionnaire"
               variant="white"
               size="sm"
               @click="unassignQuestionnaire"
            >
               Unassign
            </b-button>
         </div>
      </b-alert>

      <h2 id="questionnaire-title" class="mb-4 mt-3">{{ title }}</h2>

      <ManagerMessage v-if="isCompleted">
         <h2>This questionnaire has been completed.</h2>
         <p>
            Editing these questions has been locked. If you need to modify them, please contact me
            using Onboard's secure messaging feature in the upper right corner.
         </p>
      </ManagerMessage>

      <b-progress :value="questionIndex" :max="questions.length - 1"></b-progress>

      <div class="d-flex align-items-center justify-content-between my-4">
         <h2 id="question-number" class="text-dark-gray mb-0">{{ questionLabel }}</h2>

         <b-button
            id="btn-list-questions"
            size="sm"
            variant="secondary"
            class="mr-2"
            v-b-toggle.sidebar-questions
         >
            <b-icon-list class="mr-1" />
            List Questions
         </b-button>
      </div>

      <b-card :key="questionIndex" class="answer-card">
         <p class="h6 text-secondary" v-for="(paragraph, index) in questionParagraphs" :key="index">
            {{ paragraph }}
         </p>

         <component
            :is="currentAnswerComponent"
            :index="questionIndex"
            :readonly="isCompleted"
            @validate="updateAnswerValidation"
            class="mb-3 mt-4"
            ref="answer"
         />

         <p v-if="helpText" class="font-italic mt-1">{{ helpText }}</p>

         <b-alert variant="danger" :show="overwriteError">
            <p>
               An answer was submitted for this question while you were working.
               <router-link
                  :to="{
                     name: $route.name,
                     params: $route.params,
                     query: {question: questionIndex + 1},
                  }"
                  class="alert-link"
                  target="_blank"
               >
                  Click here
               </router-link>
               to see the latest answer.
            </p>
            <p class="mb-0">
               If you'd like to update this answer, copy your work to your clipboard then
               <a href="#" @click="$emit('refresh')" class="alert-link"> refresh </a>
               the page.
            </p>
         </b-alert>

         <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
               <span class="text-muted" style="line-height: 1">
                  <small v-if="dateSubmitted" id="last-updated">
                     Last updated {{ dateSubmitted }}
                  </small>
               </span>
               <div>
                  <b-button
                     v-if="displayClearButton"
                     id="btn-clear"
                     @click="clearAnswer"
                     variant="outline-primary"
                     class="mr-2"
                     size="sm"
                  >
                     Clear
                  </b-button>
                  <b-button
                     v-if="!isCompleted"
                     id="btn-save"
                     @click="saveCurrentAnswer"
                     :disabled="disableSave"
                     variant="primary"
                     size="sm"
                  >
                     Save
                  </b-button>
               </div>
            </b-col>
         </b-row>
      </b-card>

      <b-row class="mt-4 btn-row">
         <b-col class="d-flex align-items-center justify-content-between">
            <b-button
               id="btn-previous"
               variant="secondary"
               @click="selectQuestion(questionIndex - 1)"
            >
               <b-icon-arrow-left-short class="mr-1" /> Previous
            </b-button>
            <div>
               <b-button
                  v-if="isCompleted"
                  id="btn-next"
                  variant="primary"
                  @click="questionIndex++"
                  :disabled="questionIndex >= questions.length - 1"
               >
                  Next <b-icon-arrow-right-short class="ml-1" />
               </b-button>
               <b-button
                  v-else
                  id="btn-next"
                  variant="primary"
                  @click="selectQuestion(questionIndex + 1)"
               >
                  Next <b-icon-arrow-right-short class="ml-1" />
               </b-button>

               <b-button id="btn-review" variant="primary" class="ml-2" @click="toReview">
                  Review
               </b-button>
            </div>
         </b-col>
      </b-row>
   </b-container>
</template>

<script>
import _ from 'lodash';

import {mapGetters} from 'vuex';

import {AnswerTypes} from '@/helpers/types';
import {answerTypeComponent} from '../utils/answerTypes';

import CreateClientModal from '@/views/user/widgets/CreateClientModal';
import YearSelect from '@/components/forms/YearSelect';

export default {
   name: 'QuestionnaireAnswerForm',

   components: {
      CreateClientModal,
      YearSelect,
   },

   created() {
      this.storeAnswer();
      this.questionIndex = +this.$route.query.question - 1 || 0;
   },

   data() {
      return {
         questionIndex: 0,
         overwriteError: false,
         storedAnswer: null,
         answerIsValid: true,
         selectedUser: null,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         isSME: 'isSME',
         isRndig: 'isRndig',
         study: 'companies/study',
         company: 'companies/currentCompany',
         questions: 'questionnaire/questions',
         title: 'questionnaire/title',
         isCompleted: 'questionnaire/isCompleted',
         _users: 'users/companyUsers',
         _assignee: 'questionnaire/assignee',
         years: 'questionnaire/years',
         project: 'questionnaire/project',
      }),

      questionnaireId() {
         return this.$route.params.questionnaireId;
      },

      companyId() {
         if (this.$route.params.id) {
            return this.$route.params.id;
         } else {
            return this.company.id;
         }
      },

      questionLabel() {
         const displayIdx = this.questionIndex + 1;
         return `Question ${displayIdx}`;
      },

      currentYear() {
         return new Date().getFullYear();
      },

      lowerYear: {
         get() {
            return this.years.lower;
         },
         set(value) {
            this.$store.commit('questionnaire/setLowerYear', value);
         },
      },

      upperYear: {
         get() {
            return this.years.upper;
         },
         set(value) {
            this.$store.commit('questionnaire/setUpperYear', value);
         },
      },

      /** Users of the current company, sorted alphabetically by lastName, firstName */
      users() {
         return Object.values(this._users).sort((a, b) => {
            const nameA = `${a.lastName}${a.firstName}`.toUpperCase();
            const nameB = `${b.lastName}${b.firstName}`.toUpperCase();
            if (nameA < nameB) {
               return -1;
            }
            if (nameA > nameB) {
               return 1;
            }
            return 0;
         });
      },

      assignee() {
         if (this._assignee === null) {
            return null;
         }

         const user = this.users.find((user) => user.id === this._assignee);

         return user;
      },

      assigneeName() {
         if (!this.assignee) {
            return '';
         }

         return `${this.assignee.firstName} ${this.assignee.lastName}`;
      },

      // The currently selected question object
      currentQuestion() {
         return this.questions[this.questionIndex];
      },

      // The answer object for the currently selected question
      currentAnswer() {
         return this.currentQuestion.answer;
      },

      // The help text on the selected question object
      helpText() {
         return this.currentQuestion.helpText;
      },

      // The current question text split into paragraphs
      questionParagraphs() {
         return this.currentQuestion.question.split('\n');
      },

      // The answer component used for inputing answers for the current question
      currentAnswerComponent() {
         return answerTypeComponent(this.currentQuestion.ansType);
      },

      /** Display clear button? */
      displayClearButton() {
         if (this.isCompleted) {
            return false;
         }
         if (this.currentQuestion.ansType === AnswerTypes.PROJECT_YEARS) {
            return false;
         }
         return true;
      },

      // Disabled status for the save button
      disableSave() {
         switch (this.currentQuestion.ansType) {
            case AnswerTypes.PROJECT_YEARS:
               return !this.answerDirty;
            case AnswerTypes.CHOICE:
               return false;
            default:
               if (null === this.currentAnswer) {
                  return true;
               } else {
                  return !this.currentAnswer.dirty || !this.answerIsValid;
               }
         }
      },

      // Route to the review view
      reviewRoute() {
         const name = this.$route.meta.reviewRoute;
         const params = this.$route.params;
         return {
            name,
            params,
         };
      },

      answerDirty() {
         if (this.currentAnswer === null) {
            return false;
         }
         return this.currentAnswer.dirty;
      },

      dateSubmitted() {
         if (this.currentAnswer && this.currentAnswer.submitted) {
            return new Date(this.currentAnswer.submitted).toLocaleString();
         }
         return null;
      },
   },

   methods: {
      /** Display the create client user modal */
      addUserModal() {
         this.$bvModal.show('modal-create-client');
      },

      /**
       * Format a user name with title and department
       * @param {Object} user - A user object
       */
      formatUserName(user) {
         let text = `${user.firstName} ${user.lastName}`;
         if (user.title) {
            text += ` - ${user.title}`;
         }
         if (user.department) {
            text += ` - ${user.department}`;
         }
         return text;
      },

      /** Update the currently selected question */
      async selectQuestion(index) {
         let goNext = true;
         if (this.answerDirty) {
            goNext = await this.checkSave();
         }
         if (goNext) {
            if (index < 0) {
               let route;
               if (this.isRndig) {
                  route = {name: 'company-detail', params: {id: this.companyId}};
               } else {
                  route = {name: 'questionnaires'};
               }
               this.$router.push(route);
            } else if (index < this.questions.length) {
               this.questionIndex = index;
            } else {
               this.$router.push(this.reviewRoute);
            }
         }
      },

      async toReview() {
         let proceed = true;
         if (this.answerDirty) {
            proceed = await this.checkSave();
         }
         if (proceed) {
            this.$router.push(this.reviewRoute);
         }
      },

      /** Clear the current answer */
      clearAnswer() {
         let value = null;
         if (this.currentQuestion.validation.multiple) {
            value = [];
         }

         this.$store.commit('questionnaire/setAnswerValue', {
            questionIndex: this.questionIndex,
            value,
         });
      },

      /** Ask the user if they would like to save before proceeding */
      async checkSave() {
         if (this.answerIsValid) {
            const doSave = await this.$bvModal.msgBoxConfirm(
               'Would you like to save your work before proceeding? Any unsaved changes will be lost.',
               {
                  centered: true,
                  okTitle: 'Save',
                  cancelTitle: 'Proceed without saving',
               }
            );
            if (doSave) {
               await this.saveCurrentAnswer();
               return true;
            } else if (doSave === null) {
               return false;
            } else {
               this.revertAnswer();
               return true;
            }
         } else {
            const stay = await this.$bvModal.msgBoxConfirm(
               'This answer is invalid and cannot be saved. Would you like to stay and fix the answer, or proceed without saving? Any unsaved changes will be lost.',
               {
                  title: 'Invalid Answer',
                  headerTextVariant: 'danger',
                  centered: true,
                  okTitle: 'Stay',
                  cancelTitle: 'Proceed without saving',
               }
            );
            if (stay || stay === null) {
               return false;
            } else {
               this.revertAnswer();
               return true;
            }
         }
      },

      /** Recieves answer validation from the answer component */
      updateAnswerValidation(value) {
         this.answerIsValid = value;
      },

      /** Store the current answer in case we need to revert the answer */
      storeAnswer() {
         this.storedAnswer = _.cloneDeep(this.currentAnswer);
      },

      /** Revert the current answer to the stored answer */
      revertAnswer() {
         if (this.currentQuestion.ansType === AnswerTypes.PROJECT_YEARS) {
            this.$refs.answer.revertAnswer();
         }

         if (this.storedAnswer) {
            this.$store.commit('questionnaire/setAnswer', {
               questionIndex: this.questionIndex,
               answer: this.storedAnswer,
            });
         } else {
            this.$store.commit('questionnaire/clearAnswer', {
               questionIndex: this.questionIndex,
            });
         }
      },

      /** Assign the current questionnaire to the selected user */
      async assignQuestionnaire() {
         if (!this.selectedUser) {
            return;
         }

         await this.blockingRequest('questionnaire/assignQuestionnaire', {
            assigneeId: this.selectedUser.id,
            assignerId: this.profile.id,
         });
      },

      /** Unassign the questionnaire */
      async unassignQuestionnaire() {
         if (!this.assignee) {
            return;
         }

         await this.blockingRequest('questionnaire/unassignQuestionnaire');
      },

      /** Submit the current answer */
      async saveCurrentAnswer() {
         if (this.currentQuestion.ansType === AnswerTypes.PROJECT_YEARS) {
            return this.$refs.answer.save();
         }
         try {
            await this.blockingRequest('questionnaire/saveAnswer', {
               questionIndex: this.questionIndex,
            });
            this.storeAnswer();
         } catch (err) {
            const code = err.response.data.errors[0].code;
            if (50 === code) {
               this.overwriteError = true;
            } else if (75 === code) {
               this.$store.commit('showAlert', {
                  msg: 'This questionnaire is no longer assigned to you, so you may not submit any new answers.',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Submission failed',
                  seconds: 5,
               });
            }
         }
      },

      /** Refresh the questionnaire data */
      refresh() {
         this.$emit('refresh');
         this.overwriteError = false;
      },
   },

   watch: {
      // When the selected question changes, update the query param
      questionIndex: {
         handler(value) {
            if (this.$route.query) {
               const queryIndex = +this.$route.query.question;
               if (queryIndex !== value + 1) {
                  this.$router.replace({
                     name: this.$route.name,
                     params: this.$route.params,
                     query: {question: value + 1},
                  });
               }

               this.storeAnswer();
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.answer-card {
   position: relative;
   z-index: 2;
}
</style>

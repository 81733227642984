var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CollapseCard',{attrs:{"title":"Company Questionnaires","subtitle":_vm.hasOpenStudy ? ((_vm.companyQuestionnaires.length) + " Questionnaires") : null,"ident":"company-questionnaire"}},[(_vm.hasOpenStudy)?_c('div',[_c('div',{staticClass:"px-3 d-flex justify-content-end"},[_c('b-button',{staticClass:"action-btn ml-2",attrs:{"id":"btn-add-company-questionnaire","size":"sm","variant":"primary","to":{
               name: 'company-questionnaire-create',
               params: {id: _vm.$companyId},
            }}},[_vm._v(" Add New Questionnaire ")])],1),_c('b-table',{staticClass:"mt-3 mb-0",attrs:{"id":"table-company-questionnaires","thead-class":"table-head","fields":_vm.fields,"items":_vm.companyQuestionnaires,"sort-by":"title"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-icon-circle-fill',{staticClass:"mr-2",attrs:{"variant":_vm.questionnaireStatus(data.item).variant}}),_vm._v(" "+_vm._s(data.value)+" ")],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"cell-w-buttons justify-content-end"},[(_vm.isStaff && _vm.questionnaireStatus(data.item).text === 'Not Started')?_c('b-button',{attrs:{"id":"btn-delete-company-questionnaire","variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteQuestionnaire(data.item)}}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{staticClass:"ml-2",attrs:{"id":"btn-edit-company-questionnaire","variant":"primary","size":"sm","to":{
                     name: 'company-questionnaire-edit',
                     params: {questionnaireId: data.item.id},
                  }}},[_vm._v(" Edit ")]),_c('b-button',{staticClass:"ml-2",attrs:{"id":"btn-view-company-questionnaire","variant":"primary","size":"sm","to":{
                     name: 'company-questionnaire-complete',
                     params: {id: _vm.$companyId, questionnaireId: data.item.id},
                  }}},[_vm._v(" View ")])],1)]}}],null,false,821691638)})],1):_vm._e(),_c('b-alert',{staticClass:"m-3",attrs:{"show":!_vm.hasOpenStudy,"variant":"primary"}},[_vm._v(" This company does not have an open study. Open a study to configure company questionnaires. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const CopyStatus = Object.freeze({
   PENDING: 'PENDING',
   IN_PROGRESS: 'IN_PROGRESS',
   COMPLETE: 'COMPLETE',
   FAILED: 'FAILED',
});

const MAX_CONCURRENT_REQUESTS = 5;

/**
 * Manages a queue of PendingCopy instances, ensuring no more than
 * `maxConcurrentRequests` requests occur simultaneously.
 */
class CopyQueue {
   constructor(http, maxConcurrentRequests) {
      this.http = http;
      this.maxConcurrentRequests = maxConcurrentRequests;
      this.pendingQueue = [];
      this.inProgressCount = 0;
      this.completed = [];
   }

   /** Add a PendingCopy to the queue */
   push(pendingCopy) {
      this.pendingQueue.push(pendingCopy);
      this.checkPendingQueue();
   }

   /** Perform the copy */
   async doCopy(pendingCopy) {
      const {name, studyIds, companyId} = pendingCopy;

      // Increment the count of in progress copy actions
      this.inProgressCount++;

      // Set the status on the copy to IN_PROGRESS
      pendingCopy.start();
      try {
         const request = this.http.post(`/api/company/${companyId}/clone`, {
            newName: name,
            studyIds: studyIds,
         });
         const response = await request;

         // Set the status of the copy to COMPLETE
         pendingCopy.complete(response.data);
      } catch (err) {
         // Set the status of the copy to FAILED
         pendingCopy.fail(err.message);
      } finally {
         this.completed.push(pendingCopy);

         // Decrement the count of in progress copy actions
         this.inProgressCount--;

         // Check for any pending copy actions
         this.checkPendingQueue();
      }
   }

   /**
    * Check the pending queue, starting a new copy if there are pending copies and the max concurrent
    * request count wouldn't be exceeded.
    */
   checkPendingQueue() {
      if (this.pendingQueue.length > 0 && this.inProgressCount < this.maxConcurrentRequests) {
         const pendingCopy = this.pendingQueue.pop();
         this.doCopy(pendingCopy);
      }
   }
}

/**
 * Tracks the status of a company copy
 */
class PendingCopy {
   constructor(name, studyIds, companyId) {
      this.name = name;
      this.studyIds = studyIds;
      this.companyId = companyId;
      this.status = CopyStatus.PENDING;
      this.company = null;
      this.error = null;
   }

   /** Called before starting a copy */
   start() {
      this.status = CopyStatus.IN_PROGRESS;
   }

   /** Called upon successful copy */
   complete(data) {
      this.status = CopyStatus.COMPLETE;
      this.company = data;
   }

   /** Called upon failing to copy */
   fail(error) {
      this.status = CopyStatus.FAILED;
      this.error = error;
   }
}

const state = () => ({
   pendingCopies: [],
});

const getters = {
   pendingCopies: (state) => state.pendingCopies,
};

const mutations = {
   clear(state) {
      state.pendingCopies.splice(0);
   },
};

const actions = {
   async copyCompanies({state}, {companyId, studyIds, names}) {
      const queue = new CopyQueue(this._vm.$http, MAX_CONCURRENT_REQUESTS);

      names.forEach((name) => {
         const pendingCopy = new PendingCopy(name, studyIds, companyId);
         state.pendingCopies.push(pendingCopy);
         queue.push(pendingCopy);
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

<template>
   <CollapseCard title="Time Surveys" ident="time-survey">
      <template #subtitle>
         <span v-if="error === null && hasOpenStudy"> {{ tableItems.length }} Surveys </span>
         <span class="text-danger" v-else> Not Configured </span>
      </template>

      <div class="mt-2 d-flex align-items-center justify-content-end">
         <b-button
            id="btn-assign-employees"
            size="sm"
            variant="primary"
            class="action-btn ml-2"
            :to="{
               name: 'time-survey-assignments-staff',
            }"
         >
            Assign Employees
         </b-button>
      </div>

      <b-table
         v-if="tableItems.length > 0"
         id="table-time-surveys"
         class="mb-0"
         :fields="fields"
         :items="tableItems"
         sort-by="period"
      >
         <template #cell(actions)="data">
            <div class="cell-w-buttons d-flex justify-content-end">
               <b-button
                  :id="`btn-ts-${data.item.period.id}`"
                  variant="primary"
                  size="sm"
                  :to="{
                     name: 'time-survey-review-staff',
                     params: {periodId: data.item.period.id, id: $companyId},
                  }"
               >
                  View
               </b-button>
            </div>
         </template>
      </b-table>

      <b-alert :show="!isConfigured && hasOpenStudy" variant="primary" class="m-3">
         <p>No time surveys have been fully configured.</p>
         <p class="mb-0">
            <b v-if="error === 'NOACTIVITIES'"> This company has no qualified R&D activities. </b>
            <b v-else-if="error === 'NOPROJECTS'">
               This company has no R&D business components associated with an open study.
            </b>
            <b v-else-if="error === 'NOEMPLOYEES'">
               This company has no employees for the time periods corresponding to the company's R&D
               projects.
            </b>
         </p>
      </b-alert>

      <b-alert
         :show="isConfigured && tableItems.length === 0 && hasOpenStudy"
         variant="primary"
         class="m-3"
      >
         No time surveys are eligible for data entry. A time survey for a given year is only
         accessible after the tax year has ended. Check the Company Information section above to see
         this company's Tax Year End date.
      </b-alert>

      <b-alert :show="!hasOpenStudy" variant="primary" class="m-3">
         This company doesn't have an open study.
      </b-alert>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from '@/helpers/utils';
import {StudyPeriod} from '@/store/models/study';

export default {
   props: {
      error: String,
   },

   data() {
      return {
         fields: [StudyPeriod.tableField({sortable: false}), {key: 'actions', label: ''}],
      };
   },

   computed: {
      ...mapGetters({
         activeStudyId: 'companies/activeStudyId',
         hasOpenStudy: 'companies/hasOpenStudy',
         studyPeriodMap: 'companies/studyPeriodMap',
         timeSurveyPeriods: 'timesurvey/possiblePeriods',
      }),

      tableItems() {
         return this.timeSurveyPeriods
            .map((periodId) => this.studyPeriodMap[periodId])
            .filter((period) => +period.studyId === +this.activeStudyId)
            .sort(sortBy('startDate'))
            .map((period) => ({
               period,
            }));
      },

      isConfigured() {
         return this.error === null;
      },
   },
};
</script>

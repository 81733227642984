import {Field} from './field';

export class SummaryData {
   constructor({
      id = null,
      period,
      account = null,
      uploadFileId = null,
      vendor = null,
      vendorId = null,
      location = null,
      totalDebit = null,
      totalCredit = null,
      percentage = null,
   }) {
      this.id = id;
      this.period = period;
      this.account = account;
      this.uploadFileId = uploadFileId;
      this.vendor = vendor;
      this.vendorId = vendorId;
      this.location = location;
      this.totalDebit = totalDebit;
      this.totalCredit = totalCredit;
      this.netAmount = new Field({value: totalDebit !== null ? totalDebit - totalCredit : null});
      this.percentage = new Field({value: percentage});
   }

   get isAdHoc() {
      /** "Ad-Hoc" expense summaries are defined as those tied to an ad-hoc vendor, i.e. having a non-null vendorId value */
      return !!this.vendorId;
   }
}

<template>
   <CollapseCard
      title="User Accounts"
      :subtitle="`${customerUsers.length + smeUsers.length} Accounts`"
      ident="user-accounts"
   >
      <div class="d-flex align-items-center justify-content-between mt-3 mb-2">
         <h3 class="mb-0">Customer Accounts</h3>
         <b-button
            id="btn-add-customer"
            size="sm"
            variant="primary"
            class="action-btn ml-2"
            :to="{name: 'company-create-customer', params: {id: $companyId}}"
         >
            Add Customer
         </b-button>
      </div>
      <b-table
         id="table-customer-accounts"
         fixed
         :fields="clientAccountFields"
         sort-by="firstName"
         :items="customerUsers"
      >
         <template #cell(enabled)="data">
            <b-icon v-if="!data.value" icon="x-circle"></b-icon>
         </template>
         <template #cell(actions)="data">
            <div class="d-flex align-items-center justify-content-end cell-w-buttons">
               <b-button
                  :id="`btn-disable-user-customer-${data.index}`"
                  class="ml-2"
                  size="sm"
                  variant="danger"
                  @click="disableUser(data.item)"
               >
                  <b-icon icon="trash" class="mt-1"></b-icon>
               </b-button>
            </div>
         </template>
      </b-table>

      <!-- SME Accounts -->
      <div class="d-flex align-items-center justify-content-between mt-5 mb-2">
         <h3 class="mb-0">SME Accounts</h3>
         <b-button
            id="btn-add-sme"
            variant="primary"
            size="sm"
            class="ml-2"
            :to="{name: 'company-create-sme', params: {id: $companyId}}"
         >
            Add SME
         </b-button>
      </div>
      <b-table
         id="table-sme-accounts"
         fixed
         :fields="clientAccountFields"
         sort-by="firstName"
         :items="smeUsers"
         :class="[smeUsers.length > 0 ? 'mb-0' : 'mb-5']"
      >
         <template #cell(enabled)="data">
            <b-icon v-if="!data.value" icon="x-circle"></b-icon>
         </template>
         <template #cell(actions)="data">
            <div class="d-flex align-items-center justify-content-end cell-w-buttons">
               <b-button
                  :id="`btn-disable-user-sme-${data.index}`"
                  class="ml-2"
                  size="sm"
                  variant="danger"
                  @click="disableUser(data.item)"
               >
                  <b-icon icon="trash" class="mt-1"></b-icon>
               </b-button>
            </div>
         </template>
      </b-table>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';
import {unmungeEmail} from '@/helpers/utils';

export default {
   computed: {
      ...mapGetters({
         isStaff: 'isStaff',
         _customerUsers: 'users/customerUsers',
         _smeUsers: 'users/smeUsers',
      }),

      /** An array of customer users associated with this company */
      customerUsers() {
         return this._customerUsers.filter((user) => user.companyId == this.$companyId);
      },

      /** An array of SME accounts associated with this company */
      smeUsers() {
         return this._smeUsers.filter((user) => user.companyId == this.$companyId);
      },

      /** Fields for the client user account tables */
      clientAccountFields() {
         const fields = [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {
               key: 'email',
               sortable: true,
               formatter: (value, key, {enabled, email}) => (enabled ? email : unmungeEmail(email)),
            },
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', label: 'Department', sortable: true},
            {key: 'actions', label: ''},
         ];

         if ([...this.customerUsers, ...this.smeUsers].some((user) => user.subsidiary)) {
            fields.push({key: 'subsidiary', sortable: true});
         }
         return fields;
      },
   },

   methods: {
      async disableUser({id}) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'If you disable this user, only an Onboard Administrator can restore them. Are you sure?',
            {
               centered: true,
               title: 'Disable this user?',
               okVariant: 'danger',
               okTitle: 'Disable',
               cancelVariant: 'secondary',
            }
         );

         if (!proceed) return;

         await this.$store.dispatch('users/disableUser', {
            id,
            remove: true,
         });
      },
   },
};
</script>

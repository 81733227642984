<template>
   <b-card style="margin-bottom: 2rem">
      <div class="d-flex align-items-center justify-content-between">
         <h2 class="mb-0">Supplies</h2>

         <div class="d-flex align-items-center">
            <span id="supplies-declaration" class="mr-2">{{ suppliesDeclarationStr }}</span>
            <b-button
               id="btn-supplies"
               variant="primary"
               :to="{name: 'supplies-projects-staff', params: {id: $companyId}}"
               :disabled="suppliesDeclaration === undefined"
            >
               View
            </b-button>
         </div>
      </div>
   </b-card>
</template>

<script>
import {mapGetters} from 'vuex';
import {SuppliesDecl} from '@/store/modules/supplies';

export default {
   computed: {
      ...mapGetters({
         suppliesDeclaration: 'supplies/declaration',
      }),

      /** The company's supplies declaration */
      suppliesDeclarationStr() {
         return {
            [undefined]: 'Unconfigured',
            [null]: 'No Declaration',
            [SuppliesDecl.NONE]: 'No Supplies',
            [SuppliesDecl.NOT_USA]: 'Outside US',
            [SuppliesDecl.ONE]: 'One State',
            [SuppliesDecl.MANY]: 'Multiple States',
         }[this.suppliesDeclaration];
      },
   },
};
</script>

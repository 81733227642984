<template>
   <b-container v-if="loaded">
      <PageTitle title="Supplies" />

      <SectionAssignment section="supplies" />

      <ManagerMessage v-if="manager">
         <div v-if="completed">
            <h2>You're all done with this section!</h2>
            <p>Head over to the dashboard to find out what you need to do next.</p>
            <div class="d-flex justify-content-end">
               <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
            </div>
         </div>
         <div v-else-if="dataComplete">
            <h2 class="mb-0">Does everything look correct?</h2>
            <p>
               If all the information shown here looks correct, click
               <b>{{ hasSectionAssignment ? 'Done' : 'Complete' }}</b> below to finish up.
            </p>
         </div>
         <div v-else-if="hasSectionAssignment">
            <h2>This section still needs some data.</h2>
            <p>
               This section is not yet complete, but if you've entered all the information you have
               you can click Done below to be done with your assignment.
            </p>
         </div>
         <div v-else>
            <h2>This section still needs some data.</h2>
            <p>
               Finish entering required data in the previous screen, then come back here to complete
               this section.
            </p>
         </div>
      </ManagerMessage>

      <b-alert variant="success" class="mb-5" :show="!manager && completed">
         This section has been submitted.
      </b-alert>

      <div v-for="periodId in periodIds" :key="periodId">
         <h2 :id="`header-${periodId}`" class="mb-4">
            Supplies for {{ studyPeriodMap[periodId].label }}
         </h2>

         <b-card class="mb-4">
            <template v-if="periodId in accountData">
               <div
                  v-for="(account, accountIndex) in sortedAccounts(periodId)"
                  :key="`${periodId}:${account}`"
                  class="mb-4"
               >
                  <h2 :id="`account-name-${periodId}-${accountIndex + 1}`" class="mb-4">
                     {{ account }}
                  </h2>
                  <div>
                     <b-row class="mb-3">
                        <b-col cols="2">
                           <b>Vendor</b>
                        </b-col>
                        <b-col cols="2" v-if="locationRequired">
                           <b>Location</b>
                        </b-col>
                        <b-col cols="2">
                           <b>Total Debits</b>
                        </b-col>
                        <b-col cols="2">
                           <b>Total Credits</b>
                        </b-col>
                        <b-col cols="2">
                           <b>Net Amount</b>
                        </b-col>
                        <b-col cols="2">
                           <b>Percentage</b>
                        </b-col>
                     </b-row>
                     <b-row
                        v-for="(
                           {vendor, location, totalDebit, totalCredit, netAmount, percentage}, index
                        ) in sortedVendors(periodId, account)"
                        :key="`${periodId}:${account}:${vendor}:${location}`"
                        class="mb-3"
                     >
                        <b-col :id="`vendor-${periodId}-${index}`" cols="2">
                           <b>{{ vendor }}</b>
                        </b-col>
                        <b-col
                           :id="`location-${periodId}-${index}`"
                           cols="2"
                           v-if="locationRequired"
                        >
                           {{ usStates[location] }}
                        </b-col>
                        <b-col :id="`total-debit-${periodId}-${index}`" cols="2">{{
                           formatMoney(totalDebit)
                        }}</b-col>
                        <b-col :id="`total-credit-${periodId}-${index}`" cols="2">{{
                           formatMoney(totalCredit)
                        }}</b-col>
                        <b-col :id="`net-amount-${periodId}-${index}`" cols="2">{{
                           formatMoney(netAmount.value)
                        }}</b-col>
                        <b-col :id="`percentage-${periodId}-${index}`" cols="2">
                           {{ formatPercent(percentage.value) }}
                           <b-icon
                              icon="exclamation-circle-fill"
                              v-if="percentage.value === null"
                              class="text-warning ml-2"
                              v-b-tooltip="
                                 'This vendor\'s qualifying R&D Supply percentage has not been entered.'
                              "
                           ></b-icon>
                        </b-col>
                     </b-row>
                  </div>
               </div>
            </template>

            <div v-if="Object.keys(adHocData).length > 0">
               <h2 class="mb-4">Manually-Added Vendors</h2>
               <div>
                  <b-row class="mb-3">
                     <b-col cols="2">
                        <b>Vendor</b>
                     </b-col>
                     <b-col cols="2" v-if="locationRequired">
                        <b>Location</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Net Amount</b>
                     </b-col>
                     <b-col cols="2">
                        <b>Percentage</b>
                     </b-col>
                  </b-row>
                  <b-row
                     v-for="({vendor, location, netAmount, percentage}, index) in sortedVendors(
                        periodId,
                        null
                     )"
                     :key="`${periodId}:${vendor}`"
                     class="mb-3"
                  >
                     <b-col :id="`vendor-adhoc-${periodId}-${index}`" cols="2">
                        <b>{{ vendor }}</b>
                     </b-col>
                     <b-col
                        :id="`location-adhoc-${periodId}-${index}`"
                        cols="2"
                        v-if="locationRequired"
                     >
                        {{ usStates[location] }}
                     </b-col>
                     <b-col :id="`net-amount-adhoc-${periodId}-${index}`" cols="2">
                        {{ formatMoney(netAmount.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="netAmount.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="'This vendor\'s net amount has not been entered.'"
                        ></b-icon>
                     </b-col>
                     <b-col :id="`percentage-adhoc-${periodId}-${index}`" cols="2">
                        {{ formatPercent(percentage.value) }}
                        <b-icon
                           icon="exclamation-circle-fill"
                           v-if="percentage.value === null"
                           class="text-warning ml-2"
                           v-b-tooltip="
                              'This vendor\'s qualifying R&D Supply percentage has not been entered.'
                           "
                        ></b-icon>
                     </b-col>
                  </b-row>
               </div>
            </div>
         </b-card>
      </div>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'supplies-percentages'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-submit"
            variant="success"
            v-if="!completed && !hasSectionAssignment"
            :disabled="!dataComplete"
            @click="submit"
         >
            Complete <b-icon-check />
         </b-button>
         <b-button
            v-if="hasSectionAssignment"
            id="btn-complete-assignment"
            variant="success"
            @click="completeAssignment"
         >
            Done <b-icon-check />
         </b-button>
      </div>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {sortBy} from '@/helpers/utils';
import {SuppliesDecl} from '@/store/modules/supplies';
import SectionAssignment from '../components/SectionAssignment';

export default {
   components: {
      SectionAssignment,
   },

   data() {
      return {
         loaded: false,
      };
   },

   computed: {
      ...mapGetters({
         hasSectionAssignment: 'supplies/hasSectionAssignment',
         isSME: 'isSME',
         manager: 'manager',
         usStates: 'usStates',
         studyPeriodMap: 'companies/studyPeriodMap',
         declaration: 'supplies/declaration',
         accountData: 'supplies/accountData',
         adHocData: 'supplies/adHocData',
         adHocVendors: 'supplies/vendorsMap',
         completed: 'supplies/isCompleted',
      }),

      periodIds() {
         return Object.keys({
            ...this.accountData,
            ...this.adHocData,
         });
      },

      locationRequired() {
         return this.declaration === SuppliesDecl.MANY;
      },

      dataComplete() {
         for (let periodId in this.accountData) {
            for (let account in this.accountData[periodId]) {
               for (let vendor in this.accountData[periodId][account]) {
                  if (this.accountData[periodId][account][vendor].percentage.value === null) {
                     return false;
                  }
               }
            }
         }
         for (let periodId in this.adHocData) {
            for (let vendor in this.adHocData[periodId]) {
               if (
                  this.adHocData[periodId][vendor].percentage.value === null ||
                  this.adHocData[periodId][vendor].netAmount.value === null
               ) {
                  return false;
               }
            }
         }
         return true;
      },
   },

   methods: {
      /** Complete a supplies section assignment */
      async completeAssignment() {
         await this.blockingRequest('supplies/completeSectionAssignment', {
            companyId: this.$companyId,
         });

         if (this.isSME) {
            this.$router.push({name: 'Home'});
         }
      },

      /** Account names of a given period, sorted alphabetically */
      sortedAccounts(periodId) {
         return Object.keys(this.accountData[periodId]).sort();
      },

      /** Vendors of a given period and account, sorted by vendor name */
      sortedVendors(periodId, account) {
         if (account === null) {
            const vendorData = Object.values(this.adHocData[periodId]);
            return vendorData
               .map((data) => {
                  const vendor = this.adHocVendors[data.vendorId];
                  data.vendor = vendor.name;
                  return data;
               })
               .sort(sortBy('vendor'));
         } else {
            const vendors = Object.values(this.accountData[periodId][account]);
            return vendors.sort(sortBy('vendor'));
         }
      },

      /** Format cents as USD */
      formatMoney(cents) {
         if (cents === null) {
            return null;
         }
         const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
         });
         return formatter.format(cents / 100);
      },

      /** Format a percentage */
      formatPercent(percentage) {
         if (percentage === null) {
            return '';
         }
         return `${percentage}%`;
      },

      async submit() {
         // sanity check
         if (!this.dataComplete) {
            return;
         }
         await this.blockingRequest('supplies/submit', {companyId: this.$companyId});
      },
   },

   async created() {
      this.$store.commit('supplies/clearData');

      const requests = [
         this.$store.dispatch('supplies/loadData', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
      this.loaded = true;
   },
};
</script>

<template>
   <CollapseCard
      title="Qualified R&D Activities"
      :subtitle="`${activities.length} Qualified Activities`"
      ident="activities"
   >
      <b-table
         id="table-activities"
         thead-class="table-head"
         :class="[activities.length > 0 ? 'mb-0' : 'mb-5']"
         :fields="[{key: 'name', sortable: true}]"
         sort-by="name"
         :items="activities"
      >
         <template #head(name)="data">
            <div class="d-flex align-items-center justify-content-between cell-w-buttons">
               {{ data.label }}
               <b-button
                  id="btn-configure-activities"
                  variant="primary"
                  size="sm"
                  class="action-btn ml-2"
                  :to="{name: 'activities-configure', params: {id: $companyId}}"
               >
                  Configure Qualified Activities
               </b-button>
            </div>
         </template>
      </b-table>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         activities: 'activities/activities',
      }),
   },
};
</script>

<template>
   <b-card style="margin-bottom: 2rem">
      <div class="d-flex align-items-center justify-content-between">
         <h2 class="mb-0">Contract Research</h2>

         <div class="d-flex align-items-center">
            <span id="contractor-status" class="mr-2">{{ contractorStatus }}</span>
            <b-button
               id="btn-contractors"
               variant="primary"
               :to="{name: 'contractors-home-staff', params: {id: $companyId}}"
            >
               View
            </b-button>
         </div>
      </div>
   </b-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         isComplete: 'contractors/isComplete',
      }),

      /** Status of the contractor section */
      contractorStatus() {
         return this.isComplete ? 'Completed' : 'Incomplete';
      },
   },
};
</script>

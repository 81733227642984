<template>
   <div id="app" class="h-100">
      <component :is="layout" :loading="loading">
         <router-view
            @showSpinner="loading = true"
            @hideSpinner="loading = false"
            :key="$route.path"
         />
      </component>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   data() {
      return {
         loading: false,
      };
   },

   computed: {
      ...mapGetters(['isClient', 'isRndig']),

      layout() {
         if (this.$route.meta.layout) {
            return this.$route.meta.layout;
         }
         if (this.isClient) {
            return 'CustomerLayout';
         } else if (this.isRndig) {
            return 'RdigLayout';
         } else {
            return 'EmptyLayout';
         }
      },
   },
};
</script>

import {clientStaffRoutes} from '../utils';

import TimeSurveyAssignments from '@/views/timesurvey/TimeSurveyAssignments';
import TimeSurveyAssignmentsReview from '@/views/timesurvey/TimeSurveyAssignmentsReview';
import TimeSurveyIntro from '@/views/timesurvey/TimeSurveyIntro';
import TimeSurveyProjectCount from '@/views/timesurvey/TimeSurveyProjectCount';
import TimeSurveyReview from '@/views/timesurvey/TimeSurveyReview';

import ActivitiesConfigure from '@/views/activities/ActivitiesConfigure';
import EmployeesConfigure from '@/views/employees/EmployeesConfigure';

import store from '@/store';

const checkIntro = (to, from, next) => {
   const seenIntro = store.getters.clientState.sectionIntros.timeSurvey;
   if (!seenIntro) {
      return next({name: 'time-survey-intro', query: {redirect: to.path}});
   }
   next();
};

export default [
   {
      name: 'time-survey-base',
      path: '/employee-time',
      beforeEnter: async (to, from, next) => {
         const {params} = to;
         const companyId = store.getters.profile.companyId;

         // SME users always get directed to years view if they have an assignment in the active study
         if (store.getters.isSME) {
            if (store.getters['progress/hasTimeSurveyAssignment']) {
               return next({
                  name: 'time-survey-years',
                  params,
               });
            } else {
               return next({name: 'Home'});
            }
         }

         // Load data
         let requests = [];
         requests.push(store.dispatch('tsAssignment/loadEmployeeAssignments', {companyId}));
         await Promise.all(requests);

         const anyUnassignmened = store.getters['tsAssignment/employeeSets'].some(
            (set) => set.assignee === null
         );

         if (anyUnassignmened) {
            return next({
               name: 'time-survey-assignments',
               params: {...params, preloadedEmployeeAssignments: true},
            });
         } else {
            return next({
               name: 'time-survey-years',
               params: {...params, preloadedEmployeeAssignments: true},
            });
         }
      },
   },

   {
      name: 'time-survey-intro',
      path: '/time-survey/intro',
      component: TimeSurveyIntro,
      meta: {
         authGroup: 'client',
         stage: 'time',
         activeStudyRedirect: 'time-survey-base',
      },
   },

   // This route exists only to redirect to the appropriate
   // secion of the time survey
   {
      name: 'time-survey-year-base',
      path: '/time-survey/year/:periodId',
      /**
       * Redirect to the appropriate section of the time survey
       * For customer users, priority is:
       *    * If time survey has been validated by server, => final review
       *    * Else, => data entry / review
       * For SME users, similar except that they can't access assignment views
       */
      beforeEnter: async (to, from, next) => {
         const {params} = to;

         // Load necessary data
         let requests = [];
         requests.push(
            store.dispatch('timesurvey/loadPeriods', {
               companyId: store.getters['profile'].companyId,
            })
         );
         await Promise.all(requests);

         const tsPeriods = store.getters['timesurvey/periods'];

         if (tsPeriods.VALIDATED.includes(params.periodId)) {
            return next({name: 'time-survey-review', params});
         }

         if (store.getters['isCustomer'] && !tsPeriods.DATA.includes(params.periodId)) {
            return next({name: 'time-survey-project-count', params});
         }

         return next({name: 'time-survey-review', params});
      },
   },

   // PROJECT COUNT
   {
      name: 'time-survey-project-count',
      path: '/time-survey/:periodId/projects',
      component: TimeSurveyProjectCount,
      beforeEnter: checkIntro,
      meta: {
         authGroup: 'customer',
         stage: 'time',
         activeStudyRedirect: 'time-survey-base',
      },
   },

   // ASSIGNMENTS
   ...clientStaffRoutes({
      name: 'time-survey-assignments',
      path: '/time-survey/assignments',
      component: TimeSurveyAssignments,
      stage: 'time',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Assign Employees'},
      ],
      loadCompany: false,
      clientBeforeEnter: checkIntro,
      meta: {
         activeStudyRedirect: 'time-survey-base',
      },
   }),

   // Years
   {
      name: 'time-survey-years',
      path: '/time-survey/years',
      component: TimeSurveyAssignmentsReview,
      beforeEnter: checkIntro,
      meta: {
         authGroup: 'client',
         stage: 'time',
         activeStudyRedirect: 'time-survey-base',
      },
   },

   // REVIEW
   ...clientStaffRoutes({
      name: 'time-survey-review',
      path: '/time-survey/:periodId/employees',
      component: TimeSurveyReview,
      clientBeforeEnter: checkIntro,
      stage: 'time',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Time Survey'},
      ],
      meta: {
         activeStudyRedirect: 'time-survey-base',
      },
   }),

   {
      name: 'activities-configure',
      path: '/company/:id/activities',
      component: ActivitiesConfigure,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Configure Activities'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      name: 'employees-configure',
      path: '/company/:id/employees',
      component: EmployeesConfigure,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Employees'},
         ],
         authGroup: 'rndig',
      },
   },
];

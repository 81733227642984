<template>
   <b-modal
      id="modal-edit-contractor"
      title="Edit Contract Researcher"
      centered
      size="xl"
      ok-title="Save"
      ok-variant="success"
      @ok="submit"
      :ok-disabled="$v.$invalid || isComplete"
      ok-only
   >
      <p v-if="!isComplete">All fields are required to mark this section complete.</p>

      <ContractorForm :contractorId="contractor.id" edit :validation="$v.contractor" />
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import ContractorForm from './ContractorForm';

export default {
   components: {
      ContractorForm,
   },

   computed: {
      ...mapGetters({
         isComplete: 'contractors/isComplete',
         contractor: 'contractors/editContractor',
         contractors: 'contractors/contractors',
         contractorMap: 'contractors/contractorMap',
         financialData: 'contractors/financialData',
         timeData: 'contractorTime/data',
      }),
   },

   methods: {
      show(contractorId) {
         this.$store.commit('contractors/loadContractorForEdit', {contractorId});
         this.$bvModal.show('modal-edit-contractor');
      },

      async submit() {
         await this.blockingRequest('contractors/updateContractor');
      },
   },

   validations: {
      contractor: {
         fullname: {
            required,
            minLength(value) {
               if (value === null) {
                  return false;
               }
               return value.trim().length >= 3;
            },
            unique(fullname, thisContractor) {
               return !this.contractors
                  .filter((contractor) => {
                     return contractor.id !== thisContractor.id && contractor.fullname !== null;
                  })
                  .some((contractor) => {
                     return contractor.fullname === fullname;
                  });
            },
         },
         uploadFiles: {
            sizeLimit(files) {
               const fileSize = files.map((file) => file.size).reduce((sum, item) => sum + item, 0);
               return fileSize < MAX_INTERNAL_FILE_SIZE;
            },
         },
      },
   },
};
</script>

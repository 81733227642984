import Vue from 'vue';
import {sortBy} from '@/helpers/utils';

const state = () => ({
   industries: {},
   editIndustry: {
      data: null,
      dirty: false,
   },
});

const getters = {
   industries: (state) =>
      Object.values(state.industries).sort(sortBy('name', (x) => x.toUpperCase())),

   industryMap: (state) => state.industries,

   defaultIndustryId: (state) => {
      const industry = Object.values(state.industries).find((industry) => industry.is_default);
      return industry ? industry.id : null;
   },

   editIndustry: (state) => state.editIndustry.data,

   editIndustryActivities: (state) => state.editIndustry.data?.activities,

   editDataDirty: (state) => state.editIndustry.dirty,
};

const mutations = {
   setIndustries(state, {industries}) {
      industries.forEach((industry) => {
         Vue.set(state.industries, industry.id, industry);
      });
   },

   setIndustry(state, {industry}) {
      Vue.set(state.industries, industry.id, industry);
   },

   loadIndustryForEdit(state, {industryId}) {
      const industry = state.industries[industryId];
      state.editIndustry.data = {
         id: industry.id,
         name: industry.name,
         is_default: industry.is_default,
         activities: {...industry.activities},
         prompts: {...industry.prompts},
      };
      state.editIndustry.dirty = false;
   },

   clearEditIndustry(state) {
      state.editIndustry.data = null;
      state.editIndustry.dirty = false;
   },

   updateEditData(state, {field, value}) {
      const promptName = field.replace(/^prompts\.(.+)/, '$1');
      if (field !== promptName) {
         state.editIndustry.data.prompts[promptName] = value;
      } else {
         state.editIndustry.data[field] = value;
      }
      state.editIndustry.dirty = true;
   },

   setActivity(state, {name, description}) {
      Vue.set(state.editIndustry.data.activities, name, description);
      state.editIndustry.dirty = true;
   },

   deleteActivity(state, {name}) {
      Vue.delete(state.editIndustry.data.activities, name);
      state.editIndustry.dirty = true;
   },
};

const actions = {
   async loadIndustries({commit}) {
      const apiCall = this._vm.$httpSimple.get('/api/industry');
      const industries = (await apiCall).data;
      commit('setIndustries', {industries});
   },

   async createIndustry({commit}, {name, activities = {}, prompts = {}}) {
      const apiCall = this._vm.$httpSimple.post('/api/industry', {
         name,
         activities,
         prompts,
      });
      const newIndustry = (await apiCall).data;
      commit('setIndustry', {industry: newIndustry});
   },

   async updateIndustry({state, commit}) {
      const industryData = state.editIndustry.data;
      const apiCall = this._vm.$httpSimple.put(`/api/industry/${industryData.id}`, industryData);
      const industry = (await apiCall).data;
      commit('setIndustry', {industry});
      state.editIndustry.dirty = false;
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

<template>
   <FieldInput
      ref="input"
      :ident="id"
      append="%"
      :field="percentageField"
      :disabled="disabled"
      :required="setMany.length === 0"
      @input="setPercentage"
   />
</template>

<script>
import {mapGetters} from 'vuex';
import {integer} from 'vuelidate/lib/validators';
import FieldInput from '@/components/forms/FieldInput';

export default {
   components: {
      FieldInput,
   },

   props: {
      ident: {
         type: [String, Number],
      },
      summary: {
         type: Object,
         default: null,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      setMany: {
         type: Array,
         default: () => [],
      },
   },

   computed: {
      ...mapGetters({
         accountData: 'supplies/accountData',
         adHocData: 'supplies/adHocData',
         vendorsMap: 'supplies/vendorsMap',
      }),

      id() {
         return `input-percentage-${this.ident}`.replace(/\s/g, '-');
      },

      percentageField() {
         return this.summary?.percentage || null;
      },
   },

   methods: {
      async setPercentage(value) {
         if (this.validate(value)) {
            if (value === '') {
               value = null;
            } else {
               // Parse as int so the server doesn't complain about numbers like 1.0
               value = parseInt(value, 10);
            }

            if (this.setMany.length > 0 && value === null) {
               const proceed = await this.$bvModal.msgBoxConfirm(
                  'Are you sure you want to clear the percentage for every vendor in this account?',
                  {
                     centered: true,
                     title: 'Clear all vendors?',
                  }
               );
               if (!proceed) {
                  return;
               }
            }

            [this.summary, ...this.setMany].forEach((summary) => {
               if (summary == null) {
                  return;
               }

               this.$store.dispatch('supplies/savePercentage', {
                  companyId: this.$companyId,
                  summary,
                  percentage: value,
               });
            });
         }
      },

      /** Validate a percentage */
      validate(value) {
         if (value === '' || value === null) {
            this.$refs.input.setState(null);
            return true;
         }
         if (!integer(value)) {
            this.$refs.input.setState(false, 'Must be an integer');
            return false;
         }
         value = parseInt(value, 10);
         if (value < 0 || value > 100) {
            this.$refs.input.setState(false, 'Must be between 0 and 100');
            return false;
         }
         this.$refs.input.setState(null);
         return true;
      },
   },
};
</script>

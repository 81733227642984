<template>
   <div>
      <b-row class="test-card-row">
         <b-col
            cols="12"
            sm="6"
            xl="3"
            class="mb-3"
            :class="{gradient}"
            v-for="(part, index) in testParts"
            :key="part.name"
         >
            <b-card
               class="test-part-card"
               :class="{
                  inactive: active[index] === -1,
                  danger: active[index] === 0,
                  selected: selected === index,
               }"
               @click="cardSelected(index)"
            >
               <h4 class="my-auto">{{ part.name }}</h4>
               <icon-base class="icon" :width="120" :height="120" :viewBox="viewBox(part.icon)">
                  <component :is="part.icon" />
               </icon-base>
            </b-card>
         </b-col>
      </b-row>
   </div>
</template>

<script>
export default {
   props: {
      active: {
         type: Array,
         default: () => [0, 0, 0, 0],
      },
      selected: {
         type: Number,
         default: null,
      },
      gradient: {
         type: Boolean,
         default: true,
      },
   },

   data() {
      return {
         testParts: [
            {
               name: 'Permitted Purpose',
               icon: 'icon-target',
            },
            {
               name: 'Technological in Nature',
               icon: 'icon-science',
            },
            {
               name: 'Elimination of Uncertainty',
               icon: 'icon-uncertain',
            },
            {
               name: 'Process of Experimentation',
               icon: 'icon-experiment',
            },
         ],
      };
   },

   methods: {
      /** Returns an appropriate viewBox for the svg icon */
      viewBox(icon) {
         if (icon === 'icon-target') {
            return '0 0 24 25';
         }
         return '0 0 120 120';
      },

      cardSelected(index) {
         this.$emit('selection', index);
      },
   },
};
</script>

<style lang="scss" scoped>
.test-card-row {
   padding-left: 8px;
   padding-right: 8px;

   > div {
      padding-left: 8px;
      padding-right: 8px;

      .test-part-card {
         height: 100px;
         color: $white;
         cursor: pointer;

         .card-body {
            padding: 1rem;
            display: flex;
            align-items: center;
            position: relative;

            .icon {
               transition: transform 0.2s;
               color: rgba(255, 255, 255, 0.2);
               position: absolute;
               top: 16px;
               right: 16px;
               bottom: 16px;
               height: 68px;
               width: 68px;
            }
         }

         &:hover {
            .icon {
               transform: scale(1.1);
            }
         }

         background-color: #2b967c;

         &.inactive {
            background: $gray-800;
         }

         &.danger {
            background: $danger-soft;
         }

         &.selected {
            border: 5px solid $primary;
         }
      }

      &.gradient {
         @for $i from 0 through 3 {
            &:nth-child(#{$i + 1}) {
               .test-part-card {
                  @include gradient-blue-green(3, $i);

                  &.inactive {
                     background: $gray-800;
                  }
               }
            }
         }

         @media (max-width: map-get($container-max-widths, 'xl')) {
            @for $i from 0 through 3 {
               &:nth-child(#{$i + 1}) {
                  .test-part-card {
                     @include gradient-blue-green(2, $i % 2);
                  }
               }
            }
         }
      }
   }
}
</style>

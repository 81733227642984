<template>
   <b-container @drop.prevent @dragenter.prevent @dragover.prevent>
      <h1 class="mb-3 mt-3">Configure Document Categories</h1>

      <h3>Study</h3>

      <StudySelect class="mb-3" allow-none />

      <b-alert variant="primary" :show="!allowUploads">
         Select a study to configure document categories
      </b-alert>

      <div v-if="allowUploads">
         <b-card class="mb-3">
            <h2 class="mb-3">Documents</h2>
            <UploadTableStaff
               @newCategory="showNewCategoryModal"
               @delete="deleteCategoryModal"
               @upload="showUploadModal"
               @lock="showLockCategoryModal"
               @edit="showEditCategoryModal"
            />
         </b-card>

         <b-card>
            <h2 class="mb-3">Data Files</h2>
            <UploadTableStaff internal />
         </b-card>
      </div>

      <b-modal
         id="modal-upload-file"
         title="Upload Documents"
         centered
         size="lg"
         ok-title="Submit"
         :ok-disabled="$v.fileUpload.$invalid || fileUpload.uploading"
         :cancel-disabled="fileUpload.uploading"
         :no-close-on-esc="fileUpload.uploading"
         :no-close-on-backdrop="fileUpload.uploading"
         :hide-header-close="fileUpload.uploading"
         @ok="uploadFiles($event)"
      >
         <h4 v-if="fileUpload.category" class="mb-3">{{ fileUpload.category.name }}</h4>
         <b-form @submit.prevent="uploadFiles">
            <p>
               Please add any documents you'd like to upload.
               <span v-if="activeStudyId">
                  You must also select a study period that will be assigned to all of the files
                  you're currently uploading</span
               >.
            </p>

            <b-alert
               variant="danger"
               :show="!$v.fileUpload.files.totalUploadSize && fileUpload.files.length > 1"
            >
               The total size of the selected files is larger than what we can accept in a single
               upload. Try uploading your files in smaller batches, or compressing your files before
               uploading them.
            </b-alert>

            <b-alert
               v-for="v in $v.fileUpload.files.$each.$iter"
               variant="danger"
               :key="v.$model.name"
               :show="!v.fileSize"
            >
               Your file <b>{{ v.$model.name }}</b> is larger than what we can accept in a single
               upload. Please try again with a compressed or smaller version of this file.
            </b-alert>

            <b-alert variant="primary" :show="fileUpload.uploading">
               <b-progress variant="primary" :max="uploadProgress.total">
                  <b-progress-bar animated :value="uploadProgress.loaded">
                     <span>
                        {{ formatBytes(uploadProgress.loaded) }} /
                        {{ formatBytes(uploadProgress.total) }}
                     </span>
                  </b-progress-bar>
               </b-progress>
               Your document upload is in progress. Please don't navigate away until the upload is
               complete.
            </b-alert>

            <StudyPeriodSelect
               v-if="activeStudyId"
               v-model="fileUpload.periodId"
               active-study
               ident="upload"
               :disabled="fileUpload.uploading"
               class="mb-2"
               emptyText="I'll enter a time period later"
            />

            <b-form-file
               placeholder="Choose files or drop them here"
               drop-placeholder="Drop files here"
               multiple
               :disabled="fileUpload.uploading"
               v-model="fileUpload.files"
            ></b-form-file>
            <div class="d-flex mb-2 align-items-center justify-content-between">
               <b-form-text>{{ formatBytes(uploadSize) }} Selected</b-form-text>
               <b-form-text>Limit {{ formatBytes(MAX_UPLOAD_SIZE) }}</b-form-text>
            </div>
            <b-form-input
               id="input-description"
               v-model="fileUpload.description"
               placeholder="Enter a description"
            ></b-form-input>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-lock-category"
         :title="lockCategoryTitle"
         centered
         ok-title="Submit"
         @ok="toggleCategoryLock"
      >
         <div v-if="lockCategory.category">
            <p v-if="lockCategory.category.locked">
               Are you sure you want to unlock this category?
            </p>
            <p v-else>
               Are you sure you want to lock this category? Customers will be unable to upload files
               to this category.
            </p>
         </div>
      </b-modal>

      <b-modal
         id="modal-new-category"
         title="New Document Category"
         centered
         ok-title="Submit"
         @ok="saveNewCategory"
         :ok-disabled="$v.newCategory.$invalid"
         @shown="focusName"
      >
         <b-form @submit.prevent="saveNewCategory">
            <b-form-group
               label="Category Name"
               label-for="input-new-category-name"
               :invalid-feedback="newCategoryNameInvalidFeedback"
            >
               <b-form-input
                  id="input-new-category-name"
                  v-model="newCategory.name"
                  :state="newCategory.name && $v.newCategory.name.$invalid ? false : null"
                  ref="newName"
               ></b-form-input>
            </b-form-group>

            <b-form-group label="Projects" label-for="select-projects">
               <b-form-select
                  id="select-projects"
                  :options="projects"
                  text-field="name"
                  value-field="id"
                  v-model="newCategory.projectIds"
                  multiple
               ></b-form-select>
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-edit-category"
         title="Edit Category"
         centered
         ok-title="Submit"
         @ok="saveCategory"
         :ok-disabled="$v.editCategory.$invalid"
      >
         <b-form @submit.prevent="saveCategory">
            <b-form-group
               label="Category Name"
               label-for="input-edit-category-name"
               :invalid-feedback="editCategoryNameInvalidFeedback"
            >
               <b-form-input
                  id="input-edit-category-name"
                  v-model="editCategory.name"
                  :state="editCategory.name && $v.editCategory.name.$invalid ? false : null"
               ></b-form-input>
            </b-form-group>

            <b-form-group label="Projects" label-for="select-projects">
               <b-form-select
                  id="select-projects"
                  :options="projects"
                  text-field="name"
                  value-field="id"
                  v-model="editCategory.projectIds"
                  multiple
               ></b-form-select>
            </b-form-group>
         </b-form>
      </b-modal>

      <b-modal
         id="modal-delete-category"
         title="Delete Upload Category"
         centered
         @ok="submitDeleteCategory"
         ok-title="Delete"
         ok-variant="danger"
      >
         <p>
            Are you sure you want to delete upload category <b>{{ deleteCategory.name }}</b
            >?
         </p>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';

import {MAX_UPLOAD_SIZE} from '@/helpers/constants';
import {formatBytes} from '@/helpers/utils';
import ErrorCodes from '@/helpers/errorCodes';
import StudySelect from '../company/widgets/StudySelect';
import StudyPeriodSelect from '@/components/forms/StudyPeriodSelect';
import UploadTableStaff from './widgets/UploadTableStaff';

export default {
   name: 'ConfigureUploads',

   components: {
      UploadTableStaff,
      StudySelect,
      StudyPeriodSelect,
   },

   data() {
      return {
         fileUpload: {
            files: [],
            periodId: null,
            category: null,
            uploading: false,
            description: '',
         },
         newCategory: {
            name: null,
            projectIds: [],
         },
         editCategory: {
            id: null,
            name: null,
            projectIds: [],
         },
         deleteCategory: {
            id: null,
            name: null,
            projectIds: [],
         },
         lockCategory: {
            category: null,
         },
         MAX_UPLOAD_SIZE,
      };
   },

   async created() {
      await this.loadData();
   },

   computed: {
      ...mapGetters({
         uploadProgress: 'uploads/progress',
         projects: 'projects/projects',
         activeStudyId: 'companies/activeStudyId',
         openStudies: 'companies/openStudies',
         uploadCategoryMap: 'uploads/uploadCategoryMap',
      }),

      // The company ID for the current company
      companyId() {
         return this.$route.params.id;
      },

      /** Check for an active study OR no open studies */
      allowUploads() {
         return this.openStudies.length === 0 || this.activeStudyId;
      },

      /** Invalid feedback for the new category name input */
      newCategoryNameInvalidFeedback() {
         if (!this.$v.newCategory.name.minLength) {
            return 'Enter at least 3 characters';
         }
         return '';
      },

      /** Invalid feedback for the new category name input */
      editCategoryNameInvalidFeedback() {
         if (!this.$v.editCategory.name.minLength) {
            return 'Enter at least 3 characters';
         }
         return '';
      },

      // Title for the lock category modal
      lockCategoryTitle() {
         if (null === this.lockCategory.category) {
            return '';
         }
         return this.lockCategory.category.locked ? 'Unlock Category' : 'Lock Category';
      },

      // Total size of selected files for upload
      uploadSize() {
         if (this.fileUpload.files.length > 0) {
            return this.fileUpload.files.map((file) => file.size).reduce((sum, item) => sum + item);
         }
         return 0;
      },
   },

   methods: {
      // Format an integer representing a number of bytes
      formatBytes,

      async loadData() {
         let requests = [];

         requests.push(
            this.$store.dispatch('uploads/loadCompanyUploadCategories', {
               companyId: this.companyId,
               summary: true,
            })
         );

         requests.push(
            this.$store.dispatch('projects/loadProjects', {
               companyId: this.companyId,
            })
         );

         await this.blockUntilAllSettled(requests);
      },

      // Reset inputs and display the file upload modal
      showUploadModal(category) {
         this.fileUpload.category = category;
         this.fileUpload.description = '';
         this.fileUpload.files = [];
         this.fileUpload.periodId = null;
         this.$bvModal.show('modal-upload-file');
      },

      // Reset inputs and display the new category modal
      showNewCategoryModal() {
         this.newCategory = {
            name: null,
            projectIds: [],
         };
         this.$bvModal.show('modal-new-category');
      },

      /** Focus on the name input in the new category modal */
      focusName() {
         this.$refs.newName.focus();
      },

      // Reset inputs and display the edit category modal
      showEditCategoryModal(categoryId) {
         const category = this.uploadCategoryMap[categoryId];
         this.editCategory.id = category.id;
         this.editCategory.name = category.name;
         this.editCategory.projectIds = category.projectIds;

         this.$bvModal.show('modal-edit-category');
      },

      // Reset inputs and display the lock/unlock category modal
      showLockCategoryModal(item) {
         this.lockCategory.category = item;
         this.$bvModal.show('modal-lock-category');
      },

      // Send a file upload request
      async uploadFiles(bvModalEvent) {
         // Prevent the modal from closing immediately
         bvModalEvent.preventDefault();

         this.fileUpload.uploading = true;
         try {
            await this.blockingRequest('uploads/uploadFiles', {
               uploadCategoryId: this.fileUpload.category.id,
               files: this.fileUpload.files,
               periodId: this.fileUpload.periodId,
               description: this.fileUpload.description,
            });
            this.$bvModal.hide('modal-upload-file');
         } catch (err) {
            if (413 === err.response.status) {
               this.$store.commit('showAlert', {
                  msg: 'Upload exceeded maximum file size limit.',
                  seconds: 5,
               });
            } else {
               this.$store.commit('showAlert', {
                  response: err.response,
                  fallbackMsg: 'Upload failed',
                  seconds: 5,
               });
            }
         } finally {
            this.fileUpload.uploading = false;
         }
      },

      // Send a new category request
      async saveNewCategory() {
         try {
            await this.blockingRequest('uploads/saveNewCategory', {
               name: this.newCategory.name,
               projectIds: this.newCategory.projectIds,
               companyId: this.companyId,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
         this.$bvModal.hide('modal-new-category');
      },

      // Send an edit category request
      async saveCategory() {
         try {
            await this.blockingRequest('uploads/editCategory', {
               ...this.editCategory,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
         this.$bvModal.hide('modal-edit-category');
      },

      async deleteCategoryModal(category) {
         try {
            await this.blockingRequest('uploads/deleteUploadCategory', {
               uploadCategoryId: category.id,
            });
            this.deleteCategory = category;
            this.$bvModal.show('modal-delete-category');
         } catch (err) {
            const errCode = err.response ? err.response.data.errors[0].code : null;
            if (errCode === ErrorCodes.CANNOT_DELETE) {
               this.$bvModal.msgBoxOk(
                  'The selected upload category has related data, and cannot be deleted by a staff user. Contact an admin user to delete this upload category.',
                  {
                     title: 'Upload Category Has Related Data',
                     centered: true,
                  }
               );
            }
         }
      },

      async submitDeleteCategory() {
         try {
            await this.blockingRequest('uploads/deleteUploadCategory', {
               uploadCategoryId: this.deleteCategory.id,
               force: true,
            });
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },

      // Lock or unlock an upload category
      async toggleCategoryLock() {
         const action = this.lockCategory.category.locked
            ? 'uploads/unmarkCategoryComplete'
            : 'uploads/markCategoryComplete';
         try {
            await this.blockingRequest(action, {id: this.lockCategory.category.id});
         } catch (err) {
            this.$store.commit('showAlert', {
               response: err.response,
               fallbackMsg: 'Submission failed',
               seconds: 5,
            });
         }
      },
   },

   watch: {
      activeStudyId() {
         this.loadData();
      },
   },

   validations() {
      return {
         newCategory: {
            name: {
               required,
               minLength(value) {
                  if (value === null) {
                     return false;
                  }
                  return value.trim().length >= 3;
               },
            },
         },
         editCategory: {
            name: {
               required,
               minLength(value) {
                  if (value === null) {
                     return false;
                  }
                  return value.trim().length >= 3;
               },
            },
         },
         fileUpload: {
            files: {
               required,
               totalUploadSize(files) {
                  if (files.length > 0) {
                     const total = files.map((file) => file.size).reduce((sum, size) => sum + size);
                     return total <= MAX_UPLOAD_SIZE;
                  }
                  return true;
               },
               $each: {
                  fileSize(file) {
                     return file.size <= MAX_UPLOAD_SIZE;
                  },
               },
            },
         },
      };
   },
};
</script>

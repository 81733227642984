import store from '..';

export const FileSelection = Object.freeze({
   ALL: 'ALL',
   OPEN: 'OPEN',
   ACTIVE: 'ACTIVE',
});

export class UploadCategory {
   constructor({id, companyId, name, internal, enabled, locked = false, summary, projects}) {
      this.id = id;
      this.companyId = companyId;
      this.name = name;
      this.internal = internal;
      this.enabled = enabled;
      this.locked = locked;
      this.projects = projects;

      this.summary = summary;
   }

   static fromPropsData(uploadCategoryPropsData) {
      return new UploadCategory({
         locked: uploadCategoryPropsData.isLocked,
         ...uploadCategoryPropsData.uploadCategory,
      });
   }

   get summary() {
      const selection = store.getters['uploads/fileSelection'];
      const activeStudyId = +store.getters['companies/activeStudyId'];
      switch (selection) {
         case FileSelection.ACTIVE:
            return this._summary.filter((item) => {
               return item.studyId === null || item.studyId === activeStudyId;
            });
         case FileSelection.OPEN:
            return this._summary.filter((item) => {
               const studyId = item.period.studyId;
               const study = store.getters['companies/studyMap'][studyId];
               return item.studyId === null || (study && study.isOpen);
            });
         default:
            return this._summary;
      }
   }
   set summary(summary) {
      this._summary = summary
         ? summary.map((item) => {
              return item instanceof FileSummary ? item : new FileSummary(item);
           })
         : [];
   }

   get projectIds() {
      return this.projects.map((project) => project.id);
   }

   copy() {
      return new UploadCategory({
         id: this.id,
         companyId: this.companyId,
         name: this.name,
         internal: this.internal,
         enabled: this.enabled,
         locked: this.locked,
         summary: this.summary,
         projects: this.projects,
      });
   }
}

export class FileSummary {
   constructor({
      id = null,
      studyId,
      uploadCategoryId = null,
      name,
      description,
      validation,
      version,
      size,
      uploadedAt,
      uploadedBy,
      speriodId,
   }) {
      this.id = id;
      this.studyId = studyId;
      this.uploadCategoryId = uploadCategoryId;
      this.name = name;
      this.description = description;
      this.validation = validation;
      this.version = version;
      this.size = size;
      this.uploadedAt = uploadedAt;
      this.uploadedBy = uploadedBy;

      this.periodIds = [];
      this.periodId = speriodId;

      this.isLatestVersion = false;
   }

   get period() {
      return store.getters['companies/studyPeriodMap'][this.periodId] || null;
   }
}

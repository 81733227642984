import CompanyQuestionnaireCreate from '@/views/questionnaire/CompanyQuestionnaireCreate';
import CompanyQuestionnaireEdit from '@/views/questionnaire/CompanyQuestionnaireEdit';
import CompanyQuestionnaireAnswer from '@/views/questionnaire/CompanyQuestionnaireAnswer';
import CompanyQuestionnaireReview from '@/views/questionnaire/CompanyQuestionnaireReview';
import ProjectQuestionnaireCreate from '@/views/questionnaire/ProjectQuestionnaireCreate';
import ProjectQuestionnaireEdit from '@/views/questionnaire/ProjectQuestionnaireEdit';
import ProjectQuestionnaireAnswer from '@/views/questionnaire/ProjectQuestionnaireAnswer';
import ProjectQuestionnaireReview from '@/views/questionnaire/ProjectQuestionnaireReview';

import QTemplates from '@/views/questionnaire/QTemplates';
import QTemplateCreate from '@/views/questionnaire/QTemplateCreate';
import QTemplateEdit from '@/views/questionnaire/QTemplateEdit';

export default [
   {
      path: '/company/:id/questionnaire/create',
      name: 'company-questionnaire-create',
      component: CompanyQuestionnaireCreate,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Create Questionnaire'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/questionnaire/:questionnaireId/edit',
      name: 'company-questionnaire-edit',
      component: CompanyQuestionnaireEdit,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Edit Questionnaire'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/questionnaire/:questionnaireId/staff/review',
      name: 'company-questionnaire-review',
      component: CompanyQuestionnaireReview,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {
               text: 'Questionnaire',
               to: {
                  name: 'company-questionnaire-complete',
                  params: {id: 'FILL', questionnaireId: '__QUESTIONNAIREID__'},
               },
            },
            {text: 'Review'},
         ],
         authGroup: 'all',
         activeStudyRedirect: 'questionnaires',
      },
   },
   {
      path: '/company/:id/questionnaire/:questionnaireId/staff',
      name: 'company-questionnaire-complete',
      component: CompanyQuestionnaireAnswer,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Questionnaire'},
         ],
         authGroup: 'rndig',
         reviewRoute: 'company-questionnaire-review',
      },
   },
   {
      path: '/company/:id/project-questionnaire/create',
      name: 'project-questionnaire-create',
      component: ProjectQuestionnaireCreate,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Create Questionnaire'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/project-questionnaire/:questionnaireId/edit',
      name: 'project-questionnaire-edit',
      component: ProjectQuestionnaireEdit,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Edit Questionnaire'},
         ],
         authGroup: 'rndig',
      },
   },
   {
      path: '/company/:id/project-questionnaire/:questionnaireId/staff',
      name: 'project-questionnaire-complete',
      component: ProjectQuestionnaireAnswer,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {text: 'Questionnaire'},
         ],
         authGroup: 'rndig',
         reviewRoute: 'project-questionnaire-review',
      },
   },
   {
      path: '/company/:id/project-questionnaire/:questionnaireId/staff/review',
      name: 'project-questionnaire-review',
      component: ProjectQuestionnaireReview,
      meta: {
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {
               text: '__COMPANYNAME__',
               // BreadCrumbs component fills id with current route param
               to: {name: 'company-detail', params: {id: 'FILL'}},
            },
            {
               text: 'Questionnaire',
               to: {
                  name: 'project-questionnaire-complete',
                  params: {id: 'FILL', questionnaireId: '__QUESTIONNAIREID__'},
               },
            },
            {text: 'Review'},
         ],
         authGroup: 'rndig',
         activeStudyRedirect: 'questionnaires',
      },
   },
   {
      path: '/company/:id/questionnaire/:questionnaireId',
      name: 'customer-company-questionnaire-complete',
      component: CompanyQuestionnaireAnswer,
      meta: {
         authGroup: 'client',
         reviewRoute: 'customer-company-questionnaire-review',
         stage: 'questionnaire',
         activeStudyRedirect: 'questionnaires',
      },
   },
   {
      path: '/company/:id/questionnaire/:questionnaireId/review',
      name: 'customer-company-questionnaire-review',
      component: CompanyQuestionnaireReview,
      meta: {
         authGroup: 'client',
         stage: 'questionnaire',
         activeStudyRedirect: 'questionnaires',
      },
   },
   {
      path: '/company/:id/project-questionnaire/:questionnaireId',
      name: 'customer-project-questionnaire-complete',
      component: ProjectQuestionnaireAnswer,
      meta: {
         authGroup: 'client',
         reviewRoute: 'customer-project-questionnaire-review',
         stage: 'questionnaire',
         activeStudyRedirect: 'questionnaires',
      },
   },
   {
      path: '/company/:id/project-questionnaire/:questionnaireId/review',
      name: 'customer-project-questionnaire-review',
      component: ProjectQuestionnaireReview,
      meta: {
         authGroup: 'client',
         stage: 'questionnaire',
         activeStudyRedirect: 'questionnaires',
      },
   },

   {
      path: '/questionnaire-templates',
      name: 'qtemplates',
      component: QTemplates,
      meta: {
         authGroup: 'rndig',
         breadCrumb: [{text: 'Dashboard', to: {name: 'Home'}}, {text: 'Questionnaire Templates'}],
      },
   },

   {
      path: '/questionnaire-templates/create',
      name: 'qtemplate-create',
      component: QTemplateCreate,
      meta: {
         authGroup: 'rndig',
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {text: 'Questionnaire Templates', to: {name: 'qtemplates'}},
            {text: 'Create'},
         ],
      },
   },

   {
      path: '/questionnaire-templates/:templateId',
      name: 'qtemplate-edit',
      component: QTemplateEdit,
      meta: {
         authGroup: 'rndig',
         breadCrumb: [
            {text: 'Dashboard', to: {name: 'Home'}},
            {text: 'Questionnaire Templates', to: {name: 'qtemplates'}},
            {text: 'Edit'},
         ],
      },
   },
];

<template>
   <div class="content-container">
      <SpinnerOverlay v-show="loading" />
      <div class="content d-flex flex-column">
         <slot :loading="loading" />
      </div>
      <Footer class="footer" />
   </div>
</template>

<script>
import Footer from '@/views/common/widgets/Footer';
import SpinnerOverlay from '@/views/common/widgets/SpinnerOverlay';

export default {
   props: {
      loading: Boolean,
   },

   components: {
      Footer,
      SpinnerOverlay,
   },
};
</script>

<style lang="scss" scoped>
.content-container {
   height: 100%;
   display: flex;
   flex-direction: column;

   .content {
      flex: 1 0 auto;
   }

   .footer {
      flex-shrink: 0;
   }
}

.layout-alerts {
   position: fixed;
   left: 50%;
   top: 60px;
   transform: translateX(-50%);
   z-index: 100;
}
</style>

/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';
import {Company} from '../models/company';
import {Study, StudyState} from '../models/study';

const ACTIVE_STUDY_STORAGE_KEY = 'rdigActiveStudy';

export const PromptTypes = Object.freeze({
   supplies: {
      title: 'Supplies Instructions',
      description:
         'This text is included on the first page of the supplies section to help the user understand the types of qualifying supplies purchases their company might have made.',
   },
   uploads: {
      title: 'Supporting Document Examples',
      description: "Provide examples of supporting documentation we'd like the user to upload.",
   },
});

const state = () => ({
   currentCompany: null,
   activeStudyId: null,
   companies: {},
   studies: {},
});

const getters = {
   currentCompany: (state) => state.currentCompany,

   companies: (state) => Object.values(state.companies),
   companyMap: (state) => state.companies,

   studies: (state) => Object.values(state.studies),
   studyMap: (state) => state.studies,

   studyPeriods: (state, getters) => getters.studies.map((study) => study.periods).flat(),
   studyPeriodMap: (state, getters) => {
      return getters.studyPeriods.reduce((obj, period) => {
         obj[period.id] = period;
         return obj;
      }, {});
   },

   openStudies: (state, getters) => getters.studies.filter((study) => study.isOpen),
   openStudyPeriods: (state, getters) => {
      return getters.openStudies.map((study) => study.periods).flat();
   },

   activeStudyId: (state) => state.activeStudyId,
   activeStudy: (state) => {
      return state.studies[state.activeStudyId];
   },
   activeStudyPeriods: (state, getters) => {
      return getters.activeStudy?.periods || [];
   },

   hasOpenStudy: (state, getters) => {
      return getters.openStudies.length > 0;
   },

   prompts: (state) => state.currentCompany?.prompts,

   hasCompanyRole: (state, getters, rootState, rootGetters) => (userId) => {
      if (null === state.currentCompany) {
         return false;
      }
      if (rootGetters.isAdmin) {
         return true;
      }

      return [
         ...state.currentCompany.businessManagers,
         ...state.currentCompany.projectManagers,
         ...state.currentCompany.projectAssociates,
      ].includes(userId);
   },
};

const mutations = {
   /** Set the current company */
   setCurrentCompany(state, company) {
      state.currentCompany = company;
   },

   /** Clear the companies state object */
   clearCompanies(state) {
      state.companies = {};
   },

   /** Store an array of companies as an object keyed on the company ID */
   setCompanies(state, {companies}) {
      companies.forEach((company) => {
         Vue.set(state.companies, company.id, company);
      });
   },

   /** Remove a company from the companies state object */
   deleteCompany(state, {companyId}) {
      if (companyId in state.companies) {
         Vue.delete(state.companies, companyId);
      }
   },

   /** Update the supplies state on the current company */
   setSupplies(state, {supplies}) {
      const activeStudy = state.studies[state.activeStudyId];
      Vue.set(activeStudy, 'supplies', supplies);
   },

   /** Update contractor state on the study */
   setContractors(state, {companies, studyId}) {
      if (state.studies[studyId]) {
         Vue.set(state.studies[studyId], 'contractors', companies);
      }
   },

   /** Update contractor assignments on the study */
   setContractorAssignments(state, {assignments}) {
      const activeStudy = state.studies[state.activeStudyId];
      Vue.set(activeStudy, 'contractorAssignments', assignments);
   },

   /** Update cloud computing state on the study */
   setExpenses(state, {section, data}) {
      const activeStudy = state.studies[state.activeStudyId];
      Vue.set(activeStudy, section, data);
   },

   /** Update cloud computing state on the study */
   setExpensesAssignments(state, {section, assignments}) {
      const activeStudy = state.studies[state.activeStudyId];
      Vue.set(activeStudy, `${section}Assignments`, assignments);
   },

   /** Set the current study */
   setActiveStudy(state, studyId) {
      localStorage.setItem(ACTIVE_STUDY_STORAGE_KEY, studyId);
      state.activeStudyId = studyId;
   },

   /** Clear the studies state object */
   clearStudies(state) {
      state.studies = {};
   },

   /** Store an array of past and present studies as an object keyed on the study id */
   setStudies(state, {studies}) {
      studies.forEach((study) => {
         Vue.set(state.studies, study.id, study);
      });
   },

   /** Add a study to the list of past/present studies */
   addStudy(state, {study}) {
      Vue.set(state.studies, study.id, study);
   },

   closeStudy(state, {studyId}) {
      const study = state.studies[studyId];

      if (study && study.state === StudyState.ACTIVE) {
         study.state = StudyState.INACTIVE;
      }
   },

   openStudy(state, {studyId}) {
      const study = state.studies[studyId];

      if (study && study.state === StudyState.INACTIVE) {
         study.state = StudyState.ACTIVE;
      }
   },
};

const actions = {
   /** Fetch all companies */
   async fetchCompanies() {
      const companies = (await this._vm.$http.get('/api/company')).data;
      return companies.map((company) => new Company(company));
   },

   /** Load all companies */
   async loadCompanies({commit}) {
      const companies = (await this._vm.$httpNoStudy.get('/api/company')).data;
      const formattedCompanies = companies.map((company) => new Company(company));
      commit('setCompanies', {companies: formattedCompanies});
   },

   /**
    * Fetch a company
    * @param {string} companyId - The ID of the company to fetch
    */
   async fetchCompany({}, {companyId}) {
      const company = (await this._vm.$http.get(`/api/company/${companyId}`)).data;
      return new Company(company);
   },

   /** Load a company to the state's currentCompany */
   async loadCompany({commit, getters, rootGetters}, {companyId, detail = false}) {
      const params = {detail};

      const companyData = (await this._vm.$httpNoStudy.get(`/api/company/${companyId}`, {params}))
         .data;
      const company = new Company(companyData);
      const studies = companyData.studies.map((study) => new Study(study));

      commit('setCurrentCompany', company);
      commit('clearStudies');
      commit('setStudies', {studies});

      let activeStudyId = getters.activeStudyId;
      const storedStudyId = localStorage.getItem(ACTIVE_STUDY_STORAGE_KEY);

      if (
         storedStudyId &&
         getters.studyMap[storedStudyId]?.companyId === companyId &&
         getters.studyMap[storedStudyId]?.isOpen
      ) {
         activeStudyId = storedStudyId;
      } else if (rootGetters.isRndig) {
         if (activeStudyId && getters.studyMap[activeStudyId]?.companyId != companyId) {
            activeStudyId = null;
         }
      } else {
         if (!activeStudyId) {
            const openStudies = studies.filter((study) => study.isOpen);
            activeStudyId = openStudies.length > 0 ? openStudies[0].id : null;
         }
      }
      commit('setActiveStudy', activeStudyId);

      return company;
   },

   /** Create a company */
   async createCompany({}, company) {
      return await this._vm.$http.post('/api/company', company.export());
   },

   /** Edit a company */
   async updateCompany({commit}, {id, company}) {
      const companyData = (await this._vm.$http.put(`/api/company/${id}`, company.export())).data;
      const updatedCompany = new Company(companyData);
      commit('setCurrentCompany', updatedCompany);
      return updatedCompany;
   },

   /** Delete a company */
   async deleteCompany({commit}, {companyId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(`/api/company/${companyId}`, {params});
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
      commit('deleteCompany', {companyId});
   },

   /** Open a new study for a company */
   async createStudy({commit}, {companyId, study}) {
      const studyData = (
         await this._vm.$httpNoStudy.post(`/api/company/${companyId}/study`, study.export())
      ).data;
      const newStudy = new Study(studyData);
      commit('addStudy', {study: newStudy});
   },

   /** Close the current study for the company */
   async closeStudy({commit}, {companyId, studyId}) {
      await this._vm.$http.delete(`/api/company/${companyId}/study/${studyId}`);
      commit('closeStudy', {studyId});
      commit('setActiveStudy', null);
   },

   /** Reopen a closed study */
   async openStudy({commit}, {companyId, studyId}) {
      await this._vm.$http.post(`/api/company/${companyId}/study/${studyId}`);
      commit('openStudy', {studyId});
   },

   /** Load all past and present studies for a company */
   async loadStudies({commit}, {companyId}) {
      const studyData = (await this._vm.$http.get(`/api/company/${companyId}/study`)).data.results;
      const studies = studyData.map((study) => new Study(study));
      commit('setStudies', {studies});
   },

   async editStudy({getters}, {companyId, study}) {
      await this._vm.$http.put(
         `/api/company/${companyId}/study/${study.id}`,
         study.export({periods: false})
      );

      const storedStudy = getters.studyMap[study.id];
      storedStudy.label = study.label;
      storedStudy.description = study.description;
   },

   async addStudyPeriod({getters}, {companyId, studyId, studyPeriod}) {
      const studyData = (
         await this._vm.$http.post(
            `/api/company/${companyId}/study/${studyId}/period`,
            studyPeriod.export()
         )
      ).data;

      // Find the newly added period to get its ID
      const periodData = studyData.speriods.find((period) => {
         return period.period.lower === studyPeriod.period.lower;
      });
      if (periodData) {
         studyPeriod.id = periodData.id;
      }

      const study = getters.studyMap[studyId];
      if (study) {
         study.addPeriod(studyPeriod);
      }
   },

   async deleteStudyPeriod({getters}, {companyId, studyId, periodId, force = false}) {
      const params = {force};
      try {
         await this._vm.$http.delete(
            `/api/company/${companyId}/study/${studyId}/period/${periodId}`,
            {
               params,
            }
         );
         const study = getters.studyMap[studyId];
         if (study) {
            study.removePeriodId(periodId);
         }
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.CONFIRMATION_REQUIRED) {
            return err.response.data.errors[0].detail;
         }
         throw err;
      }
   },

   async editStudyPeriod({getters}, {companyId, studyId, studyPeriod}) {
      await this._vm.$http.put(
         `/api/company/${companyId}/study/${studyId}/period/${studyPeriod.id}`,
         studyPeriod.export()
      );

      const study = getters.studyMap[studyId];
      if (study) {
         study.removePeriodId(studyPeriod.id);
         study.addPeriod(studyPeriod);
      }
   },

   /** Assign a user to a role with a company */
   async addStaffAssignment({}, payload) {
      const companyId = payload.companyId;
      const userId = payload.userId;
      const role = payload.role;
      return await this._vm.$httpNoStudy.post(`/api/company/${companyId}/role/${userId}`, {role});
   },

   /** Unassign a user from a role with a company */
   async removeStaffAssignment({}, payload) {
      const companyId = payload.companyId;
      const userId = payload.userId;
      return await this._vm.$http.delete(`/api/company/${companyId}/role/${userId}`);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

<template>
   <b-container>
      <h1>Edit Study</h1>

      <b-card>
         <p>
            Define the start and end dates for
            <b
               >every distinct period for which you need the client to provide data, including
               baseline year</b
            >. Your users will see the Period Label for each period defined.
         </p>
         <p>
            Editing this study will uncomplete all sections. Deleting a period will destroy the data
            associated with it (users will have to reenter data). Editing a period will retain the
            data (users will have to reverify data).
            <StudyForm class="mb-3" :study="study" edit @change="dataChanged = true" />
         </p>

         <div class="d-flex justify-content-between">
            <b-button
               id="btn-cancel-study"
               variant="danger"
               :to="{name: 'company-studies', params: {id: $companyId}}"
            >
               Cancel
            </b-button>
            <b-button
               id="btn-save-study"
               variant="success"
               @click="submit"
               :disabled="
                  (study && (study.periods.length === 0 || study.label == null)) || !dataChanged
               "
            >
               Save
            </b-button>
         </div>
      </b-card>
   </b-container>
</template>

<script>
import StudyForm from './widgets/StudyForm';
import {mapGetters} from 'vuex';

export default {
   components: {
      StudyForm,
   },

   data() {
      return {
         dataChanged: false,
      };
   },

   computed: {
      ...mapGetters({
         studyMap: 'companies/studyMap',
      }),

      studyId() {
         return this.$route.params.studyId;
      },

      study() {
         return this.studyMap[this.studyId];
      },
   },

   methods: {
      async submit() {
         await this.blockingRequest('companies/editStudy', {
            companyId: this.$companyId,
            study: this.study,
         });
         this.$router.push({name: 'company-studies', params: {id: this.$companyId}});
      },
   },

   created() {
      this.$store.commit('companies/setActiveStudy', this.studyId);
   },
};
</script>

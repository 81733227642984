/* Register global components */
import Vue from 'vue';
import './layouts';

// dashboards
import AdminDashboard from '@/views/admin/Admin';
Vue.component('AdminDashboard', AdminDashboard);

import StaffDashboard from '@/views/dashboards/StaffDashboard';
Vue.component('StaffDashboard', StaffDashboard);

import CustomerDashboard from '@/views/dashboards/CustomerDashboard';
Vue.component('CustomerDashboard', CustomerDashboard);

import SmeDashboard from '@/views/dashboards/SmeDashboard';
Vue.component('SmeDashboard', SmeDashboard);

import {clientStaffRoutes} from '../utils';

import SuppliesProjects from '@/views/expenses/supplies/SuppliesProjects';
import SuppliesUploads from '@/views/expenses/supplies/SuppliesUploads';
import SuppliesPercentages from '@/views/expenses/supplies/SuppliesPercentages';
import SuppliesVendors from '@/views/expenses/supplies/SuppliesVendors';
import SuppliesReview from '@/views/expenses/supplies/SuppliesReview';

export default [
   ...clientStaffRoutes({
      name: 'supplies-projects',
      path: '/supplies/projects',
      component: SuppliesProjects,
      stage: 'supplies',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Supplies - Declaration'},
      ],
      meta: {
         activeStudyRedirect: null,
      },
   }),

   ...clientStaffRoutes({
      name: 'supplies-uploads',
      path: '/supplies/uploads',
      component: SuppliesUploads,
      stage: 'supplies',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Supplies - Uploads'},
      ],
      meta: {
         activeStudyRedirect: 'supplies-projects',
      },
   }),

   ...clientStaffRoutes({
      name: 'supplies-percentages',
      path: '/supplies/percentage',
      component: SuppliesPercentages,
      stage: 'supplies',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Supplies - Percentages'},
      ],
      meta: {
         activeStudyRedirect: 'supplies-projects',
      },
   }),

   ...clientStaffRoutes({
      name: 'supplies-vendors',
      path: '/supplies/vendors',
      component: SuppliesVendors,
      stage: 'supplies',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Supplies - Vendors'},
      ],
      meta: {
         activeStudyRedirect: 'supplies-projects',
      },
   }),

   ...clientStaffRoutes({
      name: 'supplies-review',
      path: '/supplies/review',
      component: SuppliesReview,
      stage: 'supplies',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Supplies - Review'},
      ],
      meta: {
         activeStudyRedirect: 'supplies-projects',
      },
   }),
];

import Vue from 'vue';
import {SummaryData} from '.';

export const expenseDataModuleFactory = (
   sectionName,
   documentsUploadCategory,
   {namespaced = true, state = {}, getters = {}, mutations = {}, actions = {}} = {}
) => ({
   namespaced,

   state() {
      return {
         vendors: {},
         accountData: {},
         adHocData: {},
         documentsUploadCategoryId: null,
         ...state,
      };
   },

   getters: {
      isCompleted: (state, getters, rootState, rootGetters) => {
         return rootGetters['companies/activeStudy'][sectionName].completedAt !== null;
      },

      declaration: (state, getters, rootState, rootGetters) => {
         return rootGetters['companies/activeStudy'][sectionName].wasUsed;
      },

      accountData: (state) => state.accountData,

      adHocData: (state) => state.adHocData,

      documentsUploadCategory: (state, getters, rootState, rootGetters) => {
         if (state.documentsUploadCategoryId === null) {
            return null;
         }
         return rootGetters['uploads/uploadCategoryMap'][state.documentsUploadCategoryId];
      },

      /** The user currently assigned this section */
      assignee: (state, getters, rootState, rootGetters) => {
         const assignmentsKey = `${sectionName}Assignments`;
         const assignee = rootGetters['companies/activeStudy'][assignmentsKey].find(
            (assignment) => assignment.completed === null
         );
         return assignee ? assignee.assigneeId : null;
      },

      /** Is the current user assigned to this section? */
      hasSectionAssignment: (state, getters, rootState, rootGetters) => {
         const userId = rootGetters.profile.id;
         return getters.assignee == userId;
      },

      /** The manually-created vendors */
      vendors: (state) => Object.values(state.vendors),
      vendorsMap: (state) => state.vendors,

      ...getters,
   },

   mutations: {
      clearData(state) {
         state.accountData = {};
         state.adHocData = {};
         state.vendors = {};
      },

      deleteVendor(state, {vendorId}) {
         Vue.delete(state.vendors, vendorId);
      },

      /** Store the documents upload category id */
      setUploadCategoryId(state, {uploadCategoryId}) {
         state.documentsUploadCategoryId = uploadCategoryId;
      },

      /** Store a single vendor */
      setVendor(state, vendor) {
         Vue.set(state.vendors, vendor.id, vendor);
      },

      ...mutations,
   },

   actions: {
      //
      // DECLARATION, COMPLETION
      //

      /** Update the section declaration */
      async saveDeclaration({dispatch}, {companyId, declaration}) {
         const payload = {wasUsed: declaration};
         await dispatch('_saveSectionData', {companyId, payload});
      },

      /** Submit the section */
      async submit({dispatch}, {companyId}) {
         const payload = {completed: true};
         await dispatch('_saveSectionData', {companyId, payload});
      },

      /** Unlock the section */
      async unlock({dispatch}, {companyId}) {
         const payload = {completed: false};
         await dispatch('_saveSectionData', {companyId, payload});
      },

      /** Save section data stored at the study level */
      async _saveSectionData({commit}, {companyId, payload}) {
         const response = await this._vm.$http.put(
            `/api/company/${companyId}/${sectionName}`,
            payload
         );

         commit(
            'companies/setExpenses',
            {
               section: sectionName,
               data: response.data,
            },
            {root: true}
         );
      },

      //
      // VENDORS
      //

      /** Load expenses data for a company */
      async loadData({commit, dispatch}, {companyId = null}) {
         const params = {};
         if (companyId) {
            params.company_id = companyId;
         }

         commit('clearData');

         const requests = [
            this._vm.$http.get(`/api/${sectionName}/vendor`, {params}).then((response) => {
               const vendors = response.data.results;
               for (const vendor of vendors) {
                  commit('setVendor', vendor);
               }
            }),

            this._vm.$http.get(`/api/${sectionName}/summary`, {params}).then((response) => {
               const data = response.data.results;
               dispatch('importData', {data: data});
            }),
         ];

         await Promise.all(requests);
         await dispatch('fillMissingAdHocData');
      },

      importData({state, rootGetters}, {data}) {
         const accountData = {};
         const adHocData = {};
         const studyPeriods = rootGetters['companies/studyPeriods'];

         data.forEach((summary) => {
            // Replace .period property with its full representation
            summary.period = studyPeriods.find((sp) => summary.period.upper === sp.period.upper);
            const summaryData = new SummaryData(summary);

            if (
               summaryData.netAmount.value < 0 ||
               (!summaryData.isAdHoc && summaryData.netAmount.value === 0)
            ) {
               // Negative entries are served from the API but ignored by the client.
               // Also ignore imported vendors with 0 net amount.
               return;
            }

            const storeKey = summary.period.id;

            if (summaryData.isAdHoc) {
               if (!(storeKey in adHocData)) {
                  adHocData[storeKey] = [];
               }
               adHocData[storeKey].push(summaryData);
            } else {
               if (!(storeKey in accountData)) {
                  accountData[storeKey] = {};
               }
               const account = summaryData.account;
               if (!(account in accountData[storeKey])) {
                  accountData[storeKey][account] = [];
               }
               accountData[storeKey][account].push(summaryData);
            }
         });

         state.accountData = accountData;
         state.adHocData = adHocData;
      },

      async fillMissingAdHocData({getters, rootGetters, state}) {
         const activeStudyPeriods = rootGetters['companies/activeStudyPeriods'];
         const adHocData = state.adHocData;

         getters.vendors.forEach((vendor) => {
            activeStudyPeriods.forEach((studyPeriod) => {
               const storeKey = studyPeriod.id;

               if (!(storeKey in adHocData)) {
                  Vue.set(adHocData, storeKey, []);
               } else {
                  const hasData = adHocData[storeKey].some((sd) => sd.vendorId === vendor.id);
                  if (hasData) {
                     // this vendor/period combination already exists in adHocData
                     return;
                  }
               }

               const dummySummaryData = new SummaryData({
                  period: studyPeriod,
                  vendorId: vendor.id,
                  location: vendor.location,
                  totalDebit: null,
                  totalCredit: 0,
                  percentage: null,
               });
               adHocData[storeKey].push(dummySummaryData);
            });
         });
      },

      async createVendor({commit, getters}, {name, location = null, companyId}) {
         if (!location) {
            location = getters.location;
         }

         const payload = {companyId, name, location};
         const response = await this._vm.$http.post(`/api/${sectionName}/vendor`, payload);

         await commit('setVendor', response.data);
      },

      async deleteVendor({commit}, {vendorId, force = false}) {
         const params = {force};
         await this._vm.$http.delete(`/api/${sectionName}/vendor/${vendorId}`, {params});
         commit('deleteVendor', {vendorId});
      },

      //
      // VENDOR DATA
      //

      /** Save a percentage value while managing the status of the corresponding Field */
      async savePercentage({getters}, {companyId, summary, percentage}) {
         summary.percentage.saveStarted();

         if (!summary.location) {
            summary.location = getters.location;
         }

         try {
            await this._vm.$http.post(`/api/${sectionName}/summary`, {
               companyId,
               periods: [
                  {
                     companyId,
                     vendorId: summary.vendorId,
                     vendor: summary.vendor,
                     account: summary.account,
                     uploadFileId: summary.uploadFileId,
                     period: summary.period.period,
                     location: summary.location,
                     totalDebit: summary.totalDebit,
                     totalCredit: summary.totalCredit,
                     percentage,
                  },
               ],
            });
            summary.percentage.value = percentage;
            summary.percentage.saveSuccessful();
         } catch {
            summary.percentage.saveFailed();
         }
      },

      /** Save an amount while managing the status of the corresponding VendorQuantity */
      async saveNetAmount(context, {companyId, summary, netAmount}) {
         summary.netAmount.saveStarted();

         if (!summary.location) {
            summary.location = getters.location;
         }

         try {
            await this._vm.$http.post(`/api/${sectionName}/summary`, {
               companyId,
               periods: [
                  {
                     companyId,
                     vendorId: summary.vendorId,
                     vendor: summary.vendor,
                     account: summary.account,
                     uploadFileId: summary.uploadFileId,
                     period: summary.period.period,
                     location: summary.location,
                     totalDebit: netAmount,
                     totalCredit: 0,
                     percentage: summary.percentage.value,
                  },
               ],
            });
            summary.totalCredit = 0;
            summary.totalDebit = netAmount;
            summary.netAmount.value = netAmount;
            summary.netAmount.saveSuccessful();
         } catch {
            summary.netAmount.saveFailed();
         }
      },

      //
      // SECTION ASSIGNMENT
      //

      /** Load section assignment */
      async loadSectionAssignment({commit}, {companyId}) {
         const data = (
            await this._vm.$http.get(`/api/company/${companyId}/${sectionName}/assignment`)
         ).data;
         commit(
            'companies/setExpensesAssignments',
            {
               section: sectionName,
               assignments: data,
            },
            {root: true}
         );
      },

      /** Assign the section to a user */
      async assignSection({commit}, {companyId, assignerId, assigneeId}) {
         const newAssignments = (
            await this._vm.$http.put(`/api/company/${companyId}/${sectionName}/assignment`, {
               assignerId,
               assigneeId,
            })
         ).data;

         commit(
            'companies/setExpensesAssignments',
            {
               section: sectionName,
               assignments: newAssignments,
            },
            {root: true}
         );
      },

      /** Delete the current assignment without completing the assignment */
      async unassignSection({commit}, {companyId}) {
         const newAssignments = (
            await this._vm.$http.delete(`/api/company/${companyId}/${sectionName}/assignment`)
         ).data;

         commit(
            'companies/setExpensesAssignments',
            {
               section: sectionName,
               assignments: newAssignments,
            },
            {root: true}
         );
      },

      /** Complete section assignment */
      async completeSectionAssignment({commit}, {companyId}) {
         const data = (
            await this._vm.$http.put(`/api/company/${companyId}/${sectionName}/assignment/complete`)
         ).data;

         commit(
            'companies/setExpensesAssignments',
            {
               section: sectionName,
               assignments: data,
            },
            {root: true}
         );
      },

      //
      // DOCUMENTS
      //

      /** Load the documents UploadCategory */
      async loadDocuments({dispatch, commit}, {companyId}) {
         const uploadCategory = await dispatch(
            'uploads/loadSpecialCategory',
            {
               companyId,
               category: documentsUploadCategory,
            },
            {root: true}
         );
         commit('setUploadCategoryId', {
            uploadCategoryId: uploadCategory.id,
         });
      },

      /** Upload to the documents upload category */
      async uploadDocuments({state, dispatch}, {files}) {
         if (files.length === 0) {
            return;
         }
         const validation = {
            type: documentsUploadCategory,
         };

         await dispatch(
            'uploads/uploadFiles',
            {
               uploadCategoryId: state.documentsUploadCategoryId,
               files,
               validation,
            },
            {root: true}
         );
      },

      /** Delete a document */
      async deleteDocument({state, dispatch}, {fileId, force = false}) {
         await dispatch(
            'uploads/deleteFile',
            {
               fileId,
               uploadCategoryId: state.documentsUploadCategoryId,
               force,
            },
            {root: true}
         );
      },

      ...actions,
   },
});

<template>
   <div class="h-100">
      <SpinnerOverlay v-show="loading" />
      <div class="position-relative h-100">
         <Sidebar v-if="sidebar !== SidebarStates.HIDDEN" />

         <div class="main-container" :class="{'msg-expanded': !msgCollapsed}">
            <div class="content-container" :class="containerClasses">
               <div class="content">
                  <ClientHeader />
                  <AlertMessages class="layout-alerts" />
                  <slot :loading="loading" />
               </div>
               <Footer class="footer" />
            </div>
            <ClientMessaging />
         </div>
      </div>

      <StudyConfigurationNotice />
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SidebarStates} from '@/store/modules/session';

import AlertMessages from '@/views/common/widgets/AlertMessages';
import ClientHeader from '@/views/common/widgets/ClientHeader';
import Footer from '@/views/common/widgets/Footer';
import SpinnerOverlay from '@/views/common/widgets/SpinnerOverlay';
import Sidebar from '@/views/common/widgets/Sidebar.vue';
import StudyConfigurationNotice from './widgets/StudyConfigurationNotice.vue';

import ClientMessaging from '@/views/messaging/ClientMessaging';

export default {
   props: {
      loading: Boolean,
   },

   components: {
      AlertMessages,
      ClientHeader,
      Footer,
      Sidebar,
      SpinnerOverlay,
      ClientMessaging,
      StudyConfigurationNotice,
   },

   data() {
      return {
         SidebarStates,
      };
   },

   computed: {
      ...mapGetters({
         sidebar: 'sidebar',
         profile: 'profile',
         stage: 'activeStage',
         msgCollapsed: 'messaging/clientMessagingCollapsed',
      }),
      containerClasses() {
         let backgroundImage;

         switch (this.stage?.id) {
            case 'company':
               backgroundImage = 'employees';
               break;

            case 'contractors':
               backgroundImage = 'employees';
               break;

            case 'dashboard':
               backgroundImage = 'accounting';
               break;

            case 'project':
               backgroundImage = 'research';
               break;

            case 'supplies':
               backgroundImage = 'accounting';
               break;

            case 'time':
               backgroundImage = 'time';
               break;

            case 'uploads':
               backgroundImage = 'docs';
               break;

            case 'cloud':
               backgroundImage = 'cloud';
               break;

            default:
               backgroundImage = null;
         }

         return [
            ...(backgroundImage != null ? ['bg-illustration', `bg-${backgroundImage}`] : []),
            {'no-sidebar': this.sidebar === SidebarStates.HIDDEN},
         ];
      },
   },
};
</script>

<style lang="scss" scoped>
$sidebar-width: 13rem;
$sidebar-width-collapsed: 3.9rem;

.main-container {
   min-height: 100%;
   padding-right: 0;
   transition: padding 0.5s ease;

   &.msg-expanded {
      //padding-right: 375px; // Width of <ClientMessaging>
      padding-right: 33%; // Width of <ClientMessaging>

      .content-container {
         background-position: calc(100% - 375px) bottom;
      }

      @media all and (max-width: 1335px) {
         .content-container {
            margin-left: $sidebar-width-collapsed;
         }
      }
   }
}

.content-container {
   padding-top: 0.5rem;
   margin-left: $sidebar-width;
   min-height: 100vh;
   background-position: right bottom;
   display: flex;
   flex-direction: column;

   @include lg-max {
      margin-left: $sidebar-width-collapsed;
   }

   .content {
      flex: 1 0 auto;
      padding-bottom: 2rem;
   }

   .footer {
      flex-shrink: 0;
   }

   &.no-sidebar {
      margin-left: 0;

      .container {
         padding: 1.25rem 3rem 2rem 2rem;
      }
   }

   &.bg-illustration {
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 50%;
      transition: background-position 0.5s ease;

      &.bg-accounting {
         background-image: url('../../assets/img/background/accounting.png');
      }

      &.bg-docs {
         background-image: url('../../assets/img/background/docs.png');
      }

      &.bg-employees {
         background-image: url('../../assets/img/background/employees.png');
      }

      &.bg-research {
         background-image: url('../../assets/img/background/research.png');
      }

      &.bg-time {
         background-image: url('../../assets/img/background/time.png');
      }

      &.bg-cloud {
         background-image: url('../../assets/img/background/cloud.png');
      }
   }
}

.layout-alerts {
   position: fixed;
   left: 50%;
   top: 60px;
   transform: translateX(-50%);
   z-index: 100;
}
</style>

<template>
   <div v-if="loaded">
      <h2 id="questionnaire-title" class="text-dark-gray mb-4 mt-3">{{ title }}</h2>

      <div v-if="section === 'upload'">
         <b-card v-if="projectId && projectUploadCategories.length > 0" class="mb-3">
            <h3>Documents</h3>
            <p id="manager-message-description">
               Do you have any supporting documents to add?
               {{ uploadsCopy || '' }}
            </p>

            <UploadTable :projectId="projectId" />
         </b-card>

         <div class="d-flex justify-content-between">
            <b-button id="btn-questionnaire" variant="secondary" :to="questionnaireRoute">
               <b-icon-arrow-left-short /> Back to Questionnaire
            </b-button>
            <b-button id="btn-review" variant="primary" @click="section = 'review'">
               Review my Answers
            </b-button>
         </div>
      </div>

      <div v-else>
         <ManagerMessage>
            <template #short>
               <p class="mb-0" v-if="!isCompleted">
                  Review these answers before completing this questionnaire.
               </p>
               <p class="mb-0" v-else>This questionnaire has been completed.</p>
            </template>

            <template v-if="!isCompleted">
               <h2>Review these answers before completing this questionnaire.</h2>

               <p class="mb-0">
                  Let's be sure everything looks correct here. When you're ready, click
                  <b>{{ isSME ? 'Done' : 'Complete' }}</b
                  >.
               </p>
            </template>
            <template v-else>
               <h2>This questionnaire has been completed.</h2>

               <p>Visit the dashboard to see if we still need more info from you.</p>

               <div class="d-flex align-items-center justify-content-end">
                  <b-button variant="success" :to="{name: 'Home'}">Dashboard</b-button>
               </div>
            </template>
         </ManagerMessage>

         <b-card class="mb-3" v-for="(question, index) in questions" :key="question.id">
            <p
               v-for="(paragraph, pIndex) in toParagraphs(question.question)"
               :id="`question-${index + 1}`"
               :key="pIndex"
               class="h5"
            >
               <span> {{ index + 1 }}. </span>
               {{ paragraph }}
            </p>
            <component
               :is="answerComponent(question)"
               :index="index"
               :readonly="true"
               class="mt-4"
            />
         </b-card>

         <div class="d-flex justify-content-between">
            <b-button
               v-if="projectUploadCategories.length > 0"
               id="btn-upload"
               variant="secondary"
               @click="section = 'upload'"
            >
               <b-icon-arrow-left-short /> Upload Documents
            </b-button>
            <b-button v-else id="btn-questionnaire" variant="secondary" :to="questionnaireRoute">
               <b-icon-arrow-left-short /> Back to Questionnaire
            </b-button>

            <b-button
               v-if="!isCompleted && isSME"
               id="btn-complete"
               variant="success"
               v-b-modal.modal-complete-questionnaire
            >
               Done <b-icon-check />
            </b-button>
            <b-button
               v-if="!isCompleted && !isSME"
               id="btn-complete"
               variant="success"
               v-b-modal.modal-complete-questionnaire
            >
               Complete <b-icon-check />
            </b-button>
         </div>
      </div>

      <b-modal
         id="modal-complete-questionnaire"
         :title="isSME ? 'Done Answering?' : 'Complete Questionnaire?'"
         centered
         ok-title="OK"
         @ok="completeQuestionnaire"
      >
         <p v-if="isSME">
            Marking this questionaire as Done will unassign the questionnaire from you. You will no
            longer be able to edit your answers.
         </p>
         <p v-else>
            Completing this questionnaire will lock it. You will no longer be able to edit your
            answers.
         </p>
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';

import {answerTypeComponent} from '../utils/answerTypes';
import UploadTable from '@/views/uploads/widgets/UploadTable';

export default {
   components: {
      UploadTable,
   },

   props: {
      isProject: {
         type: Boolean,
         default: false,
      },
   },

   data() {
      return {
         loaded: false,
         section: 'review',
      };
   },

   computed: {
      ...mapGetters({
         company: 'companies/currentCompany',
         industries: 'industries/industryMap',
         questions: 'questionnaire/questions',
         title: 'questionnaire/title',
         projectId: 'questionnaire/projectId',
         isCompleted: 'questionnaire/isCompleted',
         isClient: 'isClient',
         isSME: 'isSME',
         uploadCategories: 'uploads/uploadCategories',
         project: 'questionnaire/project',
      }),

      companyId() {
         return this.$route.params.id;
      },

      year() {
         return this.$route.params.year;
      },

      projectUploadCategories() {
         if (this.projectId === null) {
            return [];
         }
         return this.uploadCategories.filter((category) => {
            return category.projects.some((project) => project.id === this.projectId);
         });
      },

      questionnaireRoute() {
         const params = this.$route.params;
         const name = this.$route.name.replace('review', 'complete');
         return {name, params};
      },

      uploadsCopy() {
         return this.company.prompts.uploads;
      },
   },

   methods: {
      // The answer component used for displaying an answer
      answerComponent(answer) {
         return answerTypeComponent(answer.ansType);
      },

      toParagraphs(text) {
         return text ? text.split('\n') : text;
      },

      async completeQuestionnaire() {
         await this.blockingRequest('questionnaire/completeQuestionnaire', {
            companyId: this.companyId,
         });
         if (this.isSME) {
            this.loaded = false;
            this.$router.push({name: 'Home'});
         }
      },
   },

   async created() {
      const requests = [this.$store.dispatch('industries/loadIndustries')];
      await this.blockUntilAllSettled(requests);
   },

   async mounted() {
      if (this.isProject && this.projectUploadCategories.length > 0) {
         this.section = 'upload';
      }
      this.loaded = true;
   },
};
</script>

<template>
   <b-modal
      id="modal-study-config-changed"
      title="Study Configuration Changed"
      centered
      no-close-on-backdrop
      no-close-on-esc
      ok-only
      @ok="refresh"
   >
      We apologize for the interruption. Your study's configuration has changed and Onboard needs to
      update. Click <b>OK</b> to refresh the page and continue.
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
   computed: {
      ...mapGetters({
         studyConfigChanged: 'studyConfigChanged',
      }),
   },

   methods: {
      refresh() {
         this.$store.commit('setStudyConfigChanged', {value: false});
         this.$router.go();
      },
   },

   watch: {
      studyConfigChanged(value) {
         if (value) {
            this.$bvModal.show('modal-study-config-changed');
         }
      },
   },
};
</script>

<template>
   <b-container>
      <PageTitle title="Supplies" />

      <SectionAssignment section="supplies" />

      <ManagerMessage v-if="manager">
         <template v-if="!isCompleted">
            <h2>Now, add any supplies vendors that are unaccounted for.</h2>

            <p>
               If you have supplies vendors that aren't listed below, you can add them here. You'll
               also need to provide us with documentation to support these supply purchases, since
               the transactions won't be found in the files that were uploaded in the previous step.
            </p>

            <p class="mb-0">
               Don't worry if there are vendors listed below that did not provide qualified R&D
               supplies to your company, you'll be able to tell us about that in the next step.
            </p>
         </template>
         <template v-else>
            <h2>You're all done!</h2>
            <p>
               This data has been submitted, so as long as everything still looks correct, you're
               all done here!
            </p>
            <p>
               To upload any additional accounts, you'll need to unlock this section first. If you
               do that, make sure you proceed to the final screen to submit this data again.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </template>
      </ManagerMessage>

      <div class="d-flex align-items-center justify-content-between mb-3">
         <h2>Vendors</h2>
         <b-button
            id="btn-add-vendor"
            variant="primary"
            :disabled="isCompleted"
            v-b-modal.modal-add-vendor
         >
            Add a Vendor <b-icon-plus class="ml-1" />
         </b-button>
      </div>

      <b-card class="mb-4">
         <b-alert
            class="mb-0"
            variant="primary"
            :show="Object.keys(accountVendorMap).length === 0 && adHocVendorList.length === 0"
         >
            No vendors exist for your company. Click <b>Add a Vendor</b> to add one, or return to
            the previous page to import an expense account.
         </b-alert>

         <template v-if="adHocVendorList.length > 0">
            <h3>Manually-Added Vendors</h3>

            <b-table
               id="table-supplies-vendors-manual"
               :fields="adHocVendorFields"
               :items="adHocVendorList"
               sort-by="name"
               small
            >
               <template #cell(actions)="data">
                  <div class="cell-w-buttons justify-content-end">
                     <button
                        :id="`btn-delete-vendor-${data.index}`"
                        v-if="!isCompleted"
                        class="icon-btn icon-btn-danger"
                        type="button"
                        @click="deleteVendor(data.item)"
                     >
                        <b-icon-trash></b-icon-trash>
                     </button>
                  </div>
               </template>
            </b-table>
         </template>

         <div v-for="(vendors, account) in accountVendorMap" :key="account">
            <h3 id="supplies-account-name">Account: {{ account }}</h3>

            <b-table
               id="table-supplies-vendors"
               :fields="vendorFields"
               :items="vendors"
               sort-by="vendor"
               small
            ></b-table>
         </div>
      </b-card>

      <div class="d-flex align-items-center justify-content-between mb-4">
         <h2 class="mb-0">Supporting Documents</h2>
         <b-button id="btn-upload-support-documents" variant="primary" @click="startUploadFiles">
            Add Supporting Documents
            <b-icon-plus />
         </b-button>
      </div>

      <b-table
         v-if="documents.length > 0"
         :fields="documentFields"
         sort-by="uploadedAt"
         sort-icon-left
         :items="documents"
         id="table-supplies-supporting-docs"
         class="scrollbar mb-4 mt-3"
      >
         <template #cell(name)="data">
            <b>{{ data.value }}</b>
         </template>
         <template #cell(uploadedAt)="data">
            {{ formatDatetime(data.value) }}
         </template>
         <template #cell(uploadedBy)="data">
            {{ userName(data.value) }}
         </template>
         <template #cell(actions)="data">
            <div class="cell-w-buttons justify-content-end">
               <button
                  v-if="!isSME"
                  :id="`btn-delete-file-${data.index + 1}`"
                  class="icon-btn icon-btn-danger"
                  type="button"
                  @click="deleteFile(data.item.id)"
               >
                  <b-icon-trash></b-icon-trash>
               </button>
            </div>
         </template>
      </b-table>

      <b-modal
         id="modal-upload-docs"
         title="Upload your supporting documents"
         size="lg"
         centered
         :ok-disabled="fileSelection.length === 0 || fileSizeExceedsLimit"
         @ok="uploadFiles"
      >
         <p>
            Please upload documentation that supports your qualified supplies expenses from any
            manually-added vendors.
         </p>
         <div class="form-section">
            <b-form-group label="File" label-for="file-supplies-docs" class="mb-0">
               <b-form-file id="file-supplies-docs" v-model="fileSelection" multiple> </b-form-file>
            </b-form-group>
            <div class="d-flex align-items-center justify-content-between mb-2">
               <small :class="[fileSizeExceedsLimit ? 'text-danger' : 'text-muted']">
                  {{ formatBytes(uploadSize) }} Selected / Limit
                  {{ formatBytes(MAX_INTERNAL_FILE_SIZE) }}
               </small>
            </div>
         </div>
      </b-modal>

      <div class="d-flex align-items-center justify-content-between">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'supplies-uploads'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>

         <b-button
            id="btn-annotate-supplies"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'supplies-percentages'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>

      <b-modal
         id="modal-add-vendor"
         title="Add a Supplies Vendor"
         centered
         size="lg"
         @hidden="clearNewVendor"
         @ok="saveVendor"
         :ok-disabled="$v.newVendor.$invalid"
      >
         <p>
            Enter the name of the supplies vendor
            <span v-if="locationRequired">and the location where those supplies were consumed</span
            >. You'll be able to provide more information about your purchases from this vendor on
            the next page.
         </p>

         <b-form-group
            label="Vendor Name"
            label-for="input-vendor-name"
            :invalid-feedback="
               locationRequired
                  ? 'A vendor with this name and location already exists'
                  : 'A vendor with this name already exists'
            "
         >
            <b-form-input
               id="input-vendor-name"
               v-model="newVendor.vendor"
               :state="!$v.newVendor.unique ? false : null"
               ref="newVendorName"
            ></b-form-input>
         </b-form-group>

         <b-form-group
            v-if="locationRequired"
            label="Location supplies were consumed"
            label-for="select-location"
            invalid-feedback="Required"
         >
            <b-form-select
               :id="'select-location'"
               :options="stateOptions"
               v-model="newVendor.location"
            >
               <template #first>
                  <b-form-select-option :value="null" disabled>
                     Please select one
                  </b-form-select-option>
               </template>
            </b-form-select>
         </b-form-group>
      </b-modal>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {required, requiredIf, minLength} from 'vuelidate/lib/validators';

import {formatBytes} from '@/helpers/utils';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import {SuppliesDecl} from '@/store/modules/supplies';
import SectionAssignment from '../components/SectionAssignment';

export default {
   components: {
      SectionAssignment,
   },

   data() {
      return {
         MAX_INTERNAL_FILE_SIZE,
         newVendor: {
            vendor: '',
            location: null,
         },
         documentFields: [
            {key: 'name', label: 'File Name', sortable: true},
            {key: 'uploadedAt', sortable: true},
            {key: 'uploadedBy', sortable: true},
            {key: 'actions', label: ''},
         ],
         fileSelection: [],
      };
   },

   computed: {
      ...mapGetters({
         isSME: 'isSME',
         manager: 'manager',
         usStates: 'usStates',
         accountData: 'supplies/accountData',
         adHocVendorList: 'supplies/vendors',
         importedVendorList: 'supplies/importedVendorList',
         declaration: 'supplies/declaration',
         suppliesUploadCategory: 'supplies/documentsUploadCategory',
         isCompleted: 'supplies/isCompleted',
         location: 'supplies/location',
         users: 'users/companyUsers',
      }),

      adHocVendorFields() {
         const fields = [{key: 'name', label: 'Name', sortable: true}];
         if (this.declaration === SuppliesDecl.MANY) {
            fields.push({
               key: 'location',
               sortable: true,
               formatter: (val) => this.usStates[val],
               sortByFormatted: true,
            });
         }
         fields.push({key: 'actions', label: ''});
         return fields;
      },

      vendorFields() {
         const fields = [{key: 'vendor', label: 'Name', sortable: true}];
         if (this.declaration === SuppliesDecl.MANY) {
            fields.push({
               key: 'location',
               sortable: true,
               formatter: (val) => this.usStates[val],
               sortByFormatted: true,
            });
         }
         return fields;
      },

      /** US States formatted for a b-form-select options attribute */
      stateOptions() {
         const stateOptions = Object.entries(this.usStates).map((entry) => {
            const [key, value] = entry;
            return {text: value, value: key};
         });
         return stateOptions;
      },

      /** Returns a mapping of accounts to a list of its vendors */
      accountVendorMap() {
         const aggregate = Object.values(this.accountData).reduce((agg, periodData) => {
            Object.keys(periodData).forEach((account) => {
               if (!(account in agg)) {
                  agg[account] = [];
               }
               Object.values(periodData[account]).forEach((vendor) => {
                  if (
                     !agg[account].find(
                        (v) => v.vendor === vendor.vendor && v.location === vendor.location
                     )
                  ) {
                     agg[account].push(vendor);
                  }
               });
            });

            return agg;
         }, {});

         return aggregate;
      },

      locationRequired() {
         return this.declaration === SuppliesDecl.MANY;
      },

      documents() {
         if (this.suppliesUploadCategory === null) {
            return [];
         }
         return this.suppliesUploadCategory.summary;
      },

      /** Total size of selected files for upload */
      uploadSize() {
         if (this.fileSelection.length > 0) {
            return this.fileSelection.map((file) => file.size).reduce((sum, item) => sum + item);
         }
         return 0;
      },

      /** Do the selected files exceed the max file size for upload */
      fileSizeExceedsLimit() {
         return this.uploadSize >= MAX_INTERNAL_FILE_SIZE;
      },
   },

   methods: {
      formatBytes,

      /** Format a date string */
      formatDatetime(str) {
         return new Date(str).toLocaleString();
      },

      /** The name of the user with the given id */
      userName(id) {
         if (id in this.users) {
            const user = this.users[id];
            return `${user.firstName} ${user.lastName}`;
         }
         return 'R&D Incentives Group';
      },

      clearNewVendor() {
         this.newVendor.vendor = '';
         this.newVendor.location = null;
      },

      /** Display the file upload modal */
      startUploadFiles() {
         this.$bvModal.show('modal-upload-docs');
      },

      async uploadFiles() {
         await this.blockingRequest('supplies/uploadDocuments', {
            files: this.fileSelection,
         });
      },

      /** Delete the selected file */
      async deleteFile(fileId) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this file?',
            {
               id: 'modal-confirm-delete',
               title: 'Delete this file?',
               centered: true,
            }
         );
         if (proceed) {
            await this.blockingRequest('supplies/deleteDocument', {
               fileId,
               force: true,
            });
         }
      },

      async saveVendor() {
         await this.blockingRequest('supplies/createVendor', {
            companyId: this.$companyId,
            name: this.newVendor.vendor,
            location: this.newVendor.location,
         });
      },

      async deleteVendor({id}) {
         const proceed = await this.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this vendor?',
            {
               id: 'modal-confirm-delete',
               title: 'Delete this vendor?',
               centered: true,
               okVariant: 'danger',
            }
         );
         if (proceed) {
            await this.blockingRequest('supplies/deleteVendor', {
               vendorId: id,
               force: true,
            });
         }
      },

      /** Unlock the supplies section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('supplies/unlock', {
               companyId: this.$companyId,
            });
         }
      },
   },

   async created() {
      this.$store.commit('supplies/clearData');

      const requests = [
         this.$store.dispatch('supplies/loadData', {
            companyId: this.$companyId,
         }),

         this.$store.dispatch('supplies/loadDocuments', {
            companyId: this.$companyId,
         }),

         this.$store.dispatch('users/loadCompanyUsers', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];
      await this.blockUntilAllSettled(requests);
   },

   validations() {
      return {
         newVendor: {
            vendor: {
               required,
               minLength: minLength(3),
            },
            location: {
               required: requiredIf(() => this.locationRequired),
            },
            unique(newVendor) {
               const match = this.adHocVendorList.find((vendor) => {
                  const nameMatch = vendor.name.toLowerCase() === newVendor.vendor.toLowerCase();
                  const locationMatch = vendor.location === newVendor.location;
                  return nameMatch && (!this.locationRequired || locationMatch);
               });
               return !match;
            },
         },
      };
   },
};
</script>

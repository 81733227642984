<template>
   <b-container class="supplies-uploads">
      <PageTitle title="Supplies" />

      <SectionAssignment section="supplies" />

      <template v-if="isCompleted">
         <ManagerMessage v-if="manager">
            <h2>You're all done!</h2>
            <p>
               This data has been submitted, so as long as everything still looks correct, you're
               all done here!
            </p>
            <p>
               If you need to upload any additional accounts you'll need to unlock this section
               first. If you do that, make sure you proceed to the final screen to submit this data
               again.
            </p>
            <div class="d-flex justify-content-end">
               <b-button variant="secondary" @click="unlock">Unlock</b-button>
            </div>
         </ManagerMessage>

         <b-alert
            :show="!manager"
            variant="primary"
            class="d-flex align-items-center justify-content-between mb-5"
         >
            <span>
               The supplies section has been submitted. Click <b>Unlock</b> to make additional
               changes.
            </span>
            <b-button variant="white" @click="unlock">Unlock</b-button>
         </b-alert>
      </template>

      <template v-else>
         <ManagerMessage v-if="manager">
            <template #short>First, upload any supplies transaction files you might have.</template>

            <h2>First, upload any supplies transaction files you might have.</h2>

            <p>
               If your company has any
               <strong>expense accounts</strong> that were used to purchase supplies, upload those
               files here, one export file per account. Onboard will organize your purchases by time
               period and vendor. <b>XLSX or CSV formats</b> are accepted, and the files may contain
               any number of additional columns but must include the following:
            </p>

            <p>
               <b>
                  Vendor Name, Transaction Date, Transaction Amount (Debit){{
                     locationRequired ? ', Location (US State) where supplies were consumed' : ''
                  }}.
               </b>
            </p>

            <p>You will also be able to manually add supplies vendors on the next screen.</p>
         </ManagerMessage>

         <div class="d-flex justify-content-end">
            <b-button
               id="btn-upload-file"
               variant="primary"
               class="d-flex align-items-center my-4"
               @click="startImport"
            >
               {{
                  uploadedFiles.length === 0
                     ? 'Upload supplies transactions'
                     : "Upload another account's transactions"
               }}
               <custom-icon icon="icon-uploads" width="20" height="20" class="ml-2" />
            </b-button>
         </div>
      </template>

      <DataFileList section="supplies" />

      <div class="d-flex align-items-center justify-content-between mt-4">
         <b-button
            class="d-flex align-items-center"
            :to="$clientStaffRoute({name: 'supplies-projects'})"
         >
            <b-icon-arrow-left-short class="mr-1" />
            Back
         </b-button>
         <b-button
            id="btn-supplies-vendors"
            class="d-flex align-items-center"
            variant="primary"
            :to="$clientStaffRoute({name: 'supplies-vendors'})"
         >
            Next
            <b-icon-arrow-right-short class="ml-1" />
         </b-button>
      </div>

      <DataFileImport
         ref="fileImport"
         section="supplies"
         section-label="R&D Supplies"
         template-download
         :columns="fileImportColumns"
      >
         <template #file-select-title> Upload your supplies purchase transactions </template>
         <template #intro>
            Supplies expenses can be imported from the expense accounts in your company's
            bookkeeping software. Export any accounts which may have been used to purchase supplies
            for R&D from
            {{ prettyPeriodNames }}
            and import them here. You can download a template file containing the importable fields.
         </template>
      </DataFileImport>
   </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import {SuppliesDecl} from '@/store/modules/supplies';

import SectionAssignment from '../components/SectionAssignment';
import DataFileImport from '../components/DataFileImport';
import DataFileList from '../components/DataFileList';

export default {
   components: {
      DataFileImport,
      DataFileList,
      SectionAssignment,
   },

   data() {
      return {
         newFile: {
            file: null,
            description: null,
         },
         validationErrorFields: [
            {key: 'name', label: 'Field', sortable: true},
            {key: 'record', label: 'Row', sortable: true},
            {key: 'column', sortable: true},
            {key: 'error', sortable: true},
         ],
         decodingErrorFields: [{key: 'encoding', sortable: true}, {key: 'error'}],
         SuppliesDecl,
      };
   },

   computed: {
      ...mapGetters({
         manager: 'manager',
         activeStudy: 'companies/activeStudy',
         isCompleted: 'supplies/isCompleted',
         company: 'companies/currentCompany',
         declaration: 'supplies/declaration',
         uploadedFiles: 'suppliesUploads/uploadedFiles',
      }),

      /** Is location a required column mapping? */
      locationRequired() {
         return this.declaration === SuppliesDecl.MANY;
      },

      fileImportColumns() {
         const cols = [
            {key: 'vendor', label: 'Vendor Name', required: true},
            {key: 'purchased', label: 'Transaction Date', required: true},
            {key: 'debit', label: 'Transaction Amount (Debit)', required: true},
            {key: 'credit', label: 'Refund Amount (Credit)', required: false},
            {key: 'description', label: 'Description', required: false},
         ];
         if (this.locationRequired) {
            cols.splice(1, 0, {
               key: 'location',
               label: 'Location (US State)',
               labelLong: 'Location (US State) where supplies were consumed',
               required: true,
            });
         }

         return cols;
      },

      suppliesCopy() {
         const copy = this.company.prompts.supplies;
         return copy ? copy.split('\n') : [];
      },

      periodNames() {
         return this.activeStudy.periods.map((p) => p.label);
      },

      prettyPeriodNames() {
         const periodNames = this.periodNames;
         let returnString = '';

         if (periodNames.length < 3) {
            returnString += periodNames.join(' and ');
         } else {
            // Join all but the last period names with a comma (,)
            returnString += periodNames.slice(0, periodNames.length - 1).join(', ');

            // Add the last period name preceded by "and"
            returnString += `, and ${periodNames[periodNames.length - 1]}`;
         }
         return returnString;
      },
   },

   methods: {
      startImport() {
         this.$refs.fileImport.startNewImport();
      },

      /** Unlock the supplies section */
      async unlock() {
         const msg =
            "Unlocking this section may delay your credit calculation. You will have to assign users again and will have to mark the section complete again once you're done.";
         const title = 'Unlock this section?';
         const proceed = await this.$bvModal.msgBoxConfirm(msg, {
            title,
            centered: true,
         });
         if (proceed) {
            await this.blockingRequest('supplies/unlock', {
               companyId: this.$companyId,
            });
         }
      },
   },

   async created() {
      this.$store.commit('suppliesUploads/clearUploads');
      let requests = [
         this.$store.dispatch('suppliesUploads/loadUploadCategory', {
            companyId: this.$companyId,
         }),

         this.$store.dispatch('users/loadCompanyUsers', {
            companyId: this.$companyId,
         }),

         // Refresh company data to update study
         this.$store.dispatch('companies/loadCompany', {companyId: this.$companyId, force: true}),
      ];

      await this.blockUntilAllSettled(requests);

      if (![SuppliesDecl.ONE, SuppliesDecl.MANY].includes(this.declaration)) {
         this.$router.push({name: 'supplies-projects'});
      }
   },
};
</script>

<template>
   <b-modal
      id="modal-add-contractor"
      title="Add Contract Researchers"
      centered
      size="xl"
      @shown="onModalShown"
      @ok="submit"
   >
      <ContractorForm
         v-for="(contractor, idx) in contractors"
         :key="contractor.id"
         ref="form"
         :contractorId="contractor.id"
         :validation="$v.contractors.$each[idx]"
         :can-delete="contractors.length > 1"
         :waiting="waiting"
      />

      <template #modal-footer>
         <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
               id="btn-save-add"
               variant="success"
               @click="submit(true)"
               class="ml-2"
               :disabled="$v.$invalid || waiting"
            >
               <div class="d-flex align-items-center">
                  Save and Add Another
                  <b-spinner v-if="waiting" variant="light" class="ml-2" small />
               </div>
            </b-button>
            <b-button
               id="btn-save"
               variant="success"
               @click="submit(false)"
               class="ml-2"
               :disabled="$v.$invalid || waiting"
            >
               <div class="d-flex align-items-center">
                  Save
                  <b-spinner v-if="waiting" variant="light" class="ml-2" small />
                  <b-icon-check v-else class="ml-1" />
               </div>
            </b-button>
         </div>
      </template>
   </b-modal>
</template>

<script>
import {mapGetters} from 'vuex';
import {required} from 'vuelidate/lib/validators';
import {MAX_INTERNAL_FILE_SIZE} from '@/helpers/constants';
import ContractorForm from './ContractorForm';

export default {
   components: {
      ContractorForm,
   },

   data() {
      return {
         waiting: false,
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         contractors: 'contractors/newContractors',
         existingContractors: 'contractors/contractors',
      }),

      companyId() {
         return this.$route.params.id ? this.$route.params.id : this.profile.companyId;
      },
   },

   methods: {
      /** Called by the parent component to display the modal */
      show() {
         this.$store.dispatch('contractors/clearNewContractors');
         this.$bvModal.show('modal-add-contractor');
      },

      onModalShown() {
         this.$refs.form[0].focusName();
      },

      async submit(addAnother) {
         this.waiting = true;
         await this.blockingRequest('contractors/createContractors', {
            companyId: this.companyId,
         });
         this.waiting = false;
         if (addAnother) {
            this.$store.dispatch('contractors/clearNewContractors');
         } else {
            this.$bvModal.hide('modal-add-contractor');
         }
      },
   },

   validations: {
      contractors: {
         $each: {
            fullname: {
               required,
               minLength(value) {
                  if (value === null) {
                     return false;
                  }
                  return value.trim().length >= 3;
               },
               unique(fullname, thisContractor) {
                  return ![...this.contractors, ...this.existingContractors]
                     .filter((contractor) => {
                        return contractor.id !== thisContractor.id && contractor.fullname !== null;
                     })
                     .some((contractor) => {
                        return contractor.fullname.toLowerCase() === fullname.toLowerCase();
                     });
               },
            },
            uploadFiles: {
               sizeLimit(files) {
                  const fileSize = files
                     .map((file) => file.size)
                     .reduce((sum, item) => sum + item, 0);
                  return fileSize < MAX_INTERNAL_FILE_SIZE;
               },
            },
         },
      },
   },
};
</script>

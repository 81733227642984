import {StatusType} from '@/helpers/constants';
import {sortBy} from '@/helpers/utils';
import store from '..';

export const StudyState = Object.freeze({
   ACTIVE: 'ACTIVE',
   LOCKED: 'LOCKED',
   INACTIVE: 'INACTIVE',
});

export class Study {
   constructor({
      id = null,
      companyId,
      label = null,
      description = null,
      state = StudyState.ACTIVE,
      speriods = [],
      cloudcomputing = null,
      cloudcomputingAssignments = [],
      contractors = null,
      contractorAssignments = [],
      supplies = null,
      suppliesAssignments = [],
   } = {}) {
      this.id = id;
      this.companyId = companyId;
      this.label = label;
      this.description = description;
      this.state = state;

      this.cloudcomputing = cloudcomputing;
      this.cloudcomputingAssignments = cloudcomputingAssignments;
      this.contractors = contractors;
      this.contractorAssignments = contractorAssignments;
      this.supplies = supplies;
      this.suppliesAssignments = suppliesAssignments;

      this.periods = speriods.map((period) => {
         return period instanceof StudyPeriod ? period : new StudyPeriod({...period, studyId: id});
      });
      this.sortPeriods();
   }

   get firstPeriod() {
      return this.periods[0];
   }

   get lastPeriod() {
      return this.periods[this.periods.length - 1];
   }

   get isOpen() {
      return this.state === StudyState.ACTIVE;
   }

   get status() {
      switch (this.state) {
         case StudyState.ACTIVE:
            return {status: StatusType.COMPLETE, text: 'Open'};
         case StudyState.LOCKED:
            return {status: StatusType.IN_PROGRESS, text: 'Locked'};
         case StudyState.INACTIVE:
            return {status: StatusType.INCOMPLETE, text: 'Closed'};
         default:
            return {status: StatusType.NA};
      }
   }

   get humanPeriodLabels() {
      const periodCount = this.periods.length;
      if (periodCount < 3) {
         return this.periods.map((period) => period.label).join(', ');
      } else {
         return `${this.periods[0].label}, ..., ${this.periods[periodCount - 1].label}`;
      }
   }

   sortPeriods() {
      this.periods.sort(sortBy('startDate'));
   }

   addPeriod(period) {
      this.periods.push(period);
      this.sortPeriods();
   }

   removePeriod(period) {
      const idx = this.periods.findIndex((p) => p.isEqual(period));
      if (idx > -1) {
         this.periods.splice(idx, 1);
      }
   }

   removePeriodId(periodId) {
      const idx = this.periods.findIndex((p) => p.id === periodId);
      if (idx > -1) {
         this.periods.splice(idx, 1);
      }
   }

   export({periods = true} = {}) {
      const study = {
         label: this.label,
         description: this.description,
      };

      if (periods) {
         study.periods = this.periods.map((period) => period.export());
      }

      return study;
   }
}

export class StudyPeriod {
   constructor({id = null, period = {lower: null, upper: null}, label = null, studyId} = {}) {
      this.id = id;
      this.period = period;
      this.label = label;
      this.studyId = studyId;
   }

   static tableField({key = 'period', label = 'Time Period', sortable = true} = {}) {
      return {
         key,
         label,
         sortable,
         formatter: (period) => period.label,
         sortByFormatted: (period) => period.startDate,
      };
   }

   isEqual(other) {
      return this.period.lower === other.period.lower && this.period.upper === other.period.upper;
   }

   isBefore(other) {
      return this.startDate < other.startDate;
   }

   /**
    * Does this study period contain the given date?
    * @param {String|Date} date
    */
   containsDate(date) {
      if (!(this.startDate && this.endDate)) {
         return false;
      }
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      const check = new Date(date);
      return start < check && check < end;
   }

   /**
    * Does this study period's date range overlap the given date range?
    * @param {String|Date} startDate
    * @param {String|Date} endDate
    */
   overlaps(startDate, endDate) {
      const thisStart = new Date(this.startDate);
      const thisEnd = new Date(this.endDate);
      const otherStart = new Date(startDate);
      const otherEnd = new Date(endDate);
      return thisStart < otherEnd && thisEnd > otherStart;
   }

   get study() {
      return store.getters['companies/studyMap'][this.studyId];
   }

   get startDate() {
      if (!this.period.lower) {
         return this.period.lower;
      }
      const [year, month, day] = this.period.lower.split('-');
      return new Date(Date.UTC(year, month - 1, day));
   }
   set startDate(val) {
      if (val instanceof Date) {
         // Convert to 'YYYY-MM-DD', accounting for timezone offset
         const offset = val.getTimezoneOffset();
         val = new Date(val.getTime() - offset * 60 * 1000);
         val = val.toISOString().split('T')[0];
      }
      this.period.lower = val;
   }

   get endDate() {
      if (!this.period.upper) {
         return this.period.upper;
      }
      const [year, month, day] = this.period.upper.split('-');
      return new Date(Date.UTC(year, month - 1, day));
   }
   set endDate(val) {
      if (val instanceof Date) {
         // Convert to 'YYYY-MM-DD', accounting for timezone offset
         const offset = val.getTimezoneOffset();
         val = new Date(val.getTime() - offset * 60 * 1000);
         val = val.toISOString().split('T')[0];
      }
      this.period.upper = val;
   }

   copy() {
      return new StudyPeriod({
         id: this.id,
         period: {...this.period},
         label: this.label,
         studyId: this.studyId,
      });
   }

   export() {
      return {
         period: this.period,
         label: this.label,
      };
   }
}

<template>
   <div>
      <CollapseCard title="Staff Assignments" ident="staff-assignments">
         <template #subtitle>
            <span :class="{'text-danger': !hasStaff}">
               {{ businessManagers.length + projectManagers.length + projectAssociates.length }}
               Users
            </span>
         </template>

         <b-alert variant="warning" :show="!hasCompanyRole">
            You must have a role with this company before assigning or unassigning other users.
         </b-alert>

         <h3 class="mt-3 mb-2">Business Managers</h3>
         <b-table
            id="table-business-managers"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="businessManagers"
         >
            <template #head(actions)="">
               <b-button
                  id="btn-edit-business-managers"
                  size="sm"
                  variant="primary"
                  class="action-btn ml-2"
                  @click="editRole = 'BM'"
                  v-b-modal.modal-staff-edit
               >
                  Edit
               </b-button>
            </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>

         <h3 class="mt-5 mb-2">Project Managers</h3>
         <b-table
            id="table-project-managers"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="projectManagers"
         >
            <template #head(actions)="">
               <div class="cell-w-buttons">
                  <b-button
                     id="btn-edit-project-managers"
                     size="sm"
                     variant="primary"
                     class="action-btn ml-2"
                     @click="editRole = 'PM'"
                     v-b-modal.modal-staff-edit
                  >
                     Edit
                  </b-button>
               </div>
            </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>

         <h3 class="mt-5 mb-2">Project Associates</h3>
         <b-table
            id="table-project-associates"
            fixed
            :fields="accountFields"
            sort-by="firstName"
            :items="projectAssociates"
            :class="[projectAssociates.length > 0 ? 'mb-0' : 'mb-5']"
         >
            <template #head(actions)="">
               <div class="cell-w-buttons">
                  <b-button
                     id="btn-edit-project-associates"
                     size="sm"
                     variant="primary"
                     class="action-btn ml-2"
                     @click="editRole = 'PA'"
                     v-b-modal.modal-staff-edit
                  >
                     Edit
                  </b-button>
               </div>
            </template>
            <template #cell(enabled)="data">
               <b-icon v-if="!data.value" icon="x-circle"></b-icon>
            </template>
         </b-table>
      </CollapseCard>

      <b-modal id="modal-staff-edit" :title="staffTitle" hide-footer centered size="lg">
         <StaffAssignmentEdit
            v-if="'BM' === editRole"
            v-model="company.businessManagers"
            :users="editUsers"
            role="BM"
            :companyId="company.id"
         />
         <StaffAssignmentEdit
            v-if="'PM' === editRole"
            v-model="company.projectManagers"
            :users="editUsers"
            role="PM"
            :companyId="company.id"
         />
         <StaffAssignmentEdit
            v-if="'PA' === editRole"
            v-model="company.projectAssociates"
            :users="editUsers"
            role="PA"
            :companyId="company.id"
         />
      </b-modal>
   </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {unmungeEmail} from '@/helpers/utils';
import StaffAssignmentEdit from '@/views/company/widgets/StaffAssignmentEdit';

export default {
   components: {
      StaffAssignmentEdit,
   },

   props: {
      businessManagers: Array,
      projectManagers: Array,
      projectAssociates: Array,
      hasCompanyRole: Boolean,
   },

   data() {
      return {
         accountFields: [
            {key: 'enabled', label: '', sortable: false, thStyle: {width: '3em'}},
            {key: 'firstName', sortable: true},
            {key: 'lastName', sortable: true},
            {
               key: 'email',
               sortable: true,
               formatter: (value, key, {enabled, email}) => (enabled ? email : unmungeEmail(email)),
            },
            {key: 'actions', label: '', thStyle: {width: '120px'}},
         ],
         editRole: 'BM',
      };
   },

   computed: {
      ...mapGetters({
         profile: 'profile',
         company: 'companies/currentCompany',
         staffUsers: 'users/staffUsers',
      }),

      staffTitle() {
         switch (this.editRole) {
            case 'BM':
               return 'Business Managers';
            case 'PM':
               return 'Project Managers';
            case 'PA':
               return 'Project Associates';
            default:
               return '';
         }
      },

      editUsers() {
         if (!this.hasCompanyRole) {
            return [this.profile];
         }
         let assignedUsers;
         switch (this.editRole) {
            case 'BM':
               assignedUsers = this.businessManagers;
               break;
            case 'PM':
               assignedUsers = this.projectManagers;
               break;
            case 'PA':
               assignedUsers = this.projectAssociates;
               break;
            default:
               break;
         }
         return [...this.unassignedStaff, ...assignedUsers];
      },

      hasStaff() {
         return (
            this.businessManagers.length > 0 ||
            this.projectManagers.length > 0 ||
            this.projectAssociates.length > 0
         );
      },

      unassignedStaff() {
         return this.staffUsers.filter((user) => {
            return (
               !this.company.businessManagers.includes(user.id) &&
               !this.company.projectManagers.includes(user.id) &&
               !this.company.projectAssociates.includes(user.id)
            );
         });
      },
   },
};
</script>

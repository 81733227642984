import Vue from 'vue';
import ErrorCodes from '@/helpers/errorCodes';

import {ContractorStatus} from '../models/contractor';

export const CellType = Object.freeze({
   PERC: 'PERC',
   PROJ: 'PROJ',
   ACT: 'ACT',
});

/** Checks that the input is a valid percentage. Returns the value if so, and `null` otherwise. */
const validatePercentage = (val) => {
   if (val === null) {
      return val;
   }

   val = parseInt(val, 10);
   if (val < 0 || val > 100) {
      return null;
   }
   return val;
};

const state = () => ({
   data: {},
   edit: {
      contractorId: null,
      periodId: null,
      data: {},
      dirty: false,
   },
});

const getters = {
   data: (state) => state.data,

   configuredPeriodIds: (state, getters) => {
      const cData = Object.values(getters.data);
      if (cData.length > 0) {
         return Object.keys(cData[0]);
      }
      return [];
   },

   /** Computes the status of a contractor in a period */
   contractorPeriodStatus: (state, getters) => (contractorId, periodId) => {
      const periodData = state.data[contractorId][periodId];
      const percentage = periodData.percentage;
      if (percentage !== null) {
         if (getters.projectSumEqualsTotal(contractorId, periodId)) {
            return ContractorStatus.COMPLETE;
         } else {
            return ContractorStatus.INCOMPLETE;
         }
      }
      return ContractorStatus.NOT_STARTED;
   },

   /** Computes a contractor's project sum in a period */
   projectSum: (state, getters, rootState, rootGetters) => (contractorId, periodId) => {
      const projData = state.data[contractorId][periodId].projects || {};
      const projects = rootGetters['projects/projectsInPeriod'](periodId);
      const projectSum = projects
         .map((proj) => parseInt(projData[proj.id] || 0, 10))
         .reduce((a, b) => a + b, 0);
      return projectSum;
   },

   projectSumEqualsTotal: (state, getters) => (contractorId, periodId) => {
      const percentage = getters.data[contractorId][periodId].percentage;
      if (percentage !== null) {
         const projectSum = getters.projectSum(contractorId, periodId);
         return parseInt(percentage, 10) === parseInt(projectSum, 10);
      }
      return true;
   },

   /** A mapping of contractorIds to `ContractorStatus`, aggrigated across study periods */
   contractorStatus: (state, getters) => {
      // Loop over contractors in `state.data`
      return Object.entries(state.data).reduce((obj, [contractorId, contractorData]) => {
         // For each contractor, compute their status in each period
         const statuses = Object.keys(contractorData).map((periodId) =>
            getters.contractorPeriodStatus(contractorId, periodId)
         );

         // Aggrigate the contractor's periodly statuses into an overall status
         let contractorStatus = ContractorStatus.INCOMPLETE;
         if (statuses.every((status) => status === ContractorStatus.NOT_STARTED)) {
            contractorStatus = ContractorStatus.NOT_STARTED;
         } else if (statuses.every((status) => status === ContractorStatus.COMPLETE)) {
            contractorStatus = ContractorStatus.COMPLETE;
         } else if (statuses.some((status) => status === ContractorStatus.NONE)) {
            contractorStatus = ContractorStatus.NONE;
         }

         obj[contractorId] = contractorStatus;
         return obj;
      }, {});
   },

   /** A mapping of study periodss to `ContractorStatus` */
   periodStatus: (state, getters) => {
      // const studyPeriods = rootGetters['companies/activeStudyPeriods'];
      const studyPeriodIds = getters.configuredPeriodIds;

      // Loop over study periodss
      return studyPeriodIds.reduce((obj, periodId) => {
         // In each period, compute the status of each contractor
         const contractorStatuses = Object.keys(state.data).map((contractorId) => {
            return getters.contractorPeriodStatus(contractorId, periodId);
         });

         // Aggrigate each contractor's status into an overall status for the period
         let status = ContractorStatus.INCOMPLETE;
         if (contractorStatuses.every((status) => status === ContractorStatus.NOT_STARTED)) {
            status = ContractorStatus.NOT_STARTED;
         } else if (contractorStatuses.every((status) => status === ContractorStatus.COMPLETE)) {
            status = ContractorStatus.COMPLETE;
         } else if (contractorStatuses.some((status) => status === ContractorStatus.NONE)) {
            status = ContractorStatus.NONE;
         }

         obj[periodId] = status;
         return obj;
      }, {});
   },

   /** Returns the first period in the current study in which the contractor has incomplete time data */
   firstIncompleteTimePeriod: (state, getters, rootState, rootGetters) => (contractorId) => {
      const studyPeriods = rootGetters['companies/activeStudyPeriods'];

      const period = studyPeriods.find((period) => {
         const status = getters.contractorPeriodStatus(contractorId, period.id);
         return status !== ContractorStatus.COMPLETE;
      });

      return period || null;
   },

   /** Returns the ref period for the given contractor and period */
   refPeriodId: (state, getters, rootState, rootGetters) => (contractorId, periodId) => {
      const contractor = rootGetters['contractors/contractorMap'][contractorId];
      if (!contractor) {
         return null;
      }

      const studyPeriods = rootGetters['companies/activeStudyPeriods'];
      const idx = studyPeriods.findIndex((period) => period.id === +periodId);

      if (idx === 0) {
         return rootGetters['contractors/refPeriodId'];
      } else {
         return studyPeriods[idx - 1].id;
      }
   },

   /** Reference data for the given contractor and period */
   refData: (state, getters) => (contractorId, periodId, kind, id) => {
      const refPeriodId = getters.refPeriodId(contractorId, periodId);
      if (refPeriodId === null) {
         return null;
      }

      const periodData = state.data[contractorId][refPeriodId];
      if (periodData == null) {
         return null;
      }

      switch (kind) {
         case CellType.PERC:
            return periodData.percentage;
         case CellType.PROJ:
            return id in periodData.projects ? periodData.projects[id] : null;
      }
   },

   /** The data currently being edited */
   editData: (state) => state.edit.data[state.edit.periodId],

   /** The period currently being edited */
   editPeriodId: (state) => state.edit.periodId,

   /** The ID of the contractor currently being edited */
   editContractorId: (state) => state.edit.contractorId,

   /** Is the edit data dirty? */
   editDirty: (state) => state.edit.dirty,
};

const mutations = {
   /** Clear all time data */
   clearData(state) {
      state.data = {};
   },

   /** Clear a contractor's time data */
   clearContractorData(state, {contractorId}) {
      Vue.delete(state.data, contractorId);
   },

   /**
    * Deserialize an array of ContractorPeriod objects
    * @param {Object[]]} periodsData - an array of contractor period data
    * @param {string} [contractorId] - Indicates every period data should be stored to this contractor
    *                                  e.g. if the period data includes reference data
    */
   deserializeData(state, {periods, contractorId = null}) {
      if (periods.length === 0) {
         return;
      }
      periods.forEach((periodData) => {
         const cId = contractorId === null ? periodData.contractorId : contractorId;
         if (!(cId in state.data)) {
            Vue.set(state.data, cId, {});
         }

         const projects = periodData.projects === null ? null : {...periodData.projects};
         const activities = periodData.activities === null ? null : {...periodData.activities};
         if (activities !== null) {
            for (let key in activities) {
               // Ensure values are booleans, converting null to false
               activities[key] = !!activities[key];
            }
         }

         Vue.set(state.data[cId], periodData.speriodId, {
            percentage: periodData.percentage,
            activities,
            projects,
         });
      });
   },

   updateCell(state, {contractorId, periodId, kind, id = null, value}) {
      switch (kind) {
         case CellType.PERC:
            state.data[contractorId][periodId].percentage = value;
            break;
         case CellType.PROJ:
            state.data[contractorId][periodId].projects[id] = value;
            break;
         case CellType.ACT:
            state.data[contractorId][periodId].activities[id] = value;
            break;
      }
   },

   /** Set the period being edited */
   setEditPeriodId(state, {periodId}) {
      state.edit.periodId = periodId;
      state.edit.dirty = false;
   },

   /** Update an activity value */
   setActivity(state, {activityId, value}) {
      state.edit.data[state.edit.periodId].activities[activityId] = value;
      state.edit.dirty = true;
   },

   /** Update a project value */
   setProject(state, {projectId, value}) {
      if (value === '') {
         value = null;
      }
      Vue.set(state.edit.data[state.edit.periodId].projects, projectId, value);
      state.edit.dirty = true;
   },
};

const actions = {
   /**
    * Update a single cell of contractor time
    * @param {String|Number} companyId
    * @param {String|Number} contractorId
    * @param {String|Number} periodId
    * @param {CellType} kind - Type of cell being updated. One of 'PROJ', 'ACT', 'PERC'
    * @param {String|Number} id - Project or activity. Must be `null` for total percentage
    * @param {Number} oldValue - The previous cell value
    * @param {Number} newValue - The new cell value
    */
   async updateCell(
      {commit, dispatch, rootGetters},
      {companyId, contractorId, periodId, kind, id = null, oldValue, newValue}
   ) {
      // In the event that a user has entered an invalid value, the value in memory
      // will be out of sync with the server, so we need to make sure to use an
      // oldValue of `null`, rather than the invalid value in memory.
      oldValue = validatePercentage(oldValue);
      const payload = {
         companyId,
         contractorId,
         speriodId: periodId,
         kind,
         id,
         val: newValue,
         old: oldValue,
      };

      try {
         await this._vm.$http.put('/api/contractor/time', payload);
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.STALE_VALUE) {
            commit(
               'showAlert',
               {
                  msg: 'Failed to save percentage. Another user updated this cell while you were entering data.',
                  seconds: 5,
               },
               {root: true}
            );
            const updatedValue = err.response.data.errors[0].detail.stale;
            commit('updateCell', {
               contractorId,
               periodId,
               kind,
               id,
               value: updatedValue,
            });
         }
      }
   },

   /**
    * Update contractor period time data
    * @param {String|Number} companyId
    * @param {[Object]} periods - An array of contractor period data
    * @param {String|Number} periods.contractorId
    * @param {String|Number} periods.periodId
    * @param {Number} periods.percentage - Total percentage for the period
    * @param {Object} periods.projects - A mapping of project IDs to percentages
    */
   async updateContractors({commit, dispatch, rootGetters}, {companyId, periods}) {
      // Filter out null projects
      periods = periods.map((periodData) => {
         const projects = Object.entries(periodData.projects)
            .filter((entry) => entry[1] !== null)
            .reduce((obj, entry) => {
               obj[entry[0]] = entry[1];
               return obj;
            }, {});
         return {
            ...periodData,
            speriodId: periodData.periodId,
            projects,
         };
      });

      const data = {
         companyId,
         periods,
      };

      try {
         const response = await this._vm.$http.post('/api/contractor/time', data);

         response.data.results.forEach((result) => {
            commit('deserializeData', {periods: result.periods});
         });
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },

   /** DEPRECATED: Load a contractor's time data for editing */
   loadContractorForEdit({state, rootGetters}, {contractorId, periodId = null}) {
      state.edit.data = {};
      Object.entries(state.data[contractorId]).forEach(([periodId, data]) => {
         const editData = {
            percentage: data.percentage,
            activities: {},
            projects: {},
         };

         rootGetters['activities/activities'].forEach((activity) => {
            editData.activities[activity.id] =
               activity.id in data.activities ? data.activities[activity.id] : false;
         });

         rootGetters['projects/projectsInStudy'].forEach((project) => {
            editData.projects[project.id] =
               project.id in data.projects ? data.projects[project.id] : null;
         });

         Vue.set(state.edit.data, periodId, editData);
      });

      state.edit.periodId =
         periodId !== null ? periodId : Math.min(...Object.keys(state.edit.data));
      state.edit.contractorId = contractorId;
      state.edit.dirty = false;
   },

   /** DEPRECATED: Save the contractor time data currently being edited */
   async saveContractorTime({state, getters, rootGetters, commit, dispatch}, {companyId}) {
      // Strip any projects with `null` percentages
      const projects = Object.entries(getters.editData.projects).reduce(
         (obj, [projectId, value]) => {
            if (value !== null) {
               obj[projectId] = parseInt(value);
            }
            return obj;
         },
         {}
      );

      const data = {
         companyId,
         periods: [
            {
               contractorId: state.edit.contractorId,
               periodId: parseInt(state.edit.periodId),
               percentage: getters.projectSum,
               activities: getters.editData.activities,
               projects,
            },
         ],
      };

      try {
         const response = await this._vm.$http.post('/api/contractor/time', data);
         commit('deserializeData', {periods: response.data.results[0].periods});
         state.edit.dirty = false;
      } catch (err) {
         const errCode = err.response.data.errors[0].code;
         if (errCode === ErrorCodes.PERMISSION_DENIED) {
            commit(
               'showAlert',
               {
                  msg: "You don't have permission to edit R&D time data",
                  seconds: 5,
               },
               {root: true}
            );
            dispatch('loadContractors', {companyId});
            await dispatch(
               'contractorPermissions/loadUserPermissions',
               {
                  userId: rootGetters.profile.id,
               },
               {root: true}
            );
         } else if (errCode === ErrorCodes.LOCKED) {
            commit(
               'showAlert',
               {
                  msg: 'This section has been completed. Changes could not be saved.',
                  seconds: 10,
               },
               {root: true}
            );
            dispatch(
               'companies/loadCompany',
               {
                  companyId: rootGetters['companies/currentCompany'].id,
                  force: true,
               },
               {root: true}
            );
         }
      }
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};

<template>
   <CollapseCard title="Employees" ident="employees">
      <div class="mb-3 px-3 d-flex align-items-center justify-content-between">
         <template v-if="activeStudy">
            <div>
               <StudyPeriodSelect
                  ident="employees"
                  active-study
                  v-model="periodId"
                  @change="loadEmployees"
                  emptyText="Select a study period"
               />
            </div>
            <b-button
               id="btn-configure-employees"
               variant="primary"
               size="sm"
               class="action-btn ml-2"
               @click="toEmployeeConfig"
            >
               Configure Employees
            </b-button>
         </template>
      </div>
      <b-table
         v-if="periodId"
         id="table-employees"
         class="mb-0 scrollbar"
         :fields="fields"
         :items="employees"
         sort-by="fullname"
         responsive
         sticky-header="500px"
      >
      </b-table>

      <b-alert :show="!activeStudy" variant="primary" class="m-3">
         No study selected. Select a study to configure employees.
      </b-alert>
   </CollapseCard>
</template>

<script>
import {mapGetters} from 'vuex';
import StudyPeriodSelect from '@/components/forms/StudyPeriodSelect';

export default {
   components: {
      StudyPeriodSelect,
   },

   data() {
      return {
         periodId: null,
      };
   },

   computed: {
      ...mapGetters({
         activeStudy: 'companies/activeStudy',
         activeStudyId: 'companies/activeStudyId',
         employees: 'employees/employees',
      }),

      fields() {
         let fields = [
            {key: 'fullname', label: 'Name', sortable: true, stickyColumn: true},
            {key: 'location', label: 'U.S. State', sortable: true},
            {key: 'ident', label: 'Employee ID', sortable: true},
            {key: 'title', label: 'Job Title', sortable: true},
            {key: 'department', sortable: true},
         ];

         // Only include the subsidiary col if at least one employee has one
         if (this.employees.some((employee) => employee.subsidiary)) {
            fields.push({key: 'subsidiary', sortable: true});
         }

         return fields;
      },
   },

   methods: {
      /** Load employees for a selected year */
      async loadEmployees() {
         await this.blockingRequest('employees/loadEmployees', {
            companyId: this.$companyId,
            periodId: this.periodId,
         });
      },

      /** Redirect to employee config */
      toEmployeeConfig() {
         const route = {name: 'employees-configure', params: {id: this.$companyId}};
         if (this.periodId) {
            route.query = {periodId: this.periodId};
         }
         this.$router.push(route);
      },
   },

   watch: {
      activeStudyId() {
         this.periodId = null;
      },
   },
};
</script>

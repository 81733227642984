import {clientStaffRoutes} from '../utils';

import ContractorsHome from '@/views/contractors/ContractorsHome';
import ContractorsPermissions from '@/views/contractors/ContractorsPermissions';
import ContractorTimeYears from '@/views/contractors/ContractorTimeYears';
import ContractorTimeData from '@/views/contractors/ContractorTimeData';

export default [
   ...clientStaffRoutes({
      name: 'contractors-home',
      path: '/contractors',
      component: ContractorsHome,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors'},
      ],
      meta: {
         activeStudyRedirect: 'contractors-home',
      },
   }),
   ...clientStaffRoutes({
      name: 'contractors-permissions',
      path: '/contractors/collaborators',
      component: ContractorsPermissions,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Permissions'},
      ],
      meta: {
         activeStudyRedirect: 'contractors-home',
      },
   }),
   ...clientStaffRoutes({
      name: 'contractor-time-years',
      path: '/contractors/time',
      component: ContractorTimeYears,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Study Periods'},
      ],
      meta: {
         activeStudyRedirect: 'contractors-home',
      },
   }),
   ...clientStaffRoutes({
      name: 'contractor-time-data',
      path: '/contractors/time/:periodId',
      component: ContractorTimeData,
      stage: 'contractors',
      breadCrumb: [
         {text: 'Dashboard', to: {name: 'Home'}},
         {
            text: '__COMPANYNAME__',
            to: {name: 'company-detail', params: {id: 'FILL'}},
         },
         {text: 'Contractors', to: {name: 'contractors-home-staff', params: {id: 'FILL'}}},
         {text: 'Study Periods', to: {name: 'contractor-time-years-staff', params: {id: 'FILL'}}},
         {text: 'Time'},
      ],
      meta: {
         activeStudyRedirect: 'contractors-home',
      },
   }),
];
